import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRobot,
    faUsers,
    faDatabase,
    faGamepad,
    faMicrophone,
    faDollarSign,
    faBook,
    faMap,
    faNewspaper,
} from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';

const PublisherGameFreeToolsPage = () => {
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    const bannerStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/tools.webp")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
        textShadow: '2px 2px 4px rgba(0,0,0,0.7)',
        height: '500px',
        paddingTop: '150px',
        paddingBottom: '150px',
        position: 'relative',
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Free Tools for Game Marketing | Glitch</title>
                <meta
                    name="description"
                    content="Discover free tools to promote your game effectively, including PR resources, forums, influencer workbooks, and pricing calculators for optimized marketing."
                />
                <meta
                    name="keywords"
                    content="Free game marketing tools, game PR resources, gaming forums, influencer workbook, game showcases, pricing calculator, game press, gaming database, game wikis"
                />
                <meta name="robots" content="index, follow" />
                <meta
                    property="og:title"
                    content="Free Tools for Game Marketing | Glitch"
                />
                <meta
                    property="og:description"
                    content="Browse and utilize free tools to market your game, from gaming-related publications to PR strategies and pricing optimization."
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta
                    property="og:image"
                    content="/assets/images/publishers/tools.webp"
                />
            </Helmet>
            <Header />
            <div
                className="banner text-center d-flex flex-column justify-content-center align-items-center"
                style={bannerStyle}
            >
                <br /><br /><br /><br />
                <h1 style={{ fontSize: "2.5rem" }} >Marketing Tools<br /> for Your Game</h1>
                <p className="lead">
                    Discover the best free and paid tools to promote and market your game
                    effectively.
                </p>
                
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-4">
                <p className="lead text-center">
                    Explore a wide range of resources to find free marketing tools and
                    strategies that help you promote your game efficiently.
                </p>
                <div className="row">
                    {/* Card 0 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faMap}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Marketing Guide</h5>
                                <p className="card-text">
                                    A comprehensive marketing guide specifically for gaming to help developers and publishers learn the basics of game marketing.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsGuidePage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Community Forums</h5>
                                <p className="card-text">
                                    Boost player interaction, enhance SEO, and provide support by
                                    setting up community forums. Choose from several free forum
                                    platforms.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsForumPage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Card 2 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faDatabase}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Gaming Databases</h5>
                                <p className="card-text">
                                    Make your game visible to multiple data sources by submitting
                                    it to gaming databases. Ensure your game is listed for better
                                    visibility.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsDatabasePage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Card 3 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faGamepad}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Game Showcases</h5>
                                <p className="card-text">
                                    Find the best game showcases to present your game. Our
                                    database covers both general and niche-focused showcases
                                    happening all year round.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsShowcasesPage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Card 4 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faRobot}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Influencer Workbook</h5>
                                <p className="card-text">
                                    Manage influencer outreach without paying for software by
                                    using our free Excel workbook and influencer database.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsInfluencersPage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Card 5 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faMicrophone}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Press</h5>
                                <p className="card-text">
                                    Get gaming publications, blogs, and podcasts to feature your
                                    game. Connect with various gaming media sources through our
                                    curated list.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsPRPage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Card 6 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faDollarSign}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Pricing Calculator</h5>
                                <p className="card-text">
                                    Optimize your game pricing strategy to ensure profitability.
                                    Use our pricing calculator to set the right price for your
                                    game.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsPricingPage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Card 7 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faBook}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Wiki</h5>
                                <p className="card-text">
                                    Create a detailed game wiki for your players, providing
                                    in-depth information about characters, stories, and lore.
                                    Wikis also help improve your SEO.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsWikiPage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* Card 8 */}
                    <div className="col-md-6 col-lg-4 mb-4">
                        <div className="card h-100 shadow">
                            <div className="card-body text-center">
                                <FontAwesomeIcon
                                    icon={faNewspaper}
                                    size="3x"
                                    className="text-primary mb-3"
                                />
                                <h5 className="card-title">Newsletter</h5>
                                <p className="card-text">
                                    Create a newsletter to easily and directly
                                    engage your players. Find which newsletters solutions
                                    are best for you.
                                </p>
                            </div>
                            <div className="card-footer bg-transparent border-top-0 text-center">
                                <Link
                                    to={Navigate.publishersToolsEmailPage()}
                                    className="btn btn-primary"
                                >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PublisherGameFreeToolsPage;
