import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';

const PublisherGameNewsletterPage = () => {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Newsletter Solutions for Gaming Communities | Glitch</title>
                <meta name="description" content="Explore the best newsletter platforms tailored for gaming communities. Discover tools like Glitch Newsletter, Mailchimp, Constant Contact, and ConvertKit to engage players and keep them informed about game updates and events." />
                <meta name="keywords" content="gaming newsletter, gaming community engagement, Glitch Newsletter, Mailchimp, Constant Contact, ConvertKit, gaming updates, player retention" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Top Newsletter Solutions for Gaming Communities | Glitch" />
                <meta property="og:description" content="Discover top newsletter tools for building engagement in gaming communities, including Glitch Newsletter, Mailchimp, Constant Contact, and ConvertKit." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="/assets/images/forums/community_forums.webp" />
            </Helmet>
            <Header />
            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Top Newsletter Solutions <br /><br />for Gaming Communities</h1>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-5">
                <h2>Newsletters</h2>
                <p className="lead">Newsletters are one of the most direct ways to communicate with your players. Several solutions are available to help you gain subscribers and stay in touch. Types of updates you can send to subscribers include:</p>
                <ol className="lead">
                    <li>Bug fixes and patches</li>
                    <li>New DLC content</li>
                    <li>Strategy guides and tips</li>
                    <li>Event announcements and more</li>
                </ol>
                <p className="lead">Below are some powerful solutions for managing a gaming community newsletter:</p>

                {/* Glitch Communities Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Glitch Newsletter</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/forums/glitch.jpg" className='img-fluid rounded' alt="Glitch Communities" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Glitch offers easy newsletter management specifically designed for gaming. Subscribers can choose to receive updates on a variety of topics, including influencer campaigns, community news, and more. The newsletter integrates with Glitch Communities and supports API connections, so you can directly embed it into your media channels for a unified solution.</p>
                            <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-primary btn-lg">
                                Sign Up Here <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Mailchimp Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Mailchimp</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/newsletters/mailchimp.png" className='img-fluid rounded' alt="Mailchimp" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Mailchimp is a versatile newsletter tool that helps game developers and publishers connect directly with their audience. With powerful automation, segmentation, and personalization features, Mailchimp lets you create tailored content for various player segments, such as new players or long-time fans. Use it to share game updates, special events, patch notes, and exclusive offers. Detailed analytics provide insights into engagement, helping you continuously refine your content for maximum impact.</p>
                            <Link to={"https://mailchimp.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Constant Contact Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Constant Contact</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/newsletters/contact_contact.png" className='img-fluid rounded' alt="Constant Contact" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Constant Contact is an excellent platform for creating engaging, visually appealing newsletters. Its user-friendly templates and drag-and-drop editor make it easy for gaming companies to design newsletters that keep players informed about game updates, events, and promotions. Segmentation and automation options allow for personalized communication with different player groups. Detailed tracking tools provide insights into open rates and engagement, enabling you to refine future newsletters and maintain a strong connection with your gaming community.</p>
                            <Link to={"https://www.constantcontact.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* ConvertKit Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>ConvertKit</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/newsletters/convertkit.jpg" className='img-fluid rounded' alt="ConvertKit" />
                        </div>
                        <div className='col-sm-9'>
                            <p>ConvertKit is a fantastic tool for game developers and publishers looking to create newsletters that build strong connections with their audience. Its creator-focused features make it easy to design personalized email campaigns, perfect for sharing game updates, insider news, and exclusive content. ConvertKit's segmentation and tagging system helps target newsletters to specific player groups, ensuring relevant content reaches the right audience. Automation tools facilitate seamless drip campaigns, such as onboarding series for new players or event reminders, boosting engagement and fostering community loyalty.</p>
                            <Link to={"https://www.convertkit.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/gaming_calculator.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '3rem',
    fontWeight: 'bold',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.8)',
};

export default PublisherGameNewsletterPage;
