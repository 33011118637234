import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';

const PlayTestsListPage = () => {
    const [playTests, setPlayTests] = useState([]);
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams(); // title_id
    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchData = async () => {
            try {
                // Fetch title details
                const titleResponse = await Glitch.api.Titles.view(id);
                setTitle(titleResponse.data.data);

                // Fetch play tests for this title
                const playTestsResponse = await Glitch.api.PlayTests.list(id);
                setPlayTests(playTestsResponse.data.data);
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, navigate]);

    const getStatusBadge = (status) => {
        switch (status) {
            case 'pending':
                return <span className="badge bg-warning text-dark">Pending</span>;
            case 'in_progress':
                return <span className="badge bg-primary">In Progress</span>;
            case 'completed':
                return <span className="badge bg-success">Completed</span>;
            case 'cancelled':
                return <span className="badge bg-secondary">Cancelled</span>;
            default:
                return <span className="badge bg-light text-dark">{status}</span>;
        }
    };

    return (
        <>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingPublishersListPage() },
                        { name: title?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                        { name: 'Play Tests', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Play Tests for {title?.name || 'Loading...'}</h2>
                        <p className="lead">Manage play tests for this game title.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="d-flex justify-content-start mb-3">
                            <Link
                                to={Navigate.playtestingPublishersInvitePage(id)}
                                className="btn btn-primary mr-2 me-2"
                            >
                                <i className="fas fa-user-plus me-2"></i> Invite Play Testers
                            </Link>
                            <Link
                                to={Navigate.playtestingPublishersUpdatePage(id)}
                                className="btn btn-primary mr-2 me-2"
                            >
                                <i className="fas fa-pencil-alt me-2"></i> Edit Game Title
                            </Link>
                            <Link
                                to={Navigate.playtestingPublishersPreviewPage(id)}
                                className="btn btn-primary"
                            >
                                <i className="fas fa-search me-2"></i> Preview Game Title
                            </Link>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Tester</th>
                                    <th scope="col">Requested By</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Invite Accepted</th>
                                    <th scope="col">Feedback Submitted</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {playTests.length > 0 ? (
                                    playTests.map((playTest) => (
                                        <tr key={playTest.id}>
                                            <td>
                                                {playTest.user && (
                                                    <>
                                                        <img
                                                            src={playTest.user.avatar || getInfluencerImage(playTest?.user?.influencer)}
                                                            alt={playTest.user.display_name}
                                                            className="rounded-circle me-2"
                                                            style={{ width: '40px', height: '40px' }}
                                                        />
                                                        {playTest.user.display_name || playTest.user.username}
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {playTest.requested_by_user ? (
                                                    <span className="badge bg-info">Tester</span>
                                                ) : (
                                                    <span className="badge bg-secondary">Developer</span>
                                                )}
                                            </td>
                                            <td>{getStatusBadge(playTest.status)}</td>
                                            <td>
                                                {playTest.invite_accepted ? (
                                                    <i className="fas fa-check text-success"></i>
                                                ) : (
                                                    <i className="fas fa-times text-danger"></i>
                                                )}
                                            </td>
                                            <td>
                                                {playTest.feedback_submitted_at ? (
                                                    new Date(playTest.feedback_submitted_at).toLocaleString()
                                                ) : (
                                                    '-'
                                                )}
                                            </td>
                                            <td>
                                                <Link
                                                    to={Navigate.playtestingPublishersMessagesPage(id, playTest.id)}
                                                    className="btn btn-sm btn-outline-primary me-2"
                                                >
                                                    <i className="fas fa-envelope"></i> Message
                                                </Link>
                                                {playTest.requested_by_user && playTest.status == 'pending' ?
                                                    <>
                                                        <Link
                                                            to={Navigate.playtestingPublishersViewReviewPage(id, playTest.id)}
                                                            className="btn btn-sm btn-outline-primary me-2"
                                                        >
                                                            <i className="fas fa-search"></i> Review Request
                                                        </Link>
                                                    </>
                                                    : <>
                                                        <Link
                                                            to={Navigate.playtestingPublishersViewTestPage(id, playTest.id)}
                                                            className="btn btn-sm btn-outline-primary me-2"
                                                        >
                                                            <i className="fas fa-eye"></i> Responses
                                                        </Link>
                                                        <Link
                                                            to={Navigate.playtestingPublishersViewQuestionsPage(id, playTest.id)}
                                                            className="btn btn-sm btn-outline-secondary me-2"
                                                        >
                                                            <i className="fas fa-edit"></i> Questions
                                                        </Link>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6" className="text-center">
                                            No play tests found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </>
    );
};

export default PlayTestsListPage;
