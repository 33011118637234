import React, { Fragment, useEffect, useState } from 'react';
import CampaignBasicInfoForm from '../../component/section/campaigns/campaign_basic';
import CampaignSpendingLimitsForm from '../../component/section/campaigns/campaign_spending';
import CampaignPaymentForm from '../../component/section/campaigns/campaign_payment';
import CampaignDateForm from '../../component/section/campaigns/campaign_date';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import GameTitleForm from '../../component/section/titles/title_form';
import timeouts from '../../../../constants/timeouts';
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../component/alerts/Danger';
import { useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import CampaignInfluencerForm from '../../component/section/campaigns/campaign_influencer';
import { useParams } from 'react-router-dom';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import CampaignManagementForm from '../../component/section/campaigns/campaign_management';
import CampaignTargetingForm from '../../component/section/campaigns/campaign_targeting';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { Accordion, Form, Tab, Nav } from 'react-bootstrap';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';

function CampaignUpdatePage() {
    const { id } = useParams();
    const [campaignData, setCampaignData] = useState({});
    const [gameTitle, setGameTitle] = useState({});
    const [errors, setErrors] = useState({});
    const [titleErrors, setTitleErrors] = useState({});
    const [communities, setCommunities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [genders, setGenders] = useState([]);
    const [types, setTypes] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    
    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Communities.list({ roles: [Glitch.constants.Roles.ADMINISTRATOR, Glitch.constants.Roles.SUPER_ADMINISTRATOR, Glitch.constants.Roles.MODERATOR], order_by: 'name' }).then(response => {
            setCommunities(response.data.data);
        }).catch(error => { });

        Glitch.api.Campaigns.view(id).then(response => {
            setCampaignData(response.data.data);
            Glitch.api.Titles.view(response.data.data.title_id).then(response => {
                setGameTitle(response.data.data);
            }).catch(error => { });
            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });
        }).catch(error => { });

        Glitch.api.Users.me().then(response => { }).catch(error => { });
    }, []);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCampaignData(prevInvite => ({
            ...prevInvite,
            [name]: checked,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!campaignData.title_id) {
            Glitch.api.Titles.create(gameTitle).then(response => {
                setCampaignData({ ...campaignData, ['title_id']: response.data.data.id });
                campaignData.title_id = response.data.data.id;
                setTimeout(() => {
                    handleSubmit(e);
                }, 1500);
            }).catch(error => {
                let jsonErrors = error?.response?.data;
                if (jsonErrors) {
                    setTitleErrors(jsonErrors);
                    setTimeout(() => {
                        setTitleErrors({});
                    }, timeouts.error_message_timeout);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            Glitch.api.Titles.update(campaignData.title_id, gameTitle).then(response => { }).catch(error => {
                let jsonErrors = error?.response?.data;
                if (jsonErrors) {
                    setTitleErrors(jsonErrors);
                    setTimeout(() => {
                        setTitleErrors({});
                    }, timeouts.error_message_timeout);
                }
            });
            Glitch.api.Campaigns.update(id, campaignData).then(response => {
                navigate(Navigate.campaignsViewPage(response.data.data.id));
            }).catch(error => {
                let jsonErrors = error?.response?.data;
                if (jsonErrors) {
                    setErrors(jsonErrors);
                    setTimeout(() => {
                        setErrors({});
                    }, timeouts.error_message_timeout);
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    const handleGameTitleUpdate = (updatedFields) => {
        setGameTitle({ ...gameTitle, ...updatedFields });
    };
    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />
                <div className="container mt-4 mb-5">
                    <Breadcrumbs items={[
                        { name: 'Campaigns', link: Navigate.campaignsPage() },
                        { name: campaignData.name, link: Navigate.campaignsViewPage(campaignData.id) },
                        { name: 'Update', link: Navigate.campaignsUpdatePage(campaignData.id) }
                    ]} />
                    <h2 ><i className="fas fa-edit"></i> Update Campaign</h2>
                    <p className="lead">Use the form below to update an influencer campaign for your game.</p>

                    <div className="container mt-5">
                        <CampaignNavbar campaignId={id} />
                    </div>

                    {Object.keys(campaignData).length > 0 && (

                    <Tab.Container defaultActiveKey="basic-info">
                        <Nav variant="tabs" className="mt-4 mb-3 justify-content-center">
                            <Nav.Item>
                                <Nav.Link eventKey="basic-info"><i className="fas fa-info-circle"></i> Basic Info</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="game-title"><i className="fas fa-gamepad"></i> {campaignData.promotion_type==1 ? <>Event Info</> : <>Game Title</>}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="spending-limits"><i className="fas fa-dollar-sign"></i> Budget</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="dates"><i className="fas fa-calendar-alt"></i> Dates</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="targeting"><i className="fas fa-bullseye"></i> Targeting</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="influencers"><i className="fas fa-users"></i> Influencers</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="management"><i className="fas fa-cogs"></i> Management</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="disable-social"><i className="fas fa-clipboard-list"></i> Rate Cards</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="basic-info">
                                <CampaignBasicInfoForm campaignData={campaignData} setCampaignData={setCampaignData} communities={communities} errors={errors} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="game-title">
                                <GameTitleForm gameTitle={gameTitle} campaignData={campaignData} setGameTitle={setGameTitle} onUpdate={handleGameTitleUpdate} errors={titleErrors} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="spending-limits">
                                <CampaignSpendingLimitsForm campaignData={campaignData} setCampaignData={setCampaignData} errors={errors} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="dates">
                                <CampaignDateForm campaignData={campaignData} setCampaignData={setCampaignData} errors={errors} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="targeting">
                                <CampaignTargetingForm campaignData={campaignData} setCampaignData={setCampaignData} setCountries={setCountries} setGenders={setGenders} setTypes={setTypes} errors={errors} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="influencers">
                                <CampaignInfluencerForm campaignData={campaignData} setCampaignData={setCampaignData} errors={errors} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="management">
                                <CampaignManagementForm campaignData={campaignData} setCampaignData={setCampaignData} errors={errors} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="disable-social">
                                <div className="card">
                                    <div className="card-header bg-secondary">
                                        <h3><i className="fas fa-info-circle mr-2"></i> Enabled Social Platforms & Rate Cards</h3>
                                    </div>
                                    <div className="card-body">
                                        <p>By default, influencers can create content for all the listed social platforms. If you want to limit where they can promote your game by disabling certain platforms, select the platforms you want to disable below.</p>
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable TikTok"
                                            name="disable_tiktok"
                                            checked={campaignData.disable_tiktok}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable Instagram"
                                            name="disable_instagram"
                                            checked={campaignData.disable_instagram}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable Twitter"
                                            name="disable_twitter"
                                            checked={campaignData.disable_twitter}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable Twitch"
                                            name="disable_twitch"
                                            checked={campaignData.disable_twitch}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable YouTube"
                                            name="disable_youtube"
                                            checked={campaignData.disable_youtube}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable Reddit"
                                            name="disable_reddit"
                                            checked={campaignData.disable_reddit}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable Facebook"
                                            name="disable_facebook"
                                            checked={campaignData.disable_facebook}
                                            onChange={handleCheckboxChange}
                                        />
                                        <Form.Check
                                            type="checkbox"
                                            label="Disable Kick"
                                            name="disable_kick"
                                            checked={campaignData.disable_kick}
                                            onChange={handleCheckboxChange}
                                        />
                                        <hr />
                                        <Accordion defaultActiveKey="0" className="custom-accordion">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Rate Card - General</Accordion.Header>
                                                <Accordion.Body>
                                                    <CampaignPaymentForm title="Rate Card - General" campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            {!campaignData.disable_tiktok && (
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Rate Card For TikTok</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For TikTok" social={"tiktok"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {!campaignData.disable_twitch && (
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Rate Card For Twitch</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For Twitch" social={"twitch"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {!campaignData.disable_reddit && (
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Rate Card For Reddit</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For Reddit" social={"reddit"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {!campaignData.disable_facebook && (
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>Rate Card For Facebook</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For Facebook" social={"facebook"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {!campaignData.disable_youtube && (
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header>Rate Card For YouTube</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For YouTube" social={"youtube"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {!campaignData.disable_twitter && (
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header>Rate Card For Twitter</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For Twitter" social={"twitter"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {!campaignData.disable_instagram && (
                                                <Accordion.Item eventKey="7">
                                                    <Accordion.Header>Rate Card For Instagram</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For Instagram" social={"instagram"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                            {!campaignData.disable_kick && (
                                                <Accordion.Item eventKey="8">
                                                    <Accordion.Header>Rate Card For Kick</Accordion.Header>
                                                    <Accordion.Body>
                                                        <CampaignPaymentForm title="Rate Card For Kick" social={"kick"} campaignData={campaignData} setPaymentData={setCampaignData} errors={errors} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )}
                                        </Accordion>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    )}
                    <div className="mt-2 text-center">
                        {(Object.keys(errors).length > 0 || Object.keys(titleErrors).length > 0) && <Danger message={"There are error(s) in creating the campaign. Please check the form above."} />}
                    </div>
                    <div className="text-center mt-2">
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary btn-lg">{isLoading && <Loading />} Update Campaign</button>
                    </div>
                </div>
                <br /><br /><br />
            </Fragment>
        </>
    );
}

export default CampaignUpdatePage;
