import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';

const NewsletterImportPage = () => {
    const [newsletter, setNewsletter] = useState({});
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [errors, setErrors] = useState(null);
    const navigate = useNavigate();
    const { id, community_id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
            return;
        }

        // Fetch newsletter details
        Glitch.api.Communities.viewNewsletter(community_id, id)
            .then(response => {
                setNewsletter(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching newsletter:', error);
                setIsLoading(false);
            });
    }, [id, navigate]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileType = selectedFile.type;
            if (fileType === 'text/csv' || fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                setFile(selectedFile);
                setErrors(null);
            } else {
                setErrors("Please upload a valid CSV or XLS file.");
            }
        }
    };

    const sendImportFile = () => {
        if (!file) {
            setErrors("Please select a CSV or XLS file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        
        setUploading(true);
        setErrors(null);

        Glitch.api.Communities.importNewsletterSubscribers(community_id, id, formData)
            .then(response => {
                setUploading(false);
                alert("Subscribers imported successfully!");
                navigate(Navigate.newsletterViewPage(id, community_id));
            })
            .catch(error => {
                setUploading(false);
                const errorMessage = error?.response?.data?.error || "An error occurred during the import. Please try again.";
                setErrors(errorMessage);
            });
    };

    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: 'Add Subscribers', link: Navigate.newsletterImportPage(newsletter?.id, community_id) },
                    { name: 'Import Subscribers', link: Navigate.newsletterImportPage() }
                ]} />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">
                            Import Subscribers to {newsletter?.name || 'Loading...'}
                        </h2>
                        <p className="lead">
                            Upload a CSV or XLS file to quickly add subscribers to your email list.
                        </p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="card shadow-sm border-0">
                        <div className="card-body text-center">
                            <h5 className="card-title">Upload Subscriber File</h5>
                            <p className="card-text text-muted mb-4">
                                Make sure your file includes the columns: <strong>first_name</strong>, <strong>last_name</strong>, and <strong>email</strong>. Subscribers will be automatically opted into all categories.
                            </p>

                            <div className="mb-4">
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleFileChange}
                                    accept=".csv, .xls, .xlsx"
                                    disabled={uploading}
                                />
                            </div>

                            {errors && <div className="alert alert-danger">{errors}</div>}

                            <button
                                className="btn btn-primary"
                                onClick={sendImportFile}
                                disabled={!file || uploading}
                            >
                                {uploading ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Importing...
                                    </>
                                ) : (
                                    <>
                                        <i className="fas fa-file-import me-2"></i> Import Subscribers
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default NewsletterImportPage;
