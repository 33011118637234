import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet';

const PublisherTutorialInviteNotesPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet>
                <title>How to Take Notes on Influencers | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to take and manage notes on influencers during your campaign process. This tutorial covers utilizing Glitch's notes feature to keep track of all interactions."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Take Notes on Influencers | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial explains how to use the notes feature in Glitch to keep detailed records of your interactions with influencers, ensuring you stay organized during campaigns."
                />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Take Notes on Influencers | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to take and manage notes on influencers during your campaign process. This tutorial covers utilizing Glitch's notes feature to keep track of all interactions."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Take Notes on Influencers</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/KkA6jz40qBI"
                                title="How to Take Notes on Influencers"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            Hello! In this tutorial, we’ll guide you on how to effectively take notes on influencers during your campaign process. As you engage in negotiations, email exchanges, and meetings with multiple influencers, it’s easy to lose track of important details. That’s where the notes feature in Glitch comes in handy.
                        </p>

                        <h4>Why Taking Notes is Important</h4>
                        <p>
                            During your interactions with influencers, you may discuss various aspects of the campaign, and it’s crucial to document these details. Keeping detailed notes helps you stay organized and ensures you have a record of what was discussed, which can be valuable when making decisions later on.
                        </p>

                        <h4>How to Use the Notes Feature</h4>
                        <p>
                            To start taking notes on influencers, navigate to the 'Invites' section within your Glitch dashboard. Next to each influencer, you’ll see a “Notes” button. Clicking on this button will open a section where you can view past notes and add new ones.
                        </p>

                        <p>
                            You can also access the notes section by clicking into an influencer's profile. Scroll down, and you’ll find a dedicated area for notes. Here, you can review all previous notes and add new ones as needed.
                        </p>

                        <h4>Best Practices for Taking Notes</h4>
                        <p>
                            We recommend taking notes after every email exchange, conversation, and meeting. This habit will help you maintain a comprehensive record of all interactions, making it easier to refer back to important points when negotiating or finalizing deals with influencers.
                        </p>

                        <p>
                            Utilizing the notes feature is a critical part of managing your influencer relationships effectively. By keeping detailed notes, you’ll be better equipped to make informed decisions and manage your campaigns more efficiently.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialInviteNotesPage;
