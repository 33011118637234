import React from 'react';

const MessageMessagesList = ({ messages, currentUserId }) => {
  return (
    <div className="message-list">
      {messages.map((msg) => {
        const isCurrentUser = msg.user_id === currentUserId;
        const user = msg.user || {};
        return (
          <div key={msg.id} className={`d-flex mb-3 ${isCurrentUser ? 'justify-content-end' : 'justify-content-start'}`}>
            {!isCurrentUser && (
              <img 
                src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
                alt={user.username} 
                className="rounded-circle me-2" 
                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              />
            )}
            <div className={`message-bubble p-3 ${isCurrentUser ? 'bg-primary text-white' : 'bg-light text-dark'}`} style={{ borderRadius: '15px', width: '100%' }}>
              <p className="mb-1"><strong>{user.display_name || user.username || 'Unknown User'}</strong></p>
              <p className="mb-1">{msg.message}</p>
              <small className="text-muted">{new Date(msg.created_at).toLocaleString()}</small>
            </div>
            {isCurrentUser && (
              <img 
                src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
                alt={user.username} 
                className="rounded-circle ms-2" 
                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MessageMessagesList;
