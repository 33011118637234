import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link, useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import PublisherHeader from '../../component/layout/publisherheader';

const SchedulerListPage = () => {
    const [schedulers, setSchedulers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        const fetchSchedulers = async () => {
            try {
                Glitch.api.Scheduler.listSchedules({ page: currentPage })
                    .then((response) => {
                        setSchedulers(response.data.data);
                        setTotalPages(response.data.last_page);
                    })
                    .catch((error) => {
                        console.error('Error fetching schedulers', error);
                    });
            } catch (error) {
                console.error('Error fetching schedulers', error);
            }
        };

        fetchSchedulers();
    }, [currentPage, navigate]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <div className="pageheader-thumb mb-4"></div>
                        <h2 className="pageheader-title">Social Schedulers</h2>
                        <p className="lead">
                            Manage your social schedulers that auto-promote updates about your game on social media.
                        </p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-2">
                <div className="section-wrapper text-end">
                    <Link className="btn btn-success" to={Navigate.schedulerCreatePage()}>
                        <i className="fas fa-plus-circle"></i> Create An Auto Scheduler
                    </Link>
                </div>
            </div>

            <div className="container mb-5">
                {schedulers.length > 0 ? (
                    <div className="row">
                        {schedulers.map((scheduler) => (
                            <div key={scheduler.id} className="col-md-12 mb-4">
                                <div className="card h-100 shadow-sm">
                                    <div className="row g-0 h-100">
                                        <div className="col-md-4">
                                            <img
                                                src={
                                                    scheduler?.title?.image_main
                                                        ? scheduler.title.image_main
                                                        : '/assets/images/titles/no_image_2.png'
                                                }
                                                alt="Game thumbnail"
                                                className="img-fluid h-100"
                                                style={{ objectFit: 'cover' }}
                                            />
                                        </div>
                                        <div className="col-md-8">
                                            <div className="card-body d-flex flex-column">
                                                <h5 className="card-title">{scheduler.name}</h5>
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    {scheduler?.title?.name}
                                                </h6>
                                                <p
                                                    className="card-text flex-grow-1"
                                                    dangerouslySetInnerHTML={{ __html: scheduler?.title?.short_description }}
                                                ></p>
                                                <div className="mt-auto d-flex justify-content-between">
                                                    <div>
                                                        <Link
                                                            className="btn btn-primary btn-sm me-2"
                                                            to={Navigate.schedulerViewPage(scheduler.id)}
                                                        >
                                                            <i className="fas fa-cogs"></i> Manage
                                                        </Link>
                                                        <Link
                                                            className="btn btn-outline-secondary btn-sm"
                                                            to={Navigate.schedulerUpdatePage(scheduler.id)}
                                                        >
                                                            <i className="fas fa-edit"></i> Edit
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <span className="badge bg-info text-dark">
                                                            <i className="fas fa-calendar-alt"></i> Posts per week:{' '}
                                                            {scheduler.posts_per_week}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="card card-body bg-light text-center text-black">
                        <p className="lead">No Schedulers Have Been Created</p>
                        <div className="d-flex justify-content-center">
                            <div className="col-auto">
                                <Link to={Navigate.schedulerCreatePage()} className="btn btn-primary">
                                    Create Your First Scheduler
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                {totalPages > 1 && (
                    <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(page)}>
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </div>
        </Fragment>
    );
};

export default SchedulerListPage;
