import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Fragment } from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link, useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import axios from 'axios';
import Switch from "react-switch";
import PublisherHeader from '../../component/layout/publisherheader';

const GameShowsListPage = () => {
    const [gameShows, setGameShows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        const fetchGameShows = async () => {
            try {
                let roles = Glitch.constants.Roles.ADMINISTRATOR + ',' + Glitch.constants.Roles.SUPER_ADMINISTRATOR + ',' + Glitch.constants.Roles.MODERATOR;

                Glitch.api.GameShows.list({ page: currentPage, user_id: Glitch.util.Session.getID(), roles: roles }).then((response) => {
                    setGameShows(response.data.data);
                    setTotalPages(response.data.last_page);
                }).catch(error => {
                    console.error('Error fetching game shows', error);
                });
            } catch (error) {
                console.error('Error fetching game shows', error);
            }
        };

        fetchGameShows();
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const toggleGameShowStatus = async (gameShowId, isLive) => {
        Glitch.api.GameShows.update(gameShowId, { is_live: isLive }).then(response => {
            let updatedGameShow = response.data.data;
            setGameShows(gameShows.map(gameShow => {
                if (gameShow.id === gameShowId) {
                    return { ...gameShow, is_live: updatedGameShow.is_live };
                }
                return gameShow;
            }));
        }).catch(error => {
            console.error('Error updating game show status', error);
        });
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center pt-3">
                            <h2 className="pageheader-title">Game Showcases</h2>
                            <p className="lead">Manage your game showcases, view details, or edit them as needed.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5 mb-2">
                    <div className="section-wrapper">
                        <Link className="btn btn-success" to={Navigate.gameshowPublisherCreatePage()}>
                            <i className="fas fa-plus-circle"></i> Create A Game Showcase
                        </Link>
                    </div>
                </div>

                <div className="container">
                    {gameShows.length > 0 ? (
                        <div className="d-flex flex-column">
                            {gameShows.map(gameShow => (
                                <div key={gameShow.id} className="position-relative mb-3">
                                    <div className="card">
                                        <div className="card-body pb-0">
                                            <h5 className="card-title">{gameShow.name}</h5>
                                            <p className="card-text">
                                                <span dangerouslySetInnerHTML={createMarkup(gameShow.description)} />
                                            </p>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex align-items-start my-3 text-black">
                                                        {/* Image Section */}
                                                        <img src={gameShow.logo || '/assets/images/gameshows/nologo.webp'} alt="Game show logo" className="img-fluid" style={{ width: '180px', height: '100px', objectFit: 'cover', marginRight: '20px' }} />

                                                        {/* Text Section */}
                                                        <div className="text-black">
                                                            <h5 className="mb-1 text-black">{gameShow?.name}</h5>
                                                            <p className="text-muted mb-0"><strong>Type:</strong> {capitalizeFirstLetter(gameShow.type)}</p>
                                                            <p className="mb-0"><strong>Start Date:</strong> {gameShow.start_date}</p>
                                                            <p><strong>Location:</strong> {gameShow.location || 'Virtual'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <p><strong>Is Live:</strong> {gameShow.is_live ? 'Yes' : 'No'}</p>
                                                    <p><strong>Game Registration:</strong> {gameShow.allow_game_registration ? 'Allowed' : 'Not Allowed'}</p>
                                                    <p><strong>User Registration:</strong> {gameShow.allow_user_registration ? 'Allowed' : 'Not Allowed'}</p>
                                                </div>
                                                <div className="col-md-3">
                                                    <p><strong>Community:</strong> {gameShow?.community?.name}</p>
                                                    <p><strong>Timezone:</strong> {gameShow?.timezone}</p>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-wrap justify-content-start mt-4">
                                                <div className="me-2 mb-2">
                                                    <Link className="btn btn-primary btn-sm" to={Navigate.gameshowPublisherViewPage(gameShow.id)}>
                                                        <i className="fas fa-eye"></i> View
                                                    </Link>
                                                </div>
                                                <div className="me-2 mb-2">
                                                    <Link className="btn btn-primary btn-sm" to={Navigate.gameshowPublisherUpdatePage(gameShow.id)}>
                                                        <i className="fas fa-edit"></i> Edit
                                                    </Link>
                                                </div>
                                                <div className="me-2 mb-2">
                                                    <Link className="btn btn-primary btn-sm" to={Navigate.gameshowPublisherPromotePage(gameShow.id)}>
                                                        <i className="fas fa-tv"></i> Preview & Promote
                                                    </Link>
                                                </div>

                                                <div className="ms-2 d-flex align-items-center">
                                                    <Switch
                                                        checked={gameShow.is_live}
                                                        onChange={() => toggleGameShowStatus(gameShow.id, !gameShow.is_live)}
                                                        className="text-right"
                                                    /> &nbsp;&nbsp;&nbsp;{gameShow.is_live ? 'Live' : 'Not Live'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="card card-body bg-dark text-center">
                            <p className="lead">No Game Shows Have Been Created</p>
                            <div className="d-flex justify-content-center">
                                <div className="col-auto">
                                    <Link to={Navigate.gameshowPublisherCreatePage()} className="btn btn-primary">
                                        Create Your First Game Show
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}

                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(page)}>
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </Fragment>
        </>
    );

    const handleDelete = async (gameShowId) => {
        try {
            await Glitch.api.GameShows.delete(gameShowId);
            setGameShows(gameShows.filter(gameShow => gameShow.id !== gameShowId));
        } catch (error) {
            console.error('Error deleting game show', error);
        }
    };
};

export default GameShowsListPage;
