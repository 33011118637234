// src/pages/scheduler/scheduler_create_update_page.jsx

import React, { useEffect, useState } from 'react';
import TitleUpdateForm from '../../component/section/updates/title_update_form';
import { useNavigate, useParams } from 'react-router-dom';
import Glitch from 'glitch-javascript-sdk';
import Loading from '../../component/alerts/Loading';
import timeouts from '../../../../constants/timeouts';
import Navigate from '../../../../util/Navigate';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Danger from '../../component/alerts/Danger';

const SchedulerCreateUpdatePage = () => {
    const { id } = useParams();
    const [updateData, setUpdateData] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [schedulerData, setSchedulerData] = useState({});

    const navigate = useNavigate();

    useEffect(() => {
        const fetchSchedulerData = async () => {
            try {
                const response = await Glitch.api.Scheduler.getSchedule(id);
                setSchedulerData(response.data.data);
            } catch (error) {
                console.error('Error fetching scheduler data:', error);
                // Handle error, maybe redirect or show message
            }
        };
        fetchSchedulerData();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await Glitch.api.Scheduler.createUpdate(id, updateData);
            const newUpdate = response.data.data;
            // Redirect to the update list or detail page
            navigate(Navigate.schedulerViewPage(id));
        } catch (error) {
            console.error('Error creating update:', error);
            let jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">Create Update</h2>
                        <p className="lead">Create a new update for your scheduler.</p>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <form onSubmit={handleSubmit}>
                    <TitleUpdateForm
                        id={id}
                        updateData={updateData}
                        setUpdateData={setUpdateData}
                        errors={errors}
                        isEditMode={false}
                        schedulerData={schedulerData}
                    />

                    <div className="mt-4 text-center">
                        {Object.keys(errors).length > 0 && Object.keys(errors).map((errorKey) => (
                            errors[errorKey].map((message, index) => (
                                <Danger key={`${errorKey}-${index}`} message={message} />
                            ))
                        ))}
                    </div>

                    <div className="text-center">
                        <button type="submit" className="btn btn-primary btn-lg">
                            {isLoading ? <Loading /> : 'Create Update'}
                        </button>
                    </div>
                </form>
            </div>

            <Footer />
        </>
    );
};

export default SchedulerCreateUpdatePage;
