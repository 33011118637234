import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  faUsers,
  faChartLine,
  faHandshake,
  faTools,
  faGamepad,
  faDollarSign,
  faThumbsUp,
  faRobot,
  faDatabase,
  faMoneyBill,
  faGift,
  faPlayCircle,
  faVideo,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTwitch,
  faYoutube,
  faTiktok,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function PublishersContentPage() {

  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amplify Your Game's Reach with Live Streaming and Short-Form Content | Glitch</title>
        <meta name="description" content="Boost your game's visibility and sales by leveraging both live streaming and short-form video content. Discover how Glitch can help you maximize engagement and conversions." />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Amplify Your Game's Reach with Live Streaming and Short-Form Content | Glitch" />
        <meta property="og:description" content="Boost your game's visibility and sales by leveraging both live streaming and short-form video content. Discover how Glitch can help you maximize engagement and conversions." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/streaming_shortform_banner.png" />
      </Helmet>
      <Header />
      <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
        <h1 style={{ fontSize: "2.5rem" }} ><br /><br />Use Live Streams and Short-Form Content <br /><br />To Maximize Growth Opportunities</h1>
      </section>


      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-5">Why Combine Live Streaming and Short-Form Content?</h2>
          <div className="row text-center">
            <div className="col-md-6 mb-4">
              <FontAwesomeIcon icon={faPlayCircle} size="3x" className="text-primary mb-3" />
              <h4>Deep Engagement with Live Streaming</h4>
              <p>Live streaming offers real-time interaction, fostering community and loyalty. Platforms like <strong>Twitch</strong> and <strong>YouTube Live</strong> allow influencers to showcase your game in-depth, engaging viewers for extended periods.</p>
            </div>
            <div className="col-md-6 mb-4">
              <FontAwesomeIcon icon={faVideo} size="3x" className="text-primary mb-3" />
              <h4>High Conversions with Short-Form Content</h4>
              <p>Short-form videos on platforms like <strong>TikTok</strong> and <strong>Instagram Reels</strong> capture attention quickly, leading to higher conversion rates. They're perfect for showcasing highlights, updates, and engaging content snippets.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light text-black">
        <div className="container">
          <h2 className="text-center mb-5 text-black">Maximize Sales and Engagement</h2>
          <div className="row align-items-center mb-5">
            <div className="col-lg-6">
              <div className="ratio ratio-16x9">
                <video className="w-100 rounded shadow" src="/assets/videos/publishers/live_streaming_game.mp4" autoPlay loop muted playsInline></video>
              </div>
            </div>
            <div className="col-lg-6">
              <h3 className="mb-4 text-black">The Power of Live Streaming</h3>
              <p className="lead">Around <strong>50% of TikTok users</strong> have purchased something after watching a TikTok Live. Live streams provide an immersive experience, allowing influencers to interact with audiences and showcase your game in real-time.</p>
              <ul>
                <li>Extended viewer engagement</li>
                <li>Real-time audience interaction</li>
                <li>Builds community and loyalty</li>
              </ul>
              <Link className="btn btn-primary mt-3" to={Navigate.publishersOnboardingStep1Page()}>Start a Live Campaign</Link>
            </div>
          </div>

          <div className="row align-items-center mb-5">
            <div className="col-lg-6 order-lg-2">
              <img src="/assets/images/publishers/fornite_short.jpg" alt="Short-Form Content" className="img-fluid rounded shadow mb-3" />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h3 className="mb-4 text-black">Impact of Short-Form Content</h3>
              <p className="lead">Short-form videos have a <strong>34% higher conversion rate</strong> than traditional video ads. With platforms like TikTok and Instagram Reels, influencers can create engaging snippets that drive quick conversions.</p>
              <ul>
                <li>High viewer retention</li>
                <li>Quick, impactful messaging</li>
                <li>Ideal for product discovery</li>
              </ul>
              <Link className="btn btn-primary mt-3" to={Navigate.publishersOnboardingStep1Page()}>Launch Short-Form Campaign</Link>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 text-white" style={{ backgroundColor: '#343a40' }}>
        <div className="container">
          <h2 className="text-center mb-5">Combined Strategy for Maximum Impact</h2>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h3 className="mb-4">Engage Wide and Deep</h3>
              <p className="lead">By integrating both live streaming and short-form content into your marketing strategy, you can reach a broader audience while also fostering deeper engagement.</p>
              <ul>
                <li>Broaden reach with quick content</li>
                <li>Deepen relationships through live interaction</li>
                <li>Drive both immediate and long-term sales</li>
              </ul>
            </div>
            <div className="col-lg-6 text-center">
              <img src="/assets/images/publishers/stream_revenue.png" alt="Combined Strategy" className="img-fluid rounded shadow mb-3" />
            </div>
          </div>
          <div className="text-center mt-4">
            <Link className="btn btn-lg btn-danger" to={Navigate.publishersOnboardingStep1Page()}>Get Started with Glitch</Link>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-5">Automated Tracking and Reporting</h2>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <img src="/assets/images/publishers/performance_based.gif" alt="Automated Tracking" className="img-fluid rounded shadow mb-3" />
            </div>
            <div className="col-lg-6">
              <p className="lead">Glitch provides detailed reports on all content created, whether live streams or short-form videos. Monitor performance, engagement metrics, and conversions all in one place.</p>
              <ul>
                <li>Real-time analytics</li>
                <li>Content performance insights</li>
                <li>Option to dispute non-compliant content</li>
              </ul>
              <Link className="btn btn-primary mt-3" to={Navigate.publishersOnboardingStep1Page()}>Start Tracking Now</Link>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-center my-5">
        <h4>Explore More Features</h4>
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <Link to={Navigate.publishersAIPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faRobot} className="me-1" /> AI
          </Link>
          <Link to={Navigate.publishersCRMPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faUsers} className="me-1" /> CRM
          </Link>
          <Link to={Navigate.publishersDatabasePage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faDatabase} className="me-1" /> Database
          </Link>
          <Link to={Navigate.publishersBenefitsPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faThumbsUp} className="me-1" /> Performance-Based
          </Link>
          <Link to={Navigate.publishersOptimizationPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faTools} className="me-1" /> Optimization
          </Link>
          <Link to={Navigate.publishersContentPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faGamepad} className="me-1" /> Diverse Content
          </Link>
          <Link to={Navigate.publishersAcquisitionPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faDollarSign} className="me-1" /> Acquisition Costs
          </Link>
          <Link to={Navigate.publishersPlayTesterPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faVial} className="me-1" /> Play Testers
          </Link>
          <Link to={Navigate.publishersToolsPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faGift} className="me-1" /> Free Tools
          </Link>
          <Link to={Navigate.publishersPricingPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> Pricing
          </Link>
        </div>
      </div>

      <section className="py-5 bg-primary text-white">
        <div className="container text-center">
          <h2 className="mb-4">Revolutionize Your Game Marketing with Glitch</h2>
          <p className="lead">Experience the power of combined influencer strategies to expand your game’s reach and boost sales.</p>
          <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-lg btn-light text-primary">Get Started Now</Link>
        </div>
      </section>

      <Footer />
    </>
  );
}

const bannerStyle = {
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/diverse_content.webp")',
  height: '600px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'white',
  textAlign: 'center',
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

export default PublishersContentPage;
