import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg'; // Helper function for cropping
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../alerts/Danger';
import Select from '../../form/select';
import RequiredAsterisk from '../../form/required_asterisk';
import Navigate from '../../../../../util/Navigate';
import { Link } from 'react-router-dom';

const NewsletterForm = ({
    newsletter,
    onUpdate,
    setNewsletter,
    onBannerImageUpdate,
    setBannerImageBlob,
    communities,
    errors,
}) => {
    const [bannerImage, setBannerImage] = useState(null);
    const [cropBanner, setCropBanner] = useState({ x: 0, y: 0 });
    const [zoomBanner, setZoomBanner] = useState(1);
    const [croppedAreaPixelsBanner, setCroppedAreaPixelsBanner] = useState(null);
    const [croppedBannerSrc, setCroppedBannerSrc] = useState(null);
    const [showBannerCropper, setShowBannerCropper] = useState(false);

    useEffect(() => {
        if (newsletter) {
            setNewsletter(newsletter);
        }
    }, [newsletter, setNewsletter]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const updatedValue = type === 'checkbox' ? e.target.checked : value;
        setNewsletter({ ...newsletter, [name]: updatedValue });
    };

    const onCropCompleteBanner = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsBanner(croppedAreaPixels);
    }, []);

    const showCroppedImage = async (imageSrc, croppedAreaPixels, setImage, name) => {
        try {
            const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
            const croppedImageUrl = URL.createObjectURL(croppedImageBlob);

            if (newsletter.id && name === 'bannerImage') {
                setCroppedBannerSrc(croppedImageUrl);
                await Glitch.api.Newsletters.uploadBannerImageBlob(newsletter.id, croppedImageBlob);
            } else if (!newsletter.id && name === 'bannerImage') {
                setCroppedBannerSrc(croppedImageUrl);
                setBannerImageBlob(croppedImageBlob);
            }
            setImage(null);
        } catch (e) {
            console.error(e);
        }
    };

    const handleImageChange = (e, imageSetter, cropSetter, zoomSetter, setShowCropper) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                imageSetter(reader.result);
                cropSetter({ x: 0, y: 0 });
                zoomSetter(1);
                setShowCropper(true);
            };
        }
    };

    const providerFields = () => {
        switch (newsletter.send_method) {
            case 'smtp':
                return (
                    <>
                        {createInput('SMTP Login', 'smtp_login', newsletter.smtp_login, handleChange, 'text', 'fas fa-user', errors, 'Enter the SMTP login username.')}
                        {createInput('SMTP Password', 'smtp_password', newsletter.smtp_password, handleChange, 'password', 'fas fa-key', errors, 'Enter the SMTP login password.')}
                        {createInput('SMTP Host', 'smtp_host', newsletter.smtp_host, handleChange, 'text', 'fas fa-server', errors, 'Enter the SMTP server host address.')}
                        {createInput('SMTP Port', 'smtp_port', newsletter.smtp_port, handleChange, 'number', 'fas fa-network-wired', errors, 'Enter the SMTP server port number.')}
                    </>
                );
            case 'api_key':
            case 'mailgun':
            case 'sendgrid':
            case 'postmarkapp':
                return (
                    <>
                        {createInput('API Key', 'api_sender_key', newsletter.api_sender_key, handleChange, 'text', 'fas fa-key', errors, 'Enter the API key for the selected provider.')}
                        {createInput('API Host', 'api_sender_host', newsletter.api_sender_host, handleChange, 'text', 'fas fa-link', errors, 'Enter the API sender host (for Mailgun/Postmark only).')}
                    </>
                );
            default:
                return null;
        }
    };

    const communityOptions = Object.entries(communities).map(([key, value]) => ({
        label: value.name,
        value: value.id,
    }));

    const selectedCommunity = communities.find((community) => community.id === newsletter.community_id);


    return (
        <div className="container mt-4">
            <form>
                <div className="card mb-3">
                    <div className="card-header bg-primary text-white">
                        <h3><i className="fas fa-newspaper mr-2"></i> Newsletter Details</h3>
                    </div>
                    <div className="card-body">
                        <p className="lead">Enter details about the newsletter you are managing.</p>
                        <hr />
                        <div className="row">
                            {createInput(
                                'Name',
                                'name',
                                newsletter?.name,
                                handleChange,
                                'text',
                                'fas fa-signature',
                                errors,
                                'Enter the name of the newsletter. This name is what users will see when signing up.',
                                true
                            )}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="community_id">Community <RequiredAsterisk /></label>
                            <p className='small'>Newsletters are associated with a community. Select a community to associate this newsletter with.</p>
                            <Select
                                name="community_id"
                                className="form-select"
                                onChange={handleChange}
                                value={newsletter?.community_id}
                            >
                                <option value="">Select a Community</option>
                                {communityOptions.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Select>
                            {errors && errors['community_id'] && errors['community_id'].map((msg, idx) => (
                                <Danger message={msg} key={idx} />
                            ))}
                        </div>

                        <div className="row">
                            {createTextarea(
                                'Description',
                                'description',
                                newsletter?.description,
                                handleChange,
                                errors,
                                'Enter a description for the newsletter. This description is what users will see when signing up.',
                                false
                            )}
                        </div>

                        <div className="row">
                            {createInput(
                                'Reply Email',
                                'reply_email',
                                newsletter?.reply_email,
                                handleChange,
                                'email',
                                'fas fa-envelope',
                                errors,
                                'Enter the reply-to email for the newsletter.',
                                true
                            )}
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="send_method">
                                    <i className="fas fa-paper-plane mr-2"></i> Send Method <RequiredAsterisk />
                                </label>
                                <Select
                                    name="send_method"
                                    className="form-select"
                                    onChange={handleChange}
                                    value={newsletter?.send_method || ''}
                                >
                                    <option value="default">Default</option>
                                    <option value="smtp">SMTP</option>
                                    <option value="mailgun">Mailgun</option>
                                    <option value="sendgrid">SendGrid</option>
                                    <option value="postmarkapp">Postmark</option>
                                </Select>
                                {errors && errors['send_method'] && errors['send_method'].map((msg, idx) => (
                                    <Danger message={msg} key={idx} />
                                ))}
                            </div>
                        </div>

                        {/* Provider-specific fields */}
                        <div className="row mt-3">
                            {providerFields()}
                        </div>

                        <div className="form-check form-switch mt-4">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="auto_subscribe"
                                name="auto_subscribe"
                                checked={newsletter.auto_subscribe}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="auto_subscribe">
                                Automatically Subscribe New Members
                            </label>

                            {selectedCommunity && (
                                <p>
                                    If you utilize your Glitch Gaming community at &nbsp;
                                    <Link
                                        to={ `https://${selectedCommunity.subdomain}.glitch.fun` }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {selectedCommunity.subdomain}.glitch.fun
                                    </Link>, when a user registers, they will be automatically signed up to this newsletter.
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="card-header bg-secondary text-white">Upload Banner Image</div>
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="bannerImage">Banner Image</label>
                            <p className="small">Recommended dimensions: 3840x1240 pixels.</p>
                            <input
                                type="file"
                                accept="image/*"
                                className="form-control-file"
                                id="bannerImage"
                                onChange={(e) =>
                                    handleImageChange(e, setBannerImage, setCropBanner, setZoomBanner, setShowBannerCropper)
                                }
                            />
                            {croppedBannerSrc && (
                                <div className="mt-3">
                                    <p>Cropped Banner Image:</p>
                                    <img src={croppedBannerSrc} alt="Cropped Banner" className="img-fluid w-50" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            {/* Banner Cropper Modal */}
            {showBannerCropper && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Crop Banner Image</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => setShowBannerCropper(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="crop-container" style={{ position: 'relative', width: '100%', height: '400px' }}>
                                    <Cropper
                                        image={bannerImage}
                                        crop={cropBanner}
                                        zoom={zoomBanner}
                                        aspect={3840 / 1240}
                                        onCropChange={setCropBanner}
                                        onZoomChange={setZoomBanner}
                                        onCropComplete={onCropCompleteBanner}
                                    />
                                </div>
                                <div className="slider-container mt-3">
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoomBanner}
                                        onChange={(e) => setZoomBanner(e.target.value)}
                                        className="form-range"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowBannerCropper(false)}>
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        showCroppedImage(bannerImage, croppedAreaPixelsBanner, setBannerImage, 'bannerImage');
                                        setShowBannerCropper(false);
                                    }}
                                >
                                    Crop Image
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    function createInput(label, name, value, handleChange, type = 'text', icon, errors, description = '', required = false) {
        return (
            <div className="col">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        <i className={`${icon} mr-2`}></i> &nbsp;{label} {required ? <RequiredAsterisk /> : ''}
                    </label>
                    <p className="small">{description}</p>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    />
                </div>
                {errors && errors[name] && errors[name].map((message, index) => (
                    <Danger message={message} key={index} />
                ))}
            </div>
        );
    }

    function createTextarea(label, name, value, handleChange, errors, description, required = false) {
        return (
            <div className="col-md-12">
                <div className="mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    <textarea
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                        rows={4}
                    />
                    
                </div>
                {errors && errors[name] && errors[name].map((message, index) => (
                    <Danger message={message} key={index} />
                ))}
            </div>
        );
    }
};

export default NewsletterForm;
