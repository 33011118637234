import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import CampaignForm from '../../component/section/newsletters/campaign_form';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';

const NewsletterCampaignCreatePage = () => {
    const [campaign, setCampaign] = useState({});
    const [newsletter, setNewsletter] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [communities, setCommunities] = useState([]);
    const navigate = useNavigate();
    const { id, community_id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        Glitch.api.Communities.viewNewsletter(community_id, id)
            .then(response => {
                setNewsletter(response.data.data);

            })
            .catch(error => {
                console.error('Error fetching newsletter or campaigns', error);
            });

        // Fetching communities with roles
        Glitch.api.Communities.list({
            roles: [
                Glitch.constants.Roles.ADMINISTRATOR,
                Glitch.constants.Roles.SUPER_ADMINISTRATOR,
                Glitch.constants.Roles.MODERATOR,
            ],
            order_by: 'name',
        })
            .then(response => {
                setCommunities(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching communities:', error);
            });
    }, [navigate]);

    const handleSubmit = async (status) => {
        setIsLoading(true);
        const campaignData = { ...campaign, status };

        try {
            const response = await Glitch.api.Communities.createCampaign(community_id, id, campaignData);
            navigate(Navigate.newsletterCampaignSendPage(id, community_id, response.data.data.id ));
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: 'Create Campaign', link: Navigate.newsletterCampaignCreatePage(newsletter?.id, community_id) }]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Create Email Campaign</h2>
                        <p className="lead">Create a campaign for the newsletter to send an email to your subscribers.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                {/* Campaign Form */}
                <CampaignForm
                    campaign={campaign}
                    setCampaign={setCampaign}
                    communities={communities}
                    errors={errors}
                />

                <div className="container">
                    {errors && Object.keys(errors).length > 0 && <Danger message={"You have errors in your submission."} />}
                </div>

                <div className="mt-4 text-center">

                    <button
                        type="button"
                        onClick={() => handleSubmit('draft')}
                        className="btn btn-success mx-2"
                    >
                        {isLoading ? <Loading /> : 'Create Campaign'}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default NewsletterCampaignCreatePage;
