import React from 'react';

const MessageList = ({ thread, currentUserId }) => {
  const { users = [], messages = [] } = thread;

  return (
    <>
      {users.length > 0 && (
        <div className="participants-list mt-4">
          <h3>Participants</h3>
          <div className="d-flex">
            {users.map(user => (
              <div key={user.id} className="participant me-3 text-center">
                <img 
                  src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
                  alt={user.username} 
                  className="rounded-circle mb-1" 
                  style={{ width: '60px', height: '60px', objectFit: 'cover' }}
                />
                <p className="small mb-0">{user.display_name || user.username}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {messages.length > 0 ? (
        <div className="message-list mt-4">
          {messages.map((msg) => {
            const isCurrentUser = msg.user_id === currentUserId;
            const user = users.find(u => u.id === msg.user_id) || {};
            return (
              <div key={msg.id} className={`d-flex mb-3 ${isCurrentUser ? 'justify-content-end' : 'justify-content-start'}`}>
                {!isCurrentUser && (
                  <img 
                    src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
                    alt={user.username} 
                    className="rounded-circle me-2" 
                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                  />
                )}
                <div className={`message-bubble p-3 ${isCurrentUser ? 'bg-primary text-white' : 'bg-light text-dark'}`} style={{ borderRadius: '15px', width: '100%' }}>
                  <p className="mb-1"><strong>{user.display_name || user.username || 'Unknown User'}</strong></p>
                  <p className="mb-1">{msg.message}</p>
                  <small className="text-muted">{new Date(msg.created_at).toLocaleString()}</small>
                </div>
                {isCurrentUser && (
                  <img 
                    src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
                    alt={user.username} 
                    className="rounded-circle ms-2" 
                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                  />
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="text-center mt-4">No Messages</div>
      )}
    </>
  );
};

export default MessageList;
