import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const CreatorStreamViewCountChart = ({ data }) => {

    // Ensure data exists and is in the expected format
    if (!data || Object.keys(data).length === 0) {
        return <p>No data available</p>;
    }

    // Transform the data to fit the Recharts format
    let transformedData = [];
    const platforms = Object.keys(data);

    platforms.forEach(platform => {
        data[platform].forEach(item => {
            let existingItem = transformedData.find(transItem => transItem.time === new Date(item.created_at).toLocaleTimeString());
            if (existingItem) {
                existingItem[platform] = item.view_count;
            } else {
                transformedData.push({
                    time: new Date(item.created_at).toLocaleTimeString(),
                    [platform]: item.view_count
                });
            }
        });
    });

    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart
                data={transformedData}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                {platforms.map((platform, index) => (
                    <Line key={index} type="monotone" dataKey={platform} stroke={getRandomColor()} />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

// Function to generate a random color for each line
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default CreatorStreamViewCountChart;
