import React from "react";
import DateTimePicker from "react-datetime-picker";
import Danger from "../../alerts/Danger";
import Input from "../../form/input";
import Textarea from "../../form/textarea";
import Select from "../../form/select";


import Glitch from 'glitch-javascript-sdk';


export default function CommunityFormNewsletter({ newsletters, newsletterDefaultValue, newsletterOnChange, errors  }) {

    return (
        <>
            <h3 >Default Community Newsletter</h3>
            <p className="lead">If you have created a newsletter, you can set it as the default newsletter for the community, allowing users to sign up for it.</p>


            <div className="form-group text-left">
                <label>Default Post Status</label>
                <Select className="form-control" value={newsletterDefaultValue} onChange={newsletterOnChange}>
                    <option value={""}>Select A Newsletter</option>
                    {newsletters.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))}
 
                </Select>
                <p className="small">Select the a newsletter as the default newsletter.</p>
                {errors && errors.newsletter_id && errors.newsletter_id.map(function (name, index) {
                    return <Danger message={name} key={index} />;
                })}
            </div>

        </>
    );
}