import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import { Button, Table } from 'react-bootstrap';
import Wysiwyg from '../../component/form/wysiwyg';
import Danger from '../../component/alerts/Danger';

const CampaignsUpdateInfluencerInviteContractPage = () => {
    const [influencer, setInfluencer] = useState(null);
    const [campaign, setCampaign] = useState({});
    const [community, setCommunity] = useState({});
    const [invite, setInvite] = useState({});
    const { id, influencer_id } = useParams();
    const [subscriptions, setSubscriptions] = useState([]);
    const [isGeneratingContract, setIsGeneratingContract] = useState(false);
    const [isSendingWithDocuSign, setIsSendingWithDocuSign] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState(false);
    const [contract, setContract] = useState('');
    const [contracts, setContracts] = useState([]);
    const [serviceAdminLink, setServiceAdminLink] = useState(null);

    const navigate = useNavigate();


    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Campaigns.view(id).then(response => {
            setCampaign(response.data.data);
            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });

            Glitch.api.Communities.view(response.data.data.community_id).then(response => {
                setCommunity(response.data.data);
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Campaigns.viewInfluencerInvite(id, influencer_id).then(response => {
            setInvite(response.data.data);


        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Influencers.listContracts({ campaign_id: id, influencer_id: influencer_id }).then(response => {
            setContracts(response.data.data);
        }).catch(error => {
            console.error(error);
        });

        fetchInfluencer();
    }, []);

    const generateContract = async () => {
        setIsGeneratingContract(true);
        try {
            const response = await Glitch.api.Campaigns.generateContractFromInvite(id, influencer_id);
            setContract(response.data.data.html);
        } catch (error) {
            console.error('Error generating contract', error);
        } finally {
            setIsGeneratingContract(false);
        }
    };

    const sendWithDocuSign = async () => {
        setIsSendingWithDocuSign(true);
        try {
            const response = await Glitch.api.Campaigns.sendContractWithDocusign(id, influencer_id, { html_contract: contract });
            setContracts([...contracts, response.data.data]);
            setServiceAdminLink(response.data.data.service_admin_link);
            setEmailSent(true);
        } catch (error) {
            console.error('Error sending with Docusign', error);
        } finally {
            setIsSendingWithDocuSign(false);
        }
    };

    const fetchInfluencer = async () => {
        try {
            const response = await Glitch.api.Influencers.viewInfluencer(influencer_id, { campaign_id: id });
            setInfluencer(response.data.data);
        } catch (error) {
            console.error('Error fetching influencer', error);
        }
    };

    if (!influencer) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs items={[
                            { name: 'Campaigns', link: Navigate.campaignsPage() },
                            { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                            { name: 'Influencer Invites', link: Navigate.campaignsInvitesPage(campaign.id) },
                            { name: 'Manage Contracts', link: Navigate.campaignsUpdateInfluencerInviteCompensation(campaign.id, influencer_id) },
                        ]}
                        />
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title">Generate And Send A Contract</h2>
                            <p className="lead">The contract between you and the influencer is outlined in our terms of service. However, if you or the influencer prefers to use a custom contract, you can generate and send one below.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                </div>

                <div className="container mt-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Personal Information</h4>
                        </div>
                        <div className="card-body d-flex align-items-center">
                            <img
                                src={getInfluencerImage(influencer)}
                                alt={`${influencer.first_name}'s profile`}
                                className="img-thumbnail me-4"
                                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                            />
                            <div>
                                <p><strong>Name:</strong> {influencer.first_name || influencer.instagram_username || influencer.youtube_title}</p>
                                <p><strong>Location:</strong> {influencer.location}</p>
                                <p><strong>Speaking Language:</strong> {influencer.speaking_language}</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Past Contracts</h4>
                        </div>
                        <div className="card-body">
                            {contracts.length > 0 ? (
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Service</th>
                                            <th>Date</th>
                                            <th>Campaign</th>
                                            <th>Fully Executed</th>
                                            <th>Admin Link</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contracts.map((contractItem) => (
                                            <tr key={contractItem.id}>
                                                <td>{contractItem.service}</td>
                                                <td>{new Date(contractItem.created_at).toLocaleDateString()}</td>
                                                <td>{contractItem.campaign.name}</td>
                                                <td>{contractItem.fully_executed ? 'Yes' : 'No'}</td>
                                                <td>
                                                    <a href={contractItem.service_admin_link} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm">
                                                        View Contract
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>No past contracts found.</p>
                            )}
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Contract</h4>
                        </div>
                        <div className="card-body">
                            <p>Either insert a contract below, or have AI generate one based on the current campaign information (deliverables, delivery timeline, rate card, etc.). </p>
                            <Button variant="primary" onClick={generateContract} className="mt-2" disabled={isGeneratingContract}>
                                {isGeneratingContract ? <><FontAwesomeIcon icon={faSpinner} spin /> Generating Contract...</> : `Generate Contract`}
                            </Button>
                            <div className="mt-4">
                                <Wysiwyg id="new_note" name="new_note" onChange={(contract) => setContract(contract)} placeholder="Enter the contract here" toolbarOptions={[
                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                    ['bold', 'italic', 'underline', 'strike'],
                                    [{ 'color': [] }, { 'background': [] }],
                                    [{ 'script': 'sub' }, { 'script': 'super' }],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                                    [{ 'direction': 'rtl' }],
                                    [{ 'align': [] }],
                                    ['link', 'image', 'video'],
                                    ['clean']
                                ]}>
                                    {contract}
                                </Wysiwyg>
                            </div>
                        </div>
                    </div>

                    {serviceAdminLink && (
                        <div className="text-center mt-4 mb-3">
                            <a href={serviceAdminLink} target="_blank" rel="noopener noreferrer" className="btn btn-info">
                                View Document on Docusign
                            </a>
                        </div>
                    )}



                    <div className='text-center mt-4 mb-5'>
                        <p>The contract will be sent to the corresponding signing service for both parties to review and sign.</p>

                        {Object.keys(errors).length > 0 && Object.keys(errors).map((errorKey) => (
                            errors[errorKey].map((message, index) => (
                                <Danger key={`${errorKey}-${index}`} message={message} />
                            ))
                        ))}

                        {community.docusign_auth_token ? (
                            <Button
                                variant='success'
                                size='lg'
                                onClick={sendWithDocuSign}
                                disabled={isSendingWithDocuSign || emailSent}
                            >
                                {isSendingWithDocuSign ? <><FontAwesomeIcon icon={faSpinner} spin /> Sending To Docusign...</> : `Send With Docusign`}
                            </Button>
                        ) : (
                            <Link className={`btn btn-info`} target='_blank' to={Navigate.communitiesContractstPage(community.id)}>
                                Authenticate Docusign
                            </Link>
                        )}
                        {emailSent && (
                            <div className="alert alert-success mt-3" role="alert">
                                Contract was successfully sent to signing service.
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default CampaignsUpdateInfluencerInviteContractPage;
