import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Fragment } from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import CampaignRateCard from '../../component/section/campaigns/campaign_rate_card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import Navigate from '../../../../util/Navigate';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import InfluencerHeader from '../../component/layout/infuencerheader';
import UserManageInvites from '../../component/section/user/user_manage_invites';
import CreatorAppDownload from '../../component/section/creators/creator_app_download';

const InfluencerDownloadAppPage = () => {

    useEffect(() => {

    }, []);



    return (
        <>
            <Fragment>
                <InfluencerHeader  position={"relative"} />
               

                <CreatorAppDownload />

      
            </Fragment>
            <Footer />
        </>
    );
};

export default  InfluencerDownloadAppPage;
