import React, { useRef, useEffect, useState } from 'react';
import Danger from '../../alerts/Danger';
import RequiredAsterisk from '../../form/required_asterisk';
import EmailEditor from 'react-email-editor';
import Alerts from '../../../../../util/Alerts';

const CampaignForm = ({
    campaign = {},
    setCampaign,
    errors = {}
}) => {
    const emailEditorRef = useRef(null);
    const [editorContent, setEditorContent] = useState(campaign.content || '');
    const [selectedCategories, setSelectedCategories] = useState(campaign.categories || []);

    const categoryOptions = [
        { label: 'Game Development Updates', value: 'game_dev_updates' },
        { label: 'Patches', value: 'patches' },
        { label: 'Event Announcements', value: 'event_announcements' },
        { label: 'Exclusive Content & Offers', value: 'exclusive_content_offers' },
        { label: 'Community Highlights', value: 'community_highlights' },
        { label: 'Upcoming Games & New Releases', value: 'upcoming_games_releases' },
        { label: 'Strategy & Gameplay Tips', value: 'strategy_gameplay_tips' },
        { label: 'Industry News & Trends', value: 'industry_news_trends' },
    ];

    // Update categories state in the parent component when selectedCategories changes
    useEffect(() => {
        setCampaign((prevCampaign) => ({ ...prevCampaign, categories: selectedCategories }));
        console.log("Ok");
        if (campaign.content_json) {
            console.log("Campaign Has content");

        }
    }, [selectedCategories, setCampaign]);

    // Save the email editor content to campaign content
    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {

            console.log("Data", data);
            const { design, html } = data;
            setEditorContent(html);
            setCampaign((prevCampaign) => ({ ...prevCampaign, content: html, content_json: JSON.stringify(design) }));

            if (campaign.id) {
                Alerts.display("Html Updates Saved", "The HTML changes have been saved. Please update the campaign to apply these changes");
            } else {
                Alerts.display("Html Updates Saved", "The HTML updates have been saved. You may now create the email campaign.");

            }

        });
    };

    // Set initial design or template if needed
    const onReady = (unlayer) => {
        // Load initial design or template if required
        if (campaign.content_json) {
            console.log("Setting JSON content");
            unlayer.loadDesign(JSON.parse(campaign.content_json));
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCampaign((prevCampaign) => ({ ...prevCampaign, [name]: value }));
    };

    const handleCategoryChange = (e) => {
        const { value, checked } = e.target;
        setSelectedCategories((prevCategories) =>
            checked
                ? [...prevCategories, value]
                : prevCategories.filter((category) => category !== value)
        );
    };

    return (
        <div className="container mt-4">
            <form>
                <div className="card mb-4">
                    <div className="card-header bg-primary text-white">
                        <h3><i className="fas fa-bullhorn mr-2"></i> Campaign Details</h3>
                    </div>
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="subject">Subject <RequiredAsterisk /></label>
                            <p className='small'>Set the subject line for the email.</p>
                            <input
                                type="text"
                                className="form-control"
                                id="subject"
                                name="subject"
                                value={campaign.subject || ''}
                                onChange={handleInputChange}
                                placeholder="Enter campaign subject"
                                required
                            />
                            {errors.subject && errors.subject.map((msg, idx) => <Danger message={msg} key={idx} />)}
                        </div>

                        <div className="mb-3">
                            <label>Select Categories <RequiredAsterisk /></label>
                            <p className='small'>Select the categories to relevant to this email. This ensures users receive content relevant to their interests. Please DO NOT select all the categories, as it can cause users to unsubscribe from your mailing list by getting content they did not opt-in to receive.</p>
                            <div className="row">
                                {categoryOptions.map((category) => (
                                    <div className="col-md-6" key={category.value}>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={category.value}
                                                value={category.value}
                                                checked={selectedCategories.includes(category.value)}
                                                onChange={handleCategoryChange}
                                            />
                                            <label className="form-check-label" htmlFor={category.value}>
                                                {category.label}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {errors.categories && errors.categories.map((msg, idx) => <Danger message={msg} key={idx} />)}
                        </div>

                        <label htmlFor="content_text">HTML Email</label>

                        <div className="card mt-4">
                            <div className="card-header bg-secondary text-white">
                                <h5>Email Builder</h5>
                            </div>
                            <div className="card-body text-center">

                                <EmailEditor ref={emailEditorRef} onChange={() => { console.log("Change"); }} onReady={onReady} />



                                {errors.content && errors.content.map((msg, idx) => (
                                    <Danger message={msg} key={idx} />
                                ))}
                            </div>
                        </div>

                        <div className='text-center mt-2'>
                            <button type="button" className="btn btn-primary mb-3" onClick={exportHtml}>
                                <i className="fas fa-save"></i> Save HTML Edits
                            </button>
                            <p className='small'>Required after using HTML editor to make changes.</p>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="content_text">Text-only Content (optional)</label>
                            <p className='small'>This is an optional text only version of the email</p>
                            <textarea
                                className="form-control"
                                id="content_text"
                                name="content_text"
                                rows={3}
                                value={campaign.content_text || ''}
                                onChange={handleInputChange}
                                placeholder="Enter plain text email content (optional)"
                            />
                            {errors.content_text && errors.content_text.map((msg, idx) => (
                                <Danger message={msg} key={idx} />
                            ))}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CampaignForm;
