import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Switch from "react-switch";
import { Accordion } from 'react-bootstrap';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import SchedulerSocialForm from '../../component/section/schedulers/schedule_social_form';
import Loading from '../../component/alerts/Loading';
import SchedulerForm from '../../component/section/schedulers/schedule_form';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';
import ScheduleNavbar from '../../component/section/schedulers/schedule_navbar';

const SchedulerUpdatePage = () => {
    const [scheduler, setScheduler] = useState({});
    const [copySuccess, setCopySuccess] = useState('');
    const { id } = useParams();
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});


    const navigate = useNavigate();

    useEffect(() => {

        if (!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Scheduler.getSchedule(id).then(response => {
            setScheduler(response.data.data);

            if (response.data.data?.title?.community_id) {
                Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data?.title?.community_id).then(response => {
                    setSubscriptions(response.data.data);
                }).catch(error => {
                    console.error(error);
                });
            }
        }).catch(error => {
            console.error(error);
        });
    }, [id]);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const update = () => {

        setIsLoading(true);

        Glitch.api.Scheduler.updateSchedule(scheduler.id, scheduler).then(response => {
            setScheduler(response.data.data);

            navigate(Navigate.schedulerViewPage(scheduler.id));
        }).catch(error => {

            let jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }

        }).finally(() => {
            setIsLoading(false)
        })
    };


    return (
        <>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Schedulers', link: Navigate.schedulerListPage() },
                    { name: scheduler?.name, link: Navigate.schedulerViewPage(scheduler?.id) },
                    { name: "Update Scheduler", link: Navigate.schedulerUpdatePage(scheduler?.id) }]}
                />
            </div>

            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">Update Scheduler</h2>
                        <p className="lead">Update the scheduler.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                <ScheduleNavbar schedulerIdId={id} />
            </div>

            
            <SchedulerForm schedulerData={scheduler} setSchedulerData={setScheduler} isEditMode={true} errors={errors} />


            
            <div className="mt-2 text-center">
                {Object.keys(errors).length > 0 &&
                    Object.keys(errors).map((errorKey) => {
                        const errorValue = errors[errorKey];

                        // Check if the error value is an array
                        if (Array.isArray(errorValue)) {
                            return errorValue.map((message, index) => (
                                <Danger key={`${errorKey}-${index}`} message={message} />
                            ));
                        }

                        // If it's not an array (e.g., a string), render it directly
                        return (
                            <Danger key={errorKey} message={errorValue} />
                        );
                    })}
            </div>
            <div className='container text-center mb-5'>
                <button type="button" name='updateScheduler' onClick={update} className="btn btn-primary btn-lg">{isLoading ? <Loading /> : 'Update Scheduler'}</button>

            </div>

            <br /><br />

            <Footer />
        </>
    );
};

export default SchedulerUpdatePage;
