import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../alerts/Danger';
import RequiredAsterisk from '../../form/required_asterisk';
import Wysiwyg from '../../form/wysiwyg';
import Select from '../../form/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faSnapchat,
    faReddit,
    faYoutube,
    faPatreon,
    faSteam,
    faTelegram,
    faPinterest,
    faTwitch,
    faKickstarter, // Placeholder for Bluesky
} from '@fortawesome/free-brands-svg-icons';
import Navigate from '../../../../../util/Navigate';

const SchedulerSocialForm = ({
    schedulerData,
    setSchedulerData,
    errors,
}) => {
    const [platforms] = useState([
        { name: 'Facebook', icon: faFacebook, field: 'facebook' },
        { name: 'Instagram', icon: faInstagram, field: 'instagram' },
        { name: 'Twitter', icon: faTwitter, field: 'twitter' },
        { name: 'TikTok', icon: faTiktok, field: 'tiktok' },
        { name: 'Discord', icon: faDiscord, field: 'discord' },
        { name: 'Reddit', icon: faReddit, field: 'reddit' },
        { name: 'YouTube', icon: faYoutube, field: 'youtube' },
        { name: 'Telegram', icon: faTelegram, field: 'telegram' },
        { name: 'Bluesky', icon: faKickstarter, field: 'bluesky' }, // Using Kickstarter icon as placeholder
    ]);

    const [advancedOptions, setAdvancedOptions] = useState(false);
    const [facebookPages, setFacebookPages] = useState([]);
    const [instagramAccounts, setInstagramAccounts] = useState([]);
    const [redditSubreddits, setRedditSubreddits] = useState([]);
    const [redditFlairs, setRedditFlairs] = useState([]);
    const [selectedSubreddit, setSelectedSubreddit] = useState('');

    useEffect(() => {
        if (schedulerData.facebook_oauth_token) {
            getFacebookGroups();
        }
        if (schedulerData.facebook_oauth_token) {
            getInstagramAccounts();
        }
        if (schedulerData.reddit_oauth_token) {
            getSubreddits();
        }
    }, [schedulerData]);

    const getFacebookGroups = () => {
        Glitch.api.Scheduler.getFacebookGroups(schedulerData?.id)
            .then(response => {
                setFacebookPages(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch Facebook groups/pages:', error);
            });
    };

    const getInstagramAccounts = () => {
        Glitch.api.Scheduler.getInstagramAccounts(schedulerData?.id)
            .then(response => {
                setInstagramAccounts(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch Instagram accounts:', error);
            });
    };

    const getSubreddits = () => {
        Glitch.api.Scheduler.getRedditSubreddits(schedulerData?.id)
            .then(response => {
                console.log("Subreddits", response);
                setRedditSubreddits(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch Reddit subreddits:', error);
            });
    };

    const getSubredditFlairs = (subreddit_name) => {
        Glitch.api.Scheduler.getRedditSubredditFlairs(schedulerData?.id, subreddit_name)
            .then(response => {
                setRedditFlairs(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch Reddit subreddit flairs:', error);
            });
    };

    const connectPlatform = (platform) => {
        const params ='?scheduler_id=' + schedulerData?.id + 
                   '&redirect=' + encodeURIComponent(window.location.href);

        let oauthUrl = null;

        if (platform.field === 'facebook') {
            oauthUrl = (process.env.REACT_APP_OAUTH_FACEBOOK_URL ?? 'https://api.glitch.fun/auth/facebook/redirect') + params;
        } else if (platform.field === 'tiktok') {
            oauthUrl = (process.env.REACT_APP_OAUTH_TIKTOK_URL ?? 'https://api.glitch.fun/auth/tiktok/redirect') + params;
        } else if (platform.field === 'youtube') {
            oauthUrl = (process.env.REACT_APP_OAUTH_YOUTUBE_URL ?? 'https://api.glitch.fun/auth/google/redirect') + params;
        } else if (platform.field === 'twitter') {
            oauthUrl = (process.env.REACT_APP_OAUTH_TWITTER_URL ?? 'https://api.glitch.fun/auth/twitter/redirect') + params;
        } else if (platform.field === 'instagram') {
            oauthUrl = (process.env.REACT_APP_OAUTH_FACEBOOK_URL ?? 'https://api.glitch.fun/auth/facebook/redirect') + params;
        } else if (platform.field === 'reddit') {
            oauthUrl = (process.env.REACT_APP_OAUTH_REDDIT_URL ?? 'https://api.glitch.fun/auth/reddit/redirect') + params;
        } else if (platform.field === 'discord') {
            oauthUrl = (process.env.REACT_APP_OAUTH_REDDIT_URL ?? 'https://api.glitch.fun/auth/discord/redirect') + params;
        }
        
        if (oauthUrl) {
            Glitch.api.Users.oneTimeLoginToken().then((response) => {
                oauthUrl += '&token=' + response.data.data.one_time_login_token;

                window.open(oauthUrl, "_self");
            }).catch(error => {
                alert(error);
            })
        } else {
            alert(platform.name + " authentication not available");
        }
    };

    const clearPlatformAuth = (platform) => {
        if (window.confirm(`Are you sure you want to disconnect ${platform.name}?`)) {
            let clearAuthFunction = null;

            if (platform.field === 'facebook') {
                clearAuthFunction = Glitch.api.Scheduler.clearFacebookAuth;
            } else if (platform.field === 'instagram') {
                clearAuthFunction = Glitch.api.Scheduler.clearInstagramAuth;
            } else if (platform.field === 'twitter') {
                clearAuthFunction = Glitch.api.Scheduler.clearTwitterAuth;
            } else if (platform.field === 'reddit') {
                clearAuthFunction = Glitch.api.Scheduler.clearRedditAuth;
            } else if (platform.field === 'tiktok') {
                clearAuthFunction = Glitch.api.Scheduler.clearTikTokAuth;
            }

            if (clearAuthFunction) {
                clearAuthFunction(schedulerData?.id)
                    .then(response => {
                        const updatedSchedulerData = { ...schedulerData };
                        updatedSchedulerData[`${platform.field}_oauth_token`] = null;
                        setSchedulerData(updatedSchedulerData);
                    })
                    .catch(error => {
                        console.error(`Failed to clear ${platform.name} authentication:`, error);
                    });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        let updatedValue = type === 'checkbox' ? checked : value;

        setSchedulerData((prevData) => ({
            ...prevData,
            [name]: updatedValue,
        }));
    };

    const toggleAdvancedOptions = () => {
        setAdvancedOptions(!advancedOptions);
    };

    return (
        <div className="">
            <div className="mb-3">
                <div className="card-header bg-secondary text-white">
                    <h4>
                        <i className="fas fa-share-alt mr-2"></i> Social Platforms
                    </h4>
                </div>
                <div className="container card-body">
                    <p className="small">
                        Connect your social media accounts to allow the scheduler to post on your behalf.
                    </p>
                    {platforms.map((platform) => (
                        <div className="row align-items-center mb-3" key={platform.field}>
                            <div className="col-md-2">
                                <FontAwesomeIcon icon={platform.icon} size="2x" />
                                <span className="ms-2">{platform.name}</span>
                            </div>
                            <div className="col-md-3">
                                {platform.field !== 'bluesky' && platform.field !== 'telegram' ? (
                                    <>
                                        {!schedulerData[`${platform.field}_oauth_token`] ? (
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-sm"
                                                onClick={() => connectPlatform(platform)}
                                            >
                                                Connect
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger btn-sm"
                                                onClick={() => clearPlatformAuth(platform)}
                                            >
                                                Disconnect
                                            </button>
                                        )}
                                    </>
                                ) : null}
                            </div>
                            <div className="col-md-7">
                                {platform.field === 'facebook' && schedulerData.facebook_oauth_token && (
                                    <div>
                                        <label htmlFor="facebook_page_id">Facebook Page</label>
                                        <select
                                            className="form-select"
                                            id="facebook_page_id"
                                            name="facebook_page_id"
                                            value={schedulerData.facebook_page_id || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select a Facebook Page</option>
                                            {facebookPages.map((page) => (
                                                <option key={page.id} value={page.id}>
                                                    {page.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {platform.field === 'instagram' && schedulerData.instagram_oauth_token && (
                                    <div>
                                        <label htmlFor="instagram_account_id">Instagram Account</label>
                                        <select
                                            className="form-select"
                                            id="instagram_account_id"
                                            name="instagram_account_id"
                                            value={schedulerData.instagram_account_id || ''}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select an Instagram Account</option>
                                            {instagramAccounts.map((account) => (
                                                <option key={account.id} value={account.id}>
                                                    {account.username}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {platform.field === 'reddit' && schedulerData.reddit_oauth_token && (
                                    <div>
                                        <label htmlFor="subreddit_id">Subreddit</label>
                                        <select
                                            className="form-select"
                                            id="subreddit_id"
                                            name="subreddit_id"
                                            value={schedulerData.subreddit_id || ''}
                                            onChange={(e) => {
                                                handleInputChange(e);
                                                setSelectedSubreddit(e.target.value);
                                                getSubredditFlairs(e.target.value);
                                            }}
                                        >
                                            <option value="">Select a Subreddit</option>
                                            {redditSubreddits && Array.isArray(redditSubreddits) && redditSubreddits.map((sub) => (
                                                <option key={sub.display_name} value={sub.display_name}>
                                                    {sub.display_name_prefixed}
                                                </option>
                                            ))}
                                        </select>
                                        {redditFlairs.length > 0 && (
                                            <div className="mt-2">
                                                <label htmlFor="reddit_flair_id">Flair</label>
                                                <select
                                                    className="form-select"
                                                    id="reddit_flair_id"
                                                    name="reddit_flair_id"
                                                    value={schedulerData.reddit_flair_id || ''}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select a Flair</option>
                                                    {redditFlairs.map((flair) => (
                                                        <option key={flair.id} value={flair.id}>
                                                            {flair.text}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {platform.field === 'bluesky' && (
                                    <div>
                                        <label htmlFor="bluesky_name">Bluesky Handle</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="bluesky_name"
                                            name="bluesky_name"
                                            value={schedulerData.bluesky_name || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="bluesky_oauth_token" className="mt-2">
                                            Bluesky App Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="bluesky_oauth_token"
                                            name="bluesky_oauth_token"
                                            value={schedulerData.bluesky_oauth_token || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                )}
                                {platform.field === 'telegram' && (
                                    <div>
                                        <label htmlFor="telegram_oauth_token">Telegram Bot Token</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="telegram_oauth_token"
                                            name="telegram_oauth_token"
                                            value={schedulerData.telegram_oauth_token || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="telegram_id" className="mt-2">
                                            Telegram Chat ID
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="telegram_id"
                                            name="telegram_id"
                                            value={schedulerData.telegram_id || ''}
                                            onChange={handleInputChange}
                                        />
                                        <p className="small mt-2">
                                            Please create a bot using BotFather on Telegram and enter the Bot Token and your Chat ID.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}

                    <div className="mt-4">
                        <button className="btn btn-link" type="button" onClick={toggleAdvancedOptions}>
                            {advancedOptions ? 'Hide Advanced Options' : 'Show Advanced Options'}
                        </button>
                    </div>

                    {advancedOptions && (
                        <div className="mt-3">
                            <h5>Advanced Options</h5>
                            {platforms.map((platform) => (
                                schedulerData[`${platform.field}_oauth_token`] && (
                                    <div className="row align-items-center mb-3" key={`advanced-${platform.field}`}>
                                        <div className="col-md-4">
                                            <FontAwesomeIcon icon={platform.icon} size="1x" />
                                            <span className="ms-2">{platform.name} Posts per Week</span>
                                        </div>
                                        <div className="col-md-4">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id={`posts_per_week_${platform.field}`}
                                                name={`posts_per_week_${platform.field}`}
                                                min="0"
                                                value={schedulerData[`posts_per_week_${platform.field}`] || ''}
                                                onChange={handleInputChange}
                                            />
                                            {errors && errors[`posts_per_week_${platform.field}`] && errors[`posts_per_week_${platform.field}`].map((message, index) => (
                                                <Danger message={message} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SchedulerSocialForm;
