import React, { useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Navigate from '../../../../util/Navigate';

const InfluencerTutorialsGamesPage = () => {

    useEffect(() => {
        // Any useEffect logic if needed
    }, []);

    return (
        <>
            <Helmet>
                <title>How to Find Games to Play on Glitch | Glitch Tutorials</title>
                <meta name="description" content="Learn how to find games to play and get paid to create content for them on Glitch. Step-by-step guide and video tutorial included." />
                <meta name="robots" content="index, follow" />
                <meta charset="utf-8" />
                <meta property="og:title" content="How to Find Games to Play on Glitch | Glitch Tutorials" />
                <meta property="og:description" content="Discover how to browse games and apply for campaigns on Glitch to start earning by creating content. Watch our video and follow the tutorial." />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <Fragment>
                <InfluencerHeader position={"relative"} />
                <section className="pageheader-section-min">
                    <div className="container mt-2">
                        <Breadcrumbs
                            items={[
                                { name: 'Tutorials', link: Navigate.influencersTutorials() },
                                { name: `How To Find A Game To Play`, link: Navigate.influencersTutorialGames() },
                            ]}
                        />
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4"></div>
                            <h2 className="pageheader-title">How To Find Games To Play and Promote</h2>
                            <p className="lead">Learn how to find games to play and get paid to create content for them.</p>
                        </div>
                    </div>
                </section>

                <div className="container">

                    <div className="ratio ratio-16x9 mb-5 border border-white border-2">
                        <iframe
                            src="https://www.youtube.com/embed/uEX3OoralMY"
                            allowFullScreen
                            title="YouTube Video"
                        ></iframe>
                    </div>

                    <div className="tutorial-content">
                        <h3>Step-by-Step Guide to Finding and Promoting Games on Glitch</h3>
                        <p>
                            Welcome! In this tutorial, we’re going to cover how to find games to play on Glitch and get paid to create content for them.
                        </p>
                        <p>
                            Glitch is a platform that connects video game publishers and developers with content creators. The content creators get paid for creating content and promoting the game.
                        </p>
                        <p>
                            Once you’re logged into Glitch, go to the <strong>“Find Campaigns”</strong> section of the application. There, you’ll be able to browse the games that are available. Click on a game to learn more about it.
                        </p>
                        <p>
                            This will take you to the game’s page, where you can find more in-depth information about the game. You can watch trailers, look at images, and explore other aspects of the game.
                        </p>
                        <h4>Understanding Your Earnings</h4>
                        <p>
                            Next, you can learn more about your earning potential. Earnings are estimated based on your follower count, engagement rate, and the rate card set by the game developer or publisher.
                        </p>
                        <p>
                            Let’s start with the rate card. The rate card specifies how much the developer is willing to pay, and it can be based on flat fees, performance-based metrics, or both. For example, they may offer a $500 flat fee, and that’s it. Or they might offer a fee based on the metrics your content produces, such as shares, likes, clicks, comments, and installs. They could also offer a hybrid model of both flat fees and performance metrics.
                        </p>
                        <p>
                            The performance metrics have a mutually beneficial aspect: the longer your content produces results, the longer you earn revenue from it. This means that if people are still engaging with the content you produced three months later, you’re still making money from it, and the game is still getting publicity.
                        </p>
                        <p>
                            You may notice that payment rates differ for different platforms. Instagram might offer different payment amounts than TikTok, which might differ from Reddit and others.
                        </p>
                        <h4>Your Social Accounts</h4>
                        <p>
                            Now, with your social accounts, you have both a follower count and an engagement rate. Your potential earnings are calculated based on the rate card, your follower count, and your engagement rate. You can negotiate this, but we’ll get to that later.
                        </p>
                        <h4>Campaign Deliverables</h4>
                        <p>
                            You’ll also have the opportunity to learn about what’s expected of you. The most important area to look at is the deliverables. This is what will be expected of you for the campaign to be considered complete. You can also view other requirements, such as hashtags, affiliate links, and other items.
                        </p>
                        <p>
                            Now, if you scroll to the bottom and click <strong>“Apply”</strong>, your application will be submitted to the game before it is approved. You and the publisher or game developer can discuss details and make changes to your expectations and deliverables. A direct messaging system is built in to help with communication.
                        </p>
                        <h4>Finalizing Your Participation</h4>
                        <p>
                            Once you’re approved, you’ll receive a welcome message, and you can start promoting the campaign and creating content. Happy streaming!
                        </p>
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default InfluencerTutorialsGamesPage;
