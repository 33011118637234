import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams } from 'react-router-dom';
import Header from '../../component/layout/header';
import MessageMessagesList from '../../component/section/messages/message_messages';
import MessageParticipantsList from '../../component/section/messages/message_participants';
import MessageInput from '../../component/section/messages/message_input';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';

const MessagesReadPage = () => {
  const [thread, setThread] = useState({ users: [], messages: [] });
  const { id } = useParams();

  useEffect(() => {
    loadThread();
  }, [id]);

  const loadThread = () => {
    Glitch.api.Messages.getThread(id).then(response => {
      setThread(response.data.data);
    }).catch(error => {
      console.error("Failed to load thread:", error);
    });
  }

  const currentUserId = Glitch.util.Session.getID();

  return (
    <>
      <Header position={"relative"} />
      <div className="container my-4">
        <Breadcrumbs items={[
          { name: 'Messages', link: Navigate.messagesListPage() },
          { name: 'Read', link: Navigate.messagesThreadPage(thread.id) }]}
        />
        <h2>Message Thread</h2>
        <MessageParticipantsList users={thread.users} />
        <MessageMessagesList messages={thread.messages} currentUserId={currentUserId} />
        <MessageInput threadId={id} onMessageSent={loadThread} />
      </div>
    </>
  );
};

export default MessagesReadPage;
