import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import NewsletterForm from '../../component/section/newsletters/basic_form';
import timeouts from '../../../../constants/timeouts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Danger from '../../component/alerts/Danger';

const NewslettersCreatePage = () => {
    const [newsletter, setNewsletter] = useState({ send_method: 'default' });
    const [communities, setCommunities] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [bannerImageBlob, setBannerImageBlob] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        // Fetching communities with roles
        Glitch.api.Communities.list({
            roles: [
                Glitch.constants.Roles.ADMINISTRATOR,
                Glitch.constants.Roles.SUPER_ADMINISTRATOR,
                Glitch.constants.Roles.MODERATOR,
            ],
            order_by: 'name',
        }).then(response => {
            setCommunities(response.data.data);
        }).catch(error => {
            console.error('Error fetching communities:', error);
        });
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!newsletter.community_id) {
            setErrors({ community_id: ['Community is required'] });
            setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            return;
        }

        setIsLoading(true);

        // Create the newsletter
        Glitch.api.Communities.createNewsletter(newsletter.community_id, newsletter).then(async (response) => {
            const newsletterId = response.data.data.id;
            const uploadPromises = [];

            // Upload banner image if available
            if (bannerImageBlob) {
                try {
                    const bannerUpload = Glitch.api.Communities.uploadNewsletterBannerImageBlob(newsletter.community_id, newsletterId, bannerImageBlob)
                        .catch(error => console.error('Error uploading banner image:', error));
                    uploadPromises.push(bannerUpload);
                } catch (error) {
                    console.error(error);
                }
            }

            // Await image uploads
            if (uploadPromises.length > 0) {
                await Promise.all(uploadPromises);
            }


            // Navigate to newsletter view page
            navigate(Navigate.newsletterSubscribePage(newsletterId, newsletter.community_id));

        }).catch(error => {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: 'Create Newsletters', link: Navigate.newsletterCreatePage() },]}
                />
            </div>

            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Create a New Newsletter</h2>
                        <p className="lead">Build a newsletter for your community, customize it, and manage subscribers with ease.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                {/* Newsletter Form */}
                <NewsletterForm
                    newsletter={newsletter}
                    setNewsletter={setNewsletter}
                    communities={communities}
                    setBannerImageBlob={setBannerImageBlob}
                    errors={errors}
                />

                <div className="container">
                    {errors && Object.keys(errors).length > 0 && <Danger message={"You have errors in your submission."} />}
                </div>

                <div className="mt-4 text-center">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-lg"
                    >
                        {isLoading ? <Loading /> : 'Create Newsletter'}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default NewslettersCreatePage;
