import React from "react";

export default function Danger({ message }) {
    const displayMessage = typeof message === 'object' ? JSON.stringify(message) : message;

    return (
        <>
            <div className="alert alert-danger" role="alert">
                {displayMessage}
            </div>
        </>
    );
}
