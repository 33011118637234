import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import timeouts from '../../../../constants/timeouts';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewsletterCampaignViewPage = () => {
    const [campaign, setCampaign] = useState({});
    const [newsletter, setNewsletter] = useState({});
    const [emails, setEmails] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const { id, community_id, campaign_id } = useParams();

    const observer = useRef();
    const lastEmailElementRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
            return;
        }

        Glitch.api.Communities.viewNewsletter(community_id, id)
            .then(response => {
                setNewsletter(response.data.data);

            })
            .catch(error => {
                console.error('Error fetching newsletter or campaigns', error);
            });

        const fetchCampaignDetails = async () => {
            try {
                const campaignResponse = await Glitch.api.Communities.viewCampaign(community_id, id, campaign_id);
                setCampaign(campaignResponse.data.data);
            } catch (error) {
                console.error('Error fetching campaign:', error);
            }
        };

        fetchCampaignDetails();
    }, [community_id, id, campaign_id, navigate]);

    useEffect(() => {
        const fetchEmails = async () => {
            setIsLoading(true);
            try {
                const response = await Glitch.api.Communities.listCampaignEmails(community_id, id, campaign_id, {
                    page,
                    per_page: 10,
                    sort: 'sent_at',
                    order: 'desc'
                });
                setEmails(prevEmails => [...prevEmails, ...response.data.data]);
                setHasMore(response.data.data.length > 0);
            } catch (error) {
                console.error('Error fetching emails:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchEmails();
    }, [page, community_id, id, campaign_id]);

    const edit = async (status) => {
        navigate(Navigate.newsletterCampaignUpdatePage(id, community_id, campaign_id));

    };

    const send = async (status) => {
        navigate(Navigate.newsletterCampaignSendPage(id, community_id, campaign_id));

    };

    const toggleEmailContent = (emailId) => {
        setEmails(prevEmails =>
            prevEmails.map(email =>
                email.id === emailId ? { ...email, showContent: !email.showContent } : email
            )
        );
    };

    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: campaign?.subject, link: Navigate.newsletterCampaignViewPage(newsletter?.id, community_id, campaign?.id) }]}
                />
            </div>

            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">View Campaign</h2>
                        <p className="lead">See the information about the campaign below.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                {isLoading && page === 1 ? (
                    <Loading />
                ) : (
                    <>
                        <div className="card mb-4 shadow-sm">
                            <div className="card-header bg-primary text-white">
                                <h3 className="mb-0">
                                    <i className="fas fa-envelope-open-text mr-2"></i> Campaign Details
                                </h3>
                            </div>
                            <div className="card-body text-black">
                                <h4 className='text-black'>{campaign.subject}</h4>
                                <p>Status: <span className={`badge ${getStatusBadgeClass(campaign.status)} text-black`}>{campaign.status}</span></p>
                                <p>Scheduled At: {campaign.scheduled_at || 'Not scheduled'}</p>
                                <p>Sent At: {campaign.sent_at || 'Not sent'}</p>
                                <p>Categories: {campaign.categories?.join(', ') || 'No categories'}</p>

                                <div className="mt-3">
                                    
                                    <button className="btn btn-info mr-2 me-2" onClick={() => edit()} disabled={campaign.status === 'sent'}>
                                        <i className="fas fa-pencil-alt"></i> Edit Campaign
                                    </button>
                                    <button className="btn btn-success me-2" onClick={() => send()} disabled={campaign.status === 'sent'}>
                                        <i className="fas fa-envelope-open-text"></i> Send Campaign
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="card shadow-sm">
                            <div className="card-header bg-secondary text-white d-flex justify-content-between align-items-center">
                                <h3 className="mb-0">
                                    <i className="fas fa-envelope mr-2"></i> Sent Emails
                                </h3>
                            </div>
                            <div className="card-body">
                                {emails.length > 0 ? (
                                    <div className="list-group">
                                        {emails.map((email, index) => (
                                            <div
                                                ref={emails.length === index + 1 ? lastEmailElementRef : null}
                                                key={email.id}
                                                className="list-group-item"
                                            >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5>{email.subject}</h5>
                                                        <p className="mb-1"><strong>To:</strong> {email.subscriber?.email || 'Unknown'}</p>
                                                        <div className="d-flex flex-wrap">
                                                            <span className="badge bg-success mr-2 me-2">Delivered: {email.delivered_at ? new Date(email.delivered_at).toLocaleString() : 'No'}</span>
                                                            <span className="badge bg-info mr-2 me-2">Opened: {email.opened_at ? new Date(email.opened_at).toLocaleString() : 'No'}</span>
                                                            <span className="badge bg-warning mr-2 me-2">Replied: {email.replied_at ? new Date(email.replied_at).toLocaleString() : 'No'}</span>
                                                            <span className="badge bg-danger mr-2 me-2">Bounced: {email.bounced_at ? new Date(email.bounced_at).toLocaleString() : 'No'}</span>
                                                            <span className="badge bg-dark mr-2 me-2">Spam: {email.marked_as_spam_at ? new Date(email.marked_as_spam_at).toLocaleString() : 'No'}</span>
                                                        </div>
                                                    </div>
                                                    <button className="btn btn-outline-secondary btn-sm" onClick={() => toggleEmailContent(email.id)}>
                                                        {email.showContent ? 'Hide' : 'Show'} Content
                                                    </button>
                                                </div>
                                                {email.showContent && (
                                                    <div className="mt-3">
                                                        <h6>Email Content:</h6>
                                                        <div dangerouslySetInnerHTML={{ __html: email.content }} />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-muted">No emails found for this campaign.</p>
                                )}
                                {isLoading && <Loading />}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

// Helper function to get the badge class for campaign status
const getStatusBadgeClass = (status) => {
    switch (status) {
        case 'draft':
            return 'badge-warning';
        case 'scheduled':
            return 'badge-info';
        case 'sent':
            return 'badge-success';
        default:
            return 'badge-secondary';
    }
};

export default NewsletterCampaignViewPage;
