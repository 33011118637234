import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Switch from "react-switch";
import { Accordion } from 'react-bootstrap';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import SchedulerSocialForm from '../../component/section/schedulers/schedule_social_form';
import Loading from '../../component/alerts/Loading';

const SchedulerSocialPage = () => {
    const [scheduler, setScheduler] = useState({});
    const [copySuccess, setCopySuccess] = useState('');
    const { id } = useParams();
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Scheduler.getSchedule(id).then(response => {
            setScheduler(response.data.data);

            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });
        }).catch(error => {
            console.error(error);
        });
    }, [id]);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const update = () => {
        
        Glitch.api.Scheduler.updateSchedule(scheduler.id, scheduler).then(response => {
            setScheduler(response.data.data);

            navigate(Navigate.schedulerUpdatesCreatePage(scheduler.id));
        }).catch(error => {

        })
    };


    return (
        <>
            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">Connect Social Accounts To Scheduler</h2>
                        <p className="lead">Allows the schedule to auto post for you.</p>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="card">
                    <div className="card-header bg-primary">
                        <h2>Manage Social Accounts</h2>
                    </div>
                    <div className='container mt-1'>
                        <p className='lead'>To successfully schedule social media content, you need to connect your social media accounts to the platform. Please connect your accounts below.</p>
                    </div>
                 
                        <SchedulerSocialForm schedulerData={scheduler} setSchedulerData={setScheduler} />
                    

                </div>
                <button type="button" name='nextStep' onClick={update} className="btn btn-primary btn-lg">{isLoading ? <Loading /> : 'Next Step'}</button>

            </div>

            <Footer />
        </>
    );
};

export default SchedulerSocialPage;
