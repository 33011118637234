import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import Glitch from 'glitch-javascript-sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faEnvelope,
    faStar,
    faChevronDown,
    faChevronUp,
    faGamepad,
    faVideo,
    faDesktop,
    faCalendarAlt,
    faCodeBranch,
    faGlobe,
    faUserFriends,
    faPuzzlePiece,
    faMicrochip,
    faThumbsUp,
    faDollarSign,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'react-bootstrap';

const MediakitStandalonePage = () => {
    const [mediakit, setMediakit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [videoEmbedUrl, setVideoEmbedUrl] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        Glitch.api.Titles.view(id)
            .then((response) => {
                const mediakitData = response.data.data;
                setMediakit(mediakitData);
                setIsLoading(false);

                // Set video embed URL based on video_url
                if (mediakitData.video_url) {
                    let embedUrl = null;
                    if (mediakitData.video_url.includes('youtube.com') || mediakitData.video_url.includes('youtu.be')) {
                        const videoId = mediakitData.video_url.split('v=')[1] || mediakitData.video_url.split('/').pop();
                        embedUrl = `https://www.youtube.com/embed/${videoId}`;
                    } else if (mediakitData.video_url.includes('vimeo.com')) {
                        const videoId = mediakitData.video_url.split('/').pop();
                        embedUrl = `https://player.vimeo.com/video/${videoId}`;
                    }
                    setVideoEmbedUrl(embedUrl);
                }
            })
            .catch((error) => {
                console.error('Error fetching mediakit:', error);
                setIsLoading(false);
            });
    }, [id]);

    const toggleAdditionalInfo = () => {
        setShowAdditionalInfo(!showAdditionalInfo);
    };

    if (isLoading) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="spinner-border text-primary" role="status"></div>
            </div>
        );
    }

    if (!mediakit) {
        return (
            <div className="d-flex align-items-center justify-content-center vh-100">
                <h2>Media Kit not found.</h2>
            </div>
        );
    }

    return (
        <Fragment>
            {/* Fullscreen Banner */}
            {mediakit.image_banner && (
                <div
                    className="bg-image"
                    style={{
                        backgroundImage: `url(${mediakit.image_banner})`,
                        height: '100vh',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'relative',
                        color: 'white',
                    }}
                >
                    <div
                        className="overlay"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                    ></div>
                    <div
                        className="d-flex flex-column justify-content-center align-items-center h-100"
                        style={{ position: 'relative', zIndex: 1 }}
                    >
                        {mediakit.image_main && (
                            <img
                                src={mediakit.image_main}
                                alt={mediakit.name}
                                className="img-fluid rounded-circle mb-3"
                                style={{
                                    width: '200px',
                                    height: '200px',
                                    objectFit: 'cover',
                                    border: '5px solid white',
                                }}
                            />
                        )}
                        <h1 className="display-2 text-center">{mediakit.name}</h1>
                        {mediakit.tagline && (
                            <p className="lead text-center">{mediakit.tagline}</p>
                        )}
                        <div className="mt-4">
                            {mediakit.demo_url && (
                                <a
                                    href={mediakit.demo_url}
                                    className="btn btn-primary btn-lg mx-2"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faDownload} /> Download Demo
                                </a>
                            )}
                            {mediakit.website_url && (
                                <a
                                    href={mediakit.website_url}
                                    className="btn btn-outline-light btn-lg mx-2"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faDesktop} /> Visit Website
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Content Section */}
            <div className="container py-5">
                {/* Video Section */}
                {videoEmbedUrl && (
                    <div className="mb-5">
                        <h2 className="text-center mb-4">
                            <FontAwesomeIcon icon={faVideo} /> Trailer
                        </h2>
                        <div className="ratio ratio-16x9 shadow-lg">
                            <iframe
                                src={videoEmbedUrl}
                                title="Media Kit Video"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                )}

                {/* Key Features */}
                {mediakit.key_features && (
                    <div className="mb-5">
                        <h2 className="text-center mb-4">
                            <FontAwesomeIcon icon={faStar} /> Key Features
                        </h2>
                        <div
                            className="lead"
                            dangerouslySetInnerHTML={{ __html: mediakit.key_features }}
                        ></div>
                    </div>
                )}

                {/* Short Description */}
                {mediakit.short_description && (
                    <div className="mb-5">
                        <h2 className="text-center mb-4">
                            <FontAwesomeIcon icon={faGamepad} /> About the Game
                        </h2>
                        <div
                            className="lead text-center"
                            dangerouslySetInnerHTML={{ __html: mediakit.short_description }}
                        ></div>
                    </div>
                )}

                {/* Additional Information */}
                <div className="mb-5">
                    <div
                        className="d-flex justify-content-between align-items-center mb-3"
                        onClick={toggleAdditionalInfo}
                        style={{ cursor: 'pointer' }}
                    >
                        <h2>
                            <FontAwesomeIcon icon={faChevronDown} /> Additional Information
                        </h2>
                        <FontAwesomeIcon
                            icon={showAdditionalInfo ? faChevronUp : faChevronDown}
                            size="2x"
                        />
                    </div>
                    <Collapse in={showAdditionalInfo}>
                        <div>
                            {mediakit.long_description && (
                                <div className="mb-4">
                                    <h3>Detailed Description</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mediakit.long_description,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {mediakit.system_requirements && (
                                <div className="mb-4">
                                    <h3>System Requirements</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mediakit.system_requirements,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {mediakit.gameplay_mechanics && (
                                <div className="mb-4">
                                    <h3>Gameplay Mechanics</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mediakit.gameplay_mechanics,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {mediakit.narrative_setting && (
                                <div className="mb-4">
                                    <h3>Narrative Setting</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mediakit.narrative_setting,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {mediakit.visual_audio_style && (
                                <div className="mb-4">
                                    <h3>Visual and Audio Style</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mediakit.visual_audio_style,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {mediakit.dlc_expansion_info && (
                                <div className="mb-4">
                                    <h3>DLC and Expansion Info</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mediakit.dlc_expansion_info,
                                        }}
                                    ></div>
                                </div>
                            )}
                            {mediakit.critical_reception && (
                                <div className="mb-4">
                                    <h3>Critical Reception</h3>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: mediakit.critical_reception,
                                        }}
                                    ></div>
                                </div>
                            )}
                        </div>
                    </Collapse>
                </div>

                {/* Contact Information */}
                {(mediakit.press_contact_name || mediakit.press_contact_email) && (
                    <div className="text-center mb-5">
                        <h2 className="mb-4">
                            <FontAwesomeIcon icon={faEnvelope} /> Press Contact
                        </h2>
                        {mediakit.press_contact_name && (
                            <p>
                                <strong>Name:</strong> {mediakit.press_contact_name}
                            </p>
                        )}
                        {mediakit.press_contact_email && (
                            <p>
                                <strong>Email:</strong>{' '}
                                <a
                                    href={`mailto:${mediakit.press_contact_email}`}
                                    className="text-decoration-none"
                                >
                                    {mediakit.press_contact_email}
                                </a>
                            </p>
                        )}
                    </div>
                )}

                {/* Additional Game Information */}
                <div className="mb-5">
                    <h2 className="text-center mb-4">
                        <FontAwesomeIcon icon={faInfoCircle} /> Game Information
                    </h2>
                    <div className="row">
                        {mediakit.developer && (
                            <div className="col-md-4 mb-3">
                                <strong>Developer:</strong> {mediakit.developer}
                            </div>
                        )}
                        {mediakit.publisher && (
                            <div className="col-md-4 mb-3">
                                <strong>Publisher:</strong> {mediakit.publisher}
                            </div>
                        )}
                        {mediakit.release_date && (
                            <div className="col-md-4 mb-3">
                                <strong>Release Date:</strong>{' '}
                                {new Date(mediakit.release_date).toLocaleDateString()}
                            </div>
                        )}
                        {mediakit.age_rating && (
                            <div className="col-md-4 mb-3">
                                <strong>Age Rating:</strong> {mediakit.age_rating}
                            </div>
                        )}
                        {mediakit.multiplayer_options && (
                            <div className="col-md-4 mb-3">
                                <strong>Multiplayer Options:</strong> {mediakit.multiplayer_options}
                            </div>
                        )}
                        {mediakit.pricing && (
                            <div className="col-md-4 mb-3">
                                <strong>Pricing:</strong> {mediakit.pricing_currency}{' '}
                                {mediakit.pricing}
                            </div>
                        )}
                        {mediakit.availability && (
                            <div className="col-md-4 mb-3">
                                <strong>Availability:</strong> {mediakit.availability}
                            </div>
                        )}
                        {mediakit.platform_compatibility && mediakit.platform_compatibility.length > 0 && (
                            <div className="col-md-4 mb-3">
                                <strong>Platform Compatibility:</strong>{' '}
                                {mediakit.platform_compatibility}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Footer (Optional) */}
            <div className="bg-dark text-white text-center py-4">
                <p className="mb-0">
                    &copy; {new Date().getFullYear()} {mediakit.name}. All rights reserved.
                </p>
            </div>
        </Fragment>
    );
};

export default MediakitStandalonePage;
