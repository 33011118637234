import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet';

const PublisherTutorialCampaignsChecklistPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet>
                <title>Campaign Checklist: How to Launch Your Campaign on Glitch | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn the essential steps to launch and manage your campaign on Glitch. This tutorial covers everything from adding media to sourcing influencers."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="Campaign Checklist: How to Launch Your Campaign on Glitch | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial walks you through the key steps to launch a successful campaign on Glitch, including adding media, updating links, and inviting influencers."
                />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Campaign Checklist: How to Launch Your Campaign on Glitch | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn the essential steps to launch and manage your campaign on Glitch. This tutorial covers everything from adding media to sourcing influencers."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Campaign Checklist: How to Launch Your Campaign on Glitch</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/Dh-lYPsmtP8"
                                title="Campaign Checklist: How to Launch Your Campaign on Glitch"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            Congratulations on starting your campaign on Glitch! Now that you've begun, it's time to ensure everything is in place for a successful launch. This tutorial will guide you through the essential steps you need to take.
                        </p>

                        <h4>Step 1: Add Media to Your Campaign</h4>
                        <p>
                            The first and most important step is to add media to your campaign, such as pictures and videos of your game. Influencers are visually driven, so the more exciting your game looks, the more likely they are to engage with it. Pages lacking imagery are less likely to attract attention, whereas vibrant descriptions and engaging visuals are far more effective at sparking interest.
                        </p>

                        <p>
                            To update your media, go to the game editor, click on the ‘Game Title,’ and scroll down to the media section. Here, you can upload images and set videos like a YouTube or Vimeo promo for your game.
                        </p>

                        <h4>Step 2: Add Affiliate Links</h4>
                        <p>
                            Next, add affiliate links to your campaign. These links could direct users to your Steam page, iOS or Android store pages, your website, or other relevant destinations. By adding these links in the admin dashboard, UTM codes for tracking will be automatically added, allowing you to monitor traffic and conversions.
                        </p>

                        <h4>Step 3: Review Campaign Details</h4>
                        <p>
                            Review all fields related to your game, including the description, brief, hashtags, and other pertinent information. When reviewing, consider the influencer's perspective and ensure everything looks appealing and accurate.
                        </p>

                        <h4>Step 4: Activate Your Campaign</h4>
                        <p>
                            Make sure your campaign is set to active. Only active campaigns will be visible on the website and available for influencers to apply to. Additionally, only active campaigns will trigger email invites to be sent out.
                        </p>

                        <h4>Step 5: Share Your Campaign</h4>
                        <p>
                            While Glitch helps promote your game to influencers, you should also share your campaign across your social media accounts, Reddit pages, and other platforms. Use the share link provided to easily copy and paste your campaign details to other sites.
                        </p>

                        <h4>Step 6: Start Sourcing Influencers</h4>
                        <p>
                            Finally, start sourcing influencers by scrolling down to the bottom of the campaign page and accessing our database. You’ll find a comprehensive list of thousands of influencers to choose from. Use the filters to narrow down your search based on keywords, follower counts, and engagement rates. When you find a good fit, simply invite them to your campaign, and you’re all set!
                        </p>

                        <p>
                            By following these steps, you can ensure your campaign is off to a great start on Glitch. Good luck!
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialCampaignsChecklistPage;
