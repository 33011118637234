import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const CrawlPage = () => {
    const [links, setLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await Glitch.api.Campaigns.getActiveCampaignLinks();
                setLinks(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching campaign links:", error);
                setIsLoading(false);
            }
        };

        fetchLinks();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Helmet>
                <title>Active Campaigns</title>
                <meta name="description" content="Browse through a list of active campaigns." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Active Campaigns" />
                <meta property="og:description" content="Browse through a list of active campaigns." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <ul>
                {links?.map((link) => (
                    <li key={link?.id}><Link to={link?.link}>{link?.link}</Link></li>
                ))}
            </ul>
        </div>
    );
};

export default CrawlPage;
