import Glitch from 'glitch-javascript-sdk';
import React, { useState } from 'react';
import Moment from 'react-moment';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faReddit, faTwitch, faFacebook, faYoutube, faTiktok, faTwitter, faKickstarter 
} from '@fortawesome/free-brands-svg-icons';
import { faGamepad, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const DisputeStatus = {
  NoDispute: 0,
  DisputeCreated: 1,
  DisputeInReview: 2,
  DisputeResolvedAsFraud: 3,
  DisputeResolvedAsNotFraud: 4,
};

// Calculate earnings based on influencer campaign rates and post metrics
const calculateEarnings = (post) => {
  const { influencer_campaign, social_platform, total_views, total_comments, total_engagements, total_shares, total_reactions, total_bookmarks } = post;

  if (!influencer_campaign) return 0;

  let earnings = 0;

  const getPayment = (specific, general) => (specific !== undefined && specific !== null && specific != 0 ? specific : general);

  switch (social_platform) {
    case 'reddit':
      earnings += getPayment(influencer_campaign.payment_per_view_reddit, influencer_campaign.payment_per_view) * total_views;
      earnings += getPayment(influencer_campaign.payment_per_comment_reddit, influencer_campaign.payment_per_comment) * total_comments;
      earnings += getPayment(influencer_campaign.payment_per_share_reddit, influencer_campaign.payment_per_share) * total_shares;
      earnings += getPayment(influencer_campaign.payment_per_engagement_reddit, influencer_campaign.payment_per_engagement) * total_engagements;
      earnings += getPayment(influencer_campaign.payment_per_click_reddit, influencer_campaign.payment_per_click) * total_reactions;
      earnings += getPayment(influencer_campaign.payment_per_install_reddit, influencer_campaign.payment_per_install) * total_bookmarks;
      break;
    case 'twitch':
      earnings += getPayment(influencer_campaign.payment_per_view_twitch, influencer_campaign.payment_per_view) * total_views;
      earnings += getPayment(influencer_campaign.payment_per_comment_twitch, influencer_campaign.payment_per_comment) * total_comments;
      earnings += getPayment(influencer_campaign.payment_per_share_twitch, influencer_campaign.payment_per_share) * total_shares;
      earnings += getPayment(influencer_campaign.payment_per_engagement_twitch, influencer_campaign.payment_per_engagement) * total_engagements;
      earnings += getPayment(influencer_campaign.payment_per_click_twitch, influencer_campaign.payment_per_click) * total_reactions;
      earnings += getPayment(influencer_campaign.payment_per_install_twitch, influencer_campaign.payment_per_install) * total_bookmarks;
      break;
    case 'facebook':
      earnings += getPayment(influencer_campaign.payment_per_view_facebook, influencer_campaign.payment_per_view) * total_views;
      earnings += getPayment(influencer_campaign.payment_per_comment_facebook, influencer_campaign.payment_per_comment) * total_comments;
      earnings += getPayment(influencer_campaign.payment_per_share_facebook, influencer_campaign.payment_per_share) * total_shares;
      earnings += getPayment(influencer_campaign.payment_per_engagement_facebook, influencer_campaign.payment_per_engagement) * total_engagements;
      earnings += getPayment(influencer_campaign.payment_per_click_facebook, influencer_campaign.payment_per_click) * total_reactions;
      earnings += getPayment(influencer_campaign.payment_per_install_facebook, influencer_campaign.payment_per_install) * total_bookmarks;
      break;
    case 'youtube':
      earnings += getPayment(influencer_campaign.payment_per_view_youtube, influencer_campaign.payment_per_view) * total_views;
      earnings += getPayment(influencer_campaign.payment_per_comment_youtube, influencer_campaign.payment_per_comment) * total_comments;
      earnings += getPayment(influencer_campaign.payment_per_share_youtube, influencer_campaign.payment_per_share) * total_shares;
      earnings += getPayment(influencer_campaign.payment_per_engagement_youtube, influencer_campaign.payment_per_engagement) * total_engagements;
      earnings += getPayment(influencer_campaign.payment_per_click_youtube, influencer_campaign.payment_per_click) * total_reactions;
      earnings += getPayment(influencer_campaign.payment_per_install_youtube, influencer_campaign.payment_per_install) * total_bookmarks;
      break;
    case 'tiktok':
      earnings += getPayment(influencer_campaign.payment_per_view_tiktok, influencer_campaign.payment_per_view) * total_views;
      earnings += getPayment(influencer_campaign.payment_per_comment_tiktok, influencer_campaign.payment_per_comment) * total_comments;
      earnings += getPayment(influencer_campaign.payment_per_share_tiktok, influencer_campaign.payment_per_share) * total_shares;
      earnings += getPayment(influencer_campaign.payment_per_engagement_tiktok, influencer_campaign.payment_per_engagement) * total_engagements;
      earnings += getPayment(influencer_campaign.payment_per_click_tiktok, influencer_campaign.payment_per_click) * total_reactions;
      earnings += getPayment(influencer_campaign.payment_per_install_tiktok, influencer_campaign.payment_per_install) * total_bookmarks;
      break;
    case 'twitter':
      earnings += getPayment(influencer_campaign.payment_per_view_twitter, influencer_campaign.payment_per_view) * total_views;
      earnings += getPayment(influencer_campaign.payment_per_comment_twitter, influencer_campaign.payment_per_comment) * total_comments;
      earnings += getPayment(influencer_campaign.payment_per_share_twitter, influencer_campaign.payment_per_share) * total_shares;
      earnings += getPayment(influencer_campaign.payment_per_engagement_twitter, influencer_campaign.payment_per_engagement) * total_engagements;
      earnings += getPayment(influencer_campaign.payment_per_click_twitter, influencer_campaign.payment_per_click) * total_reactions;
      earnings += getPayment(influencer_campaign.payment_per_install_twitter, influencer_campaign.payment_per_install) * total_bookmarks;
      break;
    case 'kick':
      earnings += getPayment(influencer_campaign.payment_per_view_kick, influencer_campaign.payment_per_view) * total_views;
      earnings += getPayment(influencer_campaign.payment_per_comment_kick, influencer_campaign.payment_per_comment) * total_comments;
      earnings += getPayment(influencer_campaign.payment_per_share_kick, influencer_campaign.payment_per_share) * total_shares;
      earnings += getPayment(influencer_campaign.payment_per_engagement_kick, influencer_campaign.payment_per_engagement) * total_engagements;
      earnings += getPayment(influencer_campaign.payment_per_click_kick, influencer_campaign.payment_per_click) * total_reactions;
      earnings += getPayment(influencer_campaign.payment_per_install_kick, influencer_campaign.payment_per_install) * total_bookmarks;
      break;
    default:
      break;
  }

  return earnings.toFixed(2);
};

const getPlatformIcon = (platform) => {
  switch (platform) {
    case 'reddit': return faReddit;
    case 'twitch': return faTwitch;
    case 'facebook': return faFacebook;
    case 'youtube': return faYoutube;
    case 'tiktok': return faTiktok;
    case 'twitter': return faTwitter;
    case 'kick': return faKickstarter;
    case 'trovo': return faGamepad; // Assuming Trovo icon
    default: return faQuestionCircle;
  }
};

const getFraudStatusClass = (status) => {
  switch (status) {
    case 'passed': return 'text-success';
    case 'review': return 'text-warning';
    case 'fraudulent': return 'text-danger';
    default: return '';
  }
};

const getDisputeStatusText = (status) => {
  switch (status) {
    case DisputeStatus.NoDispute: return 'No Dispute';
    case DisputeStatus.DisputeCreated: return 'Dispute Created';
    case DisputeStatus.DisputeInReview: return 'In Review';
    case DisputeStatus.DisputeResolvedAsFraud: return 'Resolved as Fraud';
    case DisputeStatus.DisputeResolvedAsNotFraud: return 'Resolved as Not Fraud';
    default: return 'Unknown';
  }
};

const SocialPostMetrics = ({ post, showDisputeButton }) => {
  const [showExplanation, setShowExplanation] = useState(false);
  const [showDisputeModal, setShowDisputeModal] = useState(false);
  const [disputeReason, setDisputeReason] = useState('');
  const [disputeSubmitted, setDisputeSubmitted] = useState(false);

  const toggleExplanation = () => {
    setShowExplanation(!showExplanation);
  };

  const handleDispute = () => {
    Glitch.api.SocialPosts.dispute(post.id, { reason: disputeReason })
      .then((response) => {
        console.log(response);
        setDisputeSubmitted(true);
        setShowDisputeModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="card mb-3">
      <div className="card-body d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={getPlatformIcon(post.social_platform)} size="2x" className="me-3" />
          <div>
            <h5 className="card-title mb-0">{post.title}</h5>
            <small className="text-muted">
              <Moment format="MM-DD-YYYY">{post.created_at}</Moment>
            </small>
            {post.url && (
              <p className="mb-0">
                <a href={post.url} target="_blank" rel="noopener noreferrer" className="text-primary">
                  View Post
                </a>
              </p>
            )}
            <p className="mb-0"><strong>Posted by:</strong> {post.user?.username}</p>
          </div>
        </div>
        <ul className="list-inline mb-0">
          <li className="list-inline-item me-3">
            <strong>Views:</strong> {post?.total_views?.toLocaleString() || 0}
          </li>
          <li className="list-inline-item me-3">
            <strong>Comments:</strong> {post?.total_comments?.toLocaleString() || 0}
          </li>
          <li className="list-inline-item me-3">
            <strong>Engagements:</strong> {post?.total_engagements?.toLocaleString() || 0}
          </li>
          <li className="list-inline-item me-3">
            <strong>Shares:</strong> {post?.total_shares?.toLocaleString() || 0}
          </li>
          <li className="list-inline-item me-3">
            <strong>Reactions:</strong> {post?.total_reactions?.toLocaleString() || 0}
          </li>
          <li className="list-inline-item me-3">
            <strong>Bookmarks:</strong> {post?.total_bookmarks?.toLocaleString() || 0}
          </li>
        </ul>
        <div className="text-center">
          <h5 className="mb-1">Earnings</h5>
          <p className="card-text mb-1">${calculateEarnings(post)}</p>
          <p><strong>Fraud Status Check</strong></p>
          <p className={`mb-0 ${getFraudStatusClass(post.fraud_check_status)}`}>
            {['passed', 'review'].includes(post.fraud_check_status) ? (
              <span onClick={toggleExplanation} className="text-decoration-underline" role="button">
                {post.fraud_check_status.charAt(0).toUpperCase() + post.fraud_check_status.slice(1)}
              </span>
            ) : (
              post.fraud_check_status ? post.fraud_check_status.charAt(0).toUpperCase() + post.fraud_check_status.slice(1) : 'Unknown'
            )}
          </p>
          {showExplanation && post.fraud_check_explanation && (
            <div className="mt-2">
              <p className="small text-muted">{post.fraud_check_explanation}</p>
            </div>
          )}
        </div>
        {showDisputeButton && !disputeSubmitted && !post.dispute_status && (
          <Button variant="danger" onClick={() => setShowDisputeModal(true)}>Dispute</Button>
        )}
      </div>
      {(disputeSubmitted || post.dispute_status) && (
        <div className="card-footer">
          <p className="mb-0"><strong>Dispute Status:</strong> {getDisputeStatusText(post.dispute_status)}</p>
        </div>
      )}

      <Modal show={showDisputeModal} onHide={() => setShowDisputeModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className='text-black'>Dispute Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <p className='text-black'>You are about to dispute the content created by the influencer. If you win the dispute, the influencer will not be paid for this content. Please enter the reason for the dispute. The influencer will be notified, and an investigation will begin.</p>
            <Form.Group controlId="disputeReason">
              <Form.Label className='text-black'>Reason for Dispute</Form.Label>
              <Form.Control as="textarea" rows={3} value={disputeReason} onChange={(e) => setDisputeReason(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDisputeModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleDispute}>Submit Dispute</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SocialPostMetrics;
