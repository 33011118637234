import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import withRouter from "../../../../util/withRouter";

class SignUp extends Component {

    constructor(props){
        super(props);
    }

    registerAsPublisher() {
        this.props.router.navigate("/publishers/onboarding/step1");
    }

    registerAsInfluencer() {
        this.props.router.navigate("/creators/onboarding/step1");
    }

    render() {
        return (
            <Fragment>
                <Header />
                <PageHeader title={'SIGN UP'} curPage={'SignUp'} />
                <div className="signup-section padding-top padding-bottom">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-lg-5 mb-4">
                                {/* Publisher/Developer Button */}
                                <div className="card text-center p-4">
                                    <div className="card-body">
                                        <h4 className="card-title">Register as Publisher/Developer</h4>
                                        <p className="card-text">
                                            Register as a publisher or developer to find influencers who can promote your game to a wider audience.
                                        </p>
                                        <button 
                                            className="btn btn-primary w-100" 
                                            onClick={() => this.registerAsPublisher()}
                                        >
                                            Get Started as Publisher/Developer
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5 mb-4">
                                {/* Influencer Button */}
                                <div className="card text-center p-4">
                                    <div className="card-body">
                                        <h4 className="card-title">Register as Influencer</h4>
                                        <p className="card-text">
                                            Register as an influencer to discover new and interesting games to play and promote to your followers.
                                        </p>
                                        <button 
                                            className="btn btn-secondary w-100" 
                                            onClick={() => this.registerAsInfluencer()}
                                        >
                                            Get Started as Influencer
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}

export default withRouter(SignUp);
