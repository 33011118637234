import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';
import GameTitleForm from '../../component/section/titles/title_form';

const MediakitUpdatePage = () => {
    const [mediakit, setMediakit] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [gameMainImageBlob, setMainImageBlob] = useState(null);
    const [gameBannerImageBlob, setBannerImageBlob] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        Glitch.api.Titles.view(id)
            .then((response) => {
                setMediakit(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching mediakit', error);
            });
    }, [id, navigate]);

    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            // Ensure is_media_kit is set to true
            const mediakitData = { ...mediakit, is_media_kit: true };

            await Glitch.api.Titles.update(mediakit.id, mediakitData);

            const uploadPromises = [];

            if (gameMainImageBlob) {
                uploadPromises.push(
                    Glitch.api.Titles.uploadMainImageBlob(mediakit.id, gameMainImageBlob)
                );
            }

            if (gameBannerImageBlob) {
                uploadPromises.push(
                    Glitch.api.Titles.uploadBannerImageBlob(mediakit.id, gameBannerImageBlob)
                );
            }

            if (uploadPromises.length > 0) {
                await Promise.all(uploadPromises);
            }

            navigate(Navigate.mediakitsPublishersViewPage(mediakit.id));
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Media Kits', link: Navigate.mediakitsPublishersListPage() },
                        { name: mediakit?.name || 'Loading...', link: Navigate.mediakitsPublishersViewPage(id) },
                        { name: 'Update Media Kit', link: Navigate.mediakitsPublishersUpdatePage(id) },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Update Your Media Kit</h2>
                        <p className="lead">Update the details about your media kit below.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {mediakit ? (
                    <GameTitleForm
                        gameTitle={mediakit}
                        campaignData={{}}
                        setGameTitle={setMediakit}
                        setMainImageBlob={setMainImageBlob}
                        setBannerImageBlob={setBannerImageBlob}
                        errors={errors}
                        showPressKit={true}
                        isCreate={false}
                    />
                ) : (
                    <Loading />
                )}

                {errors && Object.keys(errors).length > 0 && (
                    <Danger message="You have errors in your submission." />
                )}

                <div className="mt-4 text-center">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-success mx-2"
                        disabled={isLoading}
                    >
                        {isLoading ? <Loading /> : 'Update Media Kit'}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default MediakitUpdatePage;
