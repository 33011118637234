import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PublisherHeader from '../../component/layout/publisherheader';
import Breadcrumbs from '../../component/layout/breadcrumb';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import Wysiwyg from '../../component/form/wysiwyg';
import { Button, Form, Row, Col, Card, Alert } from 'react-bootstrap';
import Navigate from '../../../../util/Navigate';

const CampaignsInviteInfluencerPage = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [influencer, setInfluencer] = useState({});
    const [campaign, setCampaign] = useState({});
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [formData, setFormData] = useState({
        email: '',
        first_name: '',
        location: '',
        speaking_language: '',
        instagram_username: '',
        instagram_follower_count: '',
        tiktok_username: '',
        tiktok_follower_count: '',
        youtube_title: '',
        youtube_subscriber_count: '',
        facebook_username: '',
        facebook_follower_count: '',
        twitch_username: '',
        twitch_follower_count: '',
        reddit_username: '',
        reddit_follower_count: '',
        twitter_username: '',
        twitter_follower_count: '',
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [customEmail, setCustomEmail] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [emailBody, setEmailBody] = useState('');

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            const currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Campaigns.view(id)
            .then((response) => {
                setCampaign(response.data.data);
                getEmailTemplates(response.data.data.community_id);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id, navigate]);

    const getEmailTemplates = (community_id) => {
        Glitch.api.Communities.listEmailTemplates(community_id)
            .then((response) => {
                setEmailTemplates(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.first_name) newErrors.first_name = 'First name is required';

        // Check if at least one social media profile is filled
        if (
            !formData.instagram_username &&
            !formData.tiktok_username &&
            !formData.youtube_title &&
            !formData.facebook_username &&
            !formData.twitch_username &&
            !formData.reddit_username &&
            !formData.twitter_username
        ) {
            newErrors.social_media = 'At least one social media profile is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!validateForm()) return;
    
        setLoading(true);
        Glitch.api.Influencers.addInfluencer(formData)
            .then((response) => {
                const influencer = response.data.data;
                const influencer_id = influencer.id;
    
                // If a custom email is selected, send the invite with the custom email
                const customEmailBody = customEmail ? emailBody : null;
    
                return Glitch.api.Campaigns.sendInfluencerInvite(id, {
                    influencer_id: influencer_id,
                    custom_invite_email: customEmailBody,
                }).then(() => {
                    // Navigate to the influencer invite view page
                    navigate(Navigate.campaignsViewInfluencerInvite(id, influencer_id));
                });
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
    
                // Extract and display error messages
                if (error.response && error.response.data) {
                    const apiErrors = error.response.data;
                    const newErrors = {};
    
                    Object.keys(apiErrors).forEach((key) => {
                        newErrors[key] = apiErrors[key].join(', ');
                    });
    
                    setErrors(newErrors);
                } else {
                    setErrors({ submit: 'Failed to send invite, please try again.' });
                }
            });
    };

    const handleTemplateChange = (e) => {
        const template_id = e.target.value;
        setSelectedTemplate(template_id);

        Glitch.api.Communities.populateEmailTemplate(campaign.community_id, template_id, {
            campaign_id: campaign.id,
        })
            .then((response) => {
                setEmailBody(response.data.data.html_version);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs
                            items={[
                                { name: 'Campaigns', link: Navigate.campaignsPage() },
                                { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                                { name: 'Find Influencers', link: Navigate.campaignsFindInfluencers(campaign.id) },
                                { name: 'Manual Invite', link: Navigate.campaignsInviteInfluencer(campaign.id) },
                            ]}
                        />
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title">Manually Invite Influencer</h2>
                            <p className="lead">
                                If we do not have an influencer in our database but you still want to invite them, you can do so by filling out the form below.
                            </p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />

                    <Card className="mt-5">
                        <Card.Header>
                            <h3  className='text-black'>Complete The Form Below</h3>
                        </Card.Header>
                        <Card.Body>
                            <p>Manually invite an influencer to your campaign that is not in our database.</p>
                            {errors.submit && <Alert variant="danger">{errors.submit}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="first_name"
                                                value={formData.first_name}
                                                onChange={handleInputChange}
                                                isInvalid={!!errors.first_name}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                               

                                {/* Social Media Profiles */}
                                <h5 className='text-black'>Social Media Profiles (At least one required)</h5>
                                {errors.social_media && <Alert variant="danger">{errors.social_media}</Alert>}
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Instagram Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="instagram_username"
                                                value={formData.instagram_username}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>TikTok Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="tiktok_username"
                                                value={formData.tiktok_username}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>YouTube Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="youtube_title"
                                                value={formData.youtube_title}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Facebook Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="facebook_username"
                                                value={formData.facebook_username}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Twitch Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="twitch_username"
                                                value={formData.twitch_username}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Twitter Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="twitter_username"
                                                value={formData.twitter_username}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Reddit Username</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="reddit_username"
                                                value={formData.reddit_username}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Email Option Selection */}
                                <h5 className='text-black'>Email Options</h5>
                                <Form.Check
                                    type="radio"
                                    label="Automatic Email"
                                    name="emailOption"
                                    id="automaticEmail"
                                    onChange={() => setCustomEmail(false)}
                                    checked={!customEmail}
                                />
                                <Form.Check
                                    type="radio"
                                    label="Custom Email"
                                    name="emailOption"
                                    id="customEmail"
                                    onChange={() => setCustomEmail(true)}
                                    checked={customEmail}
                                />

                                {customEmail && (
                                    <div className="mt-3">
                                        <p>We will automatically write and send an email on your behalf. But if you have a custom email you want to sent, fill out what you want to be displayed in the email below, and that email be sent.</p>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Select Email Template (optional)</Form.Label>
                                            <Form.Select onChange={handleTemplateChange} value={selectedTemplate || ''}>
                                                <option value="">-- Select Template --</option>
                                                {emailTemplates.map((template) => (
                                                    <option key={template.id} value={template.id}>
                                                        {template.name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                            <p className='small'>If you have written custom email templates, you can enter them in the message box below. Custom email templates can modified <Link to={Navigate.communitiesEmailTemplatesPage(campaign.community_id)}>here</Link>.</p>
                                        </Form.Group>

                                        <Wysiwyg onChange={setEmailBody} >{emailBody}</Wysiwyg>
                                    </div>
                                )}

                                <Button variant="primary" type="submit" disabled={loading}>
                                    {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Send Invite'}
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </Fragment>
        </>
    );
};

export default CampaignsInviteInfluencerPage;
