import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Loading from '../../component/alerts/Loading';
import Navigate from '../../../../util/Navigate';

const NewsletterSubscribersPage = () => {
    const [newsletter, setNewsletter] = useState({});
    const [subscribers, setSubscribers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState('created_at');
    const [order, setOrder] = useState('desc');
    const [appliedFilter, setAppliedFilter] = useState({ filter: '', sort: 'created_at', order: 'desc' });
    const navigate = useNavigate();
    const { id, community_id } = useParams();

    const observer = useRef();
    const lastSubscriberElementRef = useCallback(node => {
        if (isLoading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore]);

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
            return;
        }

        const fetchNewsletterDetails = async () => {
            try {
                const response = await Glitch.api.Communities.viewNewsletter(community_id, id);
                setNewsletter(response.data.data);
            } catch (error) {
                console.error('Error fetching newsletter:', error);
            }
        };

        fetchNewsletterDetails();
    }, [community_id, id, navigate]);

    useEffect(() => {
        const fetchSubscribers = async () => {
            setIsLoading(true);
            try {
                const response = await Glitch.api.Communities.listNewsletterSubscribers(community_id, id, {
                    page,
                    per_page: 10,
                    filter: appliedFilter.filter,
                    sort: appliedFilter.sort,
                    order: appliedFilter.order
                });
                setSubscribers(prevSubscribers => [...prevSubscribers, ...response.data.data]);
                setHasMore(response.data.data.length > 0);
            } catch (error) {
                console.error('Error fetching subscribers:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSubscribers();
    }, [page, appliedFilter, community_id, id]);

    const handleApplyFilter = () => {
        setPage(1);
        setSubscribers([]);
        setAppliedFilter({ filter, sort, order });
    };

    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: 'Subscribers', link: Navigate.newsletterSubscribersPage() }
                ]} />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">
                            Newsletter: {newsletter?.name || 'Loading...'}
                        </h2>
                        <p className="lead">
                            Manage subscribers for this newsletter.
                        </p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading && page === 1 ? (
                    <Loading />
                ) : (
                    <>
                        <div className="card mb-4 shadow-sm">
                            <div className="card-header bg-primary text-white">
                                <h3 className="mb-0">
                                    <i className="fas fa-users mr-2"></i> Subscribers
                                </h3>
                            </div>
                            <div className="card-body">
                                <div className="d-flex mb-3">
                                    <input
                                        type="text"
                                        className="form-control me-2"
                                        placeholder="Filter by name or email"
                                        value={filter}
                                        onChange={(e) => setFilter(e.target.value)}
                                    />
                                    <select
                                        className="form-select me-2"
                                        value={sort}
                                        onChange={(e) => setSort(e.target.value)}
                                    >
                                        <option value="created_at">Date Subscribed</option>
                                        <option value="email">Email</option>
                                        <option value="first_name">First Name</option>
                                    </select>
                                    <select
                                        className="form-select me-2"
                                        value={order}
                                        onChange={(e) => setOrder(e.target.value)}
                                    >
                                        <option value="asc">Ascending</option>
                                        <option value="desc">Descending</option>
                                    </select>
                                    <button className="btn btn-primary" onClick={handleApplyFilter}>
                                        Apply
                                    </button>
                                </div>

                                {subscribers.length > 0 ? (
                                    <div className="list-group">
                                        {subscribers.map((subscriber, index) => (
                                            <div
                                                ref={subscribers.length === index + 1 ? lastSubscriberElementRef : null}
                                                key={subscriber.id}
                                                className="list-group-item"
                                            >
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5 className='text-black'>{subscriber.first_name} {subscriber.last_name}</h5>
                                                        <p className="mb-1">
                                                            <strong>Email:</strong> {subscriber.email}
                                                        </p>
                                                        <p className="mb-1">
                                                            <strong>Subscribed At:</strong> {new Date(subscriber.confirmed_at).toLocaleDateString()}
                                                        </p>
                                                        <p className="mb-1">
                                                            <strong>Unsubscribed:</strong> {subscriber.unsubscribed_at ? 'Yes' : 'No'}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h6 className='text-black'>Category Preferences:</h6>
                                                        <ul className="list-unstyled">
                                                            {Object.keys(subscriber.preferences).map(category => (
                                                                subscriber.preferences[category] && (
                                                                    <li key={category}>
                                                                        <span className="badge bg-secondary me-1">{formatCategoryName(category)}</span>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-muted">No subscribers found for this newsletter.</p>
                                )}
                                {isLoading && <Loading />}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

// Helper function to format category names
const formatCategoryName = (category) => {
    switch (category) {
        case 'game_dev_updates':
            return 'Game Development Updates';
        case 'patches':
            return 'Patches';
        case 'event_announcements':
            return 'Event Announcements';
        case 'exclusive_content_offers':
            return 'Exclusive Content & Offers';
        case 'community_highlights':
            return 'Community Highlights';
        case 'upcoming_games_releases':
            return 'Upcoming Games & New Releases';
        case 'strategy_gameplay_tips':
            return 'Strategy & Gameplay Tips';
        case 'industry_news_trends':
            return 'Industry News & Trends';
        default:
            return category;
    }
};

export default NewsletterSubscribersPage;
