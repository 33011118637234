import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';

const MediakitViewPage = () => {
    const [mediakit, setMediakit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [videoEmbedUrl, setVideoEmbedUrl] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        Glitch.api.Titles.view(id)
            .then((response) => {
                const mediakitData = response.data.data;
                setMediakit(mediakitData);

                // Set video embed URL based on video_url
                if (mediakitData.video_url) {
                    let embedUrl = null;
                    if (mediakitData.video_url.includes('youtube.com') || mediakitData.video_url.includes('youtu.be')) {
                        const videoId = mediakitData.video_url.split('v=')[1] || mediakitData.video_url.split('/').pop();
                        embedUrl = `https://www.youtube.com/embed/${videoId}`;
                    } else if (mediakitData.video_url.includes('vimeo.com')) {
                        const videoId = mediakitData.video_url.split('/').pop();
                        embedUrl = `https://player.vimeo.com/video/${videoId}`;
                    }
                    setVideoEmbedUrl(embedUrl);
                }
            })
            .catch((error) => {
                console.error('Error fetching mediakit', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id, navigate]);

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this media kit? This action cannot be undone.')) {
            try {
                await Glitch.api.Titles.delete(mediakit.id);
                navigate(Navigate.mediakitsPublishersListPage());
            } catch (error) {
                console.error('Error deleting mediakit', error);
            }
        }
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Media Kits', link: Navigate.mediakitsPublishersListPage() },
                        { name: mediakit?.name || 'Loading...', link: Navigate.mediakitsPublishersViewPage(id) },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Media Kit: {mediakit?.name || 'Loading...'}</h2>
                        <p className="lead">View all details about this media kit.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        {/* Media Kit Details */}
                        <div className="card mb-4 shadow-sm">
                            {mediakit.image_banner && (
                                <div
                                    className="card-img-top bg-image"
                                    style={{
                                        backgroundImage: `url(${mediakit.image_banner})`,
                                        height: '300px',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                ></div>
                            )}
                            <div className="card-body">
                                <div className="text-center">
                                    {mediakit.image_main && (
                                        <img
                                            src={mediakit.image_main}
                                            alt={mediakit.name}
                                            className="img-fluid rounded-circle mb-3"
                                            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                        />
                                    )}
                                    <h1 className="card-title">{mediakit.name}</h1>
                                    {mediakit.tagline && (
                                        <p className="lead text-muted">{mediakit.tagline}</p>
                                    )}
                                    <div className="d-flex justify-content-center mt-4">
                                        {mediakit.demo_url && (
                                            <a
                                                href={mediakit.demo_url}
                                                className="btn btn-primary btn-lg mx-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fas fa-download"></i> Download Demo
                                            </a>
                                        )}
                                        {mediakit.website_url && (
                                            <a
                                                href={mediakit.website_url}
                                                className="btn btn-outline-secondary btn-lg mx-2"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fas fa-desktop"></i> Visit Website
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <Link
                                        to={Navigate.mediakitsPublishersUpdatePage(mediakit.id)}
                                        className="btn btn-info mr-2 me-2"
                                    >
                                        <i className="fas fa-edit"></i> Edit Media Kit
                                    </Link>
                                    <Link
                                        to={Navigate.mediakitsPublishersSharePage(mediakit.id)}
                                        className="btn btn-secondary mr-2 me-2"
                                    >
                                        <i className="fas fa-share"></i> Share Media Kit
                                    </Link>
                                    <button className="btn btn-danger" onClick={handleDelete}>
                                        <i className="fas fa-trash-alt"></i> Delete Media Kit
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Video Section */}
                        {videoEmbedUrl && (
                            <div className="card mb-4 shadow-sm">
                                <div className="card-header bg-dark text-white">
                                    <h3>
                                        <i className="fas fa-video"></i> Trailer
                                    </h3>
                                </div>
                                <div className="card-body p-0">
                                    <div className="ratio ratio-16x9">
                                        <iframe
                                            src={videoEmbedUrl}
                                            title="Media Kit Video"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Key Features */}
                        {mediakit.key_features && (
                            <div className="card mb-4 shadow-sm">
                                <div className="card-header bg-primary text-white">
                                    <h3>
                                        <i className="fas fa-star"></i> Key Features
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: mediakit.key_features }}
                                    ></div>
                                </div>
                            </div>
                        )}

                        {/* Short Description */}
                        {mediakit.short_description && (
                            <div className="card mb-4 shadow-sm">
                                <div className="card-header bg-secondary text-white">
                                    <h3>
                                        <i className="fas fa-gamepad"></i> About the Game
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: mediakit.short_description }}
                                    ></div>
                                </div>
                            </div>
                        )}

                        {/* Long Description */}
                        {mediakit.long_description && (
                            <div className="card mb-4 shadow-sm">
                                <div className="card-header bg-secondary text-white">
                                    <h3>
                                        <i className="fas fa-align-left"></i> Detailed Description
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: mediakit.long_description }}
                                    ></div>
                                </div>
                            </div>
                        )}

                        {/* System Requirements */}
                        {mediakit.system_requirements && (
                            <div className="card mb-4 shadow-sm">
                                <div className="card-header bg-secondary text-white">
                                    <h3>
                                        <i className="fas fa-microchip"></i> System Requirements
                                    </h3>
                                </div>
                                <div className="card-body">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: mediakit.system_requirements }}
                                    ></div>
                                </div>
                            </div>
                        )}

                        {/* Contact Information */}
                        {(mediakit.press_contact_name || mediakit.press_contact_email) && (
                            <div className="card mb-4 shadow-sm">
                                <div className="card-header bg-info text-white">
                                    <h3>
                                        <i className="fas fa-envelope"></i> Press Contact
                                    </h3>
                                </div>
                                <div className="card-body">
                                    {mediakit.press_contact_name && (
                                        <p>
                                            <strong>Name:</strong> {mediakit.press_contact_name}
                                        </p>
                                    )}
                                    {mediakit.press_contact_email && (
                                        <p>
                                            <strong>Email:</strong>{' '}
                                            <a
                                                href={`mailto:${mediakit.press_contact_email}`}
                                                className="text-decoration-none"
                                            >
                                                {mediakit.press_contact_email}
                                            </a>
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Additional Game Information */}
                        <div className="card mb-4 shadow-sm">
                            <div className="card-header bg-secondary text-white">
                                <h3>
                                    <i className="fas fa-info-circle"></i> Game Information
                                </h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    {mediakit.developer && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Developer:</strong> {mediakit.developer}
                                        </div>
                                    )}
                                    {mediakit.publisher && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Publisher:</strong> {mediakit.publisher}
                                        </div>
                                    )}
                                    {mediakit.release_date && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Release Date:</strong>{' '}
                                            {new Date(mediakit.release_date).toLocaleDateString()}
                                        </div>
                                    )}
                                    {mediakit.age_rating && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Age Rating:</strong> {mediakit.age_rating}
                                        </div>
                                    )}
                                    {mediakit.multiplayer_options && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Multiplayer Options:</strong>{' '}
                                            {mediakit.multiplayer_options}
                                        </div>
                                    )}
                                    {mediakit.pricing && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Pricing:</strong> {mediakit.pricing_currency}{' '}
                                            {mediakit.pricing}
                                        </div>
                                    )}
                                    {mediakit.availability && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Availability:</strong> {mediakit.availability}
                                        </div>
                                    )}
                                    {mediakit.platform_compatibility && mediakit.platform_compatibility.length > 0 && (
                                        <div className="col-md-4 mb-3">
                                            <strong>Platform Compatibility:</strong>{' '}
                                            {mediakit.platform_compatibility}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

export default MediakitViewPage;
