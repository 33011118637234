import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';

const NewsletterViewPage = () => {
    const [newsletter, setNewsletter] = useState({});
    const [campaigns, setCampaigns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { id, community_id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
            return;
        }

        // Fetch newsletter details and related campaigns
        Glitch.api.Communities.viewNewsletter(community_id, id)
            .then(response => {
                setNewsletter(response.data.data);
                return Glitch.api.Communities.listCampaigns(response.data.data.community_id, response.data.data.id);
            })
            .then(response => {
                setCampaigns(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching newsletter or campaigns', error);
                setIsLoading(false);
            });
    }, [id, navigate]);

    const handleCreateCampaign = () => {
        navigate(Navigate.newsletterCampaignCreatePage(id, community_id));
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Cannot be accessed after email is sent
        </Tooltip>
    );

    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: 'View Newsletter', link: Navigate.newsletterViewPage() },
                ]} />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">
                            Newsletter: {newsletter?.name || 'Loading...'}
                        </h2>
                        <p className="lead">
                            View all details about this newsletter and manage its campaigns.
                        </p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="card mb-4 shadow-sm">
                            <div className="card-header bg-primary text-white">
                                <h3 className="mb-0">
                                    <i className="fas fa-envelope-open-text mr-2"></i> Newsletter Details
                                </h3>
                            </div>
                            <div className="card-body">
                                <h4 className='text-black'>{newsletter?.name}</h4>
                                <p>{newsletter.description || 'No description provided.'}</p>
                                <div>
                                    <strong>Reply Email:</strong> {newsletter.reply_email}
                                </div>
                                <div>
                                    <strong>Auto-Subscribe:</strong> {newsletter.auto_subscribe ? 'Yes' : 'No'}
                                </div>
                                {newsletter.banner_image && (
                                    <div className="mt-3">
                                        <img
                                            src={newsletter.banner_image}
                                            alt="Banner"
                                            className="img-fluid rounded"
                                            style={{ maxHeight: '200px' }}
                                        />
                                    </div>
                                )}
                                <div className="mt-3">
                                    <Link
                                        to={Navigate.newsletterUpdatePage(newsletter.id, newsletter.community_id)}
                                        className="btn btn-outline-primary btn-sm me-2"
                                    >
                                        <i className="fas fa-pencil-alt"></i> Edit Newsletter
                                    </Link>
                                    <Link
                                        to={Navigate.newsletterSubscribersPage(newsletter.id, newsletter.community_id)}
                                        className="btn btn-outline-primary btn-sm me-2"
                                    >
                                        <i className="fas fa-users"></i> View Subscribers
                                    </Link>
                                    <Link
                                        to={Navigate.newsletterSubscribePage(newsletter.id, newsletter.community_id)}
                                        className="btn btn-outline-primary btn-sm"
                                    >
                                        <i className="fas fa-user-plus"></i> Add Subscribers
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="card shadow-sm">
                            <div className="card-header bg-secondary text-white d-flex justify-content-between align-items-center">
                                <h3 className="mb-0">
                                    <i className="fas fa-bullhorn mr-2"></i> Campaigns
                                </h3>
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={handleCreateCampaign}
                                >
                                    <i className="fas fa-plus-circle mr-1"></i> New Campaign
                                </button>
                            </div>
                            <div className="card-body">
                                {campaigns.length > 0 ? (
                                    <div className="list-group">
                                        {campaigns.map(campaign => (
                                            <div key={campaign.id} className="list-group-item">
                                                <div className="d-flex justify-content-between text-black">
                                                    <div>
                                                        <h5 className="mb-1 text-black">{campaign.subject}</h5>
                                                        <small className="text-muted">
                                                            {new Date(campaign.created_at).toLocaleDateString()}
                                                        </small>
                                                    </div>
                                                    <div className="text-black">
                                                        <span className={`badge ${getStatusBadgeClass(campaign.status)} text-black`}>
                                                            {capitalizeFirstLetter(campaign.status)}
                                                        </span>
                                                    </div>
                                                </div>
                                                {campaign.scheduled_at && (
                                                    <div>
                                                        <small className="text-muted text-black">
                                                            Scheduled for: {new Date(campaign.scheduled_at).toLocaleString()}
                                                        </small>
                                                    </div>
                                                )}
                                                {campaign.sent_at && (
                                                    <div>
                                                        <small className="text-muted text-black">
                                                            Sent on: {new Date(campaign.sent_at).toLocaleString()}
                                                        </small>
                                                    </div>
                                                )}
                                                <div className="d-flex mt-3">
                                                    <Link
                                                        to={Navigate.newsletterCampaignViewPage(id, community_id, campaign.id)}
                                                        className="btn btn-outline-secondary btn-sm mr-2 me-2"
                                                    >
                                                       <i className="fas fa-eye"></i> View
                                                    </Link>
                                                    <OverlayTrigger
                                                        overlay={renderTooltip}
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        disabled={!(campaign.status === 'sending' || campaign.status === 'sent')}
                                                    >
                                                        <span className="d-inline-block">
                                                            <Link
                                                                to={Navigate.newsletterCampaignUpdatePage(id, community_id, campaign.id)}
                                                                className="btn btn-outline-primary btn-sm mr-2 me-2"
                                                                onClick={(e) => (campaign.status === 'sending' || campaign.status === 'sent') && e.preventDefault()}
                                                                style={{
                                                                    pointerEvents: (campaign.status === 'sending' || campaign.status === 'sent') ? 'none' : 'auto',
                                                                }}
                                                            >
                                                                <i className="fas fa-pencil-alt"></i> Update
                                                            </Link>
                                                        </span>
                                                    </OverlayTrigger>
                                                    <OverlayTrigger
                                                        overlay={renderTooltip}
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        disabled={!(campaign.status === 'sending' || campaign.status === 'sent')}
                                                    >
                                                        <span className="d-inline-block">
                                                            <Link
                                                                to={Navigate.newsletterCampaignSendPage(id, community_id, campaign.id)}
                                                                className="btn btn-success btn-sm"
                                                                onClick={(e) => (campaign.status === 'sending' || campaign.status === 'sent') && e.preventDefault()}
                                                                style={{
                                                                    pointerEvents: (campaign.status === 'sending' || campaign.status === 'sent') ? 'none' : 'auto',
                                                                }}
                                                            >
                                                                <i className="fas fa-paper-plane"></i> Send
                                                            </Link>
                                                        </span>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="text-muted">No campaigns found for this newsletter.</p>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Fragment>
    );
};

// Helper function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

// Helper function to get the badge class for campaign status
const getStatusBadgeClass = (status) => {
    switch (status) {
        case 'draft':
            return 'badge-warning';
        case 'scheduled':
            return 'badge-info';
        case 'sent':
            return 'badge-success';
        case 'sending':
            return 'badge-secondary';
        default:
            return 'badge-light';
    }
};

export default NewsletterViewPage;
