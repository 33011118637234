import React from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faMoneyBillWave, faBroadcastTower, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Navigate from '../../../../util/Navigate';
import CreatorAppDownload from './creators/creator_app_download';
import CreatorLinks from './creators/creator_links';
import Footer from '../layout/footer';

const Creators = () => {

    const trackDownload = () => {
        if (window.fbq) {
            window.fbq('track', 'Download');
        }
        if (window.ttq) {
            window.ttq.track('Download', {
                "contents": [
                    {
                        "content_id": "1.6.3",
                        "content_type": "product",
                        "content_name": "Glitch App"
                    }
                ],
                "value": 0,
                "currency": "USD"
            });
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Download The Glitch Streaming Studio</title>
                <meta name="description" content="The easiest and fast way to stream content, create clips and post to social media." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Download The Glitch Streaming Studio" />
                <meta property="og:description" content="The easiest and fast way to stream content, create clips and post to social media." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/creators/creators_3.png" />
            </Helmet>

            <a id="goal"></a>
            <div className="container-fluid text-center text-white" style={bannerStyle}>
                <h1 style={{ fontSize: "2.5rem" }}>Glitch Streaming Studio<br /><br />  <span style={{ fontSize: "2.0rem" }}>The #1 Workflow App For Gaming Content Creators</span></h1>
                <form className="contact-form mt-4 small" id="contact-form" method="POST">
                    <div className="form-group w-100 text-center small">
                        <a className="default-button reverse-effect" href="#download"><span>Download</span></a>
                    </div>
                </form>
            </div>



            <section className="py-5 bg-dark text-white">
                <div className="container text-center">
                    <h2>Stream Live in a Few Clicks!</h2>
                    <p className="lead">Our app makes going live as easy as 1, 2, 3! Follow these simple steps:</p>
                    <div className="row text-center mt-4">
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0">
                                <button className="btn btn-primary w-100 mb-3"><strong>1:</strong> Pick Your Game</button>
                                <img src="/assets/images/creators/select_game.gif" alt="Select a Game" className="img-fluid rounded" />
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0">
                                <button className="btn btn-primary w-100 mb-3"><strong>2:</strong> Set Up Your Webcam</button>
                                <img src="/assets/images/creators/webcam.gif" alt="Set Up Webcam" className="img-fluid rounded" />
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card bg-transparent border-0">
                                <button className="btn btn-primary w-100 mb-3"><strong>3:</strong> Hit 'Go Live' and Shine!</button>
                                <img src="/assets/images/creators/go_live.gif" alt="Go Live" className="img-fluid rounded" />
                            </div>
                        </div>
                    </div>
                    <form className="contact-form mt-4" id="contact-form" method="POST">
                        <div className="form-group w-100 text-center">
                            <a className="default-button" href="#download"><span>Download The App</span></a>
                        </div>
                    </form>
                </div>
            </section>

            <section className="py-5 bg-gradient text-white">
                <div className="container text-center">
                    <h2>One Click Multicasting!</h2>
                    <p className="lead">Stream to multiple platforms with just a single click! Set up a custom RTMP URL with ease.</p>
                    <img src="/assets/images/creators/mutlicast_youtube_twitch.gif" alt="Go Live" className="img-fluid rounded mt-3" />

                    <form className="contact-form mt-4" id="contact-form" method="POST">
                        <div className="form-group w-100 text-center">
                            <a className="default-button reverse-effect" href="#download"><span>Download To Multicast</span></a>
                        </div>
                    </form>

                    <div className="mt-4">
                        <img src="/assets/images/creators/twitch.png" alt="Twitch" className="img-fluid mb-2 mx-2" style={{ maxWidth: '50px' }} />
                        <img src="/assets/images/creators/facebook.png" alt="Facebook" className="img-fluid mb-2 mx-2" style={{ maxWidth: '50px' }} />
                        <img src="/assets/images/creators/youtube.png" alt="YouTube" className="img-fluid mb-2 mx-2" style={{ maxWidth: '50px' }} />
                        <img src="/assets/images/creators/trovo.png" alt="Trovo" className="img-fluid mb-2 mx-2" style={{ maxWidth: '50px' }} />
                        <img src="/assets/images/creators/kick.png" alt="Kick" className="img-fluid mb-2 mx-2" style={{ maxWidth: '50px' }} />
                        <img src="/assets/images/creators/rtmp.png" alt="RTMP" className="img-fluid mb-2 mx-2" style={{ maxWidth: '50px' }} />
                        <small className="d-block mt-2">A few of the many platforms you can stream too</small>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-dark text-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img src="/assets/images/creators/video_editing_2.jpg" alt="Video Editing" className="img-fluid rounded" />
                        </div>
                        <div className="col-lg-6">
                            <div className="p-4">
                                <h2>Create & Edit Epic Clips Instantly!</h2>
                                <p className="lead">Slice and dice your epic gameplay moments in mere seconds! Your gameplay is automatically recorded on your computer, so there are no storage fees. Various editing tools include:</p>
                                <ul className="">
                                    <li>Combining clips</li>
                                    <li>Adding text overlays</li>
                                    <li>Incorporating voice-overs</li>
                                    <li>Changing video speeds</li>
                                    <li>Utilizing text-to-speech</li>
                                    <li>Applying transitions</li>
                                    <li>And more!</li>
                                </ul>
                                <Link to={Navigate.creatorsEditing()} className="btn btn-primary mt-3">See All Editing Features <i className="icofont-circled-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 bg-gradient text-white">
                <div className="container text-center">
                    <h2>Easily Post Your Clips To Social Media</h2>
                    <p className="lead">Share your epic moments across multiple platforms and skyrocket your following with just a single click.</p>
                    <div className="d-flex justify-content-center">
                        <img src="/assets/images/creators/tiktok.png" alt="TikTok" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/reddit.png" alt="Reddit" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/facebook.png" alt="Facebook" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/twitter.png" alt="Twitter" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/youtube.png" alt="YouTube" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                        <img src="/assets/images/creators/instagram.png" alt="Instagram" className="m-2" style={{ width: '4rem', height: '4rem' }} />
                    </div>

                    <img src="/assets/images/creators/reddit_posting.gif" alt="Go Live" className="img-fluid rounded mt-3" />

                </div>
                <form className="contact-form mt-4" id="contact-form" method="POST">
                    <div className="form-group w-100 text-center">
                        <a className="default-button" href="#download"><span>Download For Free</span></a>
                    </div>
                </form>
            </section>


            <section className="py-5 bg-dark text-white">
                <div className="container">
                    <div className="section-header text-center">
                        <h2>Feature-Packed Streaming with Glitch!</h2>
                        <p className="lead">Power up your streaming with the best tools and integrations.</p>
                    </div>
                    <div className="row text-center mt-4">
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/creators/streamelements.jpg" alt="Stream Elements" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                            <h4>Stream Elements Integration</h4>
                            <p>Integrate with Stream Elements for a seamless streaming experience.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/creators/layers.png" alt="Overlays" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                            <h4>Custom Overlays</h4>
                            <p>Enhance your stream visuals with customizable overlays.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/creators/alerts.png" alt="Alert Boxes" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                            <h4>Alert Boxes</h4>
                            <p>Engage your audience with real-time alerts and notifications.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/creators/ai.png" alt="A.I Chat Assistant" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                            <h4>A.I Co-Host</h4>
                            <p>Interact with an A.I co-host to banter with you and your followers.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/creators/chat.png" alt="Chat" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                            <h4>Interactive Chat</h4>
                            <p>Engage with your viewers in real-time chat.</p>
                        </div>
                        <div className="col-md-4 mb-4">
                            <img src="/assets/images/creators/emoji_2.png" alt="Emojis" className="img-fluid mb-3" style={{ maxWidth: '50px' }} />
                            <h4>Fun Emojis</h4>
                            <p>Express more with a wide range of emojis.</p>
                        </div>
                    </div>

                    <div className="text-center mt-4">
                        <p>The App Is Free To Download And Use! Scroll Down To Get Started.</p>
                    </div>
                </div>
            </section>



            <CreatorAppDownload />

            <CreatorLinks />
        </>
    );
};

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/creators/image_11.jpeg")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default Creators;
