import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link, useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import PublisherHeader from '../../component/layout/publisherheader';

const NewslettersListPage = () => {
    const [newsletters, setNewsletters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
        }

        const fetchNewsletters = async () => {
            try {
                Glitch.api.Communities.listNewsletters({ page: currentPage }).then((response) => {
                    setNewsletters(response.data.data);
                    setTotalPages(response.data.last_page);
                }).catch(error => {
                    console.error('Error fetching newsletters', error);
                });
            } catch (error) {
                console.error('Error fetching newsletters', error);
            }
        };

        fetchNewsletters();
    }, [currentPage, navigate]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center pt-3">
                            <h2 className="pageheader-title">Newsletters</h2>
                            <p className="lead">Manage your gaming newsletters, view details, or edit them as needed.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5 mb-2">
                    <div className="section-wrapper">
                        <Link className="btn btn-success" to={Navigate.newsletterCreatePage()}>
                            <i className="fas fa-plus-circle"></i> Create A Newsletter
                        </Link>
                    </div>
                </div>

                <div className="container">
                    {newsletters.length > 0 ? (
                        <div className="d-flex flex-column">
                            {newsletters.map(newsletter => (
                                <div key={newsletter.id} className="position-relative mb-3">
                                    <div className="card">
                                        <div className="card-body pb-0">
                                            <div className="row">
                                                
                                                <div className="col-md-12">
                                                    <h5 className="card-title">{newsletter.name}</h5>
                                                    <p className="card-text">
                                                        {newsletter.description}
                                                    </p>
                                                    <p><strong>Reply Email:</strong> {newsletter.reply_email}</p>
                                                    <p><strong>Send Method:</strong> {newsletter.send_method}</p>
                                                    <p><strong>Provider:</strong> {newsletter.provider || 'Glitch Default Email System'}</p>
                                                    <p><strong>Auto-subscribe:</strong> {newsletter.auto_subscribe ? 'Yes' : 'No'}</p>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-wrap justify-content-start mt-4">
                                                <div className="me-2 mb-2">
                                                    <Link className="btn btn-primary btn-sm" to={Navigate.newsletterUpdatePage(newsletter.id, newsletter.community_id)}>
                                                        <i className="fas fa-edit"></i> Update
                                                    </Link>
                                                </div>
                                                <div className="me-2 mb-2">
                                                    <Link className="btn btn-primary btn-sm" to={Navigate.newsletterViewPage(newsletter.id, newsletter.community_id)}>
                                                        <i className="fas fa-bullhorn"></i> Campaigns
                                                    </Link>
                                                </div>
                                                <div className="me-2 mb-2">
                                                    <Link className="btn btn-primary btn-sm" to={Navigate.newsletterSubscribersPage(newsletter.id, newsletter.community_id)}>
                                                        <i className="fas fa-users"></i> View Subscribers
                                                    </Link>
                                                </div>
                                                <div className="me-2 mb-2">
                                                    <Link className="btn btn-primary btn-sm" to={Navigate.newsletterSubscribePage(newsletter.id, newsletter.community_id)}>
                                                        <i className="fas fa-user-plus"></i> Add Subscribers
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="card card-body bg-dark text-center">
                            <p className="lead">No Newsletters Have Been Created</p>
                            <div className="d-flex justify-content-center">
                                <div className="col-auto">
                                    <Link to={Navigate.newsletterCreatePage()} className="btn btn-primary">
                                        Create Your First Newsletter
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}

                    {totalPages > 1 && (
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                                    <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => handlePageChange(page)}>
                                            {page}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    )}
                </div>
            </Fragment>
        </>
    );
};

export default NewslettersListPage;
