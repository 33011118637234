import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alerts from '../../../../util/Alerts';
import { Collapse } from 'react-bootstrap';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';

const PlayTestInviteTestersPage = () => {
    const [playTesters, setPlayTesters] = useState([]);
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTester, setSelectedTester] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState({});
    const [invitationMessage, setInvitationMessage] = useState('');
    const [invitedTesters, setInvitedTesters] = useState({});
    const { id } = useParams(); // title_id
    const navigate = useNavigate();

    const questionsList = [
        { id: 1, text: 'Was the game easy to understand from the start?' },
        { id: 2, text: 'Were there any mechanics that felt confusing or difficult to use?' },
        { id: 3, text: 'How challenging did you find the game overall: too easy, too hard, or just right?' },
        { id: 4, text: 'Did you encounter any bugs or issues during gameplay?' },
        { id: 5, text: 'Were the controls intuitive and responsive? If not, what improvements would you suggest?' },
        { id: 6, text: 'What aspects of the game did you enjoy the most? Why?' },
        { id: 7, text: 'Were there any parts of the game that felt repetitive or boring?' },
        { id: 8, text: 'How long did you feel engaged with the game before it started to lose your attention?' },
        { id: 9, text: 'Would you want to play this game again? Why or why not?' },
        { id: 10, text: 'How likely would you be to recommend this game to a friend?' },
        { id: 11, text: 'Did you feel invested in the story or the game world?' },
        { id: 12, text: 'Were there any characters, settings, or story elements that stood out to you?' },
        { id: 13, text: 'Was there anything about the world or story that felt unclear or hard to follow?' },
        { id: 14, text: 'How would you rate the game’s visuals? Did anything stand out as particularly impressive or lacking?' },
        { id: 15, text: 'How did the audio (music, sound effects, voice acting, etc.) contribute to the experience?' },
        { id: 16, text: 'Were there any audio or visual elements that distracted you from the gameplay?' },
        { id: 17, text: 'Did you experience any difficulties related to visibility, accessibility, or ease of use?' },
        { id: 18, text: 'Were there any features or options you wish the game had to make it easier to play?' },
        { id: 19, text: 'How would you describe the game in a few sentences?' },
        { id: 20, text: 'What is one thing you would change or add to improve the game?' },
        { id: 21, text: 'What was your overall impression of the game?' }
    ];

    const [expandedTesterId, setExpandedTesterId] = useState(null);
    const [showQuestions, setShowQuestions] = useState(false);

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchData = async () => {
            try {
                // Fetch title details
                const titleResponse = await Glitch.api.Titles.view(id);
                setTitle(titleResponse.data.data);

                // Fetch play testers
                const usersResponse = await Glitch.api.Users.list({ is_play_tester: true });
                setPlayTesters(usersResponse.data.data);
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, navigate]);

    const handleInviteClick = (tester) => {
        setSelectedTester(tester);
        const initialQuestions = {};

        if (title) {
            questionsList.forEach((q) => {
                const field = `q_${q.id}_enabled`;
                initialQuestions[field] = title[field] !== false; // If title[field] is false, set to false; else true
            });
        } else {
            // Default to all questions enabled if title data is not available
            questionsList.forEach((q) => {
                const field = `q_${q.id}_enabled`;
                initialQuestions[field] = true;
            });
        }
        setSelectedQuestions(initialQuestions);
        // Set invitationMessage to title.test_instructions
        setInvitationMessage(title?.test_instructions || '');
        setShowModal(true);
    };

    const handleQuestionChange = (e) => {
        const { name, checked } = e.target;
        setSelectedQuestions((prev) => ({ ...prev, [name]: checked }));
    };

    const handleSendInvite = async () => {
        try {
            const data = {
                user_id: selectedTester.id,
                developer_testing_requirements: invitationMessage,
                ...selectedQuestions,
            };
            await Glitch.api.PlayTests.invitePlayTester(id, data);
            setShowModal(false);
            setInvitedTesters((prev) => ({ ...prev, [selectedTester.id]: true }));
            Alerts.display('User Invited!', 'Invitation sent successfully to this user.');
        } catch (error) {
            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Failed To Send Invitation', message);
            } else {
                alert('Failed to send invitation.');
            }
        }
    };

    return (
        <>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingPublishersListPage() },
                        { name: title?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                        { name: 'Invite Play Testers', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Invite Play Testers</h2>
                        <p className="lead">Select play testers to invite for {title?.name || 'Loading...'}</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="d-flex justify-content-start mb-3">
                            <Link
                                to={Navigate.playtestingPublishersViewPage(id)}
                                className="btn btn-primary mr-2 me-2"
                            >
                                <i className="fas fa-undo-alt me-2"></i> Back To Manage Page
                            </Link>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Tester</th>
                                    <th scope="col">Preferred Games</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {playTesters.length > 0 ? (
                                    playTesters.map((tester) => (
                                        <tr key={tester.id}>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={tester.avatar || getInfluencerImage(tester?.influencer)}
                                                        alt={tester.display_name || tester.username}
                                                        className="rounded-circle me-2"
                                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                    />
                                                    <div>
                                                        <strong>{tester.display_name || tester.username}</strong>
                                                        <br />
                                                        <Button
                                                            variant="link"
                                                            size="sm"
                                                            onClick={() =>
                                                                setExpandedTesterId(
                                                                    expandedTesterId === tester.id ? null : tester.id
                                                                )
                                                            }
                                                        >
                                                            {expandedTesterId === tester.id ? 'Hide Details' : 'Show Details'}
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Collapse in={expandedTesterId === tester.id}>
                                                    <div className="mt-2">
                                                        {tester.bio && (
                                                            <p>
                                                                <strong>Bio:</strong> <span dangerouslySetInnerHTML={{ __html: tester.bio }} />
                                                            </p>
                                                        )}
                                                        {tester.influencer_content_type && (
                                                            <p>
                                                                <strong>Content Type:</strong>{' '}
                                                                <span dangerouslySetInnerHTML={{ __html: tester.influencer_content_type }} />
                                                            </p>
                                                        )}
                                                        {tester.influencer_content_theme && (
                                                            <p>
                                                                <strong>Content Theme:</strong>{' '}
                                                                <span dangerouslySetInnerHTML={{ __html: tester.influencer_content_theme }} />
                                                            </p>
                                                        )}
                                                        {tester.influencer_content_unique && (
                                                            <p>
                                                                <strong>Unique Content:</strong>{' '}
                                                                <span dangerouslySetInnerHTML={{ __html: tester.influencer_content_unique }} />
                                                            </p>
                                                        )}
                                                        {tester.influencer_brand_approach && (
                                                            <p>
                                                                <strong>Brand Approach:</strong>{' '}
                                                                <span dangerouslySetInnerHTML={{ __html: tester.influencer_brand_approach }} />
                                                            </p>
                                                        )}
                                                        {tester.influencer_games_why && (
                                                            <p>
                                                                <strong>Games Preference:</strong>{' '}
                                                                <span dangerouslySetInnerHTML={{ __html: tester.influencer_games_why }} />
                                                            </p>
                                                        )}
                                                    </div>
                                                </Collapse>
                                            </td>
                                            <td>
                                                {tester.types && tester.types.length > 0
                                                    ? tester.types.map((type) => type.name).join(', ')
                                                    : 'N/A'}
                                            </td>
                                            <td>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={() => handleInviteClick(tester)}
                                                    disabled={invitedTesters[tester.id]}
                                                >
                                                    {invitedTesters[tester.id] ? (
                                                        <>
                                                            <i className="fas fa-check-circle"></i> Invited
                                                        </>
                                                    ) : (
                                                        <>
                                                            <i className="fas fa-paper-plane"></i> Invite
                                                        </>
                                                    )}
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            No play testers found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {/* Invite Modal */}
                        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                            <Modal.Header className="text-black" closeButton>
                                <Modal.Title>
                                    Invite {selectedTester?.display_name || selectedTester?.username}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="text-black">
                                <div className="mb-3">
                                    <label htmlFor="invitationMessage">
                                        Testing Instructions:
                                    </label>
                                    <textarea
                                        id="invitationMessage"
                                        className="form-control"
                                        rows="4"
                                        value={invitationMessage}
                                        onChange={(e) => setInvitationMessage(e.target.value)}
                                    ></textarea>
                                </div>

                                <Button
                                    variant="link"
                                    className="p-0 mb-2"
                                    onClick={() => setShowQuestions(!showQuestions)}
                                >
                                    {showQuestions ? 'Hide Questions' : 'Show Questions'}
                                </Button>

                                <Collapse in={showQuestions}>
                                    <div>
                                        <p>Select the questions you want to include in this play test:</p>
                                        <div className="form-check">
                                            {questionsList.map((question) => (
                                                <div key={question.id} className="mb-2">
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`question_${question.id}`}
                                                        name={`q_${question.id}_enabled`}
                                                        checked={selectedQuestions[`q_${question.id}_enabled`] || false}
                                                        onChange={handleQuestionChange}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={`question_${question.id}`}
                                                    >
                                                        {question.text}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Collapse>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={handleSendInvite}>
                                    Send Invitation
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </div>
        </>
    );
};

export default PlayTestInviteTestersPage;
