import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';

const PublisherGamDatabasePage = () => {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Databases to Submit Your Game | Glitch</title>
                <meta name="description" content="Discover the top game databases like IGDB, Giant Bomb, MobyGames, and RAWG to enhance your game's visibility, boost discoverability, and drive marketing success." />
                <meta name="keywords" content="submit game, game databases, IGDB, Giant Bomb, MobyGames, RAWG, game marketing, gaming community, game development" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Top Databases to Submit Your Game | Glitch" />
                <meta property="og:description" content="Explore the best databases for game submissions like IGDB, Giant Bomb, MobyGames, and RAWG, and how they can help boost your game's marketing and awareness." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="/assets/images/databases/databases.webp" />
            </Helmet>
            <Header />
            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Top Game Databases<br /><br /> to Submit Your Game</h1>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-5">
                <h2>Submit Your Game to Top Databases</h2>
                <p className="lead">Submitting your game to leading game databases is crucial for boosting its discoverability, marketing, and overall success. These platforms serve as central hubs for gamers, developers, and industry professionals, ensuring your game gets the attention it deserves.</p>

                <p className="lead">Make sure to add your game to the following recommended databases:</p>

                {/* IGDB Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>IGDB</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/databases/igdb.jpg" className='img-fluid rounded' alt="IGDB" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Submitting your game to IGDB (Internet Game Database) is essential for developers because it enhances marketing and awareness by providing visibility in one of the most comprehensive game databases available. IGDB is integrated with major platforms like Twitch and Amazon, allowing your game to be discovered by millions of gamers and streamers. By listing your game on IGDB, you gain a presence in various gaming communities, leading to increased searchability, detailed reviews, and user-generated content that fosters organic growth. This exposure boosts your game’s credibility, engagement, and long-term success.</p>
                            <Link to={"https://www.igdb.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Giant Bomb Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Giant Bomb</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/databases/giantbomb.png" className='img-fluid rounded' alt="Giant Bomb" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Submitting a game to Giant Bomb connects developers with a passionate gaming community and a powerful platform for building awareness. Giant Bomb operates as a wiki-style site where users engage with reviews, discussions, and edits. By listing your game here, you tap into a community-driven ecosystem where players contribute detailed information, share opinions, and spread word-of-mouth, enhancing your game’s visibility. Additionally, Giant Bomb's content is indexed by search engines, making your game more discoverable, and helping you reach a broad user base for effective marketing.</p>
                            <Link to={"https://www.giantbomb.com/login-signup/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* MobyGames Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>MobyGames</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/databases/mobygames.png" className='img-fluid rounded' alt="MobyGames" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Submitting a game to MobyGames provides access to one of the most detailed and respected video game databases. MobyGames catalogues games across multiple platforms and includes developer credits, helping enhance your professional profile. By submitting your game, you gain visibility among industry professionals, enthusiasts, and journalists who reference the platform for research. The comprehensive game data, screenshots, and user-contributed reviews on MobyGames boost your game's discoverability across the web, improving marketing efforts and increasing awareness within the gaming community.</p>
                            <Link to={"https://www.mobygames.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* RAWG Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>RAWG</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/databases/rawg.jpg" className='img-fluid rounded' alt="RAWG" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Submitting your game to RAWG taps into a community-driven platform that combines game metadata with user reviews and recommendations. RAWG, known as the "Netflix for games," helps players discover new titles based on their gaming history and preferences. By listing your game, you enhance its visibility among a global audience of gamers while benefiting from the platform's integration with social features like tracking game collections and sharing experiences. This social aspect amplifies word-of-mouth marketing, boosting your game's organic reach and contributing to long-term player acquisition and awareness.</p>
                            <Link to={"https://rawg.io/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/databases/databases.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '3rem',
    fontWeight: 'bold',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.8)',
};

export default PublisherGamDatabasePage;
