import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CampaignLinksManager from '../../component/section/campaigns/campaign_links_manager';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import CampaignMentionsManager from '../../component/section/campaigns/campaign_mentions_manager';
import MessageList from '../../component/section/messages/message_list';
import MessageInput from '../../component/section/messages/message_input';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';

const PlayTestMessagePage = () => {

    const [playTest, setPlayTest] = useState(null);
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id, playtest_id } = useParams(); // title_id, playtest_id
    const navigate = useNavigate();
    const [thread, setThread] = useState({});

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchData = async () => {
            try {
                // Fetch play test details
                const playTestResponse = await Glitch.api.PlayTests.show(id, playtest_id);
                setPlayTest(playTestResponse.data.data);

                // Fetch title details
                const titleResponse = await Glitch.api.Titles.view(id);
                setTitle(titleResponse.data.data);
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

    }, [id, playtest_id, navigate]);

    useEffect(() => {
        if (playTest) {
            loadThread();
        }
    }, [playTest]);


    const loadThread = () => {

        let user_id = playTest.user_id
   
        console.log("Play Test", playTest);
        console.log("User_id", user_id);

        Glitch.api.Messages.createOrGetThread({
            users: [
                user_id, Glitch.util.Session.getID()
            ]
        }).then(response => {
            setThread(response.data.data);
        }).catch(error => {

        })

    }

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };


    return (
        <>
            <Fragment>
                <PublisherHeader position="relative" />
                <div className="container mt-4">
                    <Breadcrumbs
                        items={[
                            { name: 'Play Testing', link: Navigate.playtestingPublishersListPage() },
                            { name: title?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                            { name: 'Play Tests', link: '#' },
                        ]}
                    />
                </div>
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title">Messages Thread For {title?.name || 'Loading...'}</h2>
                            <p className="lead">Manage play tests for this game title.</p>
                        </div>
                    </div>
                </section>



                <div className="container my-5">
                    <div className="d-flex justify-content-start mb-3">
                        <Link
                            to={Navigate.playtestingPublishersViewPage(id)}
                            className="btn btn-primary mr-2 me-2"
                        >
                            <i className="fas fa-undo-alt me-2"></i> Back To Manage Page
                        </Link>
                    </div>

                    <MessageList thread={thread} />
                    <MessageInput threadId={thread.id} onMessageSent={loadThread} />

                </div>
            </Fragment>


            <Footer />
        </>
    );
};

export default PlayTestMessagePage;
