import React from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Wysiwyg({ children, name, placeholder, className, value, id, onChange, toolbarOptions = [] }) {

    const safeChildren = (typeof children === 'object' || typeof children === 'function') ? JSON.stringify(children) : children;

    let toolbar = null;


    const defaultToolbarOptions = [

        ['bold', 'italic', 'underline', 'strike'],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']
    ];

    if (toolbarOptions.length > 0) {
        toolbar = toolbarOptions;
    } else {
        toolbar = defaultToolbarOptions;
    }




    return (
        <>
            <ReactQuill
                theme="snow"
                modules={{ toolbar: toolbar }}
                style={{ backgroundColor: "white", color: "black" }}
                name={name}
                placeholder={placeholder || ''}
                className={className}
                id={id}
                value={safeChildren || ''}
                onChange={onChange}
            />
        </>
    );
}
