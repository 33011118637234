import React, { Fragment, useEffect, useState } from 'react';
import GameTitleForm from '../../component/section/titles/title_form';
import timeouts from '../../../../constants/timeouts';
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../component/alerts/Danger';
import { useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faRobot, faEdit, faTachometer, faClipboardList, faCheck } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { faAndroid, faApple, faPlaystation, faSteam, faWindows, faXbox } from '@fortawesome/free-brands-svg-icons';
import SchedulerForm from '../../component/section/schedulers/schedule_form';

function SchedulerCreatePage() {
    const [schedulerData, setSchedulerData] = useState({});
    const [gameTitle, setGameTitle] = useState({});
    const [externalGameTitle, setExternalGameTitle] = useState(null);
    const [errors, setErrors] = useState({});
    const [titleErrors, setTitleErrors] = useState({});
    const [communities, setCommunities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [genders, setGenders] = useState([]);
    const [types, setTypes] = useState([]);
    const [games, setGames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedGameId, setSelectedGameId] = useState(null);
    const [errordGameId, setErrorGameId] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState('Searching for game data...');
    const [loadError, setLoadError] = useState(false);

    const [gameMainImageBlob, setMainImageBlob] = useState(null);
    const [gameBannerImageBlob, setBannerImageBlob] = useState(null);

    const [currentStep, setCurrentStep] = useState(0);
    const totalSteps = 2;

    const stepNames = [
        "Game Info",
        "Scheduler Info",
    ];

    const navigate = useNavigate();

    const loadingMessages = [
        'Searching for game data...',
        'Data Found, parsing...',
        'Understanding game info...',
        'Generating optimized scheduler...',
        'Downloading media assets...',
        'Calibrating targeting...',
        'Setting parameters...',
        'Configuring management tools...',
        'Setting rates for influencers...',
        'Raising power levels...',
        'Checking thrusters...',
        'Lift off!!!'
    ];

    const platformIcons = {
        'Android': faAndroid,
        'iOS': faApple,
        'Steam': faSteam,
        'Windows': faWindows,
        'PlayStation': faPlaystation,
        'Xbox': faXbox
        // Add more platforms as needed
    };

    const renderPlatformIcons = (platforms) => {
        return platforms.map((platform, index) => {
            const icon = platformIcons[platform] || null;
            return icon ? <FontAwesomeIcon className='mx-2' key={index} icon={icon} /> : null;
        });
    };

    const changeLoadingMessage = (index) => {
        if (index < loadingMessages.length) {
            setLoadingMessage(loadingMessages[index]);
            setTimeout(() => changeLoadingMessage(index + 1), 10000);
        }
    };

    const nextStep = () => {
        setCurrentStep(currentStep => Math.min(currentStep + 1, totalSteps));
        window.scrollTo({
            top: document.documentElement.scrollHeight * 0.1,
            behavior: 'smooth'
        });
    };

    const prevStep = () => {
        setCurrentStep(currentStep => Math.max(currentStep - 1, 1));
        window.scrollTo({
            top: document.documentElement.scrollHeight * 0.1,
            behavior: 'smooth'
        });
    };

    const goToStep = (stepNumber) => {

        setCurrentStep(stepNumber);
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSchedulerData(prevInvite => ({
            ...prevInvite,
            [name]: checked,
        }));
    };

    const generateschedulerData = async (game_id) => {
        setIsLoading(true);
        setSelectedGameId(game_id);
        setLoadError(false);
        changeLoadingMessage(0);

        try {
            await Glitch.api.Games.viewGame(game_id);
        } catch (error) {
            console.error(error);
        }

        Glitch.api.Games.createGameScheduler(game_id).then(response => {
            const scheduler = response.data.data.scheduler;
            const title = response.data.data.title;
            const externalGame = response.data.data.game;

            try {
                scheduler.hashtags = `<ul>${scheduler?.hashtags?.map((hashtag, index) => `<li key=${index}>${hashtag}</li>`).join('')}</ul>`;
            } catch (error) {
                console.error(error);
            }

            try {
                const selectedGenders = genders.filter(gender => scheduler.genders?.some(campGender => campGender.id === gender.id));
                setGenders(selectedGenders);
            } catch (error) {
                console.error(error);
            }

            try {
                const selectedTypes = types.filter(type => scheduler.types?.some(campType => campType.id === type.id));
                setTypes(selectedTypes);
            } catch (error) {
                console.error(error);
            }

            try {
                const selectedCountries = countries.filter(country => scheduler.countries?.some(campCountry => campCountry.id === country.id));
                setCountries(selectedCountries);
            } catch (error) {
                console.error(error);
            }

            if (communities && communities.length === 1) {
                title.community_id = communities[0].id;
            }

            //Set default price
            title.pricing = title.pricing || 0;

            setSchedulerData(scheduler);
            setGameTitle(title);
            setExternalGameTitle(externalGame);
            setCurrentStep(1);
        }).catch(error => {
            console.error(error);
            setLoadError(true);
        }).finally(() => {
            setIsLoading(false);
            setSelectedGameId(null);
            setErrorGameId(game_id);
        });
    };

    useEffect(() => {

        if (!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Communities.list({ roles: [Glitch.constants.Roles.ADMINISTRATOR, Glitch.constants.Roles.SUPER_ADMINISTRATOR, Glitch.constants.Roles.MODERATOR], order_by: 'name' }).then(response => {
            setCommunities(response.data.data)
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Users.me().then(response => {
            const me = response.data.data;
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Games.listGames().then(response => {
            setGames(response.data.data);
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Utility.listCountries().then(response => {
            //setCountries(response.data.data);
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Utility.listGenders().then(response => {
            //setGenders(response.data.data);
        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Utility.listTypes().then(response => {
            //setTypes(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        console.log("Old Game Title", gameTitle);

        if (!gameTitle.id) {
            Glitch.api.Titles.create(gameTitle).then((response) => {
                console.log("New Game Title", response.data.data);
                setGameTitle(response.data.data); // This schedules the state update
        
                schedulerData.title_id = response.data.data.id;
                const updatedSchedulerData = { ...schedulerData, title_id: response.data.data.id };
                setSchedulerData(updatedSchedulerData);
        
                // Upload images
                const promises = [];
                if (gameMainImageBlob) {
                    promises.push(Glitch.api.Titles.uploadMainImageBlob(response.data.data.id, gameMainImageBlob));
                }
                if (gameBannerImageBlob) {
                    promises.push(Glitch.api.Titles.uploadBannerImageFile(response.data.data.id, gameBannerImageBlob));
                }
        
                // Wait for all uploads to complete before proceeding
                return Promise.all(promises);
            }).then(() => { 
                
                // Now proceed with creating the scheduler
                return Glitch.api.Scheduler.createSchedule(schedulerData);
            }).then(response => {
                const schedulerId = response.data.data.id;
                navigate(Navigate.schedulerSocialsPage(schedulerId));
            }).catch(error => {
                let jsonErrors = error?.response?.data;
                if (jsonErrors) {
                    setTitleErrors(jsonErrors);
                    setTimeout(() => {
                        setTitleErrors({});
                    }, timeouts.error_message_timeout)
                }
            }).finally(() => {
                setIsLoading(false);
            });
        } else {
            try {
                await Glitch.api.Titles.update(gameTitle.id, gameTitle);
                const schedulerResponse = await Glitch.api.Scheduler.createSchedule(schedulerData);
                const schedulerId = schedulerResponse.data.data.id;

                let promises = [];


                if (promises && promises.length > 0) {
                    await Promise.all(promises);
                }

                navigate(Navigate.schedulerSocialsPage(schedulerId));
            } catch (error) {
                let jsonErrors = error?.response?.data;
                if (jsonErrors) {
                    setErrors(jsonErrors);
                    setTimeout(() => {
                        setErrors({});
                    }, timeouts.error_message_timeout);
                }
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleGameTitleUpdate = (updatedFields) => {
        //setGameTitle({ ...gameTitle, ...updatedFields });
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <div className="container mt-4 mb-5">

                    {currentStep === 0 && (
                        <div className="initial-step text-center">
                            <h2>Create Your Social Scheduler <br /> With A.I. or Manually?</h2>
                            <p>Choose how you want to create your social scheduler.</p>
                            <div className="initial-buttons">
                                <button className="btn btn-primary btn-lg mx-2" name='createSchedulerAI' onClick={() => setCurrentStep('ai')}>
                                    <FontAwesomeIcon icon={faRobot} /> Create with AI
                                </button>
                                <button className="btn btn-secondary btn-lg mx-2" name='createSchedulerManually' onClick={() => setCurrentStep(1)}>
                                    <FontAwesomeIcon icon={faEdit} /> Create Manually
                                </button>
                            </div>
                        </div>
                    )}

                    {currentStep === 'ai' && (
                        <div className="ai-selection text-center">
                            <h2>Find Your Game</h2>
                            <p className='lead'>Our database connects to multiple game stores with your games information. Find the game you want to promote and let AI populate the fields for you. Population can take a minute or two, please be patient.</p>
                            <div className="search-bar">
                                <input type="text" placeholder="Search for your game..." onChange={(e) => {
                                    const search = e.target.value;
                                    if (search.length > 2) {
                                        Glitch.api.Games.listGames({ search }).then(response => {
                                            setGames(response.data.data);
                                        }).catch(error => {
                                            console.error(error);
                                        });
                                    }
                                }} />
                            </div>
                            <div className="game-list">
                                {games.map(game => (
                                    <div key={game.id} className="game-item">
                                        <p>{game.name} - {(game.store === 'steam') ? renderPlatformIcons(['Steam']) : renderPlatformIcons(JSON.parse(game.platforms))}</p>
                                        <button className="btn btn-primary btn-sm" name='selectGameWithAI' onClick={() => generateschedulerData(game.id)} disabled={selectedGameId !== null}>
                                            {selectedGameId === game.id && isLoading ? <><Loading /> {loadingMessage}</> : 'Select Game'}
                                        </button>
                                        {loadError && errordGameId === game.id && (
                                            <div className="alert alert-danger mt-4">
                                                An error has occurred, it happens. Please try to select your game again.
                                            </div>
                                        )}

                                    </div>
                                ))}
                            </div>
                            {isLoading && <Loading />}
                            {isLoading && <p>{loadingMessage}</p>}
                            {loadError && (
                                <div className="alert alert-danger mt-4">
                                    An error has occurred, it happens. Please try to select your game again.
                                </div>
                            )}
                            <p className='mt-4 mb-8'>Don't see your game? <a href="#" onClick={() => setCurrentStep(1)}>Then create it manually.</a></p>
                            <br /><br />
                        </div>
                    )}

                    {currentStep > 0 && currentStep !== 'ai' && (
                        <>
                            <div className="container">
                                <div className='d-flex align-items-center justify-content-between mb-3'>
                                    <Breadcrumbs items={[
                                        { name: 'Schedulers', link: Navigate.schedulerListPage() },
                                        { name: 'Create Scheduler', link: Navigate.schedulerCreatePage() }
                                    ]} />

                                </div>

                                <h2>Create A Social Scheduler: Step {currentStep} of {totalSteps}</h2>
                                <p className="lead">Use the form below to create a social media schedular that will automatically create social media content based on weekly updates that you or your team submit.</p>
                            </div>

                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                {stepNames.map((name, index) => (
                                    <li className="nav-item" key={index}>
                                        <button
                                            className={`nav-link ${currentStep === index + 1 ? 'active' : ''}`}
                                            id={`pills-${name.toLowerCase()}-tab`}
                                            data-toggle="pill"
                                            role="tab"
                                            aria-controls={`pills-${name.toLowerCase()}`}
                                            aria-selected={currentStep === index + 1 ? 'true' : 'false'}
                                            onClick={() => goToStep(index + 1)}
                                            name={"goStep" + (index + 1)}
                                        >
                                            {name}
                                        </button>
                                    </li>
                                ))}
                            </ul>

                            <form onSubmit={handleSubmit}>



                                {currentStep === 1 && <GameTitleForm gameTitle={gameTitle} schedulerData={schedulerData} onUpdate={handleGameTitleUpdate} setGameTitle={setGameTitle} setMainImageBlob={setMainImageBlob} setBannerImageBlob={setBannerImageBlob} errors={titleErrors} externalGameData={externalGameTitle} isCreate={true} communities={communities} requireCommunity={true} />}
                                {currentStep === 2 && <>
                                    <SchedulerForm schedulerData={schedulerData} setSchedulerData={setSchedulerData} setCountries={setCountries} setGenders={setGenders} setTypes={setTypes} communities={communities} isEditMode={false} errors={errors} />

                                    <div className="mt-2 text-center">
                                        {Object.keys(errors).length > 0 &&
                                            Object.keys(errors).map((errorKey) => {
                                                const errorValue = errors[errorKey];

                                                // Check if the error value is an array
                                                if (Array.isArray(errorValue)) {
                                                    return errorValue.map((message, index) => (
                                                        <Danger key={`${errorKey}-${index}`} message={message} />
                                                    ));
                                                }

                                                // If it's not an array (e.g., a string), render it directly
                                                return (
                                                    <Danger key={errorKey} message={errorValue} />
                                                );
                                            })}

                                        {Object.keys(titleErrors).length > 0 &&
                                            Object.keys(titleErrors).map((errorKey) => {
                                                const errorValue = titleErrors[errorKey];

                                                // Ensure errorValue is treated as an array (wrap in array if not already one)
                                                const messages = Array.isArray(errorValue) ? errorValue : [errorValue];

                                                return messages.map((message, index) => (
                                                    <Danger key={`title-${errorKey}-${index}`} message={`Game Title Error: ${message}`} />
                                                ));
                                            })}
                                    </div>
                                </>}

                                <div className="mt-4 text-center">
                                    {currentStep > 1 && (
                                        <button type="button" name='goPreviousStep' className="btn btn-lg btn-secondary ml-3" onClick={prevStep}>
                                            <FontAwesomeIcon icon={faArrowLeft} /> Previous
                                        </button>
                                    )}
                                    {currentStep < totalSteps ? (
                                        <button type="button" name='goNextStep' className="btn btn-lg btn-primary" onClick={nextStep}>
                                            Next <FontAwesomeIcon icon={faArrowRight} />
                                        </button>
                                    ) : (
                                        <button type="button" name='createScheduler' onClick={handleSubmit} className="btn btn-primary btn-lg">{isLoading ? <Loading /> : 'Create Scheduler'}</button>
                                    )}
                                </div>
                            </form>
                            <br /><br /><br />
                        </>
                    )}
                </div>
            </Fragment>
            <style>{`
                .toggle-button {
                    margin: 5px;
                    padding: 5px 15px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    font-size: 12px;
                }
                .toggle-button.visible {
                    background-color: #4CAF50; /* Green */
                    color: white;
                }
                .toggle-button.hidden {
                    background-color: #f44336; /* Red */
                    color: white;
                }
                .initial-step {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 80vh;
                }
                .initial-buttons {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                }
                .ai-selection .search-bar input {
                    width: 80%;
                    padding: 10px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
                .ai-selection .game-list {
                    max-height: 400px;
                    overflow-y: auto;
                    background: white;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
                .ai-selection .game-item {
                    padding: 10px;
                    border-bottom: 1px solid #ccc;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: black;
                    height: 65px;
                }
                .ai-selection .game-item:hover {
                    background-color: #f9f9f9;
                }
                .ai-selection .game-item:last-child {
                    border-bottom: none;
                }
            `}</style>
        </>
    );
}

export default SchedulerCreatePage;

