import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';

const NewsletterSubscribePage = () => {
    const [newsletter, setNewsletter] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [community, setCommunity] = useState({});
    const navigate = useNavigate();
    const { id, community_id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
            return;
        }

        Glitch.api.Communities.view(community_id).then(response => {
            setCommunity(response.data.data);
        })
        .catch(error => {
            console.error('Error fetching newsletter:', error);
        });

        // Fetch newsletter details
        Glitch.api.Communities.viewNewsletter(community_id, id)
            .then(response => {
                setNewsletter(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching newsletter:', error);
                setIsLoading(false);
            });
    }, [id, navigate]);

    const methods = [
        {
            title: "Standalone Sign-Up Form",
            description: "A direct link to a standalone sign-up form, allowing users to subscribe to your email list. This form can be iframed or embedded on your site.",
            icon: "fas fa-external-link-alt",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.communitiesNewsletterStandalone(id)}`, "_blank")
        },
        {
            title: "Form In Community",
            description: "Embed a form in your Glitch Gaming community. Users can join your email list and explore your game’s community hub.",
            icon: "fas fa-users",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.communitiesNewsletterForm(id)}`, "_blank")
        },
        {
            title: "Import XLS or CSV",
            description: "Upload a CSV or XLS file of your contacts to quickly add subscribers to your email list.",
            icon: "fas fa-file-upload",
            action: () => navigate(Navigate.newsletterImportPage(id, community_id))
        }
    ];

    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: 'Add Subscribers', link: Navigate.newsletterSubscribePage() }
                ]} />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">
                            Add Subscribers: {newsletter?.name || 'Loading...'}
                        </h2>
                        <p className="lead">
                            Choose How You Want To Add Subscribers To Your List
                        </p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="row">
                        {methods.map((method, index) => (
                            <div key={index} className="col-md-4 mb-4">
                                <div className="card h-100 shadow-sm border-0">
                                    <div className="card-body text-center">
                                        <div className="icon-container mb-3">
                                            <i className={`${method.icon} fa-3x text-primary`} />
                                        </div>
                                        <h5 className="card-title">{method.title}</h5>
                                        <p className="card-text text-muted">{method.description}</p>
                                        <button
                                            className="btn btn-outline-primary mt-3"
                                            onClick={method.action}
                                        >
                                            Select Method
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="card h-100 shadow-sm border-0">
                            <div className="card-body">
                                <div className="icon-container mb-3">
                                    <i className="fas fa-code fa-3x text-primary" />
                                </div>
                                <h4 className="card-title">API Access</h4>
                                <p className="card-text text-muted">You can add users to your newsletter through our API, and implement the newsletter on your website or even in your game. Here is a short guide on adding users via the API.</p>
                                
                                <h6 className="text-black">Direct API Access</h6>
                                <p>To register a user directly through our API, use the following endpoint:</p>
                                <pre><code>POST /communities/{community?.id}/newsletters/{newsletter?.id}/subscribers</code></pre>
                                <p><strong>Example Request URL:</strong></p>
                                <pre style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '5px' }}><code>POST https://api.glitch.fun/communities/{community?.id}/newsletters/{newsletter?.id}/subscribers</code></pre>
                                
                                <h6>Request Body</h6>
                                <p>Ensure you include the following JSON in the request body:</p>
                                <pre style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '5px' }}><code>
{`{
    "first_name": "Optional first name",
    "last_name": "Optional last name",
    "email": "user@example.com"
}`}
                                </code></pre>
                                
                                <h6 className='text-black'>Example using <code>curl</code></h6>
                                <p>You can also make this request using <code>curl</code>. The IDs used are the actual values to your newsletter:</p>
                                <pre style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '5px' }}><code>
{`curl -X POST https://api.glitch.fun/communities/${community?.id}/newsletters/${newsletter?.id}/subscribers \\
-H "Content-Type: application/json" \\
-d '{
    "first_name": "Optional first name",
    "last_name": "Optional last name",
    "email": "user@example.com"
}'`}
                                </code></pre>
                                
                                <h6>Expected Response</h6>
                                <p>On success, the API returns a 201 status code and a response containing the subscriber's details:</p>
                                <pre style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '5px' }}><code>
{`{
    "id": "subscriber-uuid",
    "community_newsletter_id": "{newsletter_id}",
    "email": "user@example.com",
    "first_name": "First",
    "last_name": "Last",
    "access_token": "subscriber-access-token",
    "unsubscribed_at": null,
    "confirmed_at": "2024-10-20T14:28:23.382Z",
    "preferences": {
        "game_dev_updates": true,
        "patches": false,
        ...
    },
    "created_at": "2024-10-20T14:28:23.382Z",
    "updated_at": "2024-10-20T14:28:23.382Z"
}`}
                                </code></pre>
                                
                                <h6 className="text-black mt-4">Using the JavaScript Library</h6>
                                <p>If you are working within a JavaScript environment, use the <a href="https://www.npmjs.com/package/glitch-javascript-sdk" target="_blank" rel="noopener noreferrer">Glitch JavaScript SDK</a>. Here’s a quick example, the IDs used are the actual values to your newsletter:</p>
                                <pre style={{ backgroundColor: '#f8f9fa', padding: '15px', borderRadius: '5px' }}><code>
{`import Glitch from 'glitch-javascript-sdk';

const data = {
    first_name: "First (optional)",
    last_name: "Last (optional)",
    email: "user@example.com"
};

Glitch.api.Communities.registerNewsletterSubscriber("${community?.id}", "${newsletter?.id}", data)
    .then(() => {
        console.log("Successfully subscribed to the newsletter!");
    })
    .catch(error => {
        console.error("Error during subscription:", error);
    });
`}
                                </code></pre>
                                <p>This approach is flexible and allows you to implement the newsletter subscription within any JavaScript environment, including both Node.js and browser-based applications.</p>

                                <p>The users will be required to double opt-in, which means they will receive an email confirming their opt-in. Please make sure you make this clear after they sign up to the newsletter.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default NewsletterSubscribePage;
