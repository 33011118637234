import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import timeouts from '../../../../constants/timeouts';
import Navigate from '../../../../util/Navigate';
import withRouter from '../../../../util/withRouter';
import Danger from '../../component/alerts/Danger';
import Loading from '../../component/alerts/Loading';
import Footer from '../../component/layout/footer';
import Header from '../../component/layout/header';
import PageHeader from '../../component/layout/pageheader';
import SocialMedia from '../../component/section/socialmedia';
import Glitch from 'glitch-javascript-sdk';
import CreatorTypesForm from '../../component/section/creators/creator_form_types';

const CreatorOnboardinStep3Page = (props) => {

    const [types, setTypes] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isPlayerTester, setIsPlayerTester] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        Glitch.api.Utility.listTypes().then(response => {
            setTypes(response.data.data);
        }).catch(error => {
            console.error("Error loading types", error);
        });

        Glitch.api.Users.me().then(response => {
            setIsPlayerTester(response.data.data.is_play_tester || false);
        }).catch(error => {
            console.error("Error fetching player tester status", error);
        });

    }, []);

    const goToNextScreen = () => {
        const params = new URLSearchParams(window.location.search);
        const redirect = params.get('redirect');

        let nextPageUrl = Navigate.creatorsOnboardingStep4Page();
        if (redirect) {
            nextPageUrl += `?redirect=${encodeURIComponent(redirect)}`;
        }

        navigate(nextPageUrl);
    };

    const togglePlayTester = () => {
        const newStatus = !isPlayerTester;
        setIsPlayerTester(newStatus);

        Glitch.api.Users.update({ is_play_tester: newStatus }).then(response => {
            console.log("Player tester status updated successfully.");
        }).catch(error => {
            console.error("Error toggling player tester status", error);
        });
    }

    return (
        <Fragment>
            <div className="login-section padding-top padding-bottom">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">Step 3 of 5: Favorite Game Types</h3>
                        <p>To better match you with games you want to play, select your favorite game types below.</p>
                        <hr />
                        <form className="account-form">
                            {types ? <CreatorTypesForm currentTypes={types} /> : 'Loading Types'}
                            <hr />

                            <div className="card p-4 mb-4 shadow-sm">
                                <h5 className="card-title">Become A Play Tester</h5>
                                <p className="card-text">
                                    Play testers try out games before their release. Would you like game developers to contact you to test games in your favorite genres?
                                </p>
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="playTesterToggle"
                                        checked={isPlayerTester}
                                        onChange={togglePlayTester}
                                    />
                                    <label className="form-check-label" htmlFor="playTesterToggle">
                                        Yes, I want to be a play tester
                                    </label>
                                </div>
                            </div>

                            <div className="form-group">
                                <button type="button" className="d-block btn btn-primary w-100" onClick={goToNextScreen}>
                                    <span>{isLoading ? <Loading /> : 'Next Step'}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default CreatorOnboardinStep3Page;
