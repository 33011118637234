import React, { useState, useEffect, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import InfiniteScroll from 'react-infinite-scroll-component';
import Glitch from 'glitch-javascript-sdk';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';

const defaultLogo = '/assets/images/gameshows/nologo.webp'; // Default logo path

const PublisherGameShowsPage = () => {
    const [gameShows, setGameShows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [expandedShows, setExpandedShows] = useState({}); // Track expanded descriptions

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    useEffect(() => {
        fetchGameShows();
    }, [currentPage]);

    const fetchGameShows = useCallback(async () => {
        try {
            const response = await Glitch.api.GameShows.list({
                page: currentPage,
                sort_by: 'start_date',
                sort_order: 'asc',
            });

            const newGameShows = response.data.data;
            if (newGameShows.length === 0) {
                setHasMore(false); // No more data
            } else {
                setGameShows((prevGameShows) => [...prevGameShows, ...newGameShows]);
            }
        } catch (error) {
            console.error('Error fetching game shows', error);
        }
    }, [currentPage]);

    const loadMoreGameShows = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const toggleDescription = (id) => {
        setExpandedShows((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover Gaming Showcases | Glitch</title>
                <meta
                    name="description"
                    content="Explore gaming showcases to submit your game, gain exposure, or host your own event. Find top events and opportunities in the gaming industry."
                />
                <meta
                    name="keywords"
                    content="gaming showcases, game events, game shows, game premiere, game awards, submit game, host game event"
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Explore Gaming Showcases | Glitch" />
                <meta property="og:description" content="Find gaming events and showcases to promote your game or host an event." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="/assets/images/gameshows/gameshows.webp" />
            </Helmet>

            <Header />
            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Find Gaming Showcases</h1>
                <p style={{ fontSize: "1.2rem" }}>Submit your game or host your own gaming event.</p>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-5">
                <h2>Gaming Showcases</h2>
                <p className='lead'>Gaming showcases are a great way to raise awareness for your game and engage with potential users. Research and apply to the gaming showcases listed below, or consider hosting your own.</p>

                <InfiniteScroll
                    dataLength={gameShows.length}
                    next={loadMoreGameShows}
                    hasMore={hasMore}
                    loader={<h4 className="text-center">Loading more shows...</h4>}
                    endMessage={<p className="text-center"><b>No more game shows to display</b></p>}
                >
                    <div className="row">
                        {gameShows.map((show) => (
                            <div className="col-md-4 mb-4" key={show.id}>
                                <div className="card h-100">
                                    <img
                                        src={show.logo || defaultLogo}
                                        alt={`${show.name} Logo`}
                                        className="card-img-top"
                                        style={{ height: '200px', objectFit: 'cover' }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title">{show.name}</h5>
                                        <p className="card-text"><strong>Type:</strong> {capitalizeFirstLetter(show.type)}</p>
                                        <p className="card-text"><strong>Date:</strong> {show.start_date}</p>
                                        {show.is_virtual && <span className="badge bg-success">Virtual</span>}
                                        {!show.is_virtual && <span className="badge bg-info">In-Person</span>}
                                        
                                        {expandedShows[show.id] && (
                                            <p className="mt-3 card-text">{show.description}</p>
                                        )}
                                    </div>
                                    <div className="card-footer text-center">
                                        <button
                                            className="btn btn-secondary me-2"
                                            onClick={() => toggleDescription(show.id)}
                                        >
                                            {expandedShows[show.id] ? 'Hide Info' : 'More Info'}
                                        </button>
                                        <a
                                            href={show.website}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary"
                                        >
                                            Go To Event
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            </div>

            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/gameshows/gameshows.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '3rem',
    fontWeight: 'bold',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.8)',
};

export default PublisherGameShowsPage;
