import Glitch from 'glitch-javascript-sdk';
import { Component, Fragment } from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import PageHeader from '../../component/layout/pageheader';
import withRouter from '../../../../util/withRouter';
import SidebarManageMenu from '../../component/section/communities/menu_side_manage';
import Wysiwyg from '../../component/form/wysiwyg';
import { Modal, Button, Form, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importing Bootstrap CSS
import Danger from '../../component/alerts/Danger';
import PublisherHeader from '../../component/layout/publisherheader';

class CommunitiesEmailTemplatesPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            community: {},
            templates: [],
            selectedTemplate: null,
            showModal: false,
            modalType: 'create', // 'create' or 'edit'
            formData: {
                name: '',
                type: '1',
                html_version: '',
                text_version: ''
            },
            loading: false,
            error: null,
            validationErrors: {}, // Added to hold validation errors
            placeholders: [
                '{{first_name}}',
                '{{instagram_username}}',
                '{{tiktok_username}}',
                '{{youtube_channel}}',
                '{{twitch_username}}',
                '{{twitter_username}}',
                '{{facebook_username}}',
                '{{reddit_username}}',
                '{{campaign_name}}',
                '{{campaign_description}}',
                '{{campaign_objective}}',
                '{{campaign_deliverables}}',
                '{{campaign_days_to_complete}}',
                '{{campaign_late_penalty_percentage}}',
                '{{campaign_spend_limit_per_influencer}}',
                '{{campaign_start_date}}',
                '{{campaign_end_date}}',
                '{{game_title}}',
                '{{game_short_description}}',
                '{{game_long_description}}',
                '{{game_pricing}}',
                '{{game_publisher}}',
                '{{game_developer}}',
                '{{game_steam_url}}',
                '{{game_itch_url}}',
                '{{game_iphone_url}}',
                '{{game_android_url}}',
                '{{game_website_url}}'
            ],
            copiedPlaceholder: null
        };
    }

    // Fetch all templates for the community
    getTemplates = () => {
        console.log("Community ID", this.state.community.id);
        Glitch.api.Communities.listEmailTemplates(this.state.community.id).then(response => {
            this.setState({ templates: response.data.data });
        }).catch(error => {
            this.setState({ templates: [] });
        });
    }

    // Fetch a single template
    getSingleTemplate = (template_id) => {
        Glitch.api.Communities.viewEmailTemplate(this.state.community.id, template_id).then(response => {
            this.setState({ selectedTemplate: response.data.data });
        }).catch(error => {
            console.error("Error fetching template", error);
        });
    };

    // Handle form submission for creating or updating a template
    handleFormSubmit = () => {
        const { modalType, formData } = this.state;
        this.setState({ loading: true, validationErrors: {} });

        const apiCall = modalType === 'create'
            ? Glitch.api.Communities.createEmailTemplate(this.state.community.id, formData)
            : Glitch.api.Communities.updateEmailTemplate(this.state.community.id, this.state.selectedTemplate.id, formData);

        apiCall.then(response => {
            this.setState({ showModal: false, loading: false });
            this.getTemplates();
        }).catch(error => {
            this.setState({ 
                loading: false, 
                error: error.response?.data?.message || "An error occurred",
                validationErrors: error.response?.data || {}
            });
        });
    }

    // Handle delete template
    deleteTemplate = (template_id) => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            Glitch.api.Communities.deleteEmailTemplate(this.state.community.id, template_id).then(() => {
                this.getTemplates();
            }).catch(error => {
                console.error("Error deleting template", error);
            });
        }
    }

    // Show the modal for creating or editing a template
    showModal = (type, template = null) => {
        this.setState({
            showModal: true,
            modalType: type,
            selectedTemplate: template,
            formData: template ? {
                name: template.name,
                type: template.type,
                html_version: template.html_version,
                text_version: template.text_version
            } : {
                name: '',
                type: '1',
                html_version: '',
                text_version: ''
            },
            validationErrors: {}
        });
    }

    componentDidMount() {
        let id = this.props.router.params.id;
        Glitch.api.Communities.view(id).then(response => {
            this.setState({ community: response.data.data });

            setTimeout(() => {
                this.getTemplates();
            }, 1000);
        }).catch(error => {
            console.error("Error fetching community", error);
        });
    }

    // Copy placeholder to clipboard
    copyToClipboard = (placeholder) => {
        navigator.clipboard.writeText(placeholder).then(() => {
            this.setState({ copiedPlaceholder: placeholder }, () => {
                setTimeout(() => {
                    this.setState({ copiedPlaceholder: null });
                }, 2000);
            });
        });
    };

    render() {
        const { templates, showModal, modalType, formData, loading, error, validationErrors, placeholders, copiedPlaceholder } = this.state;

        return (
            <>
                <Fragment>
                    <PublisherHeader />
                    <PageHeader title={`${this.state.community.name} Email Templates`} curPage={'Manage Account'} />
                    <div className="blog-section blog-single padding-top padding-bottom aside-bg">
                        <div className="container">
                            <div className="section-wrapper">
                                <div className="row justify-content-center pb-15">
                                    <div className="col-lg-8 col-12 pe-5 mt-2">
                                        <h3>Email Templates</h3>
                                        <p className='lead'>
                                        Email templates allow you to create custom invite emails when inviting influencers to campaigns. Manage your custom email templates here.
                                        </p>

                                        <Button variant="primary" onClick={() => this.showModal('create')}>
                                            <i className="fa fa-plus"></i> Create New Template
                                        </Button>

                                        {templates.length === 0 ? (
                                            <Alert variant="info" className="mt-3">
                                                No Templates have been created.
                                            </Alert>
                                        ) : (
                                            <ul className="list-group mt-3">
                                                {templates.map(template => (
                                                    <li key={template.id} className="list-group-item d-flex justify-content-between align-items-center" style={{marginLeft: 0}}>
                                                        {template.name}
                                                        <div>
                                                            <Button variant="info" className="me-2" onClick={() => this.showModal('edit', template)}>
                                                                <i className="fa fa-edit"></i> Edit
                                                            </Button>
                                                            <Button variant="danger" onClick={() => this.deleteTemplate(template.id)}>
                                                                <i className="fa fa-trash"></i> Delete
                                                            </Button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-7 col-12">
                                        <aside className="ps-lg-4">
                                            <SidebarManageMenu community_id={this.state.community.id} />
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal for Create/Edit Template */}
                    <Modal 
                        show={showModal} 
                        onHide={() => this.setState({ showModal: false })}
                        size="lg" // Making the modal wider
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header className='text-black' closeButton>
                            <Modal.Title>{modalType === 'create' ? 'Create New Template' : 'Edit Template'}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='text-black'>
                            {error && <Danger message={error} />}
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Template Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={formData.name}
                                        isInvalid={!!validationErrors.name}
                                        onChange={e => this.setState({ formData: { ...formData, name: e.target.value } })}
                                    />
                                    {validationErrors.name && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationErrors.name[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Select
                                        value={formData.type}
                                        isInvalid={!!validationErrors.type}
                                        onChange={e => this.setState({ formData: { ...formData, type: e.target.value } })}
                                    >
                                        <option value="1">Campaign Email</option>
                                        <option value="2">Instagram</option>
                                        <option value="3">Twitter</option>
                                        <option value="4">TikTok</option>
                                        <option value="5">YouTube</option>
                                    </Form.Select>
                                    {validationErrors.type && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationErrors.type[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>HTML Version</Form.Label>
                                    <Wysiwyg 
                                        id="html_version" 
                                        name="new_note" 
                                        onChange={content => this.setState({ formData: { ...formData, html_version: content } })} 
                                        placeholder="Write HTML email" 
                                        toolbarOptions={[
                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                            ['bold', 'italic', 'underline', 'strike'],
                                            [{ 'color': [] }, { 'background': [] }],
                                            [{ 'script': 'sub' }, { 'script': 'super' }],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            [{ 'indent': '-1' }, { 'indent': '+1' }],
                                            [{ 'direction': 'rtl' }],
                                            [{ 'align': [] }],
                                            ['link', 'image', 'video'],
                                            ['clean']
                                        ]}
                                    >
                                        {formData.html_version}
                                    </Wysiwyg>
                                  
                                    {validationErrors.html_version && (
                                        <Alert variant="danger" className="mt-2">
                                            {validationErrors.html_version[0]}
                                        </Alert>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Text Version</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={formData.text_version}
                                        isInvalid={!!validationErrors.text_version}
                                        onChange={e => this.setState({ formData: { ...formData, text_version: e.target.value } })}
                                    />
                                    {validationErrors.text_version && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationErrors.text_version[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Available Placeholders</Form.Label>
                                    <ul className="list-unstyled">
                                        {placeholders.map((placeholder, index) => (
                                            <OverlayTrigger
                                                key={index}
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-${index}`}>
                                                        {copiedPlaceholder === placeholder ? 'Copied!' : 'Click to copy'}
                                                    </Tooltip>
                                                }
                                            >
                                                <li 
                                                    className={`badge bg-secondary m-1 ${copiedPlaceholder === placeholder ? 'bg-success' : ''}`}
                                                    onClick={() => this.copyToClipboard(placeholder)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {placeholder}
                                                </li>
                                            </OverlayTrigger>
                                        ))}
                                    </ul>
                                </Form.Group>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ showModal: false })}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={this.handleFormSubmit} disabled={loading}>
                                {loading ? 'Saving...' : 'Save Changes'}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default withRouter(CommunitiesEmailTemplatesPage);
