import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import { Button, Table, Form, Modal, Alert } from 'react-bootstrap';

const CampaignsViewCreatorPaymentPage = () => {
    const [influencer, setInfluencer] = useState(null);
    const [campaign, setCampaign] = useState({});
    const { id, user_id } = useParams();
    const [errors, setErrors] = useState(null);
    const [user, setUser] = useState(null);
    const [influencerCampaign, setInfluencerCampaign] = useState({});
    const [payouts, setPayouts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            const currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        const fetchData = async () => {
            try {
                const campaignResponse = await Glitch.api.Campaigns.view(id);
                setCampaign(campaignResponse.data.data);

                const userResponse = await Glitch.api.Users.profile(user_id);
                setUser(userResponse.data.data);
                setInfluencer(userResponse.data.data.influencer);

                const influencerCampaignResponse = await Glitch.api.Campaigns.viewInfluencerCampaign(id, user_id);
                setInfluencerCampaign(influencerCampaignResponse.data.data);

                const payoutsResponse = await Glitch.api.Campaigns.listPayouts(id, { user_id: user_id });
                setPayouts(payoutsResponse.data.data);

                setLoading(false);
            } catch (error) {
                console.error(error);
                setErrors('Failed to load data. Please try again later.');
            }
        };

        fetchData();
    }, [id, user_id, navigate]);

    const handlePayment = async () => {
        try {
            setErrors(null); // Clear previous errors
            await Glitch.api.Campaigns.payInfluencer(id, user_id, { amount: paymentAmount });
            const payoutsResponse = await Glitch.api.Campaigns.listPayouts(id, { user_id: user_id });
            setPayouts(payoutsResponse.data.data);
            setShowModal(false);
            setPaymentAmount('');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrors(error.response.data.error);
            } else {
                setErrors('An unexpected error occurred. Please try again.');
            }
            console.error(error);
        }
    };

    if (loading) {
        return <FontAwesomeIcon icon={faSpinner} spin />;
    }

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs
                            items={[
                                { name: 'Campaigns', link: Navigate.campaignsPage() },
                                { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                                { name: 'Influencer Invites', link: Navigate.campaignsInvitesPage(campaign.id) },
                                { name: 'Update Compensation', link: Navigate.campaignsUpdateInfluencerInviteCompensation(campaign.id, user_id) },
                            ]}
                        />
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title">Payments To Influencer</h2>
                            <p className="lead">See past payments to influencers and make custom payments to the influencer.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                </div>

                <div className="container mt-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Personal Information</h4>
                        </div>
                        <div className="card-body d-flex align-items-center">
                            <img
                                src={getInfluencerImage(influencer)}
                                alt={`${influencer.first_name || 'Influencer'}'s profile`}
                                className="img-thumbnail me-4"
                                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                            />
                            <div>
                                <p><strong>Name:</strong> {influencer.first_name || influencer.instagram_username || influencer.youtube_title}</p>
                                <p><strong>Location:</strong> {influencer.location}</p>
                                <p><strong>Speaking Language:</strong> {influencer.speaking_language}</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h4 className='text-black'>Payouts</h4>
                            <Button variant="primary" onClick={() => setShowModal(true)}>
                                <FontAwesomeIcon icon={faDollarSign} className="me-2" />
                                Make Payment
                            </Button>
                        </div>
                        <div className="card-body">
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Currency</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {payouts.length > 0 ? payouts.map((payout) => (
                                        <tr key={payout.id}>
                                            <td>{new Date(payout.entry_date).toLocaleDateString()}</td>
                                            <td>{payout.amount}</td>
                                            <td>{payout.currency}</td>
                                            <td>{payout.status}</td>
                                        </tr>
                                    )) : (
                                        <tr>
                                            <td colSpan="4" className="text-center">No payouts found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>

                <Modal className='text-black' show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header className='text-black' closeButton>
                        <Modal.Title>Make Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='text-black'>
                        {errors && <Alert variant="danger">{errors}</Alert>}
                        <Form className='text-black'>
                            <Form.Group className="mb-3">
                                <p>Make a custom payment to the influencer.</p>
                                <Form.Label>Payment Amount</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter amount"
                                    min="0"
                                    value={paymentAmount}
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handlePayment}>
                            <FontAwesomeIcon icon={faDollarSign} className="me-2" />
                            Pay
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        </>
    );
};

export default CampaignsViewCreatorPaymentPage;
