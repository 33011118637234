// src/component/section/updates/title_update_form.jsx

import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../alerts/Danger';
import RequiredAsterisk from '../../form/required_asterisk';
import Wysiwyg from '../../form/wysiwyg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faSnapchat,
    faReddit,
    faYoutube,
    faTelegram,
    faKickstarter, // Placeholder for Bluesky
} from '@fortawesome/free-brands-svg-icons';

const TitleUpdateForm = ({
    schedulerId,
    updateData,
    setUpdateData,
    errors,
    isEditMode = false,
    schedulerData,
}) => {
    const [mediaFile, setMediaFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [existingMediaUrl, setExistingMediaUrl] = useState('');
    const [guidelinesVisible, setGuidelinesVisible] = useState(false);
    const [platformIssues, setPlatformIssues] = useState({});
    const [contentLength, setContentLength] = useState(0);
    const [mediaInfo, setMediaInfo] = useState(null);

    const platforms = [
        { name: 'Facebook', icon: faFacebook, field: 'posts_to_facebook', oauthField: 'facebook_oauth_token' },
        { name: 'Instagram', icon: faInstagram, field: 'posts_to_instagram', oauthField: 'instagram_oauth_token' },
        { name: 'Twitter', icon: faTwitter, field: 'posts_to_twitter', oauthField: 'twitter_oauth_token' },
        { name: 'TikTok', icon: faTiktok, field: 'posts_to_tiktok', oauthField: 'tiktok_oauth_token' },
        { name: 'Discord', icon: faDiscord, field: 'posts_to_discord', oauthField: 'discord_oauth_token' },
        { name: 'Reddit', icon: faReddit, field: 'posts_to_reddit', oauthField: 'reddit_oauth_token' },
        { name: 'YouTube', icon: faYoutube, field: 'posts_to_youtube', oauthField: 'youtube_oauth_token' },
        // Platforms without OAuth
        { name: 'Telegram', icon: faTelegram, field: 'posts_to_telegram', oauthField: 'telegram_oauth_token' },
        { name: 'Bluesky', icon: faKickstarter, field: 'posts_to_bluesky', oauthField: 'bluesky_oauth_token' }, // Placeholder icon
    ];

    // Set default platform posting to true for authenticated platforms
    useEffect(() => {
        if (schedulerData) {
            const updatedData = { ...updateData };
            platforms.forEach(platform => {
                if (platform.oauthField) {
                    if (schedulerData[platform.oauthField]) {
                        // Platform is authenticated
                        if (updatedData[platform.field] === undefined) {
                            updatedData[platform.field] = true;
                        }
                    } else {
                        // Not authenticated, set to false
                        updatedData[platform.field] = false;
                    }
                } else {
                    // Platforms without OAuth (always shown)
                    if (updatedData[platform.field] === undefined) {
                        updatedData[platform.field] = true;
                    }
                }
            });
            setUpdateData(updatedData);
        }
    }, [schedulerData]);

    useEffect(() => {
        if (updateData.media_id) {
            // Fetch media details
            Glitch.api.Media.get(updateData.media_id)
                .then(response => {
                    const media = response.data.data;
                    setExistingMediaUrl(media.url);
                    setMediaInfo(media);
                    validatePlatforms(updateData.content, media);
                })
                .catch(error => {
                    console.error('Error fetching media:', error);
                });
        } else {
            setExistingMediaUrl('');
            setMediaInfo(null);
            validatePlatforms(updateData.content, null);
        }
    }, [updateData.media_id]);

    const handleWysiwigInputChange = (name, value) => {
        setUpdateData((updateData) => ({ ...updateData, [name]: value }));

        // Remove HTML tags using a regular expression
        const plainTextValue = value.replace(/<\/?[^>]+(>|$)/g, "");

        setContentLength(plainTextValue.length);
        validatePlatforms(plainTextValue, mediaInfo);
    };


    const handleWysiwygChange = (name, value) => {
        setUpdateData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setContentLength(value.length);
        validatePlatforms(value, mediaInfo);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        let updatedValue = type === 'checkbox' ? checked : value;

        setUpdateData((prevData) => ({
            ...prevData,
            [name]: updatedValue,
        }));
    };

    const handleMediaUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setMediaFile(file);
            setIsUploading(true);

            try {
                const response = await Glitch.api.Media.uploadFile(file);
                const mediaId = response.data.data.id;
                const media = response.data.data;
                setUpdateData((prevData) => ({
                    ...prevData,
                    media_id: mediaId,
                }));
                setExistingMediaUrl(media.url);
                setMediaInfo(media);
                validatePlatforms(updateData.content, media);
            } catch (error) {
                console.error('Media upload failed:', error);
                // Handle upload error
            } finally {
                setIsUploading(false);
            }
        }
    };

    const toggleGuidelines = () => {
        setGuidelinesVisible(!guidelinesVisible);
    };

    const validatePlatforms = (content, media) => {
        const issues = {};
        const contentLength = content ? content.length : 0;

        platforms.forEach(platform => {
            let issue = '';

            // Skip platforms not selected
            if (!updateData[platform.field]) return;

            // Text length limitations
            if (platform.name === 'Twitter' && contentLength > 280) {
                issue = 'Text too long (max 280 characters)';
            }
            if (platform.name === 'Instagram' && contentLength > 2200) {
                issue = 'Text too long (max 2200 characters)';
            }
            if (platform.name === 'Facebook' && contentLength > 63206) {
                issue = 'Text too long (max 63206 characters)';
            }
            // Add other platforms' text limits

            // Media validations
            if (media) {
                const mediaSizeMB = media.size / (1024 * 1024);
                const mediaLength = media.length; // in seconds
                const width = media.metadata?.width;
                const height = media.metadata?.height;

                if (media.mime_type.startsWith('video/')) {
                    // Video limitations
                    if (platform.name === 'Twitter' && mediaLength > 140) { // 2 min 20 sec
                        issue = 'Video too long (max 2m20s)';
                    }
                    if (platform.name === 'Instagram' && mediaLength > 60) {
                        issue = 'Video too long (max 60s)';
                    }
                    if (platform.name === 'TikTok' && mediaLength > 3600) {
                        issue = 'Video too long (max 60m)';
                    }
                    if (platform.name === 'Twitter' && mediaSizeMB > 512) {
                        issue = 'Video file too large (max 512MB)';
                    }
                    // Add other platforms' video limits
                } else if (media.mime_type.startsWith('image/')) {
                    // Image limitations
                    if (platform.name === 'Facebook' && (width > 1200 || height > 630)) {
                        issue = 'Image dimensions exceed 1200x630px';
                    }
                    if (platform.name === 'Instagram' && (width > 1080 || height > 1080)) {
                        issue = 'Image dimensions exceed 1080x1080px';
                    }
                    // Add other platforms' image limits
                }
            }

            if (issue) {
                issues[platform.field] = issue;
            } else {
                // No issue, remove if previously set
                delete issues[platform.field];
            }
        });

        setPlatformIssues(issues);
    };

    return (
        <div className="container mt-4">
            <div className="card mb-3">
                <div className="card-header bg-primary text-white">
                    <h3>
                        {isEditMode ? 'Edit Update' : 'Create Update'}
                    </h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        {createInput(
                            'Update Title',
                            'title',
                            updateData.title,
                            handleInputChange,
                            'text',
                            'fas fa-heading',
                            errors,
                            'Enter a title for this update.',
                            true
                        )}
                        {createSelect(
                            'Update Type',
                            'update_type',
                            updateData.update_type,
                            handleInputChange,
                            [
                                { value: 'development_update', label: 'Development Update' },
                                { value: 'link', label: 'Link' },
                                { value: 'youtube_video', label: 'YouTube Video' },
                                { value: 'image', label: 'Image' },
                                { value: 'video', label: 'Video' },
                            ],
                            errors,
                            'Select the type of the update.',
                            true
                        )}
                    </div>

                    {createTextarea(
                        'Content',
                        'content',
                        updateData.content,
                        handleWysiwygChange,
                        errors,
                        'Enter the content of the update.',
                        true
                    )}
                    <p>Character count: {contentLength}</p>

                    <div className="row">
                        {createInput(
                            'External URL',
                            'media_url',
                            updateData.media_url,
                            handleInputChange,
                            'url',
                            'fas fa-link',
                            errors,
                            'Enter a URL to any media associated with the update.'
                        )}
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="media_upload">Upload Media</label>
                            <button className="btn btn-link btn-sm" type="button" onClick={toggleGuidelines}>
                                {guidelinesVisible ? 'Hide Guidelines' : 'Show Guidelines'}
                            </button>
                            {guidelinesVisible && (
                                <div className="card mt-2">
                                    <div className="card-body">
                                        <h5 className='text-black'>Media and Content Guidelines</h5>
                                        <p><strong>Text Limits:</strong></p>
                                        <ul>
                                            <li>Twitter: Max 280 characters</li>
                                            <li>Instagram: Max 2200 characters</li>
                                            <li>Facebook: Max 63206 characters</li>
                                            {/* Add more text guidelines */}
                                        </ul>
                                        <p><strong>Image Guidelines:</strong></p>
                                        <ul>
                                            <li>Facebook: 1200x630px</li>
                                            <li>Instagram: 1080x1080px</li>
                                            <li>Twitter: 1200x675px</li>
                                            <li>LinkedIn: 1200x627px</li>
                                            <li>TikTok: 1080x1920px (Vertical)</li>
                                        </ul>
                                        <p><strong>Video Guidelines:</strong></p>
                                        <ul>
                                            <li><strong>Max Video Upload Size For The Update:</strong> 150 MB</li>
                                            <li><strong>Video Duration:</strong></li>
                                            <ul>
                                                <li>Instagram/Bluesky: Up to 60 seconds</li>
                                                <li>TikTok: Up to 60 minutes</li>
                                                <li>Twitter: Up to 2 minutes and 20 seconds</li>
                                                <li>LinkedIn: 15 minutes</li>
                                                <li>Reddit: 15 minutes</li>
                                            </ul>
                                            <li><strong>Video Size Limit:</strong></li>
                                            <ul>
                                                <li>Bluesky: 50MB</li>
                                                <li>TikTok: 500 MB</li>
                                                <li>Twitter: 512MB</li>
                                                <li>Discord: 25 MB Free users</li>
                                                <li>Telegram: 2GB</li>
                                                <li>Facebook: 10GB</li>
                                                <li>LinkedIn: 5GB</li>
                                                <li>Reddit: 1GB</li>
                                                <li>YouTube: 256GB</li>
                                            </ul>
                                        </ul>
                                        {/* Add any other guidelines */}
                                    </div>
                                </div>
                            )}
                            {existingMediaUrl && (
                                <div className="mb-2">
                                    <p>Existing Media:</p>
                                    {mediaInfo?.mime_type.startsWith('video/') ? (
                                        <video controls style={{ maxWidth: '100%' }}>
                                            <source src={existingMediaUrl} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    ) : (
                                        <img src={existingMediaUrl} alt="Existing Media" style={{ maxWidth: '100%' }} />
                                    )}
                                </div>
                            )}
                            <input
                                type="file"
                                className="form-control"
                                id="media_upload"
                                name="media_upload"
                                onChange={handleMediaUpload}
                            />
                            {isUploading && <p>Uploading...</p>}
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <label>Platforms to Post To</label>
                            <div className="row">
                                {platforms.map((platform) => {
                                    // Determine if platform is authenticated or not
                                    const isPlatformAuthenticated = platform.oauthField
                                        ? schedulerData && schedulerData[platform.oauthField]
                                        : true; // Platforms without OAuth are always shown

                                    if (!isPlatformAuthenticated) {
                                        return null; // Don't render this platform
                                    }

                                    const issue = platformIssues[platform.field];
                                    const isStrikethrough = issue ? true : false;

                                    return (
                                        <div className="col-md-3" key={platform.field}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={platform.field}
                                                    name={platform.field}
                                                    checked={true}
                                                    onChange={handleInputChange}
                                                    disabled={isStrikethrough}
                                                />
                                                <label
                                                    className={`form-check-label ${isStrikethrough ? 'text-muted' : ''}`}
                                                    htmlFor={platform.field}
                                                    title={issue}
                                                >
                                                    <FontAwesomeIcon icon={platform.icon} />{' '}
                                                    {isStrikethrough ? <s>{platform.name}</s> : platform.name}
                                                </label>
                                                {issue && <p className="text-danger small">{issue}</p>}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {!platforms.some(platform => {
                                const isPlatformAuthenticated = platform.oauthField
                                    ? schedulerData && schedulerData[platform.oauthField]
                                    : true;
                                return isPlatformAuthenticated;
                            }) && (
                                <div className="alert alert-warning mt-2">
                                    Update will not go out to any platforms. Please OAuth a few platforms so your content can be posted to them.
                                </div>
                            )}
                            <p className="small mt-2">
                                To post to more platforms, please OAuth to your scheduler.
                            </p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="acknowledge_user"
                                    name="acknowledge_user"
                                    checked={updateData.acknowledge_user || false}
                                    onChange={handleInputChange}
                                />
                                <label className="form-check-label" htmlFor="acknowledge_user">
                                    Acknowledge User
                                </label>
                            </div>
                            <p className="small">
                                If enabled, the user who is associated with the update will be acknowledged by name.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function createInput(
        label,
        name,
        value,
        handleChange,
        type = 'text',
        icon,
        errors,
        description = '',
        required = false
    ) {
        return (
            <div className="col-md-6">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        <i className={`${icon} mr-2`}></i> &nbsp;{label} {required ? <RequiredAsterisk /> : ''}
                    </label>
                    <p className="small">{description}</p>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }

    function createTextarea(label, name, value, handleChange, errors, description, required = false) {
        return (
            <div className="col-md-12">
                <div className="mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    <Wysiwyg
                        children={value || ''}
                        name={name}
                        id={name}
                        onChange={(value) => {
                            handleWysiwigInputChange(name, value);
                        }}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }

    function createSelect(label, name, value, handleChange, options, errors, description, required = false) {
        return (
            <div className="col-md-6">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    <select
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    >
                        <option value="">Select an option</option>
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }
};

export default TitleUpdateForm;
