import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { Card, Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';
import Alerts from '../../../../util/Alerts';

const PlayTestReviewTestPage = () => {
    const [playTest, setPlayTest] = useState(null);
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const { id, playtest_id } = useParams(); // title_id, playtest_id
    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchData = async () => {
            try {
                // Fetch play test request details
                const playTestResponse = await Glitch.api.PlayTests.show(id, playtest_id);
                setPlayTest(playTestResponse.data.data);

                // Fetch title details
                const titleResponse = await Glitch.api.Titles.view(id);
                setTitle(titleResponse.data.data);
            } catch (error) {
                console.error('Error fetching data', error);
                setErrorMessage('Failed to fetch data. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, playtest_id, navigate]);

    const handleAccept = async () => {
        try {
            await Glitch.api.PlayTests.approveRequest(id, playTest.id);
            // Refresh data
            const playTestResponse = await Glitch.api.PlayTests.show(id, playtest_id);
            setPlayTest(playTestResponse.data.data);
            Alerts.display("Success", "Play test request approved.", 'success');
      
        } catch (error) {

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Error Approving', message);
            } else {
                alert('Failed to approve request.');
            }
        }
    };

    const handleReject = async () => {
        try {
            await Glitch.api.PlayTests.declineRequest(id, playTest.id);
            // Refresh data
            const playTestResponse = await Glitch.api.PlayTests.show(id, playtest_id);
            setPlayTest(playTestResponse.data.data);
            alert('Play test request declined.');
        } catch (error) {
            console.error('Error declining request', error);
            alert('Failed to decline request.');
        }
    };

    const getStatusBadge = (status) => {
        switch (status) {
            case 'pending':
                return <span className="badge bg-warning text-dark">Pending</span>;
            case 'in_progress':
                return <span className="badge bg-primary">In Progress</span>;
            case 'completed':
                return <span className="badge bg-success">Completed</span>;
            case 'cancelled':
                return <span className="badge bg-secondary">Cancelled</span>;
            default:
                return <span className="badge bg-light text-dark">{status}</span>;
        }
    };

    return (
        <>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingPublishersListPage() },
                        { name: title?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                        { name: 'Review Tester', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Review Play Tester</h2>
                        <p className="lead">
                            Review the application of the tester for {title?.name || 'Loading...'}
                        </p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : errorMessage ? (
                    <Alert variant="danger">{errorMessage}</Alert>
                ) : playTest ? (
                    <>
                        <Card className="mb-4">
                            <Card.Header>
                                <h3 className="text-black">Tester Information</h3>
                            </Card.Header>
                            <Card.Body className="text-black">
                                <div className="d-flex align-items-center mb-3">
                                    <img
                                        src={playTest.user.avatar || getInfluencerImage(playTest.user?.influencer)}
                                        alt={playTest.user.display_name || playTest.user.username}
                                        className="rounded-circle me-3"
                                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                    />
                                    <div>
                                        <h4 className='text-black'>{playTest.user.display_name || playTest.user.username}</h4>
                                        {playTest.user.types && playTest.user.types.length > 0 && (
                                            <p>
                                                <strong>Preferred Game Types: </strong>
                                                {playTest.user.types.map((type) => type.name).join(', ')}
                                            </p>
                                        )}
                                        {playTest.user.genres && playTest.user.genres.length > 0 && (
                                            <p>
                                                <strong>Preferred Genres: </strong>
                                                {playTest.user.genres.map((genre) => genre.name).join(', ')}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {playTest.user.bio && (
                                    <div className="mb-4">
                                        <h5 className="text-black">Bio:</h5>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: playTest.user.bio }}
                                        ></div>
                                    </div>
                                )}
                                {playTest.user.influencer_content_type && (
                                    <div className="mb-4">
                                        <h5 className="text-black">Content Type:</h5>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: playTest.user.influencer_content_type,
                                            }}
                                        ></div>
                                    </div>
                                )}
                                {playTest.user.influencer_content_theme && (
                                    <div className="mb-4">
                                        <h5 className="text-black">Content Theme:</h5>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: playTest.user.influencer_content_theme,
                                            }}
                                        ></div>
                                    </div>
                                )}
                                {playTest.user.influencer_content_unique && (
                                    <div className="mb-4">
                                        <h5 className="text-black">Unique Content:</h5>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: playTest.user.influencer_content_unique,
                                            }}
                                        ></div>
                                    </div>
                                )}
                                {playTest.user.influencer_brand_approach && (
                                    <div className="mb-4">
                                        <h5 className="text-black">Brand Approach:</h5>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: playTest.user.influencer_brand_approach,
                                            }}
                                        ></div>
                                    </div>
                                )}
                                {playTest.user.influencer_games_why && (
                                    <div className="mb-4">
                                        <h5 className="text-black">Games Preference:</h5>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: playTest.user.influencer_games_why,
                                            }}
                                        ></div>
                                    </div>
                                )}
                                <hr />
                                <div className="mb-4">
                                    <h5 className="text-black">Status:</h5>
                                    {getStatusBadge(playTest.status)}
                                </div>
                                <div className="mb-4">
                                    <h5 className="text-black">Dates:</h5>
                                    <p>
                                        <strong>Requested At:</strong>{' '}
                                        {moment(playTest.request_sent_at).format('LLL') || 'N/A'}
                                    </p>
                                    {playTest.invite_accepted_at && (
                                        <p>
                                            <strong>Accepted At:</strong>{' '}
                                            {moment(playTest.invite_accepted_at).format('LLL')}
                                        </p>
                                    )}
                                    {playTest.feedback_submitted_at && (
                                        <p>
                                            <strong>Feedback Submitted At:</strong>{' '}
                                            {moment(playTest.feedback_submitted_at).format('LLL')}
                                        </p>
                                    )}
                                </div>
                                {playTest.status === 'pending' && (
                                    <div className="d-flex">
                                        <Button variant="success" className="me-2" onClick={handleAccept}>
                                            <i className="fas fa-check-circle"></i> Accept
                                        </Button>
                                        <Button variant="danger" onClick={handleReject}>
                                            <i className="fas fa-times-circle"></i> Reject
                                        </Button>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </>
                ) : (
                    <Alert variant="warning">Play test not found.</Alert>
                )}
            </div>
        </>
    );
};

export default PlayTestReviewTestPage;
