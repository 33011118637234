import React, { useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import CreatorGettingStarted from '../../component/section/creators/creator_getting_started';
import { Helmet } from 'react-helmet';

const InfluencerTutorialsContentPage = () => {

    useEffect(() => {
        // Any useEffect logic if needed
    }, []);

    const navigate = useNavigate();


    return (
        <>
            <Helmet>
                <title>How to Start Creating Content & Promote a Game | Glitch Tutorials</title>
                <meta name="description" content="Learn how to start creating content and promoting a game once you're accepted into a campaign. Step-by-step guide and video tutorial included." />
                <meta name="robots" content="index, follow" />
                <meta charset="utf-8" />
                <meta property="og:title" content="How to Start Creating Content & Promote a Game | Glitch Tutorials" />
                <meta property="og:description" content="Congratulations on being accepted into a campaign! Learn how to start creating and promoting content for the game with our detailed guide and video." />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <Fragment>
                <InfluencerHeader position={"relative"} />
                <section className="pageheader-section-min">
                <div className="container mt-2">
                    <Breadcrumbs
                        items={[
                            { name: 'Tutorials', link: Navigate.influencersTutorials() },
                            { name: `How To Start Creating Content & Promoting A Game`, link: Navigate.influencersTutorialStarting() },
                        ]}
                    />
                    <div className="section-wrapper text-center">
                        <div className="pageheader-thumb mb-4"></div>
                        <h2 className="pageheader-title">How To Start Creating Content & Promote The Game</h2>
                        <p className="lead">Once accepted into a campaign, learn how to create content and promote a game.</p>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <p className="lead">Congratulations on finishing the approval process for a campaign. Now that you are approved, your next step is to start promoting the game. Watch the video below and also read the instructions.</p>

                
                    <div className="ratio ratio-16x9 border border-white border-2">
                        <iframe
                            src="https://www.youtube.com/embed/3m0Dv3UQhvw"
                            allowFullScreen
                            title="YouTube Video"
                        ></iframe>
                    </div>
                

                <hr />

                <CreatorGettingStarted />

 
            </div>
            </Fragment>
        </>
    );
};

export default InfluencerTutorialsContentPage;
