import { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import { Link } from "react-router-dom";
import Navigate from "../../../../util/Navigate";

const investorsData = [
    {
        name: 'Techstars',
        description: 'Techstars is the world\'s most active pre-seed investor having invested in more than 3,800 companies.',
        imageUrl: '/assets/images/investors/techstarslogo.jpg',
    },
    {
        name: 'HustleFund',
        description: 'Hustle Fund is a venture capital fund investing in hustlers at the pre-seed and seed stages.',
        imageUrl: '/assets/images/investors/hustlefund.jpg',
    },
    {
        name: 'VisibleHands',
        description: 'Visible Hands is the most trusted platform for early-stage, underrepresented founders building high-growth startups.',
        imageUrl: '/assets/images/investors/visiblehands.jpg',
    },
    {
        name: 'Google Black Founders Fund',
        description: 'Designed to help Black-led startups in Europe with up to $150K in capital, Google Cloud credits, and custom support from Google.',
        imageUrl: '/assets/images/investors/blackfoundersfund.jpg',
    },
];

class AboutPage extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>About Glitch</title>
                    <meta
                        name="description"
                        content="Discover how Glitch empowers game developers with innovative, AI-driven marketing solutions. Our mission is to make game marketing easier and more effective."
                    />
                </Helmet>
                <Fragment>
                    <Header />
                    <PageHeader title={'About Us'} curPage={'About Us'} />
                    <section className="about-section">
                        <div className="container">
                           
                            <div className="section-wrapper ">
                                <div className="text-center mt-5 mb-5">
                                    <h2>Our Values and Beliefs</h2>
                                </div>
                                <div className="row mb-5 align-items-center">
                                    <div className="col-lg-6">
                                        <img src="/assets/images/about/game_marketing_hard.webp" alt="Game Marketing" className="img-fluid rounded" />
                                    </div>
                                    <div className="col-lg-6">
                                        <h2>Game Marketing Made Easy with Innovation</h2>
                                        <p>
                                            Marketing a game is one of the toughest parts of game development, yet it’s essential. With over 15,000 games launching each year, standing out is a challenge.
                                            Traditional marketing approaches are time-consuming, cumbersome, and often leave developers feeling overwhelmed. At Glitch, we believe there’s a better way—one that’s
                                            easier, simpler, and more effective. Our innovative approach to game marketing unifies influencer outreach, community building, email engagement, playtesting, and showcases into a
                                            seamless "360° product." We empower you to connect meaningfully with your audience, creating lasting, positive outcomes for your game.
                                        </p>
                                    </div>
                                </div>

                                <div className="row mb-5 align-items-center">
                                    <div className="col-lg-6 order-lg-2">
                                        <img src="/assets/images/about/ai-friend.jpg" alt="AI Friend" className="img-fluid rounded" />
                                    </div>
                                    <div className="col-lg-6 order-lg-1">
                                        <h2>AI: Your Ally for Tedious Tasks</h2>
                                        <p>
                                            We’re not just another company throwing around AI jargon. We understand that AI can’t replace human creativity or ingenuity, but it can handle the tedious tasks like data entry, 
                                            matchmaking, and analytics. Glitch uses AI to enable even novice developers to market like pros, especially solo developers who already juggle a lot. For experienced marketers, our AI frees 
                                            up valuable time so you can focus on what matters most—your creativity.
                                        </p>
                                    </div>
                                </div>

                                <div className="row mb-5 align-items-center">
                                    <div className="col-lg-6">
                                        <img src="/assets/images/about/free-tools.jpg" alt="Free Tools" className="img-fluid rounded" />
                                    </div>
                                    <div className="col-lg-6">
                                        <h2>Why We Offer So Many Free Tools</h2>
                                        <p>
                                            If you’ve checked out our <Link to={Navigate.publishersToolsPage()}>tools section</Link>, you’ll notice we offer a wealth of free resources on game marketing—more than any competitor. Why? First, we genuinely want to see you succeed.
                                            We believe knowledge shouldn’t be hoarded. Second, we want you to trust our expertise and our results, and working with us should feel natural, not forced. Third, we believe informed developers
                                            make better decisions about marketing, whether they choose to DIY or work with a partner. For us, it’s all about transparency, honesty, and putting your success first.
                                        </p>
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <h2 className="mb-3">Investors and Funders</h2>
                                    {investorsData.map((investor, index) => (
                                        <div key={index} className="d-flex align-items-start mb-4">
                                            <img src={investor.imageUrl} alt={investor.name} className="me-3 rounded-circle" style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                                            <div>
                                                <h3>{investor.name}</h3>
                                                <p>{investor.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </Fragment>
            </>
        );
    }
}

export default AboutPage;
