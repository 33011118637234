import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import CampaignForm from '../../component/section/newsletters/campaign_form';
import timeouts from '../../../../constants/timeouts';
import CampaignSendForm from '../../component/section/newsletters/campaign_send_form';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Alerts from '../../../../util/Alerts';
import Footer from '../../component/layout/footer';

const NewsletterCampaignSendPage = () => {
    const [campaign, setCampaign] = useState({});
    const [newsletter, setNewsletter] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [communities, setCommunities] = useState([]);
    const navigate = useNavigate();
    const { id, community_id, campaign_id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        Glitch.api.Communities.viewNewsletter(community_id, id)
            .then(response => {
                setNewsletter(response.data.data);

            })
            .catch(error => {
                console.error('Error fetching newsletter or campaigns', error);
            });

        Glitch.api.Communities.viewCampaign(community_id, id, campaign_id)
            .then(response => {
                setCampaign(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching campagin:', error);
            });
    }, []);

    const send = async (status) => {
        setIsLoading(true);
        const campaignData = { ...campaign, status };

        try {
            const response = await Glitch.api.Communities.sendCampaign(community_id, id, campaign_id);
            navigate(Navigate.newsletterCampaignViewPage(id, community_id, campaign_id));
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const schedule = async (status) => {
        setIsLoading(true);
        const campaignData = { ...campaign, status };

        try {
            const response = await Glitch.api.Communities.scheduleCampaign(community_id, id, campaign_id, campaign);
            navigate(Navigate.newsletterCampaignViewPage(id, community_id, campaign_id));
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const test = async (status) => {
        setIsLoading(true);
        const campaignData = { ...campaign, status };

        try {
            const response = await Glitch.api.Communities.testCampaign(community_id, id, campaign_id, campaign);
            
            Alerts.display("Success", "Campaign Test Successfully Sent To Your Email")
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const edit = async (status) => {
        navigate(Navigate.newsletterCampaignUpdatePage(id, community_id, campaign_id));

    };


    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: campaign?.subject, link: Navigate.newsletterCampaignViewPage(newsletter?.id, community_id, campaign?.id) },
                    { name: "Send Campaign", link: Navigate.newsletterCampaignSendPage(newsletter?.id, community_id, campaign?.id) }]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Send Your Campaign</h2>
                        <p className="lead">Either choose to send the campaign now or schedule it for later.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {/* Campaign Form */}
                <CampaignSendForm
                    campaign={campaign}
                    setCampaign={setCampaign}
                    communities={communities}
                    errors={errors}
                />

                <div className="mt-4 text-center">

                    <button
                        type="button"
                        onClick={() => edit()}
                        className="btn btn-primary mx-2"
                    >
                        {isLoading ? <Loading /> : <> <i className="fas fa-pencil-alt"></i> Continue Editing</>}
                    </button>

                    <button
                        type="button"
                        onClick={() => schedule()}
                        className="btn btn-success mx-2"
                    >
                        {isLoading ? <Loading /> : <><i className="far fa-clock"></i> Schedule & Send</> }
                    </button>

                    <button
                        type="button"
                        onClick={() => send()}
                        className="btn btn-success mx-2"
                    >
                        {isLoading ? <Loading /> : <><i className="fas fa-paper-plane"></i> Send Now</>}
                    </button>

                    <button
                        type="button"
                        onClick={() => test()}
                        className="btn btn-success mx-2"
                    >
                        {isLoading ? <Loading /> : <><i className="fas fa-vial"></i> Send Test</>}
                    </button>
                </div>
            </div>
            <br />
            <br />
        </Fragment>
    );
};

export default NewsletterCampaignSendPage;
