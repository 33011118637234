import React from 'react';
import Navigate from '../../../../../util/Navigate';
import { Link } from 'react-router-dom';

const MessageThreads = ({ threads, section = '' }) => {
  if (threads.length === 0) {
    return <div className="text-center my-4">No messages have been sent.</div>;
  }

  const setLink = (thread_id) => {
    if (section === 'publisher') {
      return Navigate.publishersMessagesThreadPage(thread_id);
    } else if (section === 'influencer') {
      return Navigate.influencersMessagesThreadPage(thread_id);
    } else {
      return Navigate.messagesThreadPage(thread_id);
    }
  }

  return (
    <div className="list-group">
      {threads.map(thread => (
        thread?.messages?.length > 0 && (
          <Link to={setLink(thread.id)} className="list-group-item list-group-item-action" key={thread.id}>
            <div className="d-flex w-100 align-items-center">
              <img 
                src={thread.messages[thread.messages.length -1]?.user?.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
                alt={thread.messages[thread.messages.length -1]?.user?.username} 
                className="rounded-circle me-3" 
                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
              />
              <div className="flex-grow-1">
                <div className="d-flex justify-content-between">
                  <h5 className="mb-1">{thread.messages[thread.messages.length -1]?.user?.display_name || thread.messages[thread.messages.length -1]?.user?.username}</h5>
                  <small>{new Date(thread?.messages[thread.messages.length -1]?.created_at).toLocaleDateString()}</small>
                </div>
                <p className="mb-1 text-truncate">{thread.messages[thread.messages.length -1]?.message}</p>
                <small>Participants: {thread.users.map(user => user.username).join(', ')}</small>
              </div>
            </div>
          </Link>
        )
      ))}
    </div>
  );
};

export default MessageThreads;
