import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect, Fragment } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import CampaignLinksManager from '../../component/section/campaigns/campaign_links_manager';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Moment from 'react-moment';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Calculator from '../../../../util/Calculator';
import { faFacebook, faInstagram, faReddit, faTiktok, faTwitch, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfluencerCampaignApplyPage = () => {
    const [campaign, setCampaign] = useState({});
    const [current, setCurrent] = useState(null);
    const [invite, setInvite] = useState(null);
    const { id, campaign_id } = useParams();
    const [me, setMe] = useState({});
    const [errors, setErrors] = useState({});
    const [signingUp, setSigningUp] = useState(false); // State to track sign-up loading
    const [acceptingInvite, setAcceptingInvite] = useState(false); // State to track accept invite loading
    const [rejectingInvite, setRejectingInvite] = useState(false); // State to track reject invite loading

    const navigate = useNavigate();

    const campaignObjectiveMap = {
        1: 'Brand Awareness',
        2: 'Audience Engagement',
        3: 'Lead Generation',
        4: 'Sales Conversion',
        5: 'Brand Identity & Reputation',
        6: 'Customer Loyalty & Retention',
        7: 'Content Amplification & Diversity',
        8: 'Market Feedback & Insight',
        9: 'Educating Audience',
        10: 'Community Building',
        11: 'Driving Web Traffic',
        12: 'SEO Benefits',
    };

    const influencerCampaignTypeMap = {
        1: 'Influencer Marketing',
        2: 'Affiliate Marketing',
        3: 'Product Gifting',
        4: 'Brand Ambassador',
        5: 'Social Media Takeover',
        6: 'Contests & Giveaways',
        7: 'Event Coverage',
        8: 'Co-Creation of Products',
        9: 'Influencer Whitelisting',
        10: 'Social Issues & Cause Campaigns',
    };

    useEffect(() => {
        if (Glitch.util.Session.isLoggedIn()) {
            Glitch.api.Users.me().then(response => {
                setMe(response.data.data);

                Glitch.api.Campaigns.viewInfluencerCampaign(campaign_id, response.data.data.id).then(response => {
                    setCurrent(response.data.data);
                }).catch(error => {
                    console.error('Error fetching current campaign', error);
                });

                if (response.data.data.influencer) {
                    Glitch.api.Campaigns.viewInfluencerInvite(campaign_id, response.data.data.influencer.id).then(response => {
                        setInvite(response.data.data);
                    }).catch(error => {
                        console.error('Error fetching influencer invite', error);
                    });
                }

            }).catch(error => {
                console.error('Error fetching me', error);
            });
        }

        Glitch.api.Campaigns.view(campaign_id).then(response => {
            const updatedCampaign = {
                ...response.data.data,
                type: influencerCampaignTypeMap[response.data.data.type],
                objective: campaignObjectiveMap[response.data.data.objective],
            };
            setCampaign(updatedCampaign);
        }).catch(error => {
            console.error('Error fetching campaign', error);
        });
    }, [campaign_id]);

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    const acceptInvite = async () => {
        setAcceptingInvite(true);
        Glitch.api.Campaigns.acceptInfluencerInvite(campaign_id, me?.influencer.id).then(response => {
            navigate(Navigate.influencersManageCampaignPage(campaign.id, me.id));
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setAcceptingInvite(false);
        });
    };

    const declineInvite = async () => {
        setRejectingInvite(true);
        Glitch.api.Campaigns.declineInfluencerInvite(campaign_id, me?.influencer.id).then(response => {
            setInvite(response.data.data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setRejectingInvite(false);
        });
    };

    const register = () => {
        if (Glitch.util.Session.isLoggedIn()) {
            setSigningUp(true); // Start signing up loading state
            Glitch.api.Campaigns.createInfluencerCampaign(campaign_id, me.id).then(response => {
                navigate(Navigate.influencersManageCampaignPage(response.data.data.campaign_id, response.data.data.user_id));
            }).catch(error => {
                let jsonErrors = error?.response?.data;
                if (jsonErrors) {
                    setErrors(jsonErrors);
                    setTimeout(() => {
                        setErrors({});
                    }, timeouts.error_message_timeout);
                }
            }).finally(() => {
                setSigningUp(false); // Stop signing up loading state
            });
        } else {
            const redirectUrl = `${window.location.pathname}${window.location.search}`;
            const onboardingUrl = Navigate.creatorsOnboardingStep1Page();
            navigate(`${onboardingUrl}?redirect=${encodeURIComponent(redirectUrl)}`);
        }
    };

    return (

        <>
            <Fragment>
                <div className="login-section padding-top padding-bottom">
                    <div className="container">
                        <div className="account-wrapper" style={{ maxWidth: '800px' }}>
                            <h3 className="title">Finish Appying To {campaign?.title?.name}</h3>
                            <p>Finish applying to the {campaign?.title?.name} by clicking the button below.</p>

                            <hr />

                            <div className="text-center">
                                {errors && errors.error ? <Danger message={errors.error} key={0} /> : ''}
                                {current ? (
                                    <>
                                        <p>You have already signed up for this campaign.</p>
                                        <button className="btn btn-lg btn-primary" onClick={() => navigate(Navigate.influencersManageCampaignPage(campaign_id, me.id))}>
                                            <i className="fas fa-eye"></i> View Your Campaign
                                        </button>
                                    </>
                                ) : invite ? (
                                    <>
                                        {invite.rejected ? <p className='text-danger'>You have rejected this campaign</p> : <p>You have been invited to this campaign.</p>}
                                        <button className="btn btn-lg btn-success me-2" onClick={acceptInvite} disabled={acceptingInvite}>
                                            {acceptingInvite && (
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            )}
                                            <i className="fas fa-check"></i> Accept Invite
                                        </button>
                                        <button className="btn btn-lg btn-danger" onClick={declineInvite} disabled={rejectingInvite}>
                                            {rejectingInvite && (
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            )}
                                            <i className="fas fa-times"></i> Reject Invite
                                        </button>
                                    </>
                                ) : (
                                    <button className={`btn btn-lg btn-success ${signingUp ? 'disabled' : ''}`} onClick={register} disabled={signingUp}>
                                        {signingUp && (
                                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        )}
                                        {me?.id ? 'Apply Now!' : 'Sign Up'}
                                    </button>
                                )}
                            </div>
                            
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>


        </>
    );
};

export default InfluencerCampaignApplyPage;
