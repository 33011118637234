import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube, faReddit, faTwitter, faFacebook, faTwitch } from '@fortawesome/free-brands-svg-icons';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import { Modal, Button, Form } from 'react-bootstrap';
import Wysiwyg from '../../component/form/wysiwyg';
import { faEdit, faPlus, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_IMAGE = 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png';

const CampaignsViewInfluencerPage = () => {
    const [influencer, setInfluencer] = useState(null);
    const [campaign, setCampaign] = useState({});
    const { id, influencer_id } = useParams();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showCustomInviteModal, setShowCustomInviteModal] = useState(false);
    const [customEmail, setCustomEmail] = useState('');
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [editingNoteId, setEditingNoteId] = useState(null);
    const [editingNoteContent, setEditingNoteContent] = useState('');

    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }
        
        Glitch.api.Campaigns.view(id).then(response => {
            setCampaign(response.data.data);

            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

        fetchInfluencer();
        getNotes();
    }, []);

    const fetchInfluencer = async () => {
        try {
            const response = await Glitch.api.Influencers.viewInfluencer(influencer_id, { campaign_id: id });
            setInfluencer(response.data.data);
        } catch (error) {
            console.error('Error fetching influencer', error);
        }
    };

    const sendInvite = (custom_email = null) => {
        setIsLoading(true);
        Glitch.api.Campaigns.sendInfluencerInvite(id, { influencer_id: influencer_id, custom_invite_email: custom_email }).then(response => {
            setShowSuccessModal(true);
            fetchInfluencer();
        }).catch((error) => {
            if (error.response && error.response.status === 402) {
                if (subscriptions.length === 0) {
                    setErrorMessage(
                        <div className='text-center'>
                            <p>You must sign up for a subscription to send the invite. Please follow the button to select a subscription account.</p>
                            <Link to={Navigate.communitiesSubscribePage(campaign.community_id)} className='btn btn-success'>Get A Subscription</Link>
                        </div>
                    );
                } else {
                    setErrorMessage('You must update your payment information to send the invite.');
                }
                setShowErrorModal(true);
            } else {
                console.error('Error sending invite', error);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const handleSendCustomInvite = () => {
        sendInvite(customEmail);
        setShowCustomInviteModal(false);
        setCustomEmail('');
    };

    const getNotes = () => {
        Glitch.api.Influencers.listNotes(influencer_id, { campaign_id: id }).then(response => {
            setNotes(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    };

    const createNewNote = (note) => {
        Glitch.api.Influencers.createNote(influencer_id, { note: note, campaign_id: id }).then(response => {
            getNotes();
        }).catch(error => {
            console.error(error);
        });
    };

    const updateNote = (note_id, note) => {
        Glitch.api.Influencers.updateNote(influencer_id, note_id, { note: note }).then(response => {
            getNotes();
            setEditingNoteId(null);
            setEditingNoteContent('');
        }).catch(error => {
            console.error(error);
        });
    };

    const deleteNote = (note_id) => {
        if (window.confirm('Are you sure you want to delete this note?')) {
            Glitch.api.Influencers.deleteNote(influencer_id, note_id).then(response => {
                getNotes();
            }).catch(error => {
                console.error(error);
            });
        }
    };

    const formatNumber = (num) => {
        return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
    };

    const formatExternalUrls = (urls) => {
        if (Array.isArray(urls)) {
            return urls.map(url => url.replace(/[\[\]'"]/g, '').trim()).filter(url => url && url !== 'None');
        }
        return [];
    };

    if (!influencer) {
        return <p>Loading...</p>;
    }

    const externalUrls = formatExternalUrls(influencer.external_urls);

    const hasYouTubeData = influencer.youtube_link || influencer.youtube_custom_url || influencer.youtube_title || influencer.youtube_subscriber_count || influencer.youtube_video_count;

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs items={[
                            { name: 'Campaigns', link: Navigate.campaignsPage() },
                            { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                            { name: 'Find Influencer', link: Navigate.campaignsFindInfluencers(campaign.id) },
                            { name: 'View Influencer', link: Navigate.campaignsViewInfluencer(campaign.id, influencer_id) },
                        ]}
                        />
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4">
                            </div>
                            <h2 className="pageheader-title">View Influencer</h2>
                            <p className="lead">Learn more about the influencer.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                </div>

                <div className="container mt-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Personal Information</h4>
                        </div>
                        <div className="card-body d-flex align-items-center">
                            <img
                                src={getInfluencerImage(influencer)}
                                alt={`${influencer.first_name}'s profile`}
                                className="img-thumbnail me-4"
                                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                            />
                            <div>
                                <p><strong>Name:</strong> {influencer.first_name || influencer.instagram_username || influencer.youtube_title}</p>
                                <p><strong>Location:</strong> {influencer.location}</p>
                                <p><strong>Speaking Language:</strong> {influencer.speaking_language}</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Social Media Profiles</h4>
                        </div>
                        <div className="card-body">
                            <SocialMediaLink icon={faInstagram} data={influencer} platform="instagram" />
                            <SocialMediaLink icon={faTiktok} data={influencer} platform="tiktok" />
                            {hasYouTubeData && <SocialMediaLink icon={faYoutube} data={influencer} platform="youtube" />}
                            <SocialMediaLink icon={faTwitter} data={influencer} platform="twitter" />
                            <SocialMediaLink icon={faFacebook} data={influencer} platform="facebook" />
                            <SocialMediaLink icon={faReddit} data={influencer} platform="reddit" />
                            <SocialMediaLink icon={faTwitch} data={influencer} platform="twitch" />
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Content Details</h4>
                        </div>
                        <div className="card-body">
                            {externalUrls.length > 0 && (
                                <>
                                    <p><strong>External URLs:</strong></p>
                                    <ul>
                                        {externalUrls.map((url, index) => (
                                            <li key={index}><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <p><strong>Has Link in Bio:</strong> {influencer.has_link_in_bio ? 'Yes' : 'No'}</p>
                            <p><strong>Has Brand Deals:</strong> {influencer.has_brand_deals ? 'Yes' : 'No'}</p>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Notes</h4>
                        </div>
                        <div className="card-body">
                            {notes.map(note => (
                                <div key={note.id} className="mb-3 border p-3">
                                    {editingNoteId === note.id ? (
                                        <Wysiwyg id={`edit_note_${note.id}`} name="edit_note" onChange={(content) => setEditingNoteContent(content)} placeholder="Edit note">
                                            {editingNoteContent || note.note}
                                        </Wysiwyg>
                                    ) : (
                                        <div dangerouslySetInnerHTML={{ __html: note.note }} />
                                    )}
                                    <div className="mt-2">
                                        {editingNoteId === note.id ? (
                                            <>
                                                <Button variant="success" onClick={() => updateNote(note.id, editingNoteContent)}>Save</Button>
                                                <Button variant="secondary" onClick={() => setEditingNoteId(null)} className="ms-2">Cancel</Button>
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faEdit} className="text-primary me-2" onClick={() => { setEditingNoteId(note.id); setEditingNoteContent(note.note); }} />
                                                <FontAwesomeIcon icon={faTrash} className="text-danger" onClick={() => deleteNote(note.id)} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="mt-4">
                                <Wysiwyg id="new_note" name="new_note" onChange={(content) => setNewNote(content)} placeholder="Write a new note">
                                    {newNote}
                                </Wysiwyg>
                                <Button variant="primary" onClick={() => createNewNote(newNote)} className="mt-2">Add Note</Button>
                            </div>
                        </div>
                    </div>

                    <div className='text-center'>
                        {influencer.invite && (!influencer.invite.accepted && !influencer.invite.rejected) ? (
                            <button className='btn btn-warning btn-lg' type='button' onClick={sendInvite} disabled={isLoading}>
                                {isLoading ? <><FontAwesomeIcon icon={faSpinner} spin /> Sending Invite.... </> : `Resend Invite (Sent on ${new Date(influencer.invite.invite_created_at).toLocaleDateString()})`}
                            </button>
                        ) : influencer.invite ? (
                            <p>The user has already {influencer.invite.accepted ? 'accepted' : 'rejected'} the invite.</p>
                        ) : (
                            <>
                                <button className='btn btn-success btn-lg' type='button' onClick={sendInvite} disabled={isLoading}>
                                    {isLoading ? <><FontAwesomeIcon icon={faSpinner} spin /> Sending Invite.... </> : 'Invite To Campaign'}
                                </button>
                                <button className='btn btn-primary btn-lg ms-2' type='button' onClick={() => setShowCustomInviteModal(true)} disabled={isLoading}>
                                    {isLoading ? <><FontAwesomeIcon icon={faSpinner} spin /> </> : 'Custom Invite'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </Fragment>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>Influencer has been successfully invited.</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowSuccessModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Error Modal */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>{subscriptions.length === 0 ? 'Sign Up for a Subscription' : 'Update Payment Information'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-black'>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowErrorModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Custom Invite Modal */}
            <Modal show={showCustomInviteModal} onHide={() => setShowCustomInviteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-black'>Custom Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-black'>A custom invite allows you to personalize the email being sent to the influencer. Please write your custom email below.</p>
                    <Form.Group controlId="customEmail">
                        <Form.Label>Custom Email Template</Form.Label>
                        <Wysiwyg id="custom_email" name="custom_email" onChange={(content) => setCustomEmail(content)} placeholder="Enter custom email template">
                            {customEmail || ''}
                        </Wysiwyg>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCustomInviteModal(false)}>Cancel</Button>
                    <Button variant="primary" onClick={handleSendCustomInvite}>Send Invite</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const SocialMediaLink = ({ icon, data, platform }) => {
    if (!data[`${platform}_username`] && platform !== "youtube") {
        return null;
    }

    const formatNumber = (num) => {
        return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
    };

    const biographyField = `${platform}_biography`;
    const biography = data[biographyField];

    const hasYouTubeData = platform === "youtube" && (data.youtube_link || data.youtube_custom_url || data.youtube_title || data.youtube_subscriber_count || data.youtube_video_count);

    if (platform === "youtube" && !hasYouTubeData) {
        return null;
    }

    return (
        <div className="mb-3">
            <h6><FontAwesomeIcon icon={icon} className='text-black me-2' />
                {platform === "youtube" ? (
                    <Link to={data[`youtube_link`]} target="_blank">{data[`youtube_custom_url`] || data[`youtube_title`]}</Link>
                ) : (
                    <Link to={data[`${platform}_link`]} target="_blank">{data[`${platform}_username`]}</Link>
                )}
            </h6>
            <p><strong>Followers:</strong> {formatNumber(data[`${platform}_follower_count`] || data[`youtube_subscriber_count`])}</p>
            <p><strong>Engagement:</strong> {data[`${platform}_engagement_percent`] || data[`youtube_engagement_percent`]}%</p>
            {biography && <p><strong>Biography:</strong> {biography}</p>}
            {platform === "youtube" && hasYouTubeData && (
                <>
                    <p><strong>Video Count:</strong> {data[`youtube_video_count`]}</p>
                    <p><strong>Average Views:</strong> {data[`youtube_avg_views`]}</p>
                    <p><strong>Average Views (Shorts):</strong> {data[`youtube_avg_views_shorts`]}</p>
                    <p><strong>Average Views (Long):</strong> {data[`youtube_avg_views_long`]}</p>
                    <p><strong>Has Shorts:</strong> {data[`youtube_has_shorts`] ? 'Yes' : 'No'}</p>
                    <p><strong>Posting Frequency:</strong> {data[`youtube_posting_frequency`]} times per week</p>
                    <p><strong>Topics:</strong> {data[`youtube_topic_details`]?.join(', ')}</p>
                </>
            )}
        </div>
    );
};

export default CampaignsViewInfluencerPage;
