import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import GameTitle from '../../component/section/titles/title_display';
import Alerts from '../../../../util/Alerts';

const PlayTestGameDetailsPage = () => {
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams(); // title_id
    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchTitle = async () => {
            try {
                const response = await Glitch.api.Titles.view(id);
                setTitle(response.data.data);
            } catch (error) {
                console.error('Error fetching title', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTitle();
    }, [id, navigate]);

    const handleApply = async () => {
        try {
            await Glitch.api.PlayTests.requestPlayTest(id);
            Alerts.display('Application Succcess', 'Play test request sent successfully.');
            navigate(Navigate.playtestingUserTests());
        } catch (error) {
            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Application Failed', message);
            } else {
                alert('Failed to request play test.');
            }
        }
    };

    return (
        <>
            <InfluencerHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingUserGames() },
                        { name: title?.name || 'Loading...', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">{title?.name || 'Loading...'}</h2>
                        <p className="lead">Learn more about this game.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : title ? (
                    <>

                        <div className="card mb-4 p-1">
                            <GameTitle gameInfo={title} />

                            <div className="d-flex justify-content-center mt-4 mb-5">
                                <button className="btn btn-primary btn-lg mx-2" onClick={handleApply}>
                                    <i className="fas fa-play"></i> Apply to Test
                                </button>
                            </div>

                        </div>


                    </>
                ) : (
                    <p>Game not found.</p>
                )}
            </div>
        </>
    );
};

export default PlayTestGameDetailsPage;
