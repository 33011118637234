import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet';

const PublisherTutorialInviteRecommendedPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet>
                <title>How to Use the Recommended Influencers and AI Invites | Glitch Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to efficiently use the 'Recommended Influencers' feature and AI-powered invites on Glitch. This tutorial guides you through optimizing influencer outreach based on your campaign's criteria."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Use the Recommended Influencers and AI Invites | Glitch Tutorial" />
                <meta
                    property="og:description"
                    content="This tutorial explains how to leverage Glitch's 'Recommended Influencers' feature and AI-driven invites to streamline your influencer marketing efforts."
                />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Use the Recommended Influencers and AI Invites | Glitch Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to efficiently use the 'Recommended Influencers' feature and AI-powered invites on Glitch. This tutorial guides you through optimizing influencer outreach based on your campaign's criteria."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Use the Recommended Influencers and AI Invites</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/cgBfkntIKM0"
                                title="Using Recommended Influencers and AI Invites"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            In this tutorial, we’ll guide you through using the "Recommended Influencers" section and how to leverage AI to automatically invite influencers to your campaign.
                        </p>

                        <h4>Getting Started with Recommended Influencers</h4>
                        <p>
                            After creating your campaign, navigate to the “Recommended” section within your campaign dashboard. Here, you'll find a curated list of influencers that might be a good match based on your campaign’s details. This list is generated based on the specific information you’ve provided about your game.
                        </p>

                        <h4>How the List is Curated</h4>
                        <p>
                            The list of recommended influencers is tailored using the details from your campaign. The most crucial fields include:
                        </p>

                        <ul>
                            <li>
                                <strong>Game Descriptions:</strong> Both the short and long descriptions of your game help the AI understand what your game is about, enabling it to match influencers with relevant interests.
                            </li>
                            <li>
                                <strong>Targeting Information:</strong> Fields like “Game Type,” “Countries,” and “Age Range” ensure that the recommended influencers align with your target audience.
                            </li>
                            <li>
                                <strong>Follower Requirements:</strong> Setting a follower range helps filter influencers who meet your campaign’s reach criteria.
                            </li>
                        </ul>

                        <h4>Using AI for Automatic Invites</h4>
                        <p>
                            If you want to automate the influencer outreach process, head to the Management section of your campaign. Set the "Creator Sourcing Management" to AI, and the system will automatically invite influencers who match your criteria.
                        </p>

                        <p>
                            Once the AI starts sourcing, influencers will begin responding to your invitations. Remember, it’s important to follow up with influencers who apply—responding to an invite doesn’t necessarily mean they’ll promote your game. Managing this process is key to securing successful collaborations.
                        </p>

                        <p>
                            That’s how you can effectively use the Recommended Influencers section and AI-powered invites on Glitch. This approach helps streamline your influencer marketing efforts, ensuring you connect with the right creators for your campaign.
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialInviteRecommendedPage;
