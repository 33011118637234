import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams, Link } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import GameTitle from '../../component/section/titles/title_display';
import Alerts from '../../../../util/Alerts';

const PlayTestDetailsForTesterPage = () => {
    const [playTest, setPlayTest] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id, playtest_id } = useParams(); // playtest_id
    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchPlayTest = async () => {
            try {
                const response = await Glitch.api.PlayTests.show(id, playtest_id);
                setPlayTest(response.data.data);
            } catch (error) {
                console.error('Error fetching play test', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPlayTest();
    }, [id, navigate]);

    const handleAcceptInvite = async (playTest) => {
        try {
            await Glitch.api.PlayTests.acceptInvite(playTest.title_id, playTest.id);

            Alerts.display("Success", 'Invite accepted successfully.').
                // Refresh the list
                setIsLoading(true);

            setIsLoading(false);
        } catch (error) {

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Failed', message);
            } else {
                alert('Failed to accept invite');
            }
        }
    };

    const handleRejectInvite = async (playTest) => {
        try {
            await Glitch.api.PlayTests.rejectInvite(playTest.title_id, playTest.id);
            Alerts.display("Success", 'Invite has been rejected.').
                // Refresh the list
                setIsLoading(true);

            setIsLoading(false);
        } catch (error) {

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Failed', message);
            } else {
                alert('Failed to reject invite.');
            }
        }
    };

    const handleCancelRequest = async (playTest) => {
        try {
            await Glitch.api.PlayTests.cancelRequest(playTest.title_id, playTest.id);

            Alerts.display("Success", 'Play test was cancelled.').
                // Refresh the list
                setIsLoading(true);

            setIsLoading(false);
        } catch (error) {

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Failure', message);
            } else {
                alert('Failed to cancel play test request.');
            }

        }
    };

    return (
        <>
            <InfluencerHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingUserTests() },
                        { name: 'Play Test Details', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Play Test Details</h2>
                        <p className="lead">View details for this play test.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : playTest ? (
                    <>
                        <div className="card mb-4 text-black">
                            <div className="card-header">
                                <h3 className='text-black'>Game Information</h3>
                            </div>
                            <div className="card-body text-black">
                                <GameTitle gameInfo={playTest?.title} />
                            </div>
                        </div>

                        {playTest.user_access_key && (
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h3>Access Key</h3>
                                </div>
                                <div className="card-body">
                                    <p>
                                        Use the following key to access the game:
                                        <br />
                                        <strong>{playTest.user_access_key}</strong>
                                    </p>
                                </div>
                            </div>
                        )}

                        {playTest.developer_testing_requirements && (
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h3>Developer's Requirements</h3>
                                </div>
                                <div className="card-body">
                                    <p>{playTest.developer_testing_requirements}</p>
                                </div>
                            </div>
                        )}

                        {playTest.status === 'pending' && !playTest.requested_by_user && (
                            <>
                                <div className="card mb-4 text-black">
                                    <div className="card-header">
                                        <h3 className="text-black">Accept Or Reject Invite</h3>
                                    </div>
                                    <div className="card-body text-black">
                                        <div className="d-flex justify-content-center">
                                            <button
                                                className="btn btn-sm btn-success me-2"
                                                onClick={() => handleAcceptInvite(playTest)}
                                            >
                                                <i className="fas fa-check"></i> Accept
                                            </button>
                                            <button
                                                className="btn btn-sm btn-danger"
                                                onClick={() => handleRejectInvite(playTest)}
                                            >
                                                <i className="fas fa-times"></i> Reject
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}


                        {playTest.status === 'in_progress' && (
                            <div className="text-center">
                                <Link
                                    to={Navigate.playtestingUserAnswers(playTest.title_id, playTest.id)}
                                    className="btn btn-lg btn-primary"
                                >
                                    <i className="fas fa-edit"></i> Submit Your Feedback
                                </Link>
                            </div>
                        )}

                        {playTest.status === 'completed' && (
                            <div className="text-center">
                                <Link
                                    to={Navigate.playtestingUserAnswers(playTest.title_id, playTest.id)}
                                    className="btn btn-lg btn-primary"
                                >
                                    <i className="fas fa-edit"></i> Update Your Feedback
                                </Link>
                            </div>
                        )}
                    </>
                ) : (
                    <p>Play test not found.</p>
                )}
            </div>
        </>
    );
};

export default PlayTestDetailsForTesterPage;
