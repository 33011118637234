import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Footer from '../../component/layout/footer';

const InfluencerTutorialsPage = () => {

    useEffect(() => {
        // Any useEffect logic if needed
    }, []);

    const tutorials = [
        { title: 'How To Respond To An Invite', icon: 'fas fa-play-circle', link: Navigate.influencersTutorialInvites() },
        { title: 'How To Find A Campaign To Play and Promote', icon: 'fas fa-bullhorn', link: Navigate.influencersTutorialGames() },
        { title: 'How To Start Creating Content And Promoting A Game', icon: 'fas fa-users-cog', link: Navigate.influencersTutorialStarting() },
    ];
    
    return (
        <>
            <Fragment>
                <InfluencerHeader position={"relative"} />
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4"></div>
                            <h2 className="pageheader-title">Influencer Tutorials</h2>
                            <p className="lead">Learn how to use the site as an influencer/content creator.</p>
                        </div>
                    </div>
                </section>

                <div className="container">
                    <p className='lead'>To learn more about how to use the platform, watch our video tutorials below. Also you may <a href="https://discord.gg/gnAyG9nB" target='blank'>join our Discord here</a> and ask questions.</p>

                    <ul className="list-group">
                        {tutorials.map((tutorial, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center bg-dark text-white mb-3 p-4">
                                <div>
                                    <i className={`${tutorial.icon} me-3 theme-color`} style={{ fontSize: '1.5rem' }}></i>
                                    <Link to={tutorial.link} className="text-white">
                                        {tutorial.title}
                                    </Link>
                                </div>
                                <Link to={tutorial.link} className="text-white">
                                    <i className="fas fa-chevron-right text-white"></i>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </Fragment>
            <Footer />
        </>
    );
};

export default InfluencerTutorialsPage;
