import React, { useState, useRef } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Typeahead } from 'react-bootstrap-typeahead'; // Import Typeahead from react-bootstrap-typeahead
import 'react-bootstrap-typeahead/css/Typeahead.css'; // Import Typeahead styles

function CreatorTypesForm({ currentTypes }) {
    const [types, setTypes] = useState([]);
    const [availableTypes, setAvailableTypes] = useState(currentTypes || []);
    const typeaheadRef = useRef(null); // Create a ref to access the Typeahead instance

    const addType = (selected) => {
        if (selected.length > 0) {
            const selectedId = selected[0].id; // Get the selected ID
            Glitch.api.Users.addType({ type_id: selectedId })
                .then(response => {
                    setTypes(response.data.data.types); // Update types after adding
                    
                    // Clear the Typeahead input after successful addition
                    if (typeaheadRef.current) {
                        typeaheadRef.current.clear(); // Clear the input box
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    const removeType = (typeId) => {
        Glitch.api.Users.removeType(typeId)
            .then(response => {
                setTypes(response.data.data.types);
            })
            .catch(error => {
                console.error(error);
            });
    };

    return (
        <div>
            
            <div><strong>Your Types:</strong></div>
            {types.map((type) => (
                <span key={type.id} className="badge bg-secondary me-2">
                    {type.name}
                    <button type="button" className="btn btn-danger btn-sm ms-2" onClick={() => removeType(type.id)}>
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </span>
            ))}
            
            <div className="mt-3">
                <div className="input-group w-auto">
                    <Typeahead
                        id="game-type-select"
                        labelKey="name"
                        onChange={(selected) => {
                            addType(selected); // Add the selected type immediately
                        }}
                        options={availableTypes}
                        placeholder="Type to search and select game types..."
                        clearButton // Add clear button to reset input easily
                        highlightOnlyResult // Highlight the best matching result
                        minLength={1} // Minimum characters before showing suggestions
                        ref={typeaheadRef} // Attach the ref to Typeahead component
                    />
                </div>
            </div>
        </div>
    );
}

export default CreatorTypesForm;
