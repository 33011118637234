import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';
import GameTitleForm from '../../component/section/titles/title_form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faEdit, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';

const MediakitCreatePage = () => {
    const [mediakit, setMediakit] = useState({});
    const [errors, setErrors] = useState({});
    const [externalGameTitle, setExternalGameTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [gameMainImageBlob, setMainImageBlob] = useState(null);
    const [gameBannerImageBlob, setBannerImageBlob] = useState(null);
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(0);

    const [games, setGames] = useState([]);
    const [selectedGameId, setSelectedGameId] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState('Searching for game data...');
    const [loadError, setLoadError] = useState(false);
    const [errordGameId, setErrorGameId] = useState(null);

    const loadingMessages = [
        'Searching for game data...',
        'Data Found, parsing...',
        'Understanding game info...',
        'Generating optimized media kit...',
        'Downloading media assets...',
        'Processing data...',
        'Finalizing...',
    ];

    const changeLoadingMessage = (index) => {
        if (index < loadingMessages.length) {
            setLoadingMessage(loadingMessages[index]);
            setTimeout(() => changeLoadingMessage(index + 1), 5000);
        }
    };

    const generateMediakitData = async (game_id) => {
        setIsLoading(true);
        setSelectedGameId(game_id);
        setLoadError(false);
        changeLoadingMessage(0);

        try {
            // Assuming there is an API similar to createCampaignData for media kits
            const response = await Glitch.api.Games.createGameTitle(game_id);
            const mediakitData = response.data.data.title;
            const externalGame = response.data.data.game;

            setMediakit(mediakitData);
            setExternalGameTitle(externalGame);
            setCurrentStep(1);
        } catch (error) {
            console.error(error);
            setLoadError(true);
        } finally {
            setIsLoading(false);
            setSelectedGameId(null);
            setErrorGameId(game_id);
        }
    };

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
        }

        Glitch.api.Games.listGames().then(response => {
            setGames(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    }, [navigate]);

    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            // Ensure is_media_kit is set to true
            const mediakitData = { ...mediakit, is_media_kit: true };

            if (!mediakit.id) {
                // Create the mediakit
                const response = await Glitch.api.Titles.create(mediakitData);
                const createdMediakit = response.data.data;

                // Upload images if they exist
                const uploadPromises = [];

                if (gameMainImageBlob) {
                    uploadPromises.push(
                        Glitch.api.Titles.uploadMainImageBlob(createdMediakit.id, gameMainImageBlob)
                    );
                }

                if (gameBannerImageBlob) {
                    uploadPromises.push(
                        Glitch.api.Titles.uploadBannerImageBlob(createdMediakit.id, gameBannerImageBlob)
                    );
                }

                if (uploadPromises.length > 0) {
                    await Promise.all(uploadPromises);
                }

                navigate(Navigate.mediakitsPublishersSharePage(createdMediakit.id));
            } else {
                // Update the existing title to a mediakit
                await Glitch.api.Titles.update(mediakit.id, mediakitData);

                // Upload images if they exist
                const uploadPromises = [];

                if (gameMainImageBlob) {
                    uploadPromises.push(
                        Glitch.api.Titles.uploadMainImageBlob(mediakit.id, gameMainImageBlob)
                    );
                }

                if (gameBannerImageBlob) {
                    uploadPromises.push(
                        Glitch.api.Titles.uploadBannerImageBlob(mediakit.id, gameBannerImageBlob)
                    );
                }

                if (uploadPromises.length > 0) {
                    await Promise.all(uploadPromises);
                }

                navigate(Navigate.mediakitsPublishersViewPage(mediakit.id));
            }
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Media Kits', link: Navigate.mediakitsPublishersListPage() },
                        { name: 'Create Media Kit', link: Navigate.mediakitsPublishersCreatePage() },
                    ]}
                />
            </div>

            {currentStep === 0 && (
                <div className="initial-step text-center">
                    <h2>Create Media Kit With A.I. or Manually?</h2>
                    <p>Choose how you want to create your media kit.</p>
                    <div className="initial-buttons">
                        <button className="btn btn-primary btn-lg mx-2" onClick={() => setCurrentStep('ai')}>
                            <FontAwesomeIcon icon={faRobot} /> Create with AI
                        </button>
                        <button className="btn btn-secondary btn-lg mx-2" onClick={() => setCurrentStep(1)}>
                            <FontAwesomeIcon icon={faEdit} /> Create Manually
                        </button>
                    </div>
                </div>
            )}

            {currentStep === 'ai' && (
                <div className="ai-selection text-center container">
                    <h2>Find Your Game</h2>
                    <p className='lead'>Our database connects to multiple game stores with your games information. Find the game you want to create a media kit for and let AI populate the fields for you. Population can take a minute or two, please be patient.</p>
                    <div className="search-bar">
                        <input type="text" placeholder="Search for your game..." onChange={(e) => {
                            const search = e.target.value;
                            if (search.length > 2) {
                                Glitch.api.Games.listGames({ search }).then(response => {
                                    setGames(response.data.data);
                                }).catch(error => {
                                    console.error(error);
                                });
                            }
                        }} />
                    </div>
                    <div className="game-list">
                        {games.map(game => (
                            <div key={game.id} className="game-item">
                                <p>{game.name}</p>
                                <button className="btn btn-primary btn-sm" onClick={() => generateMediakitData(game.id)} disabled={selectedGameId !== null}>
                                    {selectedGameId === game.id && isLoading ? <><Loading /> {loadingMessage}</> : 'Select Game'}
                                </button>
                                {loadError && errordGameId === game.id && (
                                    <div className="alert alert-danger mt-4">
                                        An error has occurred, it happens. Please try to select your game again.
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {isLoading && <Loading />}
                    {isLoading && <p>{loadingMessage}</p>}
                    {loadError && (
                        <div className="alert alert-danger mt-4">
                            An error has occurred, it happens. Please try to select your game again.
                        </div>
                    )}
                    <p className='mt-4 mb-8'>Don't see your game? <a href="#" onClick={() => setCurrentStep(1)}>Then create it manually.</a></p>
                    <br /><br />
                </div>
            )}

            {currentStep > 0 && currentStep !== 'ai' && (
                <>
                    <section className="pageheader-section-min">
                        <div className="container">
                            <div className="section-wrapper text-center">
                                <h2 className="pageheader-title">Create Media Kit</h2>
                                <p className="lead">Create a media kit to share with press, influencers, and more.</p>
                            </div>
                        </div>
                    </section>

                    <div className="container mt-5 mb-5">
                        <GameTitleForm
                            gameTitle={mediakit}
                            campaignData={{}}
                            externalGameData={externalGameTitle}
                            setGameTitle={setMediakit}
                            setMainImageBlob={setMainImageBlob}
                            setBannerImageBlob={setBannerImageBlob}
                            errors={errors}
                            showPressKit={true}
                            isCreate={true}
                        />

                        {errors && Object.keys(errors).length > 0 && (
                            <Danger message="You have errors in your submission." />
                        )}

                        <div className="mt-4 text-center">
  
                            {currentStep >= 1 ? (
                                 <button
                                    type="button btn-lg"
                                    onClick={handleSubmit}
                                    className="btn btn-success mx-2"
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Loading /> : 'Create Media Kit'}
                                </button>
                            ) : (
                               <>
                               </>
                            )}
                        </div>
                    </div>
                </>
            )}

            <style>{`
                .initial-step {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 80vh;
                }
                .initial-buttons {
                    display: flex;
                    justify-content: center;
                    margin-top: 20px;
                }
                .ai-selection .search-bar input {
                    width: 80%;
                    padding: 10px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
                .ai-selection .game-list {
                    max-height: 400px;
                    overflow-y: auto;
                    background: white;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }
                .ai-selection .game-item {
                    padding: 10px;
                    border-bottom: 1px solid #ccc;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: black;
                    height: 65px;
                }
                .ai-selection .game-item:hover {
                    background-color: #f9f9f9;
                }
                .ai-selection .game-item:last-child {
                    border-bottom: none;
                }
            `}</style>
        </Fragment>
    );
};

export default MediakitCreatePage;
