import Glitch from 'glitch-javascript-sdk';
import { Component, Fragment } from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';

class CommunitiesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      communities: [],
    };
  }

  componentDidMount() {
    Glitch.api.Communities.list()
      .then((response) => {
        this.setState({ communities: response.data.data });
      })
      .catch((error) => {});
  }

  render() {
    return (
      <Fragment>
        <Header />
        <section
          className="pageheader-section"
          style={{ backgroundImage: 'url(/assets/images/pageheader/bg.jpg)' }}
        >
          <div className="container">
            <div className="section-wrapper text-center">
              <div className="pageheader-thumb mb-4">
                <img
                  style={{ maxHeight: '160px' }}
                  src="/assets/images/revenue/profits.png"
                  alt="team"
                />
              </div>
              <h2 className="pageheader-title">Communities</h2>
              <p className="lead">See the communities.</p>
            </div>
          </div>
        </section>

        <div className="container padding-bottom mt-5">
          <div className="section-wrapper">
            <Link className="btn btn-success mb-3" to={Navigate.communitiesCreatePage()}>
              Create Business Account
            </Link>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {this.state.communities.length > 0 ? (
              this.state.communities.map((community, index) => (
                <div className="col-lg-4 col-md-6 mb-4" key={index}>
                  <div className="card h-100 shadow-sm">
                    <img
                      src={
                        community.banner_image
                          ? community.banner_image
                          : `/assets/images/cover/cover_${Math.floor(Math.random() * 27) + 1}.jpeg`
                      }
                      alt={community.name}
                      className="card-img-top"
                      style={{ objectFit: 'cover', height: '200px' }}
                    />
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title">
                        <Link
                          to={
                            'http://' +
                            (community.cname && community.cname_enabled
                              ? community.cname
                              : community.subdomain + process.env.REACT_APP_SITE_DOMAIN)
                          }
                        >
                          {community.name}
                        </Link>
                      </h5>
                      <p className="card-text">{community.description}</p>
                    </div>
                    <div className="card-footer text-muted text-center">
                      <span>{community.tagline || 'Join our community'}</span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">No communities found.</p>
            )}
          </div>
        </div>

        <Footer />
      </Fragment>
    );
  }
}

export default CommunitiesList;
