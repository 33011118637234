import React, { useState, useCallback, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../alerts/Danger';
import Data from '../../../../../util/Data';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg'; // You'll create this helper function
import RequiredAsterisk from '../../form/required_asterisk';
import Wysiwyg from '../../form/wysiwyg';
import Select from '../../form/select'; // Assuming you have a Select component

const GameTitleForm = ({
    gameTitle,
    campaignData,
    onUpdate,
    setGameTitle,
    onMainImageUpdate,
    onBannerImageUpdate,
    setMainImageBlob,
    setBannerImageBlob,
    externalGameData,
    errors,
    showPressKit,
    showPlayTest,
    requireCommunity = false,
    isCreate,
    communities = [],
    editorMode = 'advanced'
}) => {
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [mainImage, setMainImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [adminTitles, setAdminTitles] = useState([]);

    const [cropMain, setCropMain] = useState({ x: 0, y: 0 });
    const [cropBanner, setCropBanner] = useState({ x: 0, y: 0 });
    const [zoomMain, setZoomMain] = useState(1);
    const [zoomBanner, setZoomBanner] = useState(1);
    const [croppedAreaPixelsMain, setCroppedAreaPixelsMain] = useState(null);
    const [croppedAreaPixelsBanner, setCroppedAreaPixelsBanner] = useState(null);

    const [croppedImageSrc, setCroppedImageSrc] = useState(null);
    const [croppedBannerImageSrc, setBannerCroppedImageSrc] = useState(null);

    const [showMainCropper, setShowMainCropper] = useState(false);
    const [showBannerCropper, setShowBannerCropper] = useState(false);

    const [selectedPastTitleId, setSelectedPastTitleId] = useState('');

    const PROMOTION_TYPE_GAME = 0;
    const PROMOTION_TYPE_EVENT = 1;

    const currencies = [
        { code: 'USD', name: 'US Dollar' },
        { code: 'EUR', name: 'Euro' },
        { code: 'GBP', name: 'British Pound' },
        { code: 'JPY', name: 'Japanese Yen' },
        { code: 'AUD', name: 'Australian Dollar' },
        { code: 'CAD', name: 'Canadian Dollar' },
        { code: 'CHF', name: 'Swiss Franc' },
        { code: 'CNY', name: 'Chinese Yuan' },
        { code: 'SEK', name: 'Swedish Krona' },
        { code: 'NZD', name: 'New Zealand Dollar' },
        { code: 'MXN', name: 'Mexican Peso' },
        { code: 'SGD', name: 'Singapore Dollar' },
        { code: 'HKD', name: 'Hong Kong Dollar' },
        { code: 'NOK', name: 'Norwegian Krone' },
        { code: 'KRW', name: 'South Korean Won' },
        { code: 'TRY', name: 'Turkish Lira' },
        { code: 'RUB', name: 'Russian Ruble' },
        { code: 'INR', name: 'Indian Rupee' },
        { code: 'BRL', name: 'Brazilian Real' },
        { code: 'ZAR', name: 'South African Rand' },
        { code: 'PLN', name: 'Polish Zloty' },
        { code: 'DKK', name: 'Danish Krone' },
        { code: 'TWD', name: 'New Taiwan Dollar' },
        { code: 'THB', name: 'Thai Baht' },
        { code: 'MYR', name: 'Malaysian Ringgit' },
        { code: 'IDR', name: 'Indonesian Rupiah' },
        { code: 'PHP', name: 'Philippine Peso' },
        { code: 'VND', name: 'Vietnamese Dong' },
        { code: 'EGP', name: 'Egyptian Pound' },
        { code: 'AED', name: 'United Arab Emirates Dirham' },
    ];

    useEffect(() => {
        if (externalGameData && externalGameData?.header_image) {
            fetchImage(externalGameData.header_image, setMainImage, setCropMain, setZoomMain, setShowMainCropper);
        }
        if (externalGameData && externalGameData?.capsule_image) {
            fetchImage(externalGameData.capsule_image, setBannerImage, setCropBanner, setZoomBanner, setShowBannerCropper);
        }

        Glitch.api.Titles.list({ is_admin: true }).then(response => {
            setAdminTitles(response.data.data);
        }).catch(error => {
            console.error('Failed to fetch admin titles:', error);
        });
    }, []);

    useEffect(() => {
        if (gameTitle) {
            if (gameTitle.image_main) {
                setCroppedImageSrc(gameTitle.image_main);
            }
            if (gameTitle.image_banner) {
                setBannerCroppedImageSrc(gameTitle.image_banner);
            }
        }
    }, [gameTitle]);

    const handlePastTitleChange = (e) => {
        const titleId = e.target.value;
        setSelectedPastTitleId(titleId);

        if (titleId !== '') {
            // Fetch the title data from the API and populate gameTitle
            Glitch.api.Titles.view(titleId).then(response => {
                setGameTitle(response.data.data);
                // Also set any images as needed
                if (response.data.data.image_main) {
                    setCroppedImageSrc(response.data.data.image_main);
                }
                if (response.data.data.image_banner) {
                    setBannerCroppedImageSrc(response.data.data.image_banner);
                }
            }).catch(error => {
                console.error('Failed to fetch title data:', error);
            });
        } else {
            // Reset the gameTitle to empty
            setGameTitle({});
            setCroppedImageSrc(null);
            setBannerCroppedImageSrc(null);
        }
    };

    const handleWysiwigInputChange = (name, value) => {
        setGameTitle((gameTitle) => ({ ...gameTitle, [name]: value }));
    };

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        // Handle boolean values for checkboxes and radio buttons
        let updatedValue = value;
        if (type === 'checkbox') {
            updatedValue = e.target.checked;
        } else if (name === 'is_virtual' || name === 'is_media_kit' || name === 'enable_playtesting') {
            updatedValue = value === '1' ? true : false;
        }

        setGameTitle({ ...gameTitle, [name]: updatedValue });
    };

    const toggleAdditionalInfo = () => {
        setShowAdditionalInfo(!showAdditionalInfo);
    };

    const onCropCompleteMain = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsMain(croppedAreaPixels);
    }, []);

    const onCropCompleteBanner = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsBanner(croppedAreaPixels);
    }, []);

    const showCroppedImage = async (imageSrc, croppedAreaPixels, setImage, name) => {
        try {
            const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
            const croppedImageUrl = URL.createObjectURL(croppedImageBlob);

            if (gameTitle.id && name === 'mainImage') {
                setCroppedImageSrc(croppedImageUrl);
                await Glitch.api.Titles.uploadMainImageBlob(gameTitle.id, croppedImageBlob);
            } else if (gameTitle.id && name === 'bannerImage') {
                setBannerCroppedImageSrc(croppedImageUrl);
                await Glitch.api.Titles.uploadBannerImageFile(gameTitle.id, croppedImageBlob);
            } else if (!gameTitle.id && name === 'mainImage') {
                setCroppedImageSrc(croppedImageUrl);
                setMainImageBlob(croppedImageBlob);
            } else if (!gameTitle.id && name === 'bannerImage') {
                setBannerCroppedImageSrc(croppedImageUrl);
                setBannerImageBlob(croppedImageBlob);
            }
            setImage(null);
        } catch (e) {
            console.error(e);
        }
    };

    const handleImageChange = (e, imageSetter, cropSetter, zoomSetter, setShowCropper) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                imageSetter(reader.result);
                cropSetter({ x: 0, y: 0 });
                zoomSetter(1);
                setShowCropper(true);
            };
        }
    };

    const fetchImage = async (url, imageSetter, cropSetter, zoomSetter, setShowCropper) => {
        const proxyUrl = `https://api.glitch.fun/api/images/proxy?url=${encodeURIComponent(url)}`;
        try {
            const response = await fetch(proxyUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                imageSetter(reader.result);
                cropSetter({ x: 0, y: 0 });
                zoomSetter(1);
                setShowCropper(true);
            };
        } catch (error) {
            console.error('Failed to fetch image:', error);
        }
    };

    const timezones = [
        { label: 'UTC', value: 'UTC' },
        { label: 'America/New_York', value: 'America/New_York' },
        { label: 'America/Chicago', value: 'America/Chicago' },
        { label: 'America/Denver', value: 'America/Denver' },
        { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
        { label: 'Europe/London', value: 'Europe/London' },
        { label: 'Europe/Paris', value: 'Europe/Paris' },
        { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
        { label: 'Australia/Sydney', value: 'Australia/Sydney' },
        // Add more as needed
    ];

    const questions = [
        { id: 1, text: 'Was the game easy to understand from the start?' },
        { id: 2, text: 'Were there any mechanics that felt confusing or difficult to use?' },
        { id: 3, text: 'How challenging did you find the game overall? Too easy, too hard, or just right?' },
        { id: 4, text: 'Did you encounter any bugs or issues during gameplay?' },
        { id: 5, text: 'Were the controls intuitive and responsive? If not, what improvements would you suggest?' },
        { id: 6, text: 'What aspects of the game did you enjoy the most? Why?' },
        { id: 7, text: 'Were there any parts of the game that felt repetitive or boring?' },
        { id: 8, text: 'How long did you feel engaged with the game before it started to lose your attention?' },
        { id: 9, text: 'Would you want to play this game again? Why or why not?' },
        { id: 10, text: 'How likely would you be to recommend this game to a friend?' },
        { id: 11, text: 'Did you feel invested in the story or the game world?' },
        { id: 12, text: 'Were there any characters, settings, or story elements that stood out to you?' },
        { id: 13, text: 'Was there anything about the world or story that felt unclear or hard to follow?' },
        { id: 14, text: 'How would you rate the game\'s visuals? Did anything stand out as particularly impressive or lacking?' },
        { id: 15, text: 'How did the audio (music, sound effects, voice acting, etc.) contribute to the experience?' },
        { id: 16, text: 'Were there any audio or visual elements that distracted you from the gameplay?' },
        { id: 17, text: 'Did you experience any difficulties related to visibility, accessibility, or ease of use?' },
        { id: 18, text: 'Were there any features or options you wish the game had to make it easier to play?' },
        { id: 19, text: 'How would you describe the game in a few sentences?' },
        { id: 20, text: 'What is one thing you would change or add to improve the game?' },
        { id: 21, text: 'What was your overall impression of the game?' },
    ];

    return (
        <div className="container mt-4">
            {isCreate && (
                <div className="mb-4">
                    <label htmlFor="pastTitleSelect">
                        <i className="fas fa-history mr-2"></i> &nbsp; Use a Past Title (Optional)
                    </label>
                    <Select
                        id="pastTitleSelect"
                        name="pastTitleSelect"
                        className="form-select"
                        onChange={handlePastTitleChange}
                        value={selectedPastTitleId}
                    >
                        <option value="">Create a New Title</option>
                        {adminTitles.map((title) => (
                            <option key={title.id} value={title.id}>
                                {title.name}
                            </option>
                        ))}
                    </Select>
                    <p className="small">
                        If you had created a game title in the past, you may select it and use it as the current title. Or create a new game title.
                    </p>
                </div>
            )}

            {(!campaignData?.promotion_type || campaignData?.promotion_type == PROMOTION_TYPE_GAME) && (
                <form>
                    {/* Existing game form */}
                    <div className="card mb-3">
                        <div className="card-header bg-primary text-white">
                            <h3>
                                <i className="fas fa-gamepad mr-2"></i> Game Title
                            </h3>
                        </div>
                        <div className="card-body">
                            <p className="lead">
                                Enter information about the game title. Please fill out
                                as much information as possible to excite the potential creator(s) you might be working
                                with.
                            </p>
                            <hr />
                            <div className="row">
                                {createInput(
                                    'Name',
                                    'name',
                                    gameTitle?.name,
                                    handleChange,
                                    'text',
                                    'fas fa-signature',
                                    errors,
                                    'Enter the name of the game.',
                                    true
                                )}
                            </div>
                            <div className="row">
                                {editorMode === 'advanced' && createInput(
                                    'Platform Compatibility (Optional)',
                                    'platform_compatibility',
                                    gameTitle?.platform_compatibility,
                                    handleChange,
                                    'text',
                                    'fab fa-steam-symbol',
                                    errors,
                                    'Enter what platforms this game is for, i.e., PlayStation 5, PC, Xbox, etc.'
                                )}
                                {editorMode === 'advanced' && createInput(
                                    'Age Rating (Optional)',
                                    'age_rating',
                                    gameTitle?.age_rating,
                                    handleChange,
                                    'text',
                                    'fas fa-child',
                                    errors,
                                    'Enter domestic and/or global age ratings for the game.'
                                )}
                            </div>
                            <div className="row">
                                {createInput(
                                    'Developer (Optional)',
                                    'developer',
                                    gameTitle?.developer,
                                    handleChange,
                                    'text',
                                    'fas fa-code-branch',
                                    errors,
                                    'Enter the development studios for the game.'
                                )}
                                {createInput(
                                    'Publisher (Optional)',
                                    'publisher',
                                    gameTitle?.publisher,
                                    handleChange,
                                    'text',
                                    'fas fa-briefcase',
                                    errors,
                                    'Enter the publishers.'
                                )}
                            </div>
                            <div className="row">
                                {editorMode === 'advanced' && createInput(
                                    'Release Date (Optional)',
                                    'release_date',
                                    gameTitle?.release_date,
                                    handleChange,
                                    'date',
                                    'fas fa-calendar-alt',
                                    errors,
                                    'Enter the date the game will be released.'
                                )}
                                {editorMode === 'advanced' && createInput(
                                    'Availability (Optional)',
                                    'availability',
                                    gameTitle?.availability,
                                    handleChange,
                                    'text',
                                    'fas fa-store',
                                    errors,
                                    'Set the availability of the game.'
                                )}
                            </div>
                            <div className="row">
                                {createInput(
                                    'Pricing (Optional)',
                                    'pricing',
                                    gameTitle?.pricing,
                                    handleChange,
                                    'number',
                                    'fas fa-tag',
                                    errors,
                                    'Enter the price for the game.'
                                )}
                                <div className="col">
                                    <div className="form-group mb-3">
                                        <label htmlFor={'pricing_currency'}>
                                            <i className={`fas fa-dollar-sign mr-2`}></i> &nbsp;'Pricing Currency (Optional)
                                        </label>
                                        <p className="small">Set the pricing currency for the country of the game.</p>
                                        <select className="form-select" name='pricing_currency' id="pricing_currency" value={gameTitle?.pricing_currency || 'USD'} onChange={handleChange}>
                                            {currencies.map((curr) => (
                                                <option key={curr.code} value={curr.code}>{curr.name} ({curr.code})</option>
                                            ))}
                                        </select>
                                        {errors && errors['pricing_currency'] && errors['pricing_currency'].map((message, index) => (
                                            <Danger message={message} key={index} />
                                        ))}
                                    </div>
                                </div>


                            </div>
                            <div className="row">
                                {createInput(
                                    'Multiplayer Options (Optional)',
                                    'multiplayer_options',
                                    gameTitle?.multiplayer_options,
                                    handleChange,
                                    'text',
                                    'fas fa-users',
                                    errors,
                                    'Enter any multiplayer options of the game.'
                                )}
                                {createInput(
                                    'Google Play Store URL (Optional)',
                                    'android_url',
                                    gameTitle?.android_url,
                                    handleChange,
                                    'url',
                                    'fab fa-android',
                                    errors,
                                    'Enter the URL for the Google Play Store download page of the game.'
                                )}
                            </div>
                            <div className="row">
                                {createInput(
                                    'Website URL (Optional)',
                                    'website_url',
                                    gameTitle?.website_url,
                                    handleChange,
                                    'url',
                                    'fas fa-globe',
                                    errors,
                                    'Enter the URL for the website of the game.'
                                )}
                                {createInput(
                                    'Steam URL (Optional)',
                                    'steam_url',
                                    gameTitle?.steam_url,
                                    handleChange,
                                    'url',
                                    'fab fa-steam',
                                    errors,
                                    'Enter the URL for the Steam download page of the game.'
                                )}
                            </div>
                            <div className="row">
                                {createInput(
                                    'Itch.io URL (Optional)',
                                    'itch_url',
                                    gameTitle?.itch_url,
                                    handleChange,
                                    'url',
                                    'fas fa-link',
                                    errors,
                                    'Enter the URL for the Itch store of the game.'
                                )}
                                {createInput(
                                    'Apple Store URL (Optional)',
                                    'apple_url',
                                    gameTitle?.apple_url,
                                    handleChange,
                                    'url',
                                    'fab fa-apple',
                                    errors,
                                    'Enter the URL for the Apple Store download page of the game.'
                                )}
                            </div>

                            {createTextarea(
                                'Short Description',
                                'short_description',
                                gameTitle?.short_description,
                                handleChange,
                                errors,
                                'Enter a 1-paragraph brief short description about the game.',
                                true
                            )}
                            {editorMode === 'advanced' && createTextarea(
                                'Long Description (Optional)',
                                'long_description',
                                gameTitle?.long_description,
                                handleChange,
                                errors,
                                'Enter a longer, more in-depth description about the game that can be multiple paragraphs.'
                            )}
                        </div>

                        <div className="card-header bg-secondary text-white">
                            Upload Game Images
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="mainImage">Main Image</label>
                                <p className="small">Recommended dimensions: 374x448 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="mainImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setMainImage,
                                            setCropMain,
                                            setZoomMain,
                                            setShowMainCropper
                                        )
                                    }
                                />
                                {croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_main && !croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Main Image:</p>
                                        <img src={gameTitle.image_main} alt="Current Main Image" className="img-fluid w-50" />
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="bannerImage">Banner Image</label>
                                <p className="small">Recommended dimensions: 3840x1240 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="bannerImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setBannerImage,
                                            setCropBanner,
                                            setZoomBanner,
                                            setShowBannerCropper
                                        )
                                    }
                                />
                                {croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedBannerImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_banner && !croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Banner Image:</p>
                                        <img
                                            src={gameTitle.image_banner}
                                            alt="Current Banner Image"
                                            className="img-fluid w-50"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="card-header bg-secondary text-white">Product Videos</div>
                        <div className="card-body">
                            {createInput(
                                'YouTube/Vimeo Video URL',
                                'video_url',
                                gameTitle?.video_url,
                                handleChange,
                                'url',
                                'fas fa-file-video',
                                errors,
                                'Enter a YouTube or Vimeo video URL to use that as a promo video.'
                            )}
                        </div>

                        {showPressKit && (
                            <>
                                <div className="card-header bg-secondary text-white">
                                    <i className="fas fa-newspaper mr-2"></i> Media Kit Information
                                </div>
                                <div className="card-body">
                                    {createInput(
                                        'Press Contact Name',
                                        'press_contact_name',
                                        gameTitle?.press_contact_name,
                                        handleChange,
                                        'text',
                                        'fas fa-user',
                                        errors,
                                        'Enter the name of the person to contact about press inquiries.'
                                    )}
                                    {createInput(
                                        'Press Contact Email',
                                        'press_contact_email',
                                        gameTitle?.press_contact_email,
                                        handleChange,
                                        'email',
                                        'fas fa-envelope',
                                        errors,
                                        'Enter the email of the person to contact about press inquiries.'
                                    )}
                                    {createInput(
                                        'Tagline',
                                        'tagline',
                                        gameTitle?.tagline,
                                        handleChange,
                                        'text',
                                        'fas fa-tag',
                                        errors,
                                        'Enter a short tagline about the game.'
                                    )}
                                    {createInput(
                                        'Demo URL',
                                        'demo_url',
                                        gameTitle?.demo_url,
                                        handleChange,
                                        'url',
                                        'fas fa-link',
                                        errors,
                                        'Enter the URL for the game demo.'
                                    )}
                                    {createTextarea(
                                        'Key Features',
                                        'key_features',
                                        gameTitle?.key_features,
                                        handleChange,
                                        errors,
                                        'Enter the key features to be highlighted.'
                                    )}
                                    <div className="form-group mb-3">
                                        <label>
                                            <i className="fas fa-info-circle mr-2"></i> &nbsp;Is this for Media Kit?{' '}
                                        </label>
                                        <div>
                                            <label className="mr-2">
                                                <input
                                                    type="radio"
                                                    name="is_media_kit"
                                                    value="1"
                                                    checked={gameTitle.is_media_kit === true || gameTitle.is_media_kit === '1'}
                                                    onChange={handleChange}
                                                />{' '}
                                                Yes
                                            </label>
                                            <label className="mr-2">
                                                <input
                                                    type="radio"
                                                    name="is_media_kit"
                                                    value="0"
                                                    checked={gameTitle.is_media_kit === false || gameTitle.is_media_kit === '0'}
                                                    onChange={handleChange}
                                                />{' '}
                                                No
                                            </label>
                                        </div>
                                        {errors && errors['is_media_kit'] && errors['is_media_kit'].map((message, index) => (
                                            <Danger message={message} key={index} />
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}




                        {requireCommunity && (
                            <>
                                <div className="card-header bg-secondary text-white">
                                    <i className="fas fa-newspaper mr-2"></i> Business Account/Community
                                </div>
                                <div className="card-body">

                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor={'community_id'}>
                                                <i className={`fas fa-user-friends mr-2`}></i> &nbsp;Required Business Account/Commuity <RequiredAsterisk />
                                            </label>
                                            <p className="small">Set the business account/community that you want to associate the game with.</p>
                                            <select className="form-select" name='community_id' id="community_id" value={gameTitle?.community_id} onChange={handleChange}>
                                                <option value={""}>Select An Account</option>
                                                {communities.map((community) => (
                                                    <option key={community.id} value={community.id}>{community.name}</option>
                                                ))}
                                            </select>
                                            {errors && errors['community_id'] && errors['community_id'].map((message, index) => (
                                                <Danger message={message} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {showPlayTest && (
                            <>
                                <div className="card-header bg-secondary text-white">
                                    <i className="fas fa-newspaper mr-2"></i> Play Test Information
                                </div>
                                <div className="card-body">

                                    <div className="form-group mb-3">
                                        <label>
                                            <i className="fas fa-info-circle mr-2"></i> &nbsp;Is Play Testing Enabled?{' '}
                                        </label>
                                        <div>
                                            <label className="mr-2">
                                                <input
                                                    type="radio"
                                                    name="enable_playtesting"
                                                    value="1"
                                                    checked={gameTitle.enable_playtesting === true || gameTitle.enable_playtesting === '1'}
                                                    onChange={handleChange}
                                                />{' '}
                                                Yes
                                            </label>
                                            <label className="mr-2">
                                                <input
                                                    type="radio"
                                                    name="enable_playtesting"
                                                    value="0"
                                                    checked={gameTitle.enable_playtesting === false || gameTitle.enable_playtesting === '0'}
                                                    onChange={handleChange}
                                                />{' '}
                                                No
                                            </label>
                                        </div>
                                        {errors && errors['enable_playtesting'] && errors['enable_playtesting'].map((message, index) => (
                                            <Danger message={message} key={index} />
                                        ))}
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor={'community_id'}>
                                                <i className={`fas fa-user-friends mr-2`}></i> &nbsp;Associated Business Account Play Test
                                            </label>
                                            <p className="small">Set the business account that you want to associate the game with.</p>
                                            <select className="form-select" name='community_id' id="community_id" value={gameTitle?.community_id} onChange={handleChange}>
                                                <option value={""}>Select An Account</option>
                                                {communities.map((community) => (
                                                    <option key={community.id} value={community.id}>{community.name}</option>
                                                ))}
                                            </select>
                                            {errors && errors['community_id'] && errors['community_id'].map((message, index) => (
                                                <Danger message={message} key={index} />
                                            ))}
                                        </div>
                                    </div>

                                    {createTextarea(
                                        'Testing Instructions (optional)',
                                        'test_instructions',
                                        gameTitle?.test_instructions,
                                        handleChange,
                                        errors,
                                        'Enter any instructions that you want the tester to follow.',
                                        false
                                    )}

                                    <h5 className="mt-4">Select Questions to Include</h5>
                                    <p className="small">Enable or disable questions you want the play testers to answer. Please DO NOT select all the questions. Typically around 5 questions is a good amount to ask for on a Play Test.</p>
                                    <div className="row">
                                        {questions.map((question, index) => (
                                            <div className="col-md-6 mb-3" key={question.id}>
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`q_${question.id}_enabled`}
                                                        name={`q_${question.id}_enabled`}
                                                        checked={gameTitle[`q_${question.id}_enabled`]}
                                                        onChange={(e) => {
                                                            const { checked } = e.target;
                                                            setGameTitle({
                                                                ...gameTitle,
                                                                [`q_${question.id}_enabled`]: checked,
                                                            });
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor={`q_${question.id}_enabled`}>
                                                        {question.text}
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}

                        <div className="card-header bg-secondary text-white d-flex justify-content-between align-items-center">
                            <span>
                                <i className="fas fa-book-open mr-2"></i>Additional Game Information
                            </span>
                            <button
                                type="button"
                                className="btn btn-light btn-sm"
                                onClick={toggleAdditionalInfo}
                            >
                                {showAdditionalInfo ? 'Hide' : 'Show'}
                            </button>
                        </div>
                        {showAdditionalInfo && (
                            <div className="card-body">
                                {createTextarea(
                                    'Gameplay Mechanics',
                                    'gameplay_mechanics',
                                    gameTitle?.gameplay_mechanics,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'Narrative Setting',
                                    'narrative_setting',
                                    gameTitle?.narrative_setting,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'Visual & Audio Style',
                                    'visual_audio_style',
                                    gameTitle?.visual_audio_style,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'DLC & Expansion Info',
                                    'dlc_expansion_info',
                                    gameTitle?.dlc_expansion_info,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'System Requirements',
                                    'system_requirements',
                                    gameTitle?.system_requirements,
                                    handleChange,
                                    errors
                                )}
                                {createTextarea(
                                    'Critical Reception',
                                    'critical_reception',
                                    gameTitle?.critical_reception,
                                    handleChange,
                                    errors
                                )}
                            </div>
                        )}


                    </div>
                </form>
            )}

            {campaignData?.promotion_type == PROMOTION_TYPE_EVENT && (
                <form>
                    {/* Event form */}
                    <div className="card mb-3">
                        <div className="card-header bg-primary text-white">
                            <h3>
                                <i className="fas fa-calendar-alt mr-2"></i> Event Details
                            </h3>
                        </div>
                        <div className="card-body">
                            <p className="lead">
                                Enter information about the event you want influencers to promote. Please fill out as
                                much information as possible to excite the potential creator(s) you might be working
                                with.
                            </p>
                            <hr />
                            <div className="row">
                                {createInput(
                                    'Event Name',
                                    'name',
                                    gameTitle?.name,
                                    handleChange,
                                    'text',
                                    'fas fa-signature',
                                    errors,
                                    'Enter the name of the event.',
                                    true
                                )}
                                {createTextarea(
                                    'Short Description',
                                    'short_description',
                                    gameTitle?.short_description,
                                    handleChange,
                                    errors,
                                    'Enter a brief short description about the event.',
                                    true
                                )}
                            </div>
                            <div className="row">
                                {createTextarea(
                                    'Long Description (Optional)',
                                    'long_description',
                                    gameTitle?.long_description,
                                    handleChange,
                                    errors,
                                    'Enter a longer, more in-depth description about the event.'
                                )}
                            </div>
                            <div className="form-group mb-3">
                                <label>
                                    <i className="fas fa-laptop mr-2"></i> &nbsp;Is the event virtual?{' '}
                                    <RequiredAsterisk />
                                </label>
                                <div>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_virtual"
                                            value="1"
                                            checked={
                                                gameTitle.is_virtual === true || gameTitle.is_virtual === '1'
                                            }
                                            onChange={handleChange}
                                        />{' '}
                                        Yes
                                    </label>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_virtual"
                                            value="0"
                                            checked={
                                                gameTitle.is_virtual === false || gameTitle.is_virtual === '0'
                                            }
                                            onChange={handleChange}
                                        />{' '}
                                        No
                                    </label>
                                </div>
                                {errors && errors['is_virtual'] && errors['is_virtual'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>

                            {(gameTitle.is_virtual === false || gameTitle.is_virtual === '0') && (
                                <>
                                    <div className="row">
                                        {createInput(
                                            'Address Line 1',
                                            'address_line_1',
                                            gameTitle?.address_line_1,
                                            handleChange,
                                            'text',
                                            'fas fa-address-card',
                                            errors,
                                            "Enter the first line of the event's physical address.",
                                            true
                                        )}
                                        {createInput(
                                            'Address Line 2',
                                            'address_line_2',
                                            gameTitle?.address_line_2,
                                            handleChange,
                                            'text',
                                            'fas fa-address-card',
                                            errors,
                                            "Enter the second line of the event's physical address."
                                        )}
                                    </div>
                                    <div className="row">
                                        {createInput(
                                            'City',
                                            'locality',
                                            gameTitle?.locality,
                                            handleChange,
                                            'text',
                                            'fas fa-city',
                                            errors,
                                            'Enter the city where the event is held.',
                                            true
                                        )}
                                        {createInput(
                                            'State/Province',
                                            'province',
                                            gameTitle?.province,
                                            handleChange,
                                            'text',
                                            'fas fa-map',
                                            errors,
                                            'Enter the state or province of the event location.',
                                            true
                                        )}
                                    </div>
                                    <div className="row">
                                        {createInput(
                                            'Postal Code',
                                            'postal_code',
                                            gameTitle?.postal_code,
                                            handleChange,
                                            'text',
                                            'fas fa-envelope',
                                            errors,
                                            'Enter the postal code of the event location.',
                                            true
                                        )}
                                        {createInput(
                                            'Country',
                                            'country',
                                            gameTitle?.country,
                                            handleChange,
                                            'text',
                                            'fas fa-flag',
                                            errors,
                                            'Enter the country where the event is held.',
                                            true
                                        )}
                                    </div>
                                </>
                            )}

                            <div className="row">
                                {createInput(
                                    'Start Date and Time',
                                    'start_date',
                                    gameTitle?.start_date,
                                    handleChange,
                                    'datetime-local',
                                    'fas fa-calendar-alt',
                                    errors,
                                    'Enter the start date and time of the event.',
                                    true
                                )}
                                {createInput(
                                    'End Date and Time',
                                    'end_date',
                                    gameTitle?.end_date,
                                    handleChange,
                                    'datetime-local',
                                    'fas fa-calendar-alt',
                                    errors,
                                    'Enter the end date and time of the event.',
                                    true
                                )}
                            </div>

                            <div className="form-group mb-3">
                                <label htmlFor="timezone">
                                    <i className="fas fa-clock mr-2"></i> &nbsp;Timezone <RequiredAsterisk />
                                </label>
                                <Select
                                    name="timezone"
                                    className="form-select"
                                    onChange={handleChange}
                                    value={gameTitle.timezone || ''}
                                >
                                    <option value="">Select a Timezone</option>
                                    {timezones.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                                <p className="small">Select the timezone for the event.</p>
                                {errors && errors['timezone'] && errors['timezone'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>

                        <div className="card-header bg-secondary text-white">Upload Event Images</div>
                        <div className="card-body">
                            {/* Image upload sections similar to game form */}
                            <div className="form-group">
                                <label htmlFor="mainImage">Main Image</label>
                                <p className="small">Recommended dimensions: 374x448 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="mainImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setMainImage,
                                            setCropMain,
                                            setZoomMain,
                                            setShowMainCropper
                                        )
                                    }
                                />
                                {croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_main && !croppedImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Main Image:</p>
                                        <img src={gameTitle.image_main} alt="Current Main Image" className="img-fluid w-50" />
                                    </div>
                                )}
                            </div>
                            <div className="form-group">
                                <label htmlFor="bannerImage">Banner Image</label>
                                <p className="small">Recommended dimensions: 3840x1240 pixels.</p>
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="form-control-file"
                                    id="bannerImage"
                                    onChange={(e) =>
                                        handleImageChange(
                                            e,
                                            setBannerImage,
                                            setCropBanner,
                                            setZoomBanner,
                                            setShowBannerCropper
                                        )
                                    }
                                />
                                {croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Cropped Image:</p>
                                        <img src={croppedBannerImageSrc} alt="Cropped" className="img-fluid w-50" />
                                    </div>
                                )}
                                {gameTitle.image_banner && !croppedBannerImageSrc && (
                                    <div className="mt-3">
                                        <p>Current Banner Image:</p>
                                        <img
                                            src={gameTitle.image_banner}
                                            alt="Current Banner Image"
                                            className="img-fluid w-50"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="card-header bg-secondary text-white">Event Video</div>
                        <div className="card-body">
                            {createInput(
                                'YouTube/Vimeo Video URL',
                                'video_url',
                                gameTitle?.video_url,
                                handleChange,
                                'url',
                                'fas fa-file-video',
                                errors,
                                'Enter a YouTube or Vimeo video URL to use that as a promo video.'
                            )}
                        </div>
                    </div>
                </form>
            )}

            {/* Main Image Cropper Modal */}
            {showMainCropper && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Crop Main Image</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => setShowMainCropper(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div
                                    className="crop-container"
                                    style={{ position: 'relative', width: '100%', height: '400px' }}
                                >
                                    <Cropper
                                        image={mainImage}
                                        crop={cropMain}
                                        zoom={zoomMain}
                                        aspect={374 / 448}
                                        onCropChange={setCropMain}
                                        onZoomChange={setZoomMain}
                                        onCropComplete={onCropCompleteMain}
                                    />
                                </div>
                                <div className="slider-container mt-3">
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoomMain}
                                        onChange={(e) => setZoomMain(e.target.value)}
                                        className="form-range"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowMainCropper(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        showCroppedImage(mainImage, croppedAreaPixelsMain, setMainImage, 'mainImage');
                                        setShowMainCropper(false);
                                    }}
                                >
                                    Crop Image
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Banner Image Cropper Modal */}
            {showBannerCropper && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Crop Banner Image</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => setShowBannerCropper(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div
                                    className="crop-container"
                                    style={{ position: 'relative', width: '100%', height: '400px' }}
                                >
                                    <Cropper
                                        image={bannerImage}
                                        crop={cropBanner}
                                        zoom={zoomBanner}
                                        aspect={3840 / 1240}
                                        onCropChange={setCropBanner}
                                        onZoomChange={setZoomBanner}
                                        onCropComplete={onCropCompleteBanner}
                                    />
                                </div>
                                <div className="slider-container mt-3">
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoomBanner}
                                        onChange={(e) => setZoomBanner(e.target.value)}
                                        className="form-range"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setShowBannerCropper(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        showCroppedImage(
                                            bannerImage,
                                            croppedAreaPixelsBanner,
                                            setBannerImage,
                                            'bannerImage'
                                        );
                                        setShowBannerCropper(false);
                                    }}
                                >
                                    Crop Image
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    function createInput(
        label,
        name,
        value,
        handleChange,
        type = 'text',
        icon,
        errors,
        description = '',
        required = false
    ) {
        return (
            <div className="col">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        <i className={`${icon} mr-2`}></i> &nbsp;{label} {required ? <RequiredAsterisk /> : ''}
                    </label>
                    <p className="small">{description}</p>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }

    function createTextarea(label, name, value, handleChange, errors, description, required = false) {
        return (
            <div className="col-md-12">
                <div className="mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    <Wysiwyg
                        children={value || ''}
                        name={name}
                        id={name}
                        onChange={(value) => {
                            handleWysiwigInputChange(name, value);
                        }}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }
};

export default GameTitleForm;
