import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactMediaRecorder } from 'react-media-recorder';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Glitch from 'glitch-javascript-sdk';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { Button, Alert } from 'react-bootstrap';

const PlayTestSubmitPlayTestAnswersPage = () => {
    const [playTest, setPlayTest] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [responses, setResponses] = useState({});
    const [me, setMe] = useState({});
    const [youtubeInitialized, setYoutubeInitialized] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [gapiReady, setGapiReady] = useState(false);
    const { id, playtest_id } = useParams();
    const navigate = useNavigate();

    const questionsList = [
        { id: 1, text: 'Was the game easy to understand from the start?' },
        { id: 2, text: 'Were there any mechanics that felt confusing or difficult to use?' },
        { id: 3, text: 'How challenging did you find the game overall: too easy, too hard, or just right?' },
        { id: 4, text: 'Did you encounter any bugs or issues during gameplay?' },
        { id: 5, text: 'Were the controls intuitive and responsive? If not, what improvements would you suggest?' },
        { id: 6, text: 'What aspects of the game did you enjoy the most? Why?' },
        { id: 7, text: 'Were there any parts of the game that felt repetitive or boring?' },
        { id: 8, text: 'How long did you feel engaged with the game before it started to lose your attention?' },
        { id: 9, text: 'Would you want to play this game again? Why or why not?' },
        { id: 10, text: 'How likely would you be to recommend this game to a friend?' },
        { id: 11, text: 'Did you feel invested in the story or the game world?' },
        { id: 12, text: 'Were there any characters, settings, or story elements that stood out to you?' },
        { id: 13, text: 'Was there anything about the world or story that felt unclear or hard to follow?' },
        { id: 14, text: 'How would you rate the game’s visuals? Did anything stand out as particularly impressive or lacking?' },
        { id: 15, text: 'How did the audio (music, sound effects, voice acting, etc.) contribute to the experience?' },
        { id: 16, text: 'Were there any audio or visual elements that distracted you from the gameplay?' },
        { id: 17, text: 'Did you experience any difficulties related to visibility, accessibility, or ease of use?' },
        { id: 18, text: 'Were there any features or options you wish the game had to make it easier to play?' },
        { id: 19, text: 'How would you describe the game in a few sentences?' },
        { id: 20, text: 'What is one thing you would change or add to improve the game?' },
        { id: 21, text: 'What was your overall impression of the game?' },
    ];

    const clientId = '92324207183-1nsbk9c6sahd6ubj7i37t09kdfu2014c.apps.googleusercontent.com';
    const apiKey = 'AIzaSyCpl_gTZYHr04aRENpMJqNG41N8jfsx5XI';
    const scopes = 'https://www.googleapis.com/auth/youtube.upload';

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const loadGapiClient = () => {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/api.js';
            script.onload = () => {
                setGapiReady(true);
                if (accessToken) {
                    initializeYoutube();
                }
            };
            script.onerror = () => {
                console.error('GAPI client failed to load.');
            };
            document.body.appendChild(script);
        };

        if (!window.gapi) {
            loadGapiClient();
        } else {
            setGapiReady(true);
        }

        const fetchUserData = async () => {
            try {
                const response = await Glitch.api.Users.me();
                setMe(response.data.data);

                if (response.data.data.google_auth_token) {
                    setAccessToken(response.data.data.google_auth_token);
                    initializeYoutube();
                } else {
                    if (
                        window.confirm(
                            'You need to authenticate with Google to upload media responses. Do you want to authenticate now?'
                        )
                    ) {
                        navigate(Navigate.authGoogle());
                    }
                }
            } catch (error) {
                console.error('Error fetching user data', error);
            }
        };

        const fetchPlayTest = async () => {
            try {
                const response = await Glitch.api.PlayTests.show(id, playtest_id);
                setPlayTest(response.data.data);

                const initialResponses = {};
                questionsList.forEach((q) => {
                    if (response.data.data[`q_${q.id}_enabled`]) {
                        const mediaResponseUrl = response.data.data[`q_${q.id}_media_response_url`] || '';
                        initialResponses[`q_${q.id}`] = {
                            response_type: response.data.data[`q_${q.id}_response_type`] || 'text',
                            text_response: response.data.data[`q_${q.id}_text_response`] || '',
                            media_response_url: mediaResponseUrl,
                            media_mime_type: response.data.data[`q_${q.id}_media_mime_type`] || '',
                            uploadProgress: null,
                            isUploaded: !!mediaResponseUrl,
                        };
                    }
                });
                setResponses(initialResponses);
            } catch (error) {
                console.error('Error fetching play test', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserData();
        fetchPlayTest();
    }, [id, playtest_id, navigate]);

    useEffect(() => {
        if (gapiReady && accessToken) {
            initializeYoutube();
        }
    }, [gapiReady, accessToken]);

    const initializeYoutube = () => {
        window.gapi.load('client', async () => {
            try {
                await window.gapi.client.init({
                    apiKey: apiKey,
                    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'],
                });
                window.gapi.client.setToken({ access_token: accessToken });
                setYoutubeInitialized(true);
            } catch (error) {
                console.error('Error initializing GAPI client:', error);
            }
        });
    };

    const handleResponseTypeChange = (questionId, responseType) => {
        if ((responseType === 'audio' || responseType === 'video') && !accessToken) {
            if (
                window.confirm(
                    'You need to authenticate with Google to upload audio or video responses. Do you want to authenticate now?'
                )
            ) {
                navigate(Navigate.authGoogle());
            }
            return;
        }

        setResponses((prevResponses) => ({
            ...prevResponses,
            [`q_${questionId}`]: {
                ...prevResponses[`q_${questionId}`],
                response_type: responseType,
            },
        }));
    };

    const handleTextResponseChange = (questionId, text) => {
        setResponses((prevResponses) => ({
            ...prevResponses,
            [`q_${questionId}`]: {
                ...prevResponses[`q_${questionId}`],
                text_response: text,
            },
        }));
    };

    const handleMediaUpload = async (questionId, blob, mimeType, clearBlobUrl) => {
        if (!youtubeInitialized) {
            alert('YouTube client is not initialized.');
            return;
        }

        try {
            const metadata = {
                snippet: {
                    title: `${playTest.title.name} - Question ${questionId}`,
                    description: `Answer to question ${questionId} for play test of ${playTest.title.name}`,
                    tags: ['PlayTest', playTest.title.name],
                    categoryId: '20',
                },
                status: {
                    privacyStatus: 'unlisted',
                },
            };

            const uploader = new MediaUploader({
                baseUrl: 'https://www.googleapis.com/upload/youtube/v3/videos',
                file: blob,
                token: accessToken,
                metadata: metadata,
                params: {
                    part: 'snippet,status',
                },
                onError: function (data) {
                    var message = data;
                    try {
                        var errorResponse = JSON.parse(data);
                        message = errorResponse.error.message;
                    } finally {
                        console.error('Upload error: ' + message);
                        alert('Failed to upload to YouTube.');
                    }
                },
                onProgress: function (progressEvent) {
                    const bytesUploaded = progressEvent.loaded;
                    const totalBytes = progressEvent.total;
                    const progressPercentage = Math.round((bytesUploaded / totalBytes) * 100);

                    setResponses((prevResponses) => ({
                        ...prevResponses,
                        [`q_${questionId}`]: {
                            ...prevResponses[`q_${questionId}`],
                            uploadProgress: progressPercentage,
                        },
                    }));
                },
                onComplete: function (data) {
                    var uploadResponse = JSON.parse(data);
                    const videoId = uploadResponse.id;
                    const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;

                    setResponses((prevResponses) => ({
                        ...prevResponses,
                        [`q_${questionId}`]: {
                            ...prevResponses[`q_${questionId}`],
                            media_response_url: videoUrl,
                            media_mime_type: mimeType,
                            uploadProgress: 100,
                            isUploaded: true,
                        },
                    }));

                    // Clear the mediaBlobUrl to remove the playback of the recorded video
                    clearBlobUrl();
                },
            });
            uploader.upload();
        } catch (error) {
            console.error('Error uploading media', error);
            alert('Failed to upload media.');
        }
    };

    const handleSubmit = async () => {
        try {
            const data = {};
            Object.entries(responses).forEach(([key, value]) => {
                const questionId = key.split('_')[1];
                data[`q_${questionId}_response_type`] = value.response_type;

                if (value.response_type === 'text') {
                    data[`q_${questionId}_text_response`] = value.text_response;
                } else {
                    data[`q_${questionId}_media_response_url`] = value.media_response_url;
                    data[`q_${questionId}_media_mime_type`] = value.media_mime_type;
                }
            });

            await Glitch.api.PlayTests.submitAnswers(playTest.title_id, playTest.id, data);
            alert('Your responses have been submitted successfully.');
            navigate(Navigate.playtestingUserTests());
        } catch (error) {
            console.error('Error submitting answers', error);
            alert('Failed to submit your responses.');
        }
    };

    return (
        <>
            <InfluencerHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingUserTests() },
                        {
                            name: playTest?.title?.name,
                            link: Navigate.playtestingUserTest(playTest?.title_id, playTest?.id),
                        },
                        { name: 'Submit Answers', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Submit Your Feedback</h2>
                        <p className="lead">Answer the following questions for the play test.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                <p className="lead">
                    Below are questions from the developer seeking your feedback. You don’t have to answer every
                    question, and you can choose to respond in text, audio, or video format.
                </p>
                <hr />

                {isLoading ? (
                    <Loading />
                ) : playTest ? (
                    <form>
                        {questionsList.map((question) => {
                            if (!playTest[`q_${question.id}_enabled`]) {
                                return null;
                            }

                            const response = responses[`q_${question.id}`];

                            return (
                                <div key={question.id} className="mb-5">
                                    <h5>{question.text}</h5>
                                    <div className="mb-3">
                                        <label className="form-label">Response Type:</label>
                                        <select
                                            className="form-select"
                                            value={response.response_type}
                                            onChange={(e) =>
                                                handleResponseTypeChange(question.id, e.target.value)
                                            }
                                        >
                                            <option value="text">Text</option>
                                            <option value="audio">Audio</option>
                                            <option value="video">Video</option>
                                        </select>
                                    </div>
                                    {response.response_type === 'text' && (
                                        <div className="mb-3">
                                            <textarea
                                                className="form-control"
                                                rows="5"
                                                value={response.text_response}
                                                onChange={(e) =>
                                                    handleTextResponseChange(question.id, e.target.value)
                                                }
                                            ></textarea>
                                        </div>
                                    )}
                                    {(response.response_type === 'audio' ||
                                        response.response_type === 'video') && (
                                        <>
                                            {response.media_response_url && response.isUploaded ? (
                                                <div className="mt-3">
                                                    <p>Your uploaded response:</p>
                                                    <div className="embed-responsive embed-responsive-16by9">
                                                        <iframe
                                                            className="embed-responsive-item"
                                                            src={`https://www.youtube.com/embed/${
                                                                response.media_response_url.split('v=')[1]
                                                            }`}
                                                            allowFullScreen
                                                            title={`Question ${question.id} Response`}
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            ) : (
                                                <ReactMediaRecorder
                                                    audio={true}
                                                    video={response.response_type === 'video'}
                                                    render={({
                                                        status,
                                                        startRecording,
                                                        stopRecording,
                                                        mediaBlobUrl,
                                                        clearBlobUrl,
                                                        previewStream,
                                                    }) => (
                                                        <div>
                                                            <p>Status: {status}</p>
                                                            {response.response_type === 'video' &&
                                                                status === 'recording' &&
                                                                previewStream && (
                                                                    <div className="mb-3">
                                                                        <video
                                                                            style={{
                                                                                width: '100%',
                                                                                maxWidth: '500px',
                                                                            }}
                                                                            ref={(video) => {
                                                                                if (video && previewStream) {
                                                                                    video.srcObject = previewStream;
                                                                                }
                                                                            }}
                                                                            autoPlay
                                                                            muted
                                                                        />
                                                                    </div>
                                                                )}
                                                            {response.response_type === 'audio' &&
                                                                status === 'recording' && (
                                                                    <div className="mb-3">
                                                                        <p>Recording audio...</p>
                                                                    </div>
                                                                )}
                                                            <Button
                                                                variant={
                                                                    status === 'recording' ? 'danger' : 'primary'
                                                                }
                                                                className="me-2"
                                                                onClick={
                                                                    status === 'recording'
                                                                        ? stopRecording
                                                                        : startRecording
                                                                }
                                                            >
                                                                {status === 'recording' ? (
                                                                    <>
                                                                        <i className="fas fa-stop"></i> Stop Recording
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <i className="fas fa-circle"></i> Start Recording
                                                                    </>
                                                                )}
                                                            </Button>
                                                            {mediaBlobUrl && (
                                                                <div className="mt-3">
                                                                    {response.response_type === 'audio' ? (
                                                                        <audio src={mediaBlobUrl} controls />
                                                                    ) : (
                                                                        <video
                                                                            src={mediaBlobUrl}
                                                                            controls
                                                                            style={{ maxWidth: '100%' }}
                                                                        />
                                                                    )}
                                                                    {!response.uploadProgress && (
                                                                        <>
                                                                            <Button
                                                                                variant="success"
                                                                                className="mt-2 me-2"
                                                                                onClick={() =>
                                                                                    fetch(mediaBlobUrl)
                                                                                        .then((r) => r.blob())
                                                                                        .then((blob) =>
                                                                                            handleMediaUpload(
                                                                                                question.id,
                                                                                                blob,
                                                                                                response.response_type ===
                                                                                                    'audio'
                                                                                                    ? 'audio/webm'
                                                                                                    : 'video/webm',
                                                                                                clearBlobUrl
                                                                                            )
                                                                                        )
                                                                                }
                                                                            >
                                                                                <i className="fas fa-upload"></i>{' '}
                                                                                Upload to YouTube
                                                                            </Button>
                                                                            <Button
                                                                                variant="danger"
                                                                                className="mt-2"
                                                                                onClick={() => {
                                                                                    clearBlobUrl();
                                                                                    setResponses((prevResponses) => ({
                                                                                        ...prevResponses,
                                                                                        [`q_${question.id}`]: {
                                                                                            ...prevResponses[
                                                                                                `q_${question.id}`
                                                                                            ],
                                                                                            media_response_url: '',
                                                                                            media_mime_type: '',
                                                                                            uploadProgress: null,
                                                                                        },
                                                                                    }));
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-trash"></i>{' '}
                                                                                Discard Recording
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {response.uploadProgress &&
                                                                response.uploadProgress < 100 && (
                                                                    <div className="mt-3">
                                                                        <p>
                                                                            Uploading: {response.uploadProgress}%
                                                                        </p>
                                                                        <div className="progress">
                                                                            <div
                                                                                className="progress-bar"
                                                                                role="progressbar"
                                                                                style={{
                                                                                    width: `${response.uploadProgress}%`,
                                                                                }}
                                                                                aria-valuenow={
                                                                                    response.uploadProgress
                                                                                }
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100"
                                                                            ></div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    )}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        })}
                        <Button variant="primary" onClick={handleSubmit}>
                            Submit Responses
                        </Button>
                    </form>
                ) : (
                    <Alert variant="warning">Play test not found.</Alert>
                )}
            </div>
        </>
    );
};

export default PlayTestSubmitPlayTestAnswersPage;

/* Additional Helper for Media Upload */
class MediaUploader {
    constructor(options) {
        const noop = function () {};
        this.file = options.file;
        this.contentType = options.contentType || this.file.type || 'application/octet-stream';
        this.metadata = options.metadata || {
            title: this.file.name,
            mimeType: this.contentType,
        };
        this.token = options.token;
        this.onComplete = options.onComplete || noop;
        this.onProgress = options.onProgress || noop;
        this.onError = options.onError || noop;
        this.offset = options.offset || 0;
        this.chunkSize = options.chunkSize || 262144; // Default to 256 KB
        this.url = options.url;
        if (!this.url) {
            const params = options.params || {};
            params.uploadType = 'resumable';
            this.url = options.baseUrl + '?' + new URLSearchParams(params).toString();
        }
        this.httpMethod = options.method || 'POST';
    }

    upload() {
        const self = this;
        const xhr = new XMLHttpRequest();
        xhr.open(this.httpMethod, this.url, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.token);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        xhr.setRequestHeader('X-Upload-Content-Length', this.file.size);
        xhr.setRequestHeader('X-Upload-Content-Type', this.contentType);

        xhr.onload = function (e) {
            if (e.target.status < 400) {
                const location = e.target.getResponseHeader('Location');
                self.url = location;
                self.sendFile();
            } else {
                self.onError(e.target.response);
            }
        };
        xhr.onerror = this.onError;
        xhr.send(JSON.stringify(this.metadata));
    }

    sendFile() {
        const content = this.file;
        const end = this.file.size;

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', this.url, true);
        xhr.setRequestHeader('Content-Type', this.contentType);
        xhr.setRequestHeader('Content-Range', `bytes ${this.offset}-${end - 1}/${this.file.size}`);
        xhr.setRequestHeader('X-Upload-Content-Type', this.file.type);

        xhr.onload = (e) => {
            if (e.target.status == 200 || e.target.status == 201) {
                this.onComplete(e.target.response);
            } else if (e.target.status == 308) {
                this.offset = parseInt(e.target.getResponseHeader('Range').split('-')[1]) + 1;
                this.sendFile();
            } else {
                this.onError(e.target.response);
            }
        };
        xhr.onerror = this.onError;
        xhr.upload.onprogress = this.onProgress;
        xhr.send(content.slice(this.offset));
    }
}
