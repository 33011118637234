import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import CampaignForm from '../../component/section/newsletters/campaign_form';
import timeouts from '../../../../constants/timeouts';
import CampaignSendForm from '../../component/section/newsletters/campaign_send_form';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';

const NewsletterCampaignUpdatePage = () => {
    const [campaign, setCampaign] = useState({});
    const [newsletter, setNewsletter] = useState({});
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [communities, setCommunities] = useState([]);
    const navigate = useNavigate();
    const { id, community_id, campaign_id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        Glitch.api.Communities.viewNewsletter(community_id, id)
        .then(response => {
            setNewsletter(response.data.data);

        })
        .catch(error => {
            console.error('Error fetching newsletter or campaigns', error);
        });

        Glitch.api.Communities.viewCampaign(community_id, id, campaign_id)
            .then(response => {
                setCampaign(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching campagin:', error);
            });
    }, []);


    const handleSubmit = async (status) => {
        setIsLoading(true);
        const campaignData = { ...campaign, status };

        try {
            const response = await Glitch.api.Communities.updateCampaign(community_id, id, campaign_id, campaign);
            navigate(Navigate.newsletterCampaignViewPage(id, community_id, campaign_id));
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
            <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: campaign?.subject, link: Navigate.newsletterCampaignViewPage(newsletter?.id, community_id, campaign?.id)},
                    { name: "Update Campaign", link: Navigate.newsletterCampaignUpdatePage(newsletter?.id, community_id, campaign?.id) }]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Update Your Campaign</h2>
                        <p className="lead">Update the details about your email campaign below.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                {/* Campaign Form */}
                {campaign && campaign.id &&
                    <CampaignForm
                        campaign={campaign}
                        setCampaign={setCampaign}
                        communities={communities}
                        errors={errors}
                    />
                }

                <div className="container">
                    {errors && Object.keys(errors).length > 0 && <Danger message={"You have errors in your submission."} />}
                </div>

                <div className="mt-4 text-center">
                    <button
                        type="button"
                        onClick={() => handleSubmit('draft')}
                        className="btn btn-success mx-2"
                    >
                        {isLoading ? <Loading /> : 'Update Campaign'}
                    </button>

                </div>
            </div>
        </Fragment>
    );
};

export default NewsletterCampaignUpdatePage;
