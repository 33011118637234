import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';

const GameShowsPromotePage = () => {
    const [show, setShow] = useState({});
    const [community, setCommunity] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        Glitch.api.GameShows.view(id)
            .then(response => {
                setShow(response.data.data);

                Glitch.api.Communities.view(response.data.data.community_id).then(response => {
                    setCommunity(response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching community:', error);
                });
            })
            .catch(error => {
                console.error('Error fetching game show:', error);
            });
    }, [id, navigate]);

    const methods = [
        {
            title: "Standalone Showcase Page",
            description: "A direct link to the standalone game showcase page with only the showcase details.",
            icon: "fas fa-external-link-alt",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.gameshowCommunityViewStandalonePage(id)}`, "_blank")
        },
        {
            title: "Community Showcase Page",
            description: "Link to the showcase page within your community, allowing users to engage with other community content.",
            icon: "fas fa-users",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.gameshowCommunityViewCommunityPage(id)}`, "_blank")
        },
        {
            title: "Standalone Game Sign-Up Form",
            description: "A link to a standalone sign-up form for games to register in the showcase. This form is suitable for embedding on your site.",
            icon: "fas fa-external-link-alt",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.gameshowCommunityRegisterDevelopersStandalonePage(id)}`, "_blank")
        },
        {
            title: "Community Game Sign-Up Form",
            description: "Link to the sign-up form for games within the Glitch Gaming community, where they can explore the community hub post-registration.",
            icon: "fas fa-users",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.gameshowCommunityRegisterDevelopersCommunityPage(id)}`, "_blank")
        },
        {
            title: "Standalone User Sign-Up Form",
            description: "A link to a standalone sign-up form for users to register for the showcase, suitable for embedding on your site.",
            icon: "fas fa-external-link-alt",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.gameshowCommunityRegisterUsersStandalonePage(id)}`, "_blank")
        },
        {
            title: "Community User Sign-Up Form",
            description: "Link to the user sign-up form within the Glitch Gaming community, where users can explore the community hub post-registration.",
            icon: "fas fa-users",
            action: () => window.open(`https://${community.subdomain}.glitch.fun${Navigate.gameshowCommunityRegisterUsersCommunityPage(id)}`, "_blank")
        },
    ];

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />
                <div className="container mt-4">
                    <Breadcrumbs
                            items={[
                                { name: 'Showcases', link: Navigate.gameshowPublisherListPage() },
                                { name: show?.name, link: Navigate.gameshowPublisherViewPage(id) },
                                { name: 'Promote Showcase', link: Navigate.gameshowPublisherPromotePage(id) },
                            ]}
                    />
                </div>
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title">Promote Game Show: {show.name}</h2>
                            <p className="lead">Explore and share links to promote your game show.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5 mb-5">
                    <div className="row">
                        {methods.map((method, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className="card h-100 text-center shadow-sm">
                                    <div className="card-body">
                                        <i className={`${method.icon} fa-3x mb-3`} aria-hidden="true"></i>
                                        <h5 className="card-title">{method.title}</h5>
                                        <p className="card-text">{method.description}</p>
                                        <button 
                                            className="btn btn-primary mt-3" 
                                            onClick={method.action}
                                        >
                                            Go to Link
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default GameShowsPromotePage;
