import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet';
import Navigate from '../../../../util/Navigate';

const PublisherTutorialsPage = () => {

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet>
                <title>Comprehensive Glitch Tutorials | Learn How to Use Glitch Effectively</title>
                <meta
                    name="description"
                    content="Explore our comprehensive Glitch tutorials covering everything from sending invites to influencers, using custom email templates, managing notes, and more."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="Comprehensive Glitch Tutorials | Learn How to Use Glitch Effectively" />
                <meta
                    property="og:description"
                    content="Access a variety of tutorials to help you navigate and use Glitch for influencer marketing, from managing invites and creating custom emails to taking notes on influencers."
                />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Comprehensive Glitch Tutorials | Learn How to Use Glitch Effectively" />
                <meta
                    name="twitter:description"
                    content="Explore our detailed Glitch tutorials to master every aspect of the platform, including influencer invites, custom email templates, and more."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />

            <section className="pageheader-section-min mt-5">
                <div className="container text-center">
                    <div className="section-wrapper">
                        <h2 className="pageheader-title">Publisher/Developer Tutorials</h2>
                        <p className="lead">Watch and Read Our Tutorials</p>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <p className='lead'>To learn more about how to use the platform, watch our video tutorials below. Also you may <a href="https://discord.gg/gnAyG9nB" target='blank'>join our Discord here</a> and ask questions.</p>
                <div className="row">
                    <div className="col-12 mb-4">
                        <h3 className="text-uppercase">Campaign Overview</h3>
                        <div className="list-group">
                            <Link to={Navigate.publishersTutorialsCampaignsOverviewPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>Getting Started</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsCampaignsCreatePage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How To Create A Campaign</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsCampaignsChecklistPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>Campaign Checklist</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsCampaignsRatecardPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>Rate Card</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 mb-4">
                        <h3 className="text-uppercase">Campaign Invite Management</h3>
                        <div className="list-group">
                            <Link to={Navigate.publishersTutorialsInvitesDatabasePage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How to Utilize The Influencer Database</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsInvitesInvitePage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How To Send Invites</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsInvitesCustomPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How to Send Custom Emails and Use Email Templates</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsInvitesRecommendedPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How to Utilize Recommended Influencers and AI Automated Invites</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsInvitesNegotiationsPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How to Negotiate With Influencers And Set Custom Deals</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsInvitesNotesPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How to Leave Notes About Influencers</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                            <Link to={Navigate.publishersTutorialsInvitesPipelinesPage()} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <span>How to Correctly Manage Influencers In The Pipelines</span>
                                <i className="fas fa-chevron-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialsPage;
