import React, { useRef, useEffect, useState } from 'react';
import Danger from '../../alerts/Danger';
import Select from '../../form/select';
import RequiredAsterisk from '../../form/required_asterisk';
import EmailEditor from 'react-email-editor';

const CampaignSendForm = ({
    campaign = {},
    setCampaign,
    errors = {}
}) => {
    const emailEditorRef = useRef(null);
    const [editorContent, setEditorContent] = useState(campaign.content || '');

    console.log("Campaign", campaign);

    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
            const { html } = data;
            setEditorContent(html);
            console.log('exportHtml', html);
        });
    };

    const onReady = (unlayer) => {
        // editor is ready
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCampaign((prevCampaign) => ({ ...prevCampaign, [name]: value }));
    };

    return (
        <div className="container mt-4">
            <form>
                <div className="card mb-4">
                    <div className="card-header bg-primary text-white">
                        <h3><i className="fas fa-bullhorn mr-2"></i> Send Campaign Details</h3>
                    </div>
                    <div className="card-body">
                        <p className='lead'>Preview your campaign below before sending. To make additional edits, use the edit button. You can also send a copy to yourself, schedule the campaign for later, or send it now.</p>
                        <div className="mb-3">
                            <h4 className='text-black'>Preview</h4>
                            <iframe
                                srcDoc={campaign.content}
                                title="Campaign Content Preview"
                                style={{ width: '100%', height: '500px', border: '1px solid #ccc' }}
                            />
                        </div>

                        <div className="mb-3">
                            <label htmlFor="scheduled_at">Scheduled Date & Time (optional)</label>
                            <input
                                type="datetime-local"
                                className="form-control"
                                id="scheduled_at"
                                name="scheduled_at"
                                value={campaign.scheduled_at || ''}
                                onChange={handleInputChange}
                            />
                            {errors.scheduled_at && errors.scheduled_at.map((msg, idx) => (
                                <Danger message={msg} key={idx} />
                            ))}
                        </div>

                    </div>
                </div>
            </form>
        </div>
    );
};

export default CampaignSendForm;
