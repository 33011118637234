import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { Form } from 'react-bootstrap';

const MediakitSharePage = () => {
    const [mediakit, setMediakit] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [communities, setCommunities] = useState([]);
    const [community, setCommunity] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        // Fetch mediakit details
        Glitch.api.Titles.view(id)
            .then(response => {
                setMediakit(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching mediakit:', error);
            });

        // Fetch communities where the user is an admin
        Glitch.api.Communities.list({
            roles: [
                Glitch.constants.Roles.ADMINISTRATOR,
                Glitch.constants.Roles.SUPER_ADMINISTRATOR,
                Glitch.constants.Roles.MODERATOR,
            ],
            order_by: 'name',
        })
            .then(response => {
                setCommunities(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching communities:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [id, navigate]);

    const handleCommunityChange = (event) => {
        const communityId = event.target.value;
        const selectedCommunity = communities.find(c => c.id.toString() === communityId);
        setCommunity(selectedCommunity);
    };

    const openStandalonePage = () => {
        window.open(Navigate.mediakitsCommunityStandalonePage(id), "_blank");
    };

    const openCommunityPage = () => {
        if (community) {
            window.open(`https://${community.subdomain}.glitch.fun${Navigate.mediakitsCommunityCommunityPage(id)}`, "_blank");
        } else {
            alert('Please select a community.');
        }
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Media Kits', link: Navigate.mediakitsPublishersListPage() },
                        { name: mediakit?.name || 'Loading...', link: Navigate.mediakitsPublishersViewPage(id) },
                        { name: 'Share Media Kit', link: Navigate.mediakitsPublishersSharePage(id) },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Share Your Media Kit</h2>
                        <p className="lead">Choose how you want to share your media kit.</p>
                    </div>
                </div>
            </section>
            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="row">
                        {/* Standalone Media Kit Page */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100 shadow-sm border-0">
                                <div className="card-body text-center">
                                    <div className="icon-container mb-3">
                                        <i className="fas fa-external-link-alt fa-3x text-primary" />
                                    </div>
                                    <h5 className="card-title">Standalone Media Kit Page</h5>
                                    <p className="card-text text-muted">
                                        A direct link to a standalone media kit page, allowing users to view your media kit without distractions.
                                    </p>
                                    <button
                                        className="btn btn-outline-primary mt-3"
                                        onClick={openStandalonePage}
                                    >
                                        Open Standalone Page
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Media Kit In Community */}
                        <div className="col-md-6 mb-4">
                            <div className="card h-100 shadow-sm border-0">
                                <div className="card-body text-center">
                                    <div className="icon-container mb-3">
                                        <i className="fas fa-users fa-3x text-primary" />
                                    </div>
                                    <h5 className="card-title">Media Kit In Community</h5>
                                    <p className="card-text text-muted">
                                        Embed your media kit within one of your communities. Users can explore your media kit and engage with your community.
                                    </p>
                                    {communities.length > 0 ? (
                                        <>
                                            <Form.Group controlId="communitySelect" className="mt-3">
                                                <Form.Label>Select Community</Form.Label>
                                                <Form.Control as="select" onChange={handleCommunityChange}>
                                                    <option value="">-- Select a Community --</option>
                                                    {communities.map((c) => (
                                                        <option key={c.id} value={c.id.toString()}>
                                                            {c.name}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                            <button
                                                className="btn btn-outline-primary mt-3"
                                                onClick={openCommunityPage}
                                                disabled={!community}
                                            >
                                                Open Media Kit in Community
                                            </button>
                                        </>
                                    ) : (
                                        <p className="text-danger mt-3">
                                            You are not an admin of any communities.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default MediakitSharePage;
