import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { Card, Button, Collapse } from 'react-bootstrap';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';

const PlayTestDetailsPage = () => {
    const [playTest, setPlayTest] = useState(null);
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showInfluencerDetails, setShowInfluencerDetails] = useState(false);
    const { id, playtest_id } = useParams(); // title_id, playtest_id
    const navigate = useNavigate();

    const questionsList = [
        { id: 1, text: 'Was the game easy to understand from the start?' },
        { id: 2, text: 'Were there any mechanics that felt confusing or difficult to use?' },
        { id: 3, text: 'How challenging did you find the game overall: too easy, too hard, or just right?' },
        { id: 4, text: 'Did you encounter any bugs or issues during gameplay?' },
        { id: 5, text: 'Were the controls intuitive and responsive? If not, what improvements would you suggest?' },
        { id: 6, text: 'What aspects of the game did you enjoy the most? Why?' },
        { id: 7, text: 'Were there any parts of the game that felt repetitive or boring?' },
        { id: 8, text: 'How long did you feel engaged with the game before it started to lose your attention?' },
        { id: 9, text: 'Would you want to play this game again? Why or why not?' },
        { id: 10, text: 'How likely would you be to recommend this game to a friend?' },
        { id: 11, text: 'Did you feel invested in the story or the game world?' },
        { id: 12, text: 'Were there any characters, settings, or story elements that stood out to you?' },
        { id: 13, text: 'Was there anything about the world or story that felt unclear or hard to follow?' },
        { id: 14, text: 'How would you rate the game’s visuals? Did anything stand out as particularly impressive or lacking?' },
        { id: 15, text: 'How did the audio (music, sound effects, voice acting, etc.) contribute to the experience?' },
        { id: 16, text: 'Were there any audio or visual elements that distracted you from the gameplay?' },
        { id: 17, text: 'Did you experience any difficulties related to visibility, accessibility, or ease of use?' },
        { id: 18, text: 'Were there any features or options you wish the game had to make it easier to play?' },
        { id: 19, text: 'How would you describe the game in a few sentences?' },
        { id: 20, text: 'What is one thing you would change or add to improve the game?' },
        { id: 21, text: 'What was your overall impression of the game?' }
    ];


    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchData = async () => {
            try {
                // Fetch play test details
                const playTestResponse = await Glitch.api.PlayTests.show(id, playtest_id);
                setPlayTest(playTestResponse.data.data);

                // Fetch title details
                const titleResponse = await Glitch.api.Titles.view(id);
                setTitle(titleResponse.data.data);
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, playtest_id, navigate]);

    return (
        <>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingPublishersListPage() },
                        { name: title?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                        { name: 'Play Test Details', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Play Test Details</h2>
                        <p className="lead">View details for this play test.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : playTest ? (
                    <>
                        <Card className="mb-4">
                            <Card.Header>
                                <h3 className="text-black">Tester Information</h3>
                            </Card.Header>
                            <Card.Body className="text-black">
                                <div className="d-flex align-items-center mb-3">
                                    <img
                                        src={playTest.user.avatar || getInfluencerImage(playTest.user?.influencer)}
                                        alt={playTest.user.display_name || playTest.user.username}
                                        className="rounded-circle me-3"
                                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                                    />
                                    <div>
                                        <h4 className='text-black'>{playTest.user.display_name || playTest.user.username}</h4>
                                        {playTest.user.types && playTest.user.types.length > 0 && (
                                            <p>
                                                <strong>Preferred Game Types: </strong>
                                                {playTest.user.types.map((type) => type.name).join(', ')}
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <Button
                                    variant="info"
                                    onClick={() => setShowInfluencerDetails(!showInfluencerDetails)}
                                    aria-controls="influencer-details"
                                    aria-expanded={showInfluencerDetails}
                                    className='mr-2 me-2'
                                >
                                    {showInfluencerDetails ? 'Hide' : 'Show'} Additional Details
                                </Button>
                                <Link
                                    to={Navigate.playtestingPublishersMessagesPage(id, playTest.id)}
                                    className="btn btn-outline-primary mr-2 ml-2"
                                >
                                    <i className="fas fa-envelope"></i> Message
                                </Link>

                                <Collapse in={showInfluencerDetails}>
                                    <div id="influencer-details" className="mt-4">
                                        {playTest.user.bio && (
                                            <p>
                                                <strong>Bio:</strong> <span dangerouslySetInnerHTML={{ __html: playTest.user.bio }} />
                                            </p>
                                        )}
                                        {playTest.user.influencer_content_type && (
                                            <p>
                                                <strong>Content Type:</strong> <span dangerouslySetInnerHTML={{ __html: playTest.user.influencer_content_type }} />
                                            </p>
                                        )}
                                        {playTest.user.influencer_content_theme && (
                                            <p>
                                                <strong>Content Theme:</strong> <span dangerouslySetInnerHTML={{ __html: playTest.user.influencer_content_theme }} />
                                            </p>
                                        )}
                                        {playTest.user.influencer_content_unique && (
                                            <p>
                                                <strong>Unique Content:</strong> <span dangerouslySetInnerHTML={{ __html: playTest.user.influencer_content_unique }} />
                                            </p>
                                        )}
                                        {playTest.user.influencer_brand_approach && (
                                            <p>
                                                <strong>Brand Approach:</strong> <span dangerouslySetInnerHTML={{ __html: playTest.user.influencer_brand_approach }} />
                                            </p>
                                        )}
                                        {playTest.user.influencer_games_why && (
                                            <p>
                                                <strong>Games Preference:</strong> <span dangerouslySetInnerHTML={{ __html: playTest.user.influencer_games_why }} />
                                            </p>
                                        )}
                                    </div>
                                </Collapse>
                            </Card.Body>
                        </Card>

                        <Card>
                            <Card.Header>
                                <h3 className="text-black">Feedback Responses</h3>
                            </Card.Header>
                            <Card.Body className="text-black">
                                {questionsList.map((question) => {
                                    const enabled = playTest[`q_${question.id}_enabled`];
                                    if (!enabled) return null;

                                    const responseType = playTest[`q_${question.id}_response_type`];
                                    const textResponse = playTest[`q_${question.id}_text_response`];
                                    const mediaResponseUrl = playTest[`q_${question.id}_media_response_url`];
                                    const mediaMimeType = playTest[`q_${question.id}_media_mime_type`];

                                    return (
                                        <div key={question.id} className="mb-4">
                                            <h5 className="text-black">{question.text}</h5>
                                            {responseType === 'text' && (
                                                <p>{textResponse || 'No response provided'}</p>
                                            )}
                                            {responseType === 'audio' && mediaResponseUrl && (
                                                <audio controls src={mediaResponseUrl}></audio>
                                            )}
                                            {responseType === 'video' && mediaResponseUrl && (
                                                <div className="mt-3">

                                                    <div className="embed-responsive embed-responsive-16by9">
                                                        <iframe
                                                            className="embed-responsive-item"
                                                            src={`https://www.youtube.com/embed/${mediaResponseUrl.split('v=')[1]
                                                                }`}
                                                            allowFullScreen
                                                            title={`Question ${question.id} Response`}
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            )}
                                            {!responseType && <p>No response provided</p>}
                                        </div>
                                    );
                                })}
                            </Card.Body>
                        </Card>
                    </>
                ) : (
                    <p>Play test not found.</p>
                )}
            </div>
        </>
    );
};

export default PlayTestDetailsPage;
