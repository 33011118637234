import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Fragment } from 'react';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link, useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import axios from 'axios';
import Switch from "react-switch";
import PublisherHeader from '../../component/layout/publisherheader';
import GameShowForm from '../../component/section/gameshows/basic_form';
import timeouts from '../../../../constants/timeouts';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Danger from '../../component/alerts/Danger';

const GameShowsCreatePage = () => {

    const [gameShow, setGameShow] = useState({});
    const [communities, setCommunities] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const [gameLogoBlob, setLogoBlob] = useState(null);
    const [gameBannerImageBlob, setBannerImageBlob] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        Glitch.api.Communities.list({ roles: [Glitch.constants.Roles.ADMINISTRATOR, Glitch.constants.Roles.SUPER_ADMINISTRATOR, Glitch.constants.Roles.MODERATOR], order_by: 'name' }).then(response => {
            setCommunities(response.data.data)
        }).catch(error => {
            console.error(error);
        });

    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);


        Glitch.api.GameShows.create(gameShow).then(async (response) => {

            let promises = [];

            if (gameLogoBlob) {
                const promise1 = Glitch.api.GameShows.uploadLogoBlob(response.data.data.id, gameLogoBlob).then((response) => {
                }).catch(error => {
                    console.error(error);
                });
                promises.push(promise1)
            }

            if (gameBannerImageBlob) {
                const promise2 = Glitch.api.GameShows.uploadBannerImageBlob(response.data.data.id, gameBannerImageBlob).then((response) => {
                }).catch(error => {
                    console.error(error);
                });

                promises.push(promise2)
            }



            if (promises && promises.length > 0) {
                await Promise.all(promises);
            }

            navigate(Navigate.gameshowPublisherPromotePage(response.data.data.id));

        }).catch(error => {
            let jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout)
            }
        }).finally(() => {
            setIsLoading(false);
        });

    };


    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />
                <div className="container mt-4">
                    <Breadcrumbs
                        items={[
                            { name: 'Showcases', link: Navigate.gameshowPublisherListPage() },
                            { name: 'Create Game Showcase', link: Navigate.gameshowPublisherCreatePage() },
                        ]}
                    />
                </div>
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title">Create A Game Showcase</h2>
                            <p className="lead">Create a new game showcase event.</p>
                        </div>
                    </div>
                </section>

                <div className="container">

                    <GameShowForm gameShow={gameShow} setGameShow={setGameShow} communities={communities} setLogoBlob={setLogoBlob} setBannerImageBlob={setBannerImageBlob} errors={errors} />

                    {errors && Object.keys(errors).length > 0 && (
                        <Danger message="You have errors in your submission." />
                    )}

                    <div className='mt-4 text-center'>
                        <button type="button" onClick={handleSubmit} className="btn btn-primary btn-lg">{isLoading ? <Loading /> : 'Create Game Show'}</button>
                    </div>


                </div>
            </Fragment>
        </>
    );


};

export default GameShowsCreatePage;
