import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import ProfileUpdateHeader from "../../component/section/profileupdate";
import Navigate from "../../../../util/Navigate";
import withRouter from "../../../../util/withRouter";
import Danger from "../../component/alerts/Danger";
import Warning from "../../component/alerts/Warning";
import Success from "../../component/alerts/Success";
import TournamentItem from "../../component/section/competitions/detail_tournament_item";
import Footer from "../../component/layout/footer";
import Glitch from 'glitch-javascript-sdk';
import Loading from "../../component/alerts/Loading";
import timeouts from "../../../../constants/timeouts";
import Wysiwyg from "../../component/form/wysiwyg";
import AccountHeader from "../../component/layout/accountheader";

class AccountVerifyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            verfied: false,  
        };
    }

   

    componentDidMount() {

        let id = this.props.router.params.id;

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let token = params.token;

        let email = params.email;

        Glitch.api.Users.verifyAccount({email: email, verification_code: token}).then(response => {
            console.log(response);
            this.setState({verfied: true});
        }).catch(error => {
            console.log(error);
        });
      
    }
   

    render() {
    

        return (
            <Fragment>
             
                <div className="container mt-5 text-center mb-5">
                    <h2>Verification Status</h2>
                    {this.state.verfied ? <Success message={'Your account has been verified successfully! There is nothing else that you need to do.'} /> : <Danger message={'Your account could not be verified. Please try again.'} />}
                </div>
            </Fragment>
        );
    }
}

export default withRouter(AccountVerifyPage);
