import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import Header from '../../component/layout/header';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import MessageCreateForm from '../../component/section/messages/message_create_form';

const MessagesCreatePage = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [message, setMessage] = useState('');

  useEffect(() => {
    Glitch.api.Users.list().then(response => {
      setUsers(response.data.data);
    }).catch(error => {
      console.error("Failed to fetch users:", error);
    });
  }, []);

  const handleUserSelection = (event) => {
    const userId = event.target.value;
    setSelectedUsers(prevSelectedUsers => {
      const updatedSelectedUsers = new Set(prevSelectedUsers);
      if (event.target.checked) {
        updatedSelectedUsers.add(userId);
      } else {
        updatedSelectedUsers.delete(userId);
      }
      return updatedSelectedUsers;
    });
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    const usersArray = Array.from(selectedUsers);
    if (usersArray.length === 0) {
      alert('Please select at least one user to send a message.');
      return;
    }
    Glitch.api.Messages.createOrGetThread({ users: [Glitch.util.Session.getID(), ...usersArray] }).then(response => {
      Glitch.api.Messages.sendMessage({ message: message, thread_id: response.data.data.id }).then(() => {
        alert('Message sent successfully!');
        // Navigate to the thread page after sending the message
        Navigate.to(Navigate.messagesThreadPage(response.data.data.id));
      }).catch(error => {
        console.error("Failed to send message:", error);
      });
    }).catch(error => {
      console.error("Failed to create or get thread:", error);
    });
  };

  return (
    <>
      <Header position={"relative"} />

      <div className="container my-4">
        <Breadcrumbs items={[
          { name: 'Messages', link: Navigate.messagesListPage() },
          { name: 'Create', link: Navigate.messagesCreatePage() }]}
        />
        <h2>Create New Message</h2>
        <MessageCreateForm 
          users={users} 
          onUserSelection={handleUserSelection} 
          onMessageChange={handleMessageChange} 
        />
        <button type="button" className="btn btn-primary" onClick={sendMessage}>
          <i className="fas fa-paper-plane"></i> Send Message
        </button>
      </div>
    </>
  );
};

export default MessagesCreatePage;
