import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faVideo, faTools, faGamepad, faCalculator, faUsers, faBroadcastTower } from '@fortawesome/free-solid-svg-icons';
import Navigate from '../../../../../util/Navigate';

function CreatorLinks() {
    return (
        <div className="container text-center mb-4 pt-3">
            <h4>Learn More About Creator Opportunities</h4>
            <div className="d-flex justify-content-center align-items-center flex-wrap">
                <Link to={Navigate.creatorsInfluencersPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faUsers} className="me-1" /> Influencer Program
                </Link>
                <Link to={Navigate.creatorsPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faVideo} className="me-1" /> Streaming App
                </Link>
                <Link to={Navigate.creatorsMultistreamingPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faBroadcastTower} className="me-1" /> Mutlicasting
                </Link>
                <Link to={Navigate.creatorsOptimizationPage()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faTools} className="me-1" /> Pro Tools
                </Link>
                <Link to={Navigate.creatorsEditing()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faTools} className="me-1" /> Editor Tools
                </Link>
                <Link to={Navigate.creatorsFeatures()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faRobot} className="me-1" /> Features
                </Link>
                <Link to={Navigate.creatorsCalculator()} className="btn btn-outline-primary mx-2 mb-2">
                    <FontAwesomeIcon icon={faCalculator} className="me-1" /> Earnings Calculator
                </Link>
            </div>
        </div>
    );
}

export default CreatorLinks;
