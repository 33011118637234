import React from 'react';

const MessageCreateForm = ({ users, onUserSelection, onMessageChange }) => {
  return (
    <>
      <div className="mb-3">
        <label className="form-label">Select Users</label>
        <div className="user-list">
          {users.map(user => (
            <div key={user.id} className="form-check d-flex align-items-center mb-2">
              <input 
                className="form-check-input me-2" 
                type="checkbox" 
                value={user.id} 
                id={`user-${user.id}`} 
                onChange={onUserSelection}
              />
              <label className="form-check-label d-flex align-items-center" htmlFor={`user-${user.id}`}>
                <img 
                  src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
                  alt={user.username} 
                  className="rounded-circle me-2" 
                  style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                />
                {user.display_name || user.username}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="messageText" className="form-label">Message</label>
        <textarea 
          className="form-control" 
          id="messageText" 
          rows="3" 
          onChange={onMessageChange}
          placeholder="Type your message here..."
        ></textarea>
      </div>
    </>
  );
};

export default MessageCreateForm;
