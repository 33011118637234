import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faYoutube, faReddit, faTwitter, faFacebook, faTwitch } from '@fortawesome/free-brands-svg-icons';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import { getInfluencerImage } from '../../../../util/InfluencerUtils';
import CampaignNavbar from '../../component/section/campaigns/campaign_navbar';
import { Button, Form, Accordion } from 'react-bootstrap';
import Wysiwyg from '../../component/form/wysiwyg';
import CampaignPaymentForm from '../../component/section/campaigns/campaign_payment';
import Danger from '../../component/alerts/Danger';
import RequiredAsterisk from '../../component/form/required_asterisk';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const DEFAULT_IMAGE = 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png';

const CampaignsUpdateInfluencerInviteCompensationPage = () => {
    const [influencer, setInfluencer] = useState(null);
    const [campaign, setCampaign] = useState({});
    const [invite, setInvite] = useState({});
    const { id, influencer_id } = useParams();
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [errors, setErrors] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        if(!Glitch.util.Session.isLoggedIn()) {
            var currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }
        
        Glitch.api.Campaigns.view(id).then(response => {
            setCampaign(response.data.data);

            Glitch.api.Subscriptions.listCommunityInfluencerSubscriptions(response.data.data.community_id).then(response => {
                setSubscriptions(response.data.data);
            }).catch(error => {
                console.error(error);
            });

        }).catch(error => {
            console.error(error);
        });

        Glitch.api.Campaigns.viewInfluencerInvite(id, influencer_id).then(response => {
            setInvite(response.data.data);
        }).catch(error => {

        });

        fetchInfluencer();
    }, []);

    const handleInputChange = (e) => {
        setInvite({ ...invite, [e.target.name]: e.target.value });
    };

    const handleWysiwigInputChange = (name, value) => {
        setInvite(invite => ({ ...invite, [name]: value }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setInvite(prevInvite => ({
            ...prevInvite,
            [name]: checked,
        }));
    };

    function createInputField(name, label, description, type = 'text', required = false, errors) {
        return (
            <>
                <div className="mb-3 text-start">
                    <label className='fw-bold fw-bolder ' htmlFor={name}>{label} {required ? <RequiredAsterisk /> : ''}</label>
                    <p className="small">{description}</p>
                    <input type={type} className="form-control" name={name} value={invite[name] || ''} onChange={handleInputChange} />
                </div>
                {errors && errors[name] && errors[name].map(function (name, index) {
                    return <Danger message={name} key={index} />;
                })}
            </>
        );
    }

    function createTextAreaField(name, label, description, errors, required = false) {
        return (
            <>
                <div className="mb-3 text-start">
                    <label htmlFor={name}>{label} {required ? <RequiredAsterisk /> : ''}</label>
                    <p className="small">{description}</p>
                    <Wysiwyg children={invite[name] || ''} name={name} id={name} onChange={(value) => { handleWysiwigInputChange(name, value) }} />
                </div>
                {errors && errors[name] && errors[name].map(function (name, index) {
                    return <Danger message={name} key={index} />;
                })}
            </>
        );
    }

    const fetchInfluencer = async () => {
        try {
            const response = await Glitch.api.Influencers.viewInfluencer(influencer_id, { campaign_id: id });
            setInfluencer(response.data.data);
        } catch (error) {
            console.error('Error fetching influencer', error);
        }
    };

    const updateCompensation = () => {
        setIsLoading(true);
        Glitch.api.Campaigns.updateInfluencerCompensationInvite(id, influencer_id, invite).then(response => {
            fetchInfluencer();
            setEmailSent(true);
            setErrors({});

            setTimeout(() => {
                setEmailSent(false);
            }, 6000);

        }).catch((error) => {
            setErrors(error.response.data || {});
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const formatNumber = (num) => {
        return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
    };

    const formatExternalUrls = (urls) => {
        if (Array.isArray(urls)) {
            return urls.map(url => url.replace(/[\[\]'"]/g, '').trim()).filter(url => url && url !== 'None');
        }
        return [];
    };

    if (!influencer) {
        return <p>Loading...</p>;
    }

    const externalUrls = formatExternalUrls(influencer.external_urls);

    const hasYouTubeData = influencer.youtube_link || influencer.youtube_custom_url || influencer.youtube_title || influencer.youtube_subscriber_count || influencer.youtube_video_count;

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />

                <section className="pageheader-section-min">
                    <div className="container mt-4">
                        <Breadcrumbs items={[
                            { name: 'Campaigns', link: Navigate.campaignsPage() },
                            { name: campaign.name, link: Navigate.campaignsViewPage(campaign.id) },
                            { name: 'Influencer Invites', link: Navigate.campaignsInvitesPage(campaign.id) },
                            { name: 'Update Compensation', link: Navigate.campaignsUpdateInfluencerInviteCompensation(campaign.id, influencer_id) },
                        ]}
                        />
                        <div className="section-wrapper text-center">
                            <div className="pageheader-thumb mb-4">
                            </div>
                            <h2 className="pageheader-title">Resend Compensation Offer</h2>
                            <p className="lead">As you negotiate compensation with an influencer, you can resend the compensation here based on your discussions.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5">
                    <CampaignNavbar campaignId={id} />
                </div>

                <div className="container mt-4">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Personal Information</h4>
                        </div>
                        <div className="card-body d-flex align-items-center">
                            <img
                                src={getInfluencerImage(influencer)}
                                alt={`${influencer.first_name}'s profile`}
                                className="img-thumbnail me-4"
                                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                            />
                            <div>
                                <p><strong>Name:</strong> {influencer.first_name || influencer.instagram_username || influencer.youtube_title}</p>
                                <p><strong>Location:</strong> {influencer.location}</p>
                                <p><strong>Speaking Language:</strong> {influencer.speaking_language}</p>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Social Media Profiles</h4>
                        </div>
                        <div className="card-body">
                            <SocialMediaLink icon={faInstagram} data={influencer} platform="instagram" />
                            <SocialMediaLink icon={faTiktok} data={influencer} platform="tiktok" />
                            {hasYouTubeData && <SocialMediaLink icon={faYoutube} data={influencer} platform="youtube" />}
                            <SocialMediaLink icon={faTwitter} data={influencer} platform="twitter" />
                            <SocialMediaLink icon={faFacebook} data={influencer} platform="facebook" />
                            <SocialMediaLink icon={faReddit} data={influencer} platform="reddit" />
                            <SocialMediaLink icon={faTwitch} data={influencer} platform="twitch" />
                        </div>
                    </div>

                    <div className="card mb-4">
                        <div className="card-header">
                            <h4 className='text-black'>Content Details</h4>
                        </div>
                        <div className="card-body">
                            {externalUrls.length > 0 && (
                                <>
                                    <p><strong>External URLs:</strong></p>
                                    <ul>
                                        {externalUrls.map((url, index) => (
                                            <li key={index}><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <p><strong>Has Link in Bio:</strong> {influencer.has_link_in_bio ? 'Yes' : 'No'}</p>
                            <p><strong>Has Brand Deals:</strong> {influencer.has_brand_deals ? 'Yes' : 'No'}</p>
                        </div>
                    </div>

                    <div className='text-center mt-2'>
                        {createTextAreaField('deliverables', 'Deliverables', 'Specify any specific deliverables that you want the influencer to execute during the campaign.', errors, false)}
                        {createInputField('days_to_complete', 'Days To Complete', 'The number of days that the influencer has to complete the deliverables. Leave blank or enter 0 for infinite.', 'number', errors, false)}
                        {createTextAreaField('usage_rights', 'Content Usage Rights', 'Specify the content ownership and usage rights for the content generated during the campaign.', errors, false)}

                        <div className='text-start'>
                            <h5>Disable Social Platforms</h5>
                            <p>By default, influencers can create content for all the listed social platforms. If you want to limit where they can promote your game by disabling certain platforms, select the platforms you want to disable below.</p>
                        

                        <Form.Check 
                            type="checkbox" 
                            label="Disable TikTok" 
                            name="disable_tiktok" 
                            checked={invite.disable_tiktok} 
                            onChange={handleCheckboxChange} 
                        />
                        <Form.Check 
                            type="checkbox" 
                            label="Disable Instagram" 
                            name="disable_instagram" 
                            checked={invite.disable_instagram} 
                            onChange={handleCheckboxChange} 
                        />
                        <Form.Check 
                            type="checkbox" 
                            label="Disable Twitter" 
                            name="disable_twitter" 
                            checked={invite.disable_twitter} 
                            onChange={handleCheckboxChange} 
                        />
                        <Form.Check 
                            type="checkbox" 
                            label="Disable Twitch" 
                            name="disable_twitch" 
                            checked={invite.disable_twitch} 
                            onChange={handleCheckboxChange} 
                        />
                        <Form.Check 
                            type="checkbox" 
                            label="Disable YouTube" 
                            name="disable_youtube" 
                            checked={invite.disable_youtube} 
                            onChange={handleCheckboxChange} 
                        />
                        <Form.Check 
                            type="checkbox" 
                            label="Disable Reddit" 
                            name="disable_reddit" 
                            checked={invite.disable_reddit} 
                            onChange={handleCheckboxChange} 
                        />
                        <Form.Check 
                            type="checkbox" 
                            label="Disable Facebook" 
                            name="disable_facebook" 
                            checked={invite.disable_facebook} 
                            onChange={handleCheckboxChange} 
                        />
                        <Form.Check 
                            type="checkbox" 
                            label="Disable Kick" 
                            name="disable_kick" 
                            checked={invite.disable_kick} 
                            onChange={handleCheckboxChange} 
                        />
                        </div>

                        <p className='text-left'>If you want to fine-tune payment options for each social platform, use the options below.</p>

                        <Accordion defaultActiveKey="0" className="custom-accordion">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Rate Card - General</Accordion.Header>
                                <Accordion.Body>
                                    <CampaignPaymentForm title="Rate Card - General" campaignData={invite} setPaymentData={setInvite} errors={errors}/>
                                </Accordion.Body>
                            </Accordion.Item>

                            {!invite.disable_tiktok && (
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Rate Card For TikTok</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For TikTok" social={"tiktok"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {!invite.disable_twitch && (
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Rate Card For Twitch</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For Twitch" social={"twitch"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {!invite.disable_reddit && (
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Rate Card For Reddit</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For Reddit" social={"reddit"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {!invite.disable_facebook && (
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Rate Card For Facebook</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For Facebook" social={"facebook"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {!invite.disable_youtube && (
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Rate Card For YouTube</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For YouTube" social={"youtube"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {!invite.disable_twitter && (
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Rate Card For Twitter</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For Twitter" social={"twitter"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {!invite.disable_instagram && (
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Rate Card For Instagram</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For Instagram" social={"instagram"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                            {!invite.disable_kick && (
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header>Rate Card For Kick</Accordion.Header>
                                    <Accordion.Body>
                                        <CampaignPaymentForm title="Rate Card For Kick" social={"kick"} campaignData={invite} setPaymentData={setInvite} errors={errors} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            )}
                        </Accordion>
                    </div>

                    <div className='text-center mt-4 mb-5'>
                        <p>An email will be sent to the influencer about the compensation changes. </p>


                        {Object.keys(errors).length > 0 && Object.keys(errors).map((errorKey) => (
                            errors[errorKey].map((message, index) => (
                                <Danger key={`${errorKey}-${index}`} message={message} />
                            ))
                        ))}

                        <button
                            className='btn btn-success btn-lg'
                            type='button'
                            onClick={updateCompensation}
                            disabled={isLoading || emailSent}>
                            {isLoading ? <><FontAwesomeIcon icon={faSpinner} spin /> Sending Email.... </> : `Update and Resend`}
                        </button>
                        {emailSent && (
                            <div className="alert alert-success mt-3" role="alert">
                                Email was successfully sent about the updated compensation
                            </div>
                        )}
                    </div>
                </div>
            </Fragment>
        </>
    );
};

const SocialMediaLink = ({ icon, data, platform }) => {
    if (!data[`${platform}_username`] && platform !== "youtube") {
        return null;
    }

    const formatNumber = (num) => {
        return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
    };

    const biographyField = `${platform}_biography`;
    const biography = data[biographyField];

    const hasYouTubeData = platform === "youtube" && (data.youtube_link || data.youtube_custom_url || data.youtube_title || data.youtube_subscriber_count || data.youtube_video_count);

    if (platform === "youtube" && !hasYouTubeData) {
        return null;
    }

    return (
        <div className="mb-3">
            <h6><FontAwesomeIcon icon={icon} className='text-black me-2' />
                {platform === "youtube" ? (
                    <Link to={data[`youtube_link`]} target="_blank">{data[`youtube_custom_url`] || data[`youtube_title`]}</Link>
                ) : (
                    <Link to={data[`${platform}_link`]} target="_blank">{data[`${platform}_username`]}</Link>
                )}
            </h6>
            <p><strong>Followers:</strong> {formatNumber(data[`${platform}_follower_count`] || data[`youtube_subscriber_count`])}</p>
            <p><strong>Engagement:</strong> {data[`${platform}_engagement_percent`] || data[`youtube_engagement_percent`]}%</p>
            {biography && <p><strong>Biography:</strong> {biography}</p>}
            {platform === "youtube" && hasYouTubeData && (
                <>
                    <p><strong>Video Count:</strong> {data[`youtube_video_count`]}</p>
                    <p><strong>Average Views:</strong> {data[`youtube_avg_views`]}</p>
                    <p><strong>Average Views (Shorts):</strong> {data[`youtube_avg_views_shorts`]}</p>
                    <p><strong>Average Views (Long):</strong> {data[`youtube_avg_views_long`]}</p>
                    <p><strong>Has Shorts:</strong> {data[`youtube_has_shorts`] ? 'Yes' : 'No'}</p>
                    <p><strong>Posting Frequency:</strong> {data[`youtube_posting_frequency`]} times per week</p>
                    <p><strong>Topics:</strong> {data[`youtube_topic_details`]?.join(', ')}</p>
                </>
            )}
        </div>
    );
};

export default CampaignsUpdateInfluencerInviteCompensationPage;
