import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../../component/layout/footer';
import PublisherHeader from '../../component/layout/publisherheader';
import { Helmet } from 'react-helmet';

const PublisherTutorialInviteDatabasePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Additional logic can be added here if needed
    }, []);

    return (
        <>
            <Helmet>
                <title>How to Use the Glitch Database to Find Influencers | Tutorial</title>
                <meta
                    name="description"
                    content="Learn how to effectively use the Glitch database to find and invite influencers for your campaign. This tutorial covers filtering and engagement rate optimization."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="follow, index" />
                <meta property="og:title" content="How to Use the Glitch Database to Find Influencers | Tutorial" />
                <meta
                    property="og:description"
                    content="Step-by-step tutorial on using the Glitch database to discover influencers, filter results, and optimize your campaign’s reach."
                />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="How to Use the Glitch Database to Find Influencers | Tutorial" />
                <meta
                    name="twitter:description"
                    content="Learn how to effectively use the Glitch database to find and invite influencers for your campaign. This tutorial covers filtering and engagement rate optimization."
                />
                <meta name="twitter:image" content="https://www.glitch.fun/assets/images/logo/glitch_3.png" />
            </Helmet>

            <PublisherHeader position={"relative"} />
            <section className="pageheader-section-min mt-5">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">How to Use the Glitch Database to Find Influencers</h2>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                            <iframe
                                src="https://www.youtube.com/embed/fZwHuxEsKlY"
                                title="Using the Glitch Database to Find Influencers"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                    <div className="col-12">
                        <h3>Tutorial Overview</h3>
                        <p>
                            Welcome! In this Glitch tutorial, you’ll learn how to effectively use the database to find influencers for your campaign.
                            Before getting started, ensure you’ve already created a campaign. The database is designed to match influencers with the specific details of your game, 
                            so having a campaign in place is essential. If you haven’t created one yet, don’t worry—it’s free and only takes a few minutes.
                        </p>

                        <h4>Accessing the Database</h4>
                        <p>
                            Once your campaign is set up, click on the ‘Find’ button to access the database. Here, you’ll find a list of thousands of influencers, complete with 
                            key details such as their active platforms, follower counts, and engagement rates.
                        </p>

                        <h4>Understanding Engagement Rates</h4>
                        <p>
                            The most important metric to consider is the engagement rate. A higher engagement rate means that when an influencer creates content about your game, 
                            it’s more likely to resonate with their audience and lead to better conversions.
                        </p>

                        <h4>Influencer Cost Estimates</h4>
                        <p>
                            You’ll also notice an estimated price tag next to each influencer. This estimate is based on the values you set in your rate card, as well as the influencer’s 
                            follower count and engagement rate. The higher the values in your rate card, the higher the cost estimates will be for working with an influencer.
                        </p>

                        <h4>Filtering the Database</h4>
                        <p>
                            To find the right influencers, you’ll want to align their interests, target audience, and your budget. For example, let’s search for influencers 
                            interested in ‘Horror’ games. Enter ‘Horror’ in the search bar, and you’ll see the results filter to show relevant influencers. 
                            You can further narrow the results by setting specific criteria. For instance, you can set a maximum follower count of 20,000 in the Instagram section 
                            to focus on micro-influencers.
                        </p>

                        <h4>Sending Invites</h4>
                        <p>
                            Once you’ve found influencers that match your criteria, click on the ‘Invite’ button to send out invitations. Keep in mind that response rates can vary, 
                            but generally, you might expect around a 10% response rate. This means that for every 10 invites you send, you might receive 3 responses, 
                            with one potentially leading to a signed contract.
                        </p>

                        <p>
                            Play around with the database filters until you find the influencers that best match your campaign needs. Good luck with your influencer marketing!
                        </p>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PublisherTutorialInviteDatabasePage;
