import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link, useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import PublisherHeader from '../../component/layout/publisherheader';

const PlayTestGamesListPage = () => {
    const [titles, setTitles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
        }

        const fetchTitles = async () => {
            try {
                await Glitch.api.Titles.list({ enable_playtesting: true, count_testers: true, is_admin: true, page: currentPage })
                    .then((response) => {
                        setTitles(response.data.data);
                        setTotalPages(response.data.meta.last_page || 1);
                    })
                    .catch((error) => {
                        console.error('Error fetching titles', error);
                    });
            } catch (error) {
                console.error('Error fetching titles', error);
            }
        };

        fetchTitles();
    }, [currentPage, navigate]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const disablePlayTesting = (titleId) => {
        if (window.confirm('Are you sure you want to disable play testing for this game?')) {
            Glitch.api.Titles.update(titleId, { enable_playtesting: false })
                .then(() => {
                    setTitles(titles.filter((title) => title.id !== titleId));
                })
                .catch((error) => {
                    console.error('Error disabling play testing', error);
                });
        }
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center pt-3">
                        <h2 className="pageheader-title">Your Games for Play Testing</h2>
                        <p className="lead">Manage the games that you have enabled for play testing.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-2">
                <div className="d-flex">
                    <Link className="btn btn-success" to={Navigate.playtestingPublishersCreatePage()}>
                        <i className="fas fa-plus-circle"></i> Add New Game
                    </Link>
                </div>
            </div>

            <div className="container">
                {titles.length > 0 ? (
                    <>
                        {titles.map((title) => (
                            <div key={title.id} className="card mb-4">
                                <div className="row g-0">
                                   
                                        <div className="col-md-4">
                                            <img
                                                src={title.image_main || '/assets/images/titles/no_image_2.png'}
                                                className="img-fluid rounded-start"
                                                alt={title.name}
                                                style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                                            />
                                        </div>
                                    
                                    <div className={`col-md-8`}>
                                        <div className="card-body">
                                            <h5 className="card-title">{title.name}</h5>
                                            <div
                                                className="card-text"
                                                dangerouslySetInnerHTML={{ __html: title.short_description }}
                                            ></div>
                                            {title.testers_count !== undefined && (
                                                <p>
                                                    <strong>Number of Play Testers:</strong> {title.testers_count}
                                                </p>
                                            )}
                                            <div className="mt-3">
                                                <Link
                                                    className="btn btn-primary btn-sm me-2"
                                                    to={Navigate.playtestingPublishersViewPage(title.id)}
                                                >
                                                    <i className="fas fa-users"></i> View Play Tests
                                                </Link>
                                                <Link
                                                    className="btn btn-secondary btn-sm me-2"
                                                    to={Navigate.playtestingPublishersUpdatePage(title.id)}
                                                >
                                                    <i className="fas fa-edit"></i> Update Title
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="card card-body bg-light text-center text-black">
                        <p className="lead">You have no games available for play testing.</p>
                        <div className="d-flex justify-content-center">
                            <div className="col-auto">
                                <Link to={Navigate.playtestingPublishersCreatePage()} className="btn btn-primary">
                                    Add Your First Game
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                {/* Pagination */}
                {totalPages > 1 && (
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(page)}>
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </div>
        </Fragment>
    );
};

export default PlayTestGamesListPage;
