import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link, useNavigate } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import PublisherHeader from '../../component/layout/publisherheader';
import Loading from '../../component/alerts/Loading';

const MediakitListPage = () => {
    const [mediakits, setMediakits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchMediakits = async () => {
            try {
                const response = await Glitch.api.Titles.list({
                    page: currentPage,
                    is_admin: true,
                    is_media_kit: true,
                });
                setMediakits(response.data.data);
                setTotalPages(response.data.last_page);
            } catch (error) {
                console.error('Error fetching mediakits', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMediakits();
    }, [currentPage, navigate]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center pt-3">
                        <h2 className="pageheader-title">Media Kits</h2>
                        <p className="lead">Manage your media kits for your game(s).</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-2">
                <div className="section-wrapper">
                    <Link className="btn btn-success mb-3" to={Navigate.mediakitsPublishersCreatePage()}>
                        <i className="fas fa-plus-circle"></i> Create A Media Kit
                    </Link>
                </div>

                {isLoading ? (
                    <Loading />
                ) : mediakits.length > 0 ? (
                    <div className="row">
                        {mediakits.map((mediakit) => (
                            <div className="col-md-6 col-lg-4 mb-4" key={mediakit.id}>
                                <div className="card h-100 shadow-sm">
                                    <img
                                        src={mediakit.image_main || '/assets/images/titles/stream_1.jpeg'}
                                        className="card-img-top"
                                        alt={mediakit.name}
                                        style={{ height: '200px', objectFit: 'cover' }}
                                    />
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title text-black">{mediakit.name}</h5>
                                        <p className="card-text text-black-50">
                                            {mediakit.short_description
                                                ? mediakit.short_description.substring(0, 100) + '...'
                                                : 'No description provided.'}
                                        </p>
                                        <div className="mt-auto">
                                            <Link
                                                to={Navigate.mediakitsPublishersViewPage(mediakit.id)}
                                                className="btn btn-primary btn-sm mr-2 me-2"
                                            >
                                                <i className="fas fa-eye"></i> View
                                            </Link>
                                            <Link
                                                to={Navigate.mediakitsPublishersUpdatePage(mediakit.id)}
                                                className="btn btn-info btn-sm mr-2 me-2"
                                            >
                                                <i className="fas fa-edit"></i> Edit
                                            </Link>
                                            <Link
                                                to={Navigate.mediakitsPublishersSharePage(mediakit.id)}
                                                className="btn btn-secondary btn-sm"
                                            >
                                                <i className="fas fa-share"></i> Share
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="card card-body bg-dark text-center">
                        <p className="lead text-white">No Media Kits Have Been Created</p>
                        <div className="d-flex justify-content-center">
                            <div className="col-auto">
                                <Link to={Navigate.mediakitsPublishersCreatePage()} className="btn btn-primary">
                                    Create Your First Media Kit
                                </Link>
                            </div>
                        </div>
                    </div>
                )}

                {totalPages > 1 && (
                    <nav aria-label="Page navigation example" className="mt-4">
                        <ul className="pagination justify-content-center">
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => handlePageChange(page)}>
                                        {page}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </div>
        </Fragment>
    );
};

export default MediakitListPage;
