import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, Link } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Alerts from '../../../../util/Alerts';

const PlayTestMyPlayTestsPage = () => {
    const [playTests, setPlayTests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchPlayTests = async () => {
            try {
                const response = await Glitch.api.PlayTests.mine();
                setPlayTests(response.data.data);
            } catch (error) {
                console.error('Error fetching play tests', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPlayTests();
    }, [navigate]);

    const getStatusBadge = (status) => {
        switch (status) {
            case 'pending':
                return <span className="badge bg-warning text-dark">Pending</span>;
            case 'in_progress':
                return <span className="badge bg-primary">In Progress</span>;
            case 'completed':
                return <span className="badge bg-success">Completed</span>;
            case 'cancelled':
                return <span className="badge bg-secondary">Cancelled</span>;
            default:
                return <span className="badge bg-light text-dark">{status}</span>;
        }
    };

    const handleAcceptInvite = async (playTest) => {
        try {
            await Glitch.api.PlayTests.acceptInvite(playTest.title_id, playTest.id);
          
            Alerts.display("Success", 'Invite accepted successfully.').
            // Refresh the list
            setIsLoading(true);
            const response = await Glitch.api.PlayTests.getUserPlayTests();
            setPlayTests(response.data.data);
            setIsLoading(false);
        } catch (error) {

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Failed', message);
            } else {
                alert('Failed to accept invite');
            }
        }
    };

    const handleRejectInvite = async (playTest) => {
        try {
            await Glitch.api.PlayTests.rejectInvite(playTest.title_id, playTest.id);
            Alerts.display("Success", 'Invite has been rejected.').
            // Refresh the list
            setIsLoading(true);
            const response = await Glitch.api.PlayTests.getUserPlayTests();
            setPlayTests(response.data.data);
            setIsLoading(false);
        } catch (error) {

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Failed', message);
            } else {
                alert('Failed to reject invite.');
            }
        }
    };

    const handleCancelRequest = async (playTest) => {
        try {
            await Glitch.api.PlayTests.cancelRequest(playTest.title_id, playTest.id);

            Alerts.display("Success", 'Play test was cancelled.').
            // Refresh the list
            setIsLoading(true);
            const response = await Glitch.api.PlayTests.getUserPlayTests();
            setPlayTests(response.data.data);
            setIsLoading(false);
        } catch (error) {

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Failure', message);
            } else {
                alert('Failed to cancel play test request.');
            }

        }
    };

    return (
        <>
            <InfluencerHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingUserTests() },
                        { name: 'My Play Tests', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">My Play Tests</h2>
                        <p className="lead">View your play test requests and invites.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <div className="d-flex justify-content-start mb-3">
                            <Link
                                to={Navigate.playtestingUserGames()}
                                className="btn btn-primary"
                            >
                                <i className="fas fa-gamepad me-2"></i> Find Games
                            </Link>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Game Title</th>
                                    <th scope="col">Requested By</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Invite Accepted</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {playTests.length > 0 ? (
                                    playTests.map((playTest) => (
                                        <tr key={playTest.id}>
                                            <td>
                                                {playTest.title && (
                                                    <>
                                                        <img
                                                            src={playTest.title.image_main || '/assets/images/titles/no_image_2.png'}
                                                            alt={playTest.title.name}
                                                            className="rounded-circle me-2"
                                                            style={{ width: '40px', height: '40px' }}
                                                        />
                                                        {playTest.title.name}
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {playTest.requested_by_user ? (
                                                    <span className="badge bg-info">You</span>
                                                ) : (
                                                    <span className="badge bg-secondary">Developer</span>
                                                )}
                                            </td>
                                            <td>{getStatusBadge(playTest.status)}</td>
                                            <td>
                                                {playTest.invite_accepted ? (
                                                    <i className="fas fa-check text-success"></i>
                                                ) : (
                                                    <i className="fas fa-times text-danger"></i>
                                                )}
                                            </td>
                                            <td>
                                                {playTest.status === 'pending' && !playTest.requested_by_user && (
                                                    <>
                                                        <button
                                                            className="btn btn-sm btn-success me-2"
                                                            onClick={() => handleAcceptInvite(playTest)}
                                                        >
                                                            <i className="fas fa-check"></i> Accept
                                                        </button>
                                                        <button
                                                            className="btn btn-sm btn-danger me-2"
                                                            onClick={() => handleRejectInvite(playTest)}
                                                        >
                                                            <i className="fas fa-times"></i> Reject
                                                        </button>
                                                        <Link
                                                            className="btn btn-sm btn-info"
                                                            to={Navigate.playtestingUserTest(playTest.title_id, playTest.id)}
                                                        >
                                                            <i className="fas fa-search"></i> More Info
                                                        </Link>
                                                    </>
                                                )}
                                                {playTest.status === 'pending' && playTest.requested_by_user && (
                                                    <button
                                                        className="btn btn-sm btn-secondary"
                                                        onClick={() => handleCancelRequest(playTest)}
                                                    >
                                                        <i className="fas fa-times"></i> Cancel Request
                                                    </button>
                                                )}
                                                {playTest.status === 'in_progress' && (
                                                    <>
                                                        <Link
                                                            to={Navigate.playtestingUserTest(playTest.title_id, playTest.id)}
                                                            className="btn btn-sm btn-info me-2"
                                                        >
                                                            <i className="fas fa-info-circle"></i> Details
                                                        </Link>
                                                        <Link
                                                            to={Navigate.playtestingUserAnswers(playTest.title_id, playTest.id)}
                                                            className="btn btn-sm btn-primary"
                                                        >
                                                            <i className="fas fa-edit"></i> Submit Answers
                                                        </Link>
                                                    </>
                                                )}
                                                {playTest.status === 'completed' && (
                                                    <>
                                                    <Link
                                                        to={Navigate.playtestingUserTest(playTest.title_id, playTest.id)}
                                                        className="btn btn-sm btn-info me-2"
                                                    >
                                                        <i className="fas fa-eye"></i> View Details
                                                    </Link>
                                                        <Link
                                                            to={Navigate.playtestingUserAnswers(playTest.title_id, playTest.id)}
                                                            className="btn btn-sm btn-primary"
                                                        >
                                                            <i className="fas fa-edit"></i> Update Answers
                                                        </Link>
                                                    </>


                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center">
                                            You have no play tests.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </>
    );
};

export default PlayTestMyPlayTestsPage;
