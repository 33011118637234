import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';

const PublisherGameWikiPage = () => {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Top Wiki Solutions for Gaming Communities | Glitch</title>
                <meta name="description" content="Discover the best wiki platforms for building vibrant communities around your game. Explore options like MediaWiki, JS.wiki, World Anvil, and Fandom to enhance engagement, boost SEO, and create a thriving game universe." />
                <meta name="keywords" content="wiki software for gaming, game wiki, MediaWiki, JS.wiki, World Anvil, Fandom, game community, game marketing, SEO for gaming, game development" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Top Wiki Solutions for Gaming Communities | Glitch" />
                <meta property="og:description" content="Explore the best wiki platforms for building and managing gaming communities, including MediaWiki, JS.wiki, World Anvil, and Fandom." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="/assets/images/wikis/wikis.webp" />
            </Helmet>
            <Header />
            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Top Wiki Solutions <br /><br />for Gaming Communities</h1>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-5">
                <h2>Why Use a Wiki for Your Game?</h2>
                <p className="lead">A game wiki can be a powerful tool for both marketing and community building. Some key benefits include:</p>
                <ol className="lead">
                    <li>Deepening player engagement by offering detailed information about characters, lore, and gameplay mechanics.</li>
                    <li>Acting as a comprehensive FAQ to address common player questions during gameplay.</li>
                    <li>Adding vibrance and depth to your game's narrative, enhancing the overall player experience.</li>
                    <li>Boosting SEO, increasing visibility and discoverability for your game in organic search results.</li>
                </ol>

                <h3>How to Build Your Game Wiki</h3>
                <p className="lead">Creating a wiki may seem like a daunting task, but you can use it to help develop your game at the same time. As you design characters, worlds, and mechanics, document everything in the wiki. This allows you to refine ideas while also preparing a resource for players upon release. When your game is ready, reformat the wiki for public access, showcasing what you want players to see.</p>

                <h3>Top Wiki Solutions for Games</h3>
                <p className="lead">Here are some recommended platforms to help you build an engaging and informative wiki for your game:</p>

                {/* MediaWiki Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>MediaWiki</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/wikis/mediawiki.jpg" className='img-fluid rounded' alt="MediaWiki" />
                        </div>
                        <div className='col-sm-9'>
                            <p>MediaWiki is a powerful and customizable platform ideal for supporting a game release. It allows developers to create an open, collaborative space where players can share game guides, lore, strategies, and updates. This boosts organic, user-driven content while fostering a sense of community. Its transparent, centralized nature ensures players have consistent access to information, creating a loyal fanbase that amplifies the game’s presence through word-of-mouth.</p>
                            <Link to={"https://www.mediawiki.org/wiki/MediaWiki"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* WikiJS Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>JS.wiki</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/wikis/wikijs.png" className='img-fluid rounded' alt="JS.wiki" />
                        </div>
                        <div className='col-sm-9'>
                            <p>JS.wiki offers a modern, user-friendly platform with sleek, customizable features that enhance community building. It allows players to contribute to game guides, lore, and FAQs while maintaining a visually appealing and intuitive design. JS.wiki’s SEO optimization and collaborative features help boost retention and visibility, strengthening community ties and encouraging players to participate in your game’s development and discussions.</p>
                            <Link to={"https://js.wiki/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* World Anvil Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>World Anvil</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/wikis/worldanvil.jpg" className='img-fluid rounded' alt="World Anvil" />
                        </div>
                        <div className='col-sm-9'>
                            <p>World Anvil is an immersive world-building platform ideal for games with complex narratives. Its rich storytelling tools allow players to explore maps, timelines, and character backstories, deepening engagement. World Anvil’s ability to foster community-driven content encourages fans to contribute to the lore and share their creations, creating a dynamic community that spreads organically through player interactions.</p>
                            <Link to={"https://www.worldanvil.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Fandom Card */}
                <div className="card my-4 p-4 shadow-lg">
                    <h4 className='text-dark'>Fandom</h4>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <img src="/assets/images/wikis/fandom.png" className='img-fluid rounded' alt="Fandom" />
                        </div>
                        <div className='col-sm-9'>
                            <p>Fandom is a widely used platform that provides extensive tools for building and managing gaming communities. Players can contribute and edit content, including game guides, character profiles, and more, boosting engagement. Fandom’s strong SEO presence ensures your wiki is highly discoverable, while its integrated multimedia support allows developers to share game trailers and artwork, enhancing the appeal and visibility of your game.</p>
                            <Link to={"https://www.fandom.com/"} target='_blank' className="btn btn-primary btn-lg">
                                Learn More <FontAwesomeIcon icon={faGamepad} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/wikis/wikis.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '3rem',
    fontWeight: 'bold',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.8)',
};

export default PublisherGameWikiPage;
