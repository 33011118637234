import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams, Link } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import NewsletterForm from '../../component/section/newsletters/basic_form';
import timeouts from '../../../../constants/timeouts';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Danger from '../../component/alerts/Danger';

const NewsletterUpdatePage = () => {
    const [newsletter, setNewsletter] = useState({});
    const [communities, setCommunities] = useState([]);
    const [errors, setErrors] = useState({});
    const [bannerImageBlob, setBannerImageBlob] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const { id, community_id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
            return;
        }

        Glitch.api.Communities.list({
            roles: [
                Glitch.constants.Roles.ADMINISTRATOR,
                Glitch.constants.Roles.SUPER_ADMINISTRATOR,
                Glitch.constants.Roles.MODERATOR,
            ],
            order_by: 'name',
        }).then(response => {
            setCommunities(response.data.data);
        }).catch(error => {
            console.error('Error fetching communities:', error);
        });

        // Fetch newsletter details and related campaigns
        Glitch.api.Communities.viewNewsletter(community_id, id)
            .then(response => {
                setNewsletter(response.data.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching newsletter or campaigns', error);
                setIsLoading(false);
            });
    }, [id, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!newsletter.community_id) {
            setErrors({ community_id: ['Community is required'] });
            setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            return;
        }

        setIsLoading(true);

        // Create the newsletter
        Glitch.api.Communities.updateNewsletter(newsletter.community_id, id, newsletter).then(async (response) => {
            const newsletterId = response.data.data.id;
            const uploadPromises = [];

            // Upload banner image if available
            if (bannerImageBlob) {
                try {
                    const bannerUpload = Glitch.api.Communities.uploadNewsletterBannerImageBlob(newsletter.community_id, newsletterId, bannerImageBlob)
                        .catch(error => console.error('Error uploading banner image:', error));
                    uploadPromises.push(bannerUpload);
                } catch (error) {
                    console.error(error);
                }
            }

            // Await image uploads
            if (uploadPromises.length > 0) {
                await Promise.all(uploadPromises);
            }


            // Navigate to newsletter view page
            navigate(Navigate.newsletterViewPage(newsletterId, newsletter.community_id));

        }).catch(error => {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => setErrors({}), timeouts.error_message_timeout);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };



    return (
        <Fragment>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Newsletters', link: Navigate.newsletterListPage() },
                    { name: newsletter?.name, link: Navigate.newsletterViewPage(newsletter?.id, community_id) },
                    { name: 'Update Newsletters', link: Navigate.newsletterCreatePage() }
                ]}
                />
            </div>

            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Update Newsletter</h2>
                        <p className="lead">Update the information about the newsletter below.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                {/* Newsletter Form */}
                <NewsletterForm
                    newsletter={newsletter}
                    setNewsletter={setNewsletter}
                    communities={communities}
                    setBannerImageBlob={setBannerImageBlob}
                    errors={errors}
                />

                <div className="container">
                    {errors && Object.keys(errors).length > 0 && <Danger message={"You have errors in your submission."} />}
                </div>

                <div className="mt-4 text-center">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-lg"
                    >
                        {isLoading ? <Loading /> : 'Update Newsletter'}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

// Helper function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

// Helper function to get the badge class for campaign status
const getStatusBadgeClass = (status) => {
    switch (status) {
        case 'draft':
            return 'badge-warning';
        case 'scheduled':
            return 'badge-info';
        case 'sent':
            return 'badge-success';
        case 'sending':
            return 'badge-secondary';
        default:
            return 'badge-light';
    }
};

export default NewsletterUpdatePage;
