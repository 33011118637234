import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './getCroppedImg'; // Helper function for cropping
import Glitch from 'glitch-javascript-sdk';
import Danger from '../../alerts/Danger';
import Select from '../../form/select';
import RequiredAsterisk from '../../form/required_asterisk';
import Navigate from '../../../../../util/Navigate';
import { Link } from 'react-router-dom';
import Wysiwyg from '../../form/wysiwyg';

const GameShowForm = ({
    gameShow,
    onUpdate,
    setGameShow,
    onLogoUpdate,
    onBannerImageUpdate,
    setLogoBlob,
    setBannerImageBlob,
    communities,
    errors,
}) => {
    const [logo, setLogo] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);

    const [cropLogo, setCropLogo] = useState({ x: 0, y: 0 });
    const [cropBanner, setCropBanner] = useState({ x: 0, y: 0 });
    const [zoomLogo, setZoomLogo] = useState(1);
    const [zoomBanner, setZoomBanner] = useState(1);
    const [croppedAreaPixelsLogo, setCroppedAreaPixelsLogo] = useState(null);
    const [croppedAreaPixelsBanner, setCroppedAreaPixelsBanner] = useState(null);

    const [croppedLogoSrc, setCroppedLogoSrc] = useState(null);
    const [croppedBannerSrc, setCroppedBannerSrc] = useState(null);

    const [showLogoCropper, setShowLogoCropper] = useState(false);
    const [showBannerCropper, setShowBannerCropper] = useState(false);

    useEffect(() => {
        if (gameShow) {
            setGameShow(gameShow);
        }
    }, [gameShow, setGameShow]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const updatedValue = type === 'checkbox' ? e.target.checked : value;
        setGameShow({ ...gameShow, [name]: updatedValue });
    };

    const handleWysiwigInputChange = (name, value) => {
        setGameShow(gameShow => ({ ...gameShow, [name]: value }));
    };

    const onCropCompleteLogo = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsLogo(croppedAreaPixels);
    }, []);

    const onCropCompleteBanner = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsBanner(croppedAreaPixels);
    }, []);

    const showCroppedImage = async (imageSrc, croppedAreaPixels, setImage, name) => {
        try {
            const croppedImageBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
            const croppedImageUrl = URL.createObjectURL(croppedImageBlob);

            if (gameShow.id && name === 'logo') {
                setCroppedLogoSrc(croppedImageUrl);
                await Glitch.api.GameShows.uploadLogoBlob(gameShow.id, croppedImageBlob);
            } else if (gameShow.id && name === 'bannerImage') {
                setCroppedBannerSrc(croppedImageUrl);
                await Glitch.api.GameShows.uploadBannerImageBlob(gameShow.id, croppedImageBlob);
            } else if (!gameShow.id && name === 'logo') {
                setCroppedLogoSrc(croppedImageUrl);
                setLogoBlob(croppedImageBlob);
            } else if (!gameShow.id && name === 'bannerImage') {
                setCroppedBannerSrc(croppedImageUrl);
                setBannerImageBlob(croppedImageBlob);
            }
            setImage(null);
        } catch (e) {
            console.error(e);
        }
    };

    const handleImageChange = (e, imageSetter, cropSetter, zoomSetter, setShowCropper) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                imageSetter(reader.result);
                cropSetter({ x: 0, y: 0 });
                zoomSetter(1);
                setShowCropper(true);
            };
        }
    };

    const timezones = [
        { label: 'UTC', value: 'UTC' },
        { label: 'America/New_York', value: 'America/New_York' },
        { label: 'America/Chicago', value: 'America/Chicago' },
        { label: 'America/Denver', value: 'America/Denver' },
        { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
        { label: 'Europe/London', value: 'Europe/London' },
        { label: 'Europe/Paris', value: 'Europe/Paris' },
        { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
        { label: 'Australia/Sydney', value: 'Australia/Sydney' },
    ];

    const communityOptions = Object.entries(communities).map(([key, value]) => ({
        label: value.name, // Format the label
        value: value.id,
    }));

    return (
        <div className="container mt-4">
            <form>
                <div className="card mb-3">
                    <div className="card-header bg-primary text-white">
                        <h3>
                            <i className="fas fa-trophy mr-2"></i> Game Show
                        </h3>
                    </div>
                    <div className="card-body">
                        <p className="lead">Enter details about the game showcase you are hosting.</p>
                        <hr />
                        <div className="row">
                            {createInput(
                                'Name',
                                'name',
                                gameShow?.name,
                                handleChange,
                                'text',
                                'fas fa-signature',
                                errors,
                                'Enter the name of the game showcase.',
                                true
                            )}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="type">Business Account <RequiredAsterisk /></label>
                            <p className="small">Select a business account to associate the campaign with. If you have not created one, you can <Link to={Navigate.communitiesCreatePage()}>create one here</Link></p>

                            <Select name="community_id" className="form-select" onChange={handleChange} value={gameShow?.community_id}>
                                <option key={"-1"} value={""}>
                                        Select A Business Account
                                </option>
                                {communityOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Select>

                            {errors && errors['community_id'] && errors['community_id'].map(function (name, index) {
                                return <Danger message={name} key={index} />;
                            })}
                        </div>

                        <div className="row">
                            {createTextarea(
                                'Description',
                                'description',
                                gameShow?.description,
                                handleChange,
                                errors,
                                'Enter a description for the game showcase.',
                                true
                            )}
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="type">
                                    <i className="fas fa-list-alt mr-2"></i> Type <RequiredAsterisk />
                                </label>
                                <p className="small">Select the type of event.</p>
                                <Select
                                    name="type"
                                    className="form-select"
                                    onChange={handleChange}
                                    value={gameShow?.type || ''}
                                >
                                    <option value="">Select a type</option>
                                    <option value="showcase">Showcase</option>
                                    <option value="award">Award</option>
                                    <option value="game_update">Game Update</option>
                                    <option value="game_premiere">Game Premiere</option>
                                </Select>
                               
                                {errors && errors['type'] && errors['type'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>

                            {createInput(
                                'Website URL',
                                'website',
                                gameShow?.website,
                                handleChange,
                                'url',
                                'fas fa-globe',
                                errors,
                                'Enter the website URL for the showcase.',
                                true
                            )}
                        </div>

                        <div className="row">
                            {createInput(
                                'Start Date',
                                'start_date',
                                gameShow?.start_date,
                                handleChange,
                                'datetime-local',
                                'fas fa-calendar-alt',
                                errors,
                                'Enter the start date of the showcase.',
                                true
                            )}
                            {createInput(
                                'End Date (Optional)',
                                'end_date',
                                gameShow?.end_date,
                                handleChange,
                                'datetime-local',
                                'fas fa-calendar-alt',
                                errors,
                                'Enter the end date of the showcase.'
                            )}
                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="timezone">
                                    <i className="fas fa-clock mr-2"></i> Timezone <RequiredAsterisk />
                                </label>
                                <Select
                                    name="timezone"
                                    className="form-select"
                                    onChange={handleChange}
                                    value={gameShow?.timezone || ''}
                                >
                                    <option value="">Select a timezone</option>
                                    {timezones.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Select>
                                {errors && errors['timezone'] && errors['timezone'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>

                        <div className="row mb-2 mt-2">
                            <div className="col">
                                <label htmlFor="is_live">
                                    <i className="fas fa-door-open mr-2"></i> Have the event live & publicly listed? <RequiredAsterisk />
                                </label>
                                <div>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_live"
                                            value="1"
                                            checked={gameShow?.is_live === true || gameShow?.is_live === '1'}
                                            onChange={handleChange}
                                        />{' '}
                                        Yes
                                    </label>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_live"
                                            value="0"
                                            checked={gameShow?.is_live === false || gameShow?.is_live === '0'}
                                            onChange={handleChange}
                                        />{' '}
                                        No
                                    </label>
                                </div>
                                {errors && errors['is_live'] && errors['is_live'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>
                        <hr/>

                        <div className="row mb-2">
                            <div className="col">
                                <label htmlFor="allow_game_registration">
                                    <i className="fas fas fa-gamepad mr-2"></i> Allow games to apply to be in the event? <RequiredAsterisk />
                                </label>
                                <div>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="allow_game_registration"
                                            value="1"
                                            checked={gameShow?.allow_game_registration === true || gameShow?.allow_game_registration === '1'}
                                            onChange={handleChange}
                                        />{' '}
                                        Yes
                                    </label>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="allow_game_registration"
                                            value="0"
                                            checked={gameShow?.allow_game_registration === false || gameShow?.allow_game_registration === '0'}
                                            onChange={handleChange}
                                        />{' '}
                                        No
                                    </label>
                                </div>
                                {errors && errors['allow_game_registration'] && errors['allow_game_registration'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>
                        {gameShow?.allow_game_registration == true && (
                            <div className="mb-3">
                                <label htmlFor="description_game_registration">
                                    Game Registration Description <RequiredAsterisk />
                                </label>
                                <Wysiwyg children={gameShow['description_game_registration'] || ''} name={'description_game_registration'} id={'description_game_registration'} onChange={(value) => {handleWysiwigInputChange('description_game_registration', value)}} />
                               
                                <p className="small">Provide details about the registration process and requirements for games applying to participate.</p>
                                {errors && errors['description_game_registration'] && errors['description_game_registration'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        )}
                        <hr/>

                        <div className="row mb-2">
                            <div className="col">
                                <label htmlFor="allow_user_registration">
                                    <i className="fas fa-running mr-2"></i> Allow users to RSVP to the event? <RequiredAsterisk />
                                </label>
                                <div>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="allow_user_registration"
                                            value="1"
                                            checked={gameShow?.allow_user_registration === true || gameShow?.allow_user_registration === '1'}
                                            onChange={handleChange}
                                        />{' '}
                                        Yes
                                    </label>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="allow_user_registration"
                                            value="0"
                                            checked={gameShow?.allow_user_registration === false || gameShow?.allow_user_registration === '0'}
                                            onChange={handleChange}
                                        />{' '}
                                        No
                                    </label>
                                </div>
                                {errors && errors['allow_user_registration'] && errors['allow_user_registration'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>
                        {gameShow?.allow_user_registration == true && (
                            <div className="mb-3">
                                <label htmlFor="description_user_register">
                                    User RSVP Description <RequiredAsterisk />
                                </label>
                                <Wysiwyg children={gameShow['description_user_register'] || ''} name={'description_user_register'} id={'description_user_register'} onChange={(value) => {handleWysiwigInputChange('description_user_register', value)}} />

                                <p className="small">Provide details about the RSVP process and any requirements for users attending the event.</p>
                                {errors && errors['description_user_register'] && errors['description_user_register'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        )}
                        <hr/>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="is_virtual">
                                    <i className="fas fa-laptop mr-2"></i> Is the event virtual? <RequiredAsterisk />
                                </label>
                                <div>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_virtual"
                                            value="1"
                                            checked={gameShow?.is_virtual === true || gameShow?.is_virtual === '1'}
                                            onChange={handleChange}
                                        />{' '}
                                        Yes
                                    </label>
                                    <label className="mr-2">
                                        <input
                                            type="radio"
                                            name="is_virtual"
                                            value="0"
                                            checked={gameShow?.is_virtual === false || gameShow?.is_virtual === '0'}
                                            onChange={handleChange}
                                        />{' '}
                                        No
                                    </label>
                                </div>
                                {errors && errors['is_virtual'] && errors['is_virtual'].map((message, index) => (
                                    <Danger message={message} key={index} />
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="card-header bg-secondary text-white">Upload Game Show Images</div>
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="logo">Logo Image</label>
                            <p className="small">Recommended dimensions: 200x200 pixels.</p>
                            <input
                                type="file"
                                accept="image/*"
                                className="form-control-file"
                                id="logo"
                                onChange={(e) =>
                                    handleImageChange(e, setLogo, setCropLogo, setZoomLogo, setShowLogoCropper)
                                }
                            />
                            {croppedLogoSrc && (
                                <div className="mt-3">
                                    <p>Cropped Logo:</p>
                                    <img src={croppedLogoSrc} alt="Cropped Logo" className="img-fluid w-50" />
                                </div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="bannerImage">Banner Image</label>
                            <p className="small">Recommended dimensions: 3840x1240 pixels.</p>
                            <input
                                type="file"
                                accept="image/*"
                                className="form-control-file"
                                id="bannerImage"
                                onChange={(e) =>
                                    handleImageChange(e, setBannerImage, setCropBanner, setZoomBanner, setShowBannerCropper)
                                }
                            />
                            {croppedBannerSrc && (
                                <div className="mt-3">
                                    <p>Cropped Banner Image:</p>
                                    <img src={croppedBannerSrc} alt="Cropped Banner" className="img-fluid w-50" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </form>

            {/* Logo Cropper Modal */}
            {showLogoCropper && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Crop Logo Image</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => setShowLogoCropper(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="crop-container" style={{ position: 'relative', width: '100%', height: '400px' }}>
                                    <Cropper
                                        image={logo}
                                        crop={cropLogo}
                                        zoom={zoomLogo}
                                        aspect={1 / 1}
                                        onCropChange={setCropLogo}
                                        onZoomChange={setZoomLogo}
                                        onCropComplete={onCropCompleteLogo}
                                    />
                                </div>
                                <div className="slider-container mt-3">
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoomLogo}
                                        onChange={(e) => setZoomLogo(e.target.value)}
                                        className="form-range"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowLogoCropper(false)}>
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        showCroppedImage(logo, croppedAreaPixelsLogo, setLogo, 'logo');
                                        setShowLogoCropper(false);
                                    }}
                                >
                                    Crop Image
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Banner Cropper Modal */}
            {showBannerCropper && (
                <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Crop Banner Image</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() => setShowBannerCropper(false)}
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="crop-container" style={{ position: 'relative', width: '100%', height: '400px' }}>
                                    <Cropper
                                        image={bannerImage}
                                        crop={cropBanner}
                                        zoom={zoomBanner}
                                        aspect={3840 / 1240}
                                        onCropChange={setCropBanner}
                                        onZoomChange={setZoomBanner}
                                        onCropComplete={onCropCompleteBanner}
                                    />
                                </div>
                                <div className="slider-container mt-3">
                                    <label>Zoom</label>
                                    <input
                                        type="range"
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        value={zoomBanner}
                                        onChange={(e) => setZoomBanner(e.target.value)}
                                        className="form-range"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowBannerCropper(false)}>
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        showCroppedImage(bannerImage, croppedAreaPixelsBanner, setBannerImage, 'bannerImage');
                                        setShowBannerCropper(false);
                                    }}
                                >
                                    Crop Image
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    function createInput(label, name, value, handleChange, type = 'text', icon, errors, description = '', required = false) {
        return (
            <div className="col">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        <i className={`${icon} mr-2`}></i> &nbsp;{label} {required ? <RequiredAsterisk /> : ''}
                    </label>
                    <p className="small">{description}</p>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    />
                </div>
                {errors && errors[name] && errors[name].map((message, index) => (
                    <Danger message={message} key={index} />
                ))}
            </div>
        );
    }

    function createTextarea(label, name, value, handleChange, errors, description, required = false) {
        return (
            <div className="col-md-12">
                <div className="mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    
                    <Wysiwyg children={gameShow[name] || ''} name={name} id={name} onChange={(value) => {handleWysiwigInputChange(name, value)}} />

                   
                    
                </div>
                {errors && errors[name] && errors[name].map((message, index) => (
                    <Danger message={message} key={index} />
                ))}
            </div>
        );
    }
};

export default GameShowForm;
