import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, Link } from 'react-router-dom';
import InfluencerHeader from '../../component/layout/infuencerheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Alerts from '../../../../util/Alerts';

const PlayTestFindGamesToTestPage = () => {
    const [titles, setTitles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchTitles = async () => {
            try {
                // Fetch titles where play testing is enabled
                const response = await Glitch.api.Titles.list({ enable_playtesting: true });
                setTitles(response.data.data);
            } catch (error) {
                console.error('Error fetching titles', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTitles();
    }, [navigate]);

    const handleApply = async (titleId) => {
        try {
            await Glitch.api.PlayTests.requestPlayTest(titleId);
         
            Alerts.display('Application Succcess', 'Play test request sent successfully.');
        } catch (error) {
            console.error('Error requesting play test', error);

            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display('Application Failed', message);
            } else {
                alert('Failed to request play test.');
            }

        }
    };

    return (
        <>
            <InfluencerHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingUserTests() },
                        { name: 'Find Games to Test', link: '#' },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Find Games to Test</h2>
                        <p className="lead">Browse games available for play testing and apply to test them.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="row">
                        {titles.length > 0 ? (
                            titles.map((title) => (
                                <div key={title.id} className="col-md-4 mb-4">
                                    <div className="card h-100 position-relative">
                                        {title.image_main && (
                                            <img
                                                src={title?.image_main || '/assets/images/titles/no_image_2.png'}
                                                className="card-img-top"
                                                alt={title.name}
                                                style={{ height: '200px', objectFit: 'cover' }}
                                            />
                                        )}
                                        <div className="card-body d-flex flex-column">
                                            <h5 className="card-title">{title.name}</h5>
                                            <p className="card-text flex-grow-1">{title.tagline}</p>
                                            <div className="mt-auto">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => handleApply(title.id)}
                                                >
                                                    <i className="fas fa-play"></i> Apply to Test
                                                </button>
                                                <Link
                                                    to={Navigate.playtestingUserGame(title.id)}
                                                    className="btn btn-link text-decoration-none"
                                                >
                                                    More Info
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No games available for play testing at the moment.</p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default PlayTestFindGamesToTestPage;
