import React from "react";
import DateTimePicker from "react-datetime-picker";
import Danger from "../../alerts/Danger";
import Input from "../../form/input";
import Textarea from "../../form/textarea";
import Roles from "../../../../../constants/roles";
import Select from "../../form/select";
import Glitch from 'glitch-javascript-sdk';


export default function CommunityFormUserUpdate({ roleValue, roleOnChange, errors  }) {

    return (
        <>
            <h3 >Update User</h3>
            <p className="lead">Update the user's information for this community.</p>

            <div className="form-group-checkbox">
                <label>User Role</label>
                <Select className="form-control" value={roleValue} onChange={roleOnChange}>
                    <option value={""}>Select A Role</option>
                    <option value={Glitch.constants.Roles.SUPER_ADMINISTRATOR}>Super Administrator</option>
                    <option value={Glitch.constants.Roles.ADMINISTRATOR}>Administrator</option>
                    <option value={Glitch.constants.Roles.MODERATOR}>Moderator</option>
                    <option value={Glitch.constants.Roles.PARTICIPANT}>Member</option>
                </Select>

                <p className="small">Select a role for the user.</p>
                {errors && errors.template_id && errors.template_id.map(function (name, index) {
                    return <Danger message={name} key={index} />;
                })}
            </div>

            

           

        </>
    );
}