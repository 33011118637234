import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';
import GameTitleForm from '../../component/section/titles/title_form';
import GameTitle from '../../component/section/titles/title_display';

const PlayTestGamesPreviewPage = () => {
    const [title, setGameTitle] = useState(null);
    const [communities, setCommunities] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [gameMainImageBlob, setMainImageBlob] = useState(null);
    const [gameBannerImageBlob, setBannerImageBlob] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        Glitch.api.Communities.list({
            roles: [
                Glitch.constants.Roles.ADMINISTRATOR,
                Glitch.constants.Roles.SUPER_ADMINISTRATOR,
                Glitch.constants.Roles.MODERATOR,
            ],
            order_by: 'name',
        })
            .then(response => {
                setCommunities(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching communities:', error);
            });

        Glitch.api.Titles.view(id)
            .then((response) => {
                setGameTitle(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching gameTitle', error);
            });
    }, [id, navigate]);


    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Test', link: Navigate.playtestingPublishersListPage() },
                        { name: title?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                        { name: 'Update Game', link: Navigate.playtestingPublishersUpdatePage(id) },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Preview Your Game Title</h2>
                        <p className="lead">Before sending your game title to testers, preview it to make sure everything looks right.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : title ? (
                    <>

                        <div className="card mb-4 p-1">
                            <GameTitle gameInfo={title} />

                            <div className="d-flex justify-content-center mt-4 mb-5">
                                <Link className="btn btn-primary btn-lg mx-2" to={Navigate.playtestingPublishersInvitePage(title?.id)}>
                                    <i className="fas fa-user-plus"></i> Invite New Testers
                                </Link>
                                <Link className="btn btn-primary btn-lg mx-2" to={Navigate.playtestingPublishersViewPage(title?.id)}>
                                    <i className="fas fa-users"></i> View Current Testers
                                </Link>
                            </div>

                        </div>


                    </>
                ) : (
                    <p>Game not found.</p>
                )}
            </div>
        </Fragment>
    );
};

export default PlayTestGamesPreviewPage;
