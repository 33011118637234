import React, { Component, Fragment } from 'react';
import { ProgressBar, Button, Form, Row, Col } from 'react-bootstrap';
import Glitch from 'glitch-javascript-sdk';
import CommunityFormCname from '../../component/section/communities/form_community_cname';
import CommunityFormFeatures from '../../component/section/communities/form_community_features';
import CommunityFormLabels from '../../component/section/communities/form_community_labels';
import CommunityFormMedia from '../../component/section/communities/form_community_media';
import CommunityFormSocial from '../../component/section/communities/form_community_social';
import Danger from '../../component/alerts/Danger';
import Loading from '../../component/alerts/Loading';
import withRouter from '../../../../util/withRouter';
import Navigate from '../../../../util/Navigate';

class CommunitiesGettingStartedPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            data: {},
            community: {},
            errors: {},
            isLoading: false,
            isLoadingMainImage: false,
            logoImages: [],
            isLoadingBannerImage: false,
            bannerImages: [],
        };
    }

    componentDidMount() {
        this.loadCommunity();
    }

    loadCommunity() {
        const id = this.props.router.params.id;

        Glitch.api.Communities.view(id).then(response => {
            this.setState({ community: response.data.data, data: response.data.data });
        }).catch(error => {
            console.error(error);
        });
    }

    updateCommunity(event) {
        event.preventDefault();
        const { data } = this.state;
        const id = this.props.router.params.id;

        this.setState({ isLoading: true });

        Glitch.api.Communities.update(id, data).then(response => {
            this.setState({ isLoading: false });
            this.props.router.navigate(Navigate.communitiesManagePage(id)); // Update with the desired navigation path
        }).catch(error => {
            this.setState({ isLoading: false });
            const jsonErrors = error?.response?.data;

            if (jsonErrors) {
                this.setState({ errors: jsonErrors });
                setTimeout(() => {
                    this.setState({ errors: {} });
                }, 5000);
            }
        });
    }

    nextStep = () => {
        this.setState({ step: this.state.step + 1 });
    }

    prevStep = () => {
        this.setState({ step: this.state.step - 1 });
    }

    logoOnChange = (imageList) => {
        this.setState({ logoImages: imageList });
    };

    saveLogo = () => {
        const image = this.state.logoImages[0];
        const id = this.props.router.params.id;

        this.setState({ isLoadingMainImage: true });

        const blob = Glitch.util.Data.dataURItoBlob(image.data_url);

        Glitch.api.Communities.uploadLogoBlob(id, blob).then(response => {
            this.setState({ community: response.data.data, logoImages: [], isLoadingMainImage: false });
        }).catch(error => {
            this.setState({ isLoadingMainImage: false });
        });
    }

    bannerImageOnChange = (imageList) => {
        this.setState({ bannerImages: imageList });
    };

    saveBannerImage = () => {
        const image = this.state.bannerImages[0];
        const id = this.props.router.params.id;

        this.setState({ isLoadingBannerImage: true });

        const blob = Glitch.util.Data.dataURItoBlob(image.data_url);

        Glitch.api.Communities.uploadBannerImageBlob(id, blob).then(response => {
            this.setState({ community: response.data.data, bannerImages: [], isLoadingBannerImage: false });
        }).catch(error => {
            this.setState({ isLoadingBannerImage: false });
        });
    }

    renderStepContent = () => {
        const { step, data, community, errors, isLoading, logoImages, bannerImages, isLoadingMainImage, isLoadingBannerImage } = this.state;

        let community_url =  community.subdomain + process.env.REACT_APP_SITE_DOMAIN;

        switch (step) {
            case 1:
                return (
                    <Form className="text-left" style={{ textAlign: "left" }}>
                        <h3>Access Your Community</h3>
                        <p>Your community is accessible via the subdomain below. You can share this link with your users, who will be able to sign up and create content.</p>
                        <p><a target="_blank" href={"//" + community_url}>{community.subdomain}{process.env.REACT_APP_SITE_DOMAIN}</a></p>
                    </Form>
                );
            case 2:
                return (
                    <Form className="text-left" style={{ textAlign: "left" }}>
                        <CommunityFormCname
                            cnameValue={data.cname}
                            cnameOnChange={(e) => this.setState({ data: { ...data, cname: e.target.value } })}
                            cnameEnabledValue={data.cname_enabled === 'true' || data.cname_enabled === true}
                            cnameEnabledOnChange={(e) => this.setState({ data: { ...data, cname_enabled: e.target.checked } })}
                            subdomain={community.subdomain}
                            errors={errors}
                        />
                    </Form>
                );
            case 3:
                return (
                    <>
                    <Form className="text-left" style={{ textAlign: "left" }}>
                        <CommunityFormFeatures
                            disableStreamsValue={data.disable_streams === 'true' || data.disable_streams === true}
                            disableStreamsOnChange={(e) => this.setState({ data: { ...data, disable_streams: e.target.checked } })}
                            disableCompetitionsValue={data.disable_competitions === 'true' || data.disable_competitions === true}
                            disableCompetitionsOnChange={(e) => this.setState({ data: { ...data, disable_competitions: e.target.checked } })}
                            disableForumsValue={data.disable_forums === 'true' || data.disable_forums === true}
                            disableForumsOnChange={(e) => this.setState({ data: { ...data, disable_forums: e.target.checked } })}
                            disableTeamsValue={data.disable_teams === 'true' || data.disable_teams === true}
                            disableTeamsOnChange={(e) => this.setState({ data: { ...data, disable_teams: e.target.checked } })}
                            disableUsersValue={data.disable_users === 'true' || data.disable_users === true}
                            disableUsersOnChange={(e) => this.setState({ data: { ...data, disable_users: e.target.checked } })}
                            errors={errors}
                        />
                        
                    </Form>

                    <h4 className='text-start mt-3'>Explanation Of Features</h4>
                    <ul className='text-start'>
                        <li><strong>Streams:</strong> Streams will be the ability for users to create live streams about your game and create recordings. Users will be able to watch those streams.</li>
                        <li><strong>Competitions:</strong> Competitions will be the ability for users to create and manage their own competitions. This can be used for tournaments, contests, and other events.</li>
                        <li><strong>Forums:</strong> Forums will be the ability for users to post content and discuss topics related to your game. This can be used for memes, sharing clips, bug reports, feedback, and general discussion.</li>
                        <li><strong>Teams:</strong> Teams will be the ability for users to create and manage teams. This can be used for clans, guilds, and other groups.</li>
                        <li><strong>Users:</strong> Users will be the ability for users to create accounts and interact with your community. This can be used for user profiles, messaging, and other features.</li>
                    </ul>
                    </>
                );
            case 4:
                return (
                    <Form className="text-left" style={{ textAlign: "left" }}>
                        {community.logo && (
                            <>
                                <h5>Current Logo</h5>
                                <img src={community.logo} className="img-fluid" alt="Community Logo" />
                            </>
                        )}
                        {community.banner_image && (
                            <>
                                <h5>Current Banner</h5>
                                <img src={community.banner_image} className="img-fluid" alt="Community Banner" />
                            </>
                        )}
                        <CommunityFormMedia
                            logoValue={logoImages}
                            isLoadingMainImage={isLoadingMainImage}
                            logoOnChange={this.logoOnChange}
                            saveLogo={this.saveLogo}
                            bannerImageValue={bannerImages}
                            isLoadingBannerImage={isLoadingBannerImage}
                            bannerImageOnChange={this.bannerImageOnChange}
                            saveBannerImage={this.saveBannerImage}
                            errors={errors}
                        />
                    </Form>
                );
            case 5:
                return (
                    <Form className="text-left" style={{ textAlign: "left" }}>
                        <CommunityFormSocial
                            facebookValue={data.facebook_page}
                            facebookOnChange={(e) => this.setState({ data: { ...data, facebook_page: e.target.value } })}
                            twitterValue={data.twitter_page}
                            twitterOnChange={(e) => this.setState({ data: { ...data, twitter_page: e.target.value } })}
                            instagramValue={data.instagram_page}
                            instagramOnChange={(e) => this.setState({ data: { ...data, instagram_page: e.target.value } })}
                            snapchatValue={data.snapchat_page}
                            snapchatOnChange={(e) => this.setState({ data: { ...data, snapchat_page: e.target.value } })}
                            tiktokValue={data.tiktok_page}
                            tiktokOnChange={(e) => this.setState({ data: { ...data, tiktok_page: e.target.value } })}
                            paetronValue={data.paetron_page}
                            paetronOnChange={(e) => this.setState({ data: { ...data, paetron_page: e.target.value } })}
                            twitchValue={data.twitch_page}
                            twitchOnChange={(e) => this.setState({ data: { ...data, twitch_page: e.target.value } })}
                            youtubeValue={data.youtube_page}
                            youtubeOnChange={(e) => this.setState({ data: { ...data, youtube_page: e.target.value } })}
                            discordValue={data.discord_page}
                            discorOnChange={(e) => this.setState({ data: { ...data, discord_page: e.target.value } })}
                            steamValue={data.steam_page}
                            steamOnChange={(e) => this.setState({ data: { ...data, steam_page: e.target.value } })}
                            errors={errors}
                        />
                    </Form>
                );
            default:
                return <div>Unknown step</div>;
        }
    }

    render() {
        const { step } = this.state;
        const steps = ['Community Website','CName', 'Features', 'Media', 'Social Profiles'];
        const progressPercentage = (step / steps.length) * 100;

        return (
            <Fragment>
                <div className="d-flex flex-column justify-content-center align-items-center text-center h-100" style={{ gap: '20px' }}>
                    <div className="mt-5 w-75">
                        <h2>Getting Started with Your Community</h2>
                        <ProgressBar now={progressPercentage} label={`${step}/${steps.length}`} />
                        <p className="lead mt-4">Step {step} of {steps.length}: {steps[step - 1]}</p>
                        {this.renderStepContent()}
                        <div className="d-flex justify-content-between mt-4 text-center">
                            {step > 1 && (
                                <Button variant="secondary" onClick={this.prevStep}>
                                    <i className="fas fa-arrow-left"></i> Previous
                                </Button>
                            )}
                            {step < steps.length && (
                                <Button variant="primary" onClick={this.nextStep}>
                                    Next <i className="fas fa-arrow-right"></i>
                                </Button>
                            )}
                            {step === steps.length && (
                                <Button variant="success" onClick={(e) => this.updateCommunity(e)}>
                                    {this.state.isLoading ? <Loading /> : 'Finish Setup'}
                                </Button>
                            )}
                        </div>
                        {(Object.keys(this.state.errors).length > 0) && (
                            <Danger message={"There are errors in your form submission. Please check the form above."} />
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CommunitiesGettingStartedPage);
