import React, { useState } from 'react';
import Glitch from 'glitch-javascript-sdk';

const MessageInput = ({ threadId, onMessageSent }) => {
  const [newMessage, setNewMessage] = useState('');

  const sendMessage = () => {
    if (newMessage.trim() !== '') {
      Glitch.api.Messages.sendMessage({ message: newMessage, thread_id: threadId }).then((response) => {
        setNewMessage('');
        onMessageSent(response?.data?.data); // Call the passed function to refresh the thread
      }).catch(error => {
        console.error("Failed to send message:", error);
      });
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  }

  return (
    <div className="message-input mt-4">
      <div className="input-group">
        <textarea 
          className="form-control" 
          placeholder="Type your message..." 
          value={newMessage} 
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          rows={1}
          style={{ resize: 'none' }}
        ></textarea>
        <button className="btn btn-primary" onClick={sendMessage}>
          <i className="fas fa-paper-plane"></i>
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
