// src/pages/scheduler/scheduler_view_page.jsx

import Glitch from 'glitch-javascript-sdk';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faInstagram,
    faTwitter,
    faTiktok,
    faDiscord,
    faReddit,
    faYoutube,
    faTelegram,
    faKickstarter, // Placeholder for Bluesky
} from '@fortawesome/free-brands-svg-icons';
import ScheduleNavbar from '../../component/section/schedulers/schedule_navbar';
import Breadcrumbs from '../../component/layout/breadcrumb';

const SchedulerViewPage = () => {
    const [scheduler, setScheduler] = useState({});
    const [updates, setUpdates] = useState([]);
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [warningMessage, setWarningMessage] = useState(null);

    const navigate = useNavigate();

    const platforms = [
        { name: 'Facebook', icon: faFacebook, oauthField: 'facebook_oauth_token' },
        { name: 'Instagram', icon: faInstagram, oauthField: 'instagram_oauth_token' },
        { name: 'Twitter', icon: faTwitter, oauthField: 'twitter_oauth_token' },
        { name: 'TikTok', icon: faTiktok, oauthField: 'tiktok_oauth_token' },
        { name: 'Discord', icon: faDiscord, oauthField: 'discord_oauth_token' },
        { name: 'Reddit', icon: faReddit, oauthField: 'reddit_oauth_token' },
        { name: 'YouTube', icon: faYoutube, oauthField: 'youtube_oauth_token' },
        // Platforms without OAuth
        { name: 'Telegram', icon: faTelegram, oauthField: 'telegram_oauth_token' },
        { name: 'Bluesky', icon: faKickstarter, oauthField: 'bluesky_oauth_token' }, // Placeholder icon
    ];

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            const currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        }

        Glitch.api.Scheduler.getSchedule(id)
            .then(response => {
                setScheduler(response.data.data);

                Glitch.api.Scheduler.listUpdates(response.data.data.id)
                    .then(res => {
                        setUpdates(res.data.data);
                        const authenticatedCount = platforms.filter(platform =>
                            platform.oauthField && response.data.data[platform.oauthField]
                        ).length;

                        if (authenticatedCount === 0) {
                            setWarningMessage(
                                "No accounts have been authenticated, and therefore no post will be scheduled. Please authenticate your social media accounts."
                            );
                        }
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false);
                    });
            })
            .catch(error => {
                console.error(error);
                setIsLoading(false);
            });
    }, [id, navigate]);

    const createMarkup = htmlContent => ({ __html: htmlContent });

    if (isLoading) {
        return (
            <>
                <PublisherHeader position={"relative"} />
                <div className="container my-5 text-center">
                    <Loading />
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Schedulers', link: Navigate.schedulerListPage() },
                    { name: scheduler?.name, link: Navigate.schedulerViewPage(scheduler?.id) }]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">{scheduler.name || 'Scheduler'}</h2>
                        <p className="lead">View the scheduler details for the game {scheduler?.title?.name}.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5">
                <ScheduleNavbar schedulerIdId={id} />
            </div>

            <div className="container my-5">

                {/* Basic Scheduler Information */}
                <div className="card mb-4">

                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h3 className="text-black">Scheduler Information</h3>
                        <Link to={Navigate.schedulerUpdatePage(scheduler.id)} className="btn btn-primary">
                            <i className="fas fa-edit"></i> Update
                        </Link>
                    </div>
                    <div className="card-body">
                        <p><strong>Name:</strong> {scheduler.name}</p>
                        <p><strong>Pull from Community:</strong> {scheduler.pull_from_community ? 'Yes' : 'No'}</p>
                        <p><strong>Posts Per Week:</strong> {scheduler.posts_per_week}</p>
                        <p><strong>Targeting Information:</strong> {scheduler.targeting_information}</p>
                        <p><strong>Hashtags:</strong> {scheduler.hashtags}</p>

                        <div className='row'>
                            <div className='col-2'>
                                <img
                                    src={
                                        scheduler?.title?.image_main
                                            ? scheduler.title.image_main
                                            : '/assets/images/titles/no_image_2.png'
                                    }
                                    alt="Game thumbnail"
                                    className="img-fluid h-100"
                                    style={{ objectFit: 'cover' }}
                                />

                            </div>
                            <div className='col-10'>
                                <h6 className="card-subtitle mb-2 text-muted">
                                    {scheduler?.title?.name}
                                </h6>
                                <p
                                    className="card-text flex-grow-1"
                                    dangerouslySetInnerHTML={{ __html: scheduler?.title?.short_description }}
                                ></p>

                            </div>

                        </div>

                    </div>
                </div>

                {/* Authenticated Social Platforms */}
                <div className="card mb-4">
                    <div className="card-header">
                        <h3 className="text-black">Authenticated Social Platforms</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            {platforms.map(platform => {
                                const isAuthenticated = platform.oauthField ? scheduler[platform.oauthField] : true;
                                if (isAuthenticated) {
                                    return (
                                        <div className="col-md-3 text-center mb-3" key={platform.name}>
                                            <div className="platform-card p-2 border rounded">
                                                <FontAwesomeIcon icon={platform.icon} size="2x" />
                                                <p>{platform.name}</p>
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            {/* Warning Message */}
                            {warningMessage && (
                                <div className="alert alert-warning text-center">
                                    {warningMessage}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* List of Updates */}
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h3 className="text-black">Updates</h3>
                        <Link to={Navigate.schedulerUpdatesCreatePage(scheduler.id)} className="btn btn-primary">
                            <i className="fas fa-plus-circle"></i> Add New Update
                        </Link>
                    </div>
                    <div className="card-body">
                        {updates.length > 0 ? (
                            <div className="list-group">
                                {updates.map(update => (
                                    <div key={update.id} className="list-group-item">
                                        <h5>{update.title}</h5>
                                        <div dangerouslySetInnerHTML={createMarkup(update.content)} />
                                        <Link to={Navigate.schedulerUpdatesCreatePage(scheduler.id, update.id)} className="btn btn-sm btn-secondary mt-2">
                                            Edit
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No updates available.</p>
                        )}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default SchedulerViewPage;
