import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';

const GameShowsViewPage = () => {
    const [show, setShow] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.publishersOnboardingStep1Page());
        }

        Glitch.api.GameShows.view(id)
            .then(response => {
                setShow(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching game show', error);
            });
    }, [id, navigate]);

    const capitalizeFirstLetter = (string) => {
        if(!string) {
            return '';
        }
        
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };

    return (
        <>
            <Fragment>
                <PublisherHeader position={"relative"} />
                <div className="container mt-4">
                    <Breadcrumbs
                            items={[
                                { name: 'Showcases', link: Navigate.gameshowPublisherListPage() },
                                { name: show?.name, link: Navigate.gameshowPublisherViewPage(id) },
                                { name: 'View Showcase', link: Navigate.gameshowPublisherViewPage(id) },
                            ]}
                    />
                </div>
                <section className="pageheader-section-min">
                    <div className="container">
                        <div className="section-wrapper text-center">
                            <h2 className="pageheader-title">Game Show: {show.name}</h2>
                            <p className="lead">View all details about this game show event.</p>
                        </div>
                    </div>
                </section>

                <div className="container mt-5 mb-5">
                    <div className="row">
                        {/* Left Column - Main Details */}
                        <div className="col-md-8">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <h5 className="card-title">About the Game Show</h5>
                                    <p className="card-text text-black">
                                        <span dangerouslySetInnerHTML={createMarkup(show.description)} />
                                    </p>

                                    <div className="mt-4 text-black">
                                        <h6 className='text-black'><i className="fas fa-calendar-alt me-2"></i>Event Dates</h6>
                                        <p>
                                            <strong>Start:</strong> {show.start_date} <br />
                                            {show.end_date && <><strong>End:</strong> {show.end_date}</>}
                                        </p>
                                        <h6 className='text-black'><i className="fas fa-map-marker-alt me-2"></i>Location</h6>
                                        <p>
                                            {show.is_virtual
                                                ? 'Virtual Event'
                                                : `${show.location} (${show.address?.locality}, ${show.address?.province}, ${show.address?.country})`}
                                        </p>
                                        <h6 className='text-black'><i className="fas fa-globe me-2"></i>Website</h6>
                                        <p>
                                            <a href={show.website} target="_blank" rel="noreferrer">
                                                {show.website}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Right Column - Images */}
                        <div className="col-md-4">
                            <div className="card mb-4">
                                <div className="card-body text-center">
                                    <h5 className="card-title">Logo</h5>
                                    <img
                                        src={show.logo || '/assets/images/gameshows/nologo.webp'}
                                        alt="Game show logo"
                                        className="img-fluid mb-4"
                                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                                    />
                                    <h5 className="card-title">Banner Image</h5>
                                    <img
                                        src={show.banner_image || '/assets/images/gameshows/nobanner.webp'}
                                        alt="Game show banner"
                                        className="img-fluid"
                                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Additional Information Section */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Additional Information</h5>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <strong>Type:</strong> {capitalizeFirstLetter(show.type)}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Timezone:</strong> {show.timezone}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Is Live & Public:</strong> {show.is_live ? 'Yes' : 'No'}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Allow Game Registration:</strong> {show.allow_game_registration ? 'Yes' : 'No'}
                                        </li>
                                        <li className="list-group-item">
                                            <strong>Allow User Registration:</strong> {show.allow_user_registration ? 'Yes' : 'No'}
                                        </li>
                                        {show?.community && (
                                            <li className="list-group-item">
                                                <strong>Community:</strong> {show.community?.name}
                                            </li>
                                        )}
                                        {show?.campaign && (
                                            <li className="list-group-item">
                                                <strong>Campaign:</strong> {show.campaign?.name}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <Link to={Navigate.gameshowPublisherUpdatePage(show.id)} className="btn btn-primary me-2">
                                <i className="fas fa-edit"></i> Edit
                            </Link>
                            <Link to={Navigate.gameshowPublisherPromotePage(show.id)} className="btn btn-info me-2">
                                <i className="fas fa-share"></i> Promote
                            </Link>
                            <Link to={Navigate.gameshowPublisherListPage()} className="btn btn-secondary">
                                <i className="fas fa-arrow-left"></i> Back to List
                            </Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        </>
    );
};

export default GameShowsViewPage;
