import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import { Link } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Glitch from 'glitch-javascript-sdk';
import FreeToolsSubmenu from '../../component/section/publishers/FreeToolsSubmenu';

const PublisherGameInfluencersPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [game, setGame] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        if (name && email) {
            setLoading(true); // Show spinner
            Glitch.api.Influencers.workbook({ name, email, game, format: 'xls' })
                .then(() => {
                    setModalVisible(false);
                    setSuccessMessage('The workbook has been sent to your email.');
                })
                .catch(error => {
                    console.error('Error generating download link:', error);
                })
                .finally(() => {
                    setLoading(false); // Hide spinner
                });
        }
    };

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Free Influencer Marketing Tools & Database | Glitch</title>
                <meta name="description" content="Access free influencer marketing tools and a curated influencer database to boost your game's visibility. Find and reach out to gaming influencers easily." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Free Influencer Marketing Tools & Database | Glitch" />
                <meta property="og:description" content="Browse and download contact details of gaming influencers for free. Get tools to manage influencer outreach and campaigns." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="/assets/images/influencers/influencers.webp" />
            </Helmet>
            <Header />

            <div className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <br /><br />
                <h1 style={{ fontSize: "2.5rem" }}>Find Gaming Influencers for Free</h1>
            </div>

            <div className="container mt-5">
                <FreeToolsSubmenu />
            </div>

            <div className="container mt-5">
                <p className='lead'>Download our influencer marketing workbook to help manage your outreach and collaborations with gaming influencers.</p>

                {successMessage && (
                    <div className="alert alert-success">
                        {successMessage}
                    </div>
                )}

                <div className='mb-3'>
                    <button className="btn btn-success" onClick={() => setModalVisible(true)}>
                        <FontAwesomeIcon icon={faDownload} /> Download Workbook
                        {loading && <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>}
                    </button>
                </div>

                <p className='lead'>
                    You can use Glitch's influencer database for free. <Link to={Navigate.publishersOnboardingStep1Page()}>Sign up here</Link> to access our growing list of gaming influencers.
                </p>

                <p className='lead'>
                    New to influencer marketing? Download our workbook and watch the video below to learn how to effectively manage the influencer outreach process.
                </p>

                <div className="ratio ratio-16x9 mb-4 border border-white border-2">
                    <iframe
                        src="https://www.youtube.com/embed/faIw7vgukHs"
                        title="How to Send Invites to Influencers"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>

            {modalVisible && (
                <div className="modal show d-block" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-black">Download Workbook (Emailed to You)</h5>
                                <button type="button" className="btn-close" onClick={() => setModalVisible(false)}></button>
                            </div>
                            <div className="modal-body text-black">
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Name</label>
                                    <input type="text" id="name" className="form-control" value={name} onChange={e => setName(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="email" id="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="game" className="form-label">Game (Optional)</label>
                                    <input type="text" id="game" className="form-control" value={game} onChange={e => setGame(e.target.value)} />
                                </div>
                                <p className="text-muted">Note: The list will be emailed to you and updated monthly.</p>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" onClick={handleDownload}>
                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ) : (
                                        'Download'
                                    )}
                                </button>
                                <button className="btn btn-secondary" onClick={() => setModalVisible(false)}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Footer />
        </>
    );
};

const bannerStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/influencers/influencers.webp")`,
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublisherGameInfluencersPage;
