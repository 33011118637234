import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faCalendarAlt, faUsers, faGlobe, faLink, faGamepad, faEnvelope, faRobot, faDatabase, faMoneyBill, faGift, faThumbsUp, faTools, faDollarSign, faChartLine, faStar, faComments, faVial } from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function PublishersShowcasesPage() {

    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Host Your Own Gaming Showcase | Glitch</title>
                <meta name="description" content="Elevate your game's visibility by hosting virtual showcases. Register developers and attendees, integrate with Glitch Community and Newsletter, and connect with your audience." />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Host Your Own Gaming Showcase | Glitch" />
                <meta property="og:description" content="Elevate your game's visibility by hosting virtual showcases. Register developers and attendees, integrate with Glitch Community and Newsletter, and connect with your audience." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={currentUrl} />
                <meta property="og:image" content="https://www.glitch.fun/assets/images/publishers/media_kit_banner.webp" />
            </Helmet>
            <Header />
            <section className="container-fluid text-center text-white py-5" style={bannerStyle}>
                <h1 style={{ fontSize: "2.5rem" }}><br /><br />Host Your Own<br /><br />Gaming Showcase</h1>
            </section>

            {/* Main Content */}
            <section className="py-5">
                <div className="container">

                    {/* Statistics Section */}
                    <div className="mb-5 text-center">
                        <h2 className="mb-4">Why Host a Gaming Showcase?</h2>
                        <p className="lead">Participating in game showcases is an excellent opportunity to gain exposure and connect with players and industry professionals.</p>
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faChartLine} size="3x" className="text-success mb-3" />
                                <h4>100% Increase in Pre-Orders</h4>
                                <p>Games showcased at major events can see a 100% boost in pre-orders.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faUsers} size="3x" className="text-primary mb-3" />
                                <h4>370,000 Visitors</h4>
                                <p>Events like Gamescom attract massive audiences, enhancing brand visibility.</p>
                            </div>
                            <div className="col-md-4">
                                <FontAwesomeIcon icon={faComments} size="3x" className="text-warning mb-3" />
                                <h4>85% Increased Engagement</h4>
                                <p>Gamers feel more connected after experiencing a game at a showcase.</p>
                            </div>
                        </div>
                    </div>

                    {/* Feature 1 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4">Host Virtual Showcases</h2>
                            <p className="lead">Create and host virtual gaming showcases to highlight your game and connect with players worldwide.</p>
                            <ul className="lead">
                                <li><FontAwesomeIcon icon={faGlobe} className="me-2 text-primary" /> Global Reach</li>
                                <li><FontAwesomeIcon icon={faCalendarAlt} className="me-2 text-success" /> Schedule Events</li>
                            </ul>
                            <form className="contact-form mt-4" id="contact-form" method="POST">
                                <div className="form-group w-100 text-center">
                                    <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Get Started for Free</span></Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <div className="ratio ratio-16x9 rounded shadow mb-3 border border-white border-2">
                                <video className="w-100 rounded shadow" src="/assets/videos/publishers/game_competition.mp4" autoPlay loop muted playsInline></video>
                            </div>
                        </div>
                    </div>

                    {/* Feature 2 */}
                    <div className="row align-items-center mb-5 bg-light p-4 rounded">
                        <div className="col-lg-6 order-lg-2 text-black">
                            <h2 className="mb-4 text-black">Developer and Attendee Registration</h2>
                            <p className="lead">Manage registrations for developers showcasing their games and attendees eager to participate.</p>
                            <ul className="lead">
                                <li><FontAwesomeIcon icon={faUsers} className="me-2 text-info" /> Easy Sign-Up Process</li>
                                <li><FontAwesomeIcon icon={faGamepad} className="me-2 text-danger" /> Showcase Multiple Games</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <img src="/assets/images/publishers/showcase_registration.png" alt="Developer Registration" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* Feature 3 */}
                    <div className="row align-items-center mb-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4">Integrated with Glitch Community and Newsletter</h2>
                            <p className="lead">Enhance engagement by integrating your showcase with Glitch's gaming community and newsletter features.</p>
                            <ul className="lead">
                                <li><FontAwesomeIcon icon={faLink} className="me-2 text-warning" /> Seamless Integration</li>
                                <li><FontAwesomeIcon icon={faEnvelope} className="me-2 text-success" /> Promote via Newsletters</li>
                            </ul>
                            <form className="contact-form mt-4" id="contact-form" method="POST">
                                <div className="form-group w-100 text-center">
                                    <Link className="default-button reverse-effect" to={Navigate.publishersOnboardingStep1Page()}><span>Sign Up Now</span></Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/publishers/showcase_integration.gif" alt="Community and Newsletter Integration" className="img-fluid rounded shadow mb-3 border border-white border-2" />
                        </div>
                    </div>

                    {/* CTA Section */}
                    <div className='pt-5 pb-5 bg-dark text-white text-center rounded' style={{ backgroundImage: "url(/assets/images/cta/bg.jpg)" }}>
                        <h2 className="text-danger mb-4">Boost Your Game's Visibility</h2>
                        <p className="lead">Games presented at showcases are 50% more likely to secure funding and receive extensive media coverage.</p>
                        <Link to={Navigate.publishersOnboardingStep1Page()} className="btn btn-lg btn-danger text-white">Host a Showcase Now</Link>
                        <p className="lead mt-4">Discover more about the benefits of hosting a gaming showcase.</p>
                        <a href="https://blog.glitch.fun/hosting-gaming-showcases/" target='_blank' className="btn btn-outline-light">Read Full Article</a>
                    </div>

                    {/* Learn More Section */}
                    <div className="container text-center my-5">
                        <h4>Learn More About Glitch's Features</h4>
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                            <Link
                                to={Navigate.publishersAIPage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faRobot} className="me-1" /> AI
                            </Link>
                            <Link
                                to={Navigate.publishersCRMPage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faUsers} className="me-1" /> CRM
                            </Link>
                            <Link
                                to={Navigate.publishersDatabasePage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faDatabase} className="me-1" /> Database
                            </Link>
                            <Link
                                to={Navigate.publishersBenefitsPage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faThumbsUp} className="me-1" /> Performance-Based
                            </Link>
                            <Link
                                to={Navigate.publishersOptimizationPage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faTools} className="me-1" /> Optimization
                            </Link>
                            <Link to={Navigate.publishersContentPage()} className="btn btn-outline-primary mx-2 mb-2">
                                <FontAwesomeIcon icon={faGamepad} className="me-1" /> Diverse Content
                            </Link>
                            <Link
                                to={Navigate.publishersAcquisitionPage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faDollarSign} className="me-1" /> Acquisition Costs
                            </Link>
                            <Link to={Navigate.publishersPlayTesterPage()} className="btn btn-outline-primary mx-2 mb-2">
                                <FontAwesomeIcon icon={faVial} className="me-1" /> Play Testers
                            </Link>
                            <Link
                                to={Navigate.publishersToolsPage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faGift} className="me-1" /> Free Tools
                            </Link>
                            <Link to={Navigate.publishersPlayTesterPage()} className="btn btn-outline-primary mx-2 mb-2">
                                <FontAwesomeIcon icon={faVial} className="me-1" /> Play Testers
                            </Link>
                            <Link
                                to={Navigate.publishersPricingPage()}
                                className="btn btn-outline-primary mx-2 mb-2"
                            >
                                <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> Pricing
                            </Link>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </>
    );
}

const bannerStyle = {
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/media_kit_banner.webp")',
    height: '500px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)'
};

export default PublishersShowcasesPage;
