import React, { useState, useEffect, Fragment } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import PublisherHeader from '../../component/layout/publisherheader';
import timeouts from '../../../../constants/timeouts';
import Danger from '../../component/alerts/Danger';
import Breadcrumbs from '../../component/layout/breadcrumb';
import GameTitleForm from '../../component/section/titles/title_form';

const PlayTestGamesUpdatePage = () => {
    const [gameTitle, setGameTitle] = useState(null);
    const [communities, setCommunities] = useState([]);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [gameMainImageBlob, setMainImageBlob] = useState(null);
    const [gameBannerImageBlob, setBannerImageBlob] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        Glitch.api.Communities.list({
            roles: [
                Glitch.constants.Roles.ADMINISTRATOR,
                Glitch.constants.Roles.SUPER_ADMINISTRATOR,
                Glitch.constants.Roles.MODERATOR,
            ],
            order_by: 'name',
        })
            .then(response => {
                setCommunities(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching communities:', error);
            });

        Glitch.api.Titles.view(id)
            .then((response) => {
                setGameTitle(response.data.data);
            })
            .catch((error) => {
                console.error('Error fetching gameTitle', error);
            });
    }, [id, navigate]);

    const handleSubmit = async () => {
        setIsLoading(true);

        try {
            // Ensure is_media_kit is set to true
            const gameTitleData = { ...gameTitle, is_media_kit: true };

            await Glitch.api.Titles.update(gameTitle.id, gameTitleData);

            const uploadPromises = [];

            if (gameMainImageBlob) {
                uploadPromises.push(
                    Glitch.api.Titles.uploadMainImageBlob(gameTitle.id, gameMainImageBlob)
                );
            }

            if (gameBannerImageBlob) {
                uploadPromises.push(
                    Glitch.api.Titles.uploadBannerImageBlob(gameTitle.id, gameBannerImageBlob)
                );
            }

            if (uploadPromises.length > 0) {
                await Promise.all(uploadPromises);
            }

            navigate(Navigate.playtestingPublishersViewPage(gameTitle.id));
        } catch (error) {
            const jsonErrors = error?.response?.data;
            if (jsonErrors) {
                setErrors(jsonErrors);
                setTimeout(() => {
                    setErrors({});
                }, timeouts.error_message_timeout);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Test', link: Navigate.playtestingPublishersListPage() },
                        { name: gameTitle?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                        { name: 'Update Game', link: Navigate.playtestingPublishersUpdatePage(id) },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Update Your Game Title</h2>
                        <p className="lead">Update the details about your game title for the play testers.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {gameTitle ? (
                    <GameTitleForm
                        gameTitle={gameTitle}
                        campaignData={{}}
                        setGameTitle={setGameTitle}
                        setMainImageBlob={setMainImageBlob}
                        setBannerImageBlob={setBannerImageBlob}
                        communities={communities}
                        errors={errors}
                        showPlayTest={true}
                        isCreate={false}
                    />
                ) : (
                    <Loading />
                )}

                {errors && Object.keys(errors).length > 0 && (
                    <Danger message="You have errors in your submission." />
                )}

                <div className="mt-4 text-center">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-success mx-2"
                        disabled={isLoading}
                    >
                        {isLoading ? <Loading /> : 'Update Game'}
                    </button>
                </div>
            </div>
        </Fragment>
    );
};

export default PlayTestGamesUpdatePage;
