import React, { useEffect, useState } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Navigate from '../../../../util/Navigate';
import Loading from '../../component/alerts/Loading';
import Breadcrumbs from '../../component/layout/breadcrumb';
import Alerts from '../../../../util/Alerts';
import Wysiwyg from '../../component/form/wysiwyg';
import RequiredAsterisk from '../../component/form/required_asterisk';
import Danger from '../../component/alerts/Danger';

const PlayTestManageQuestionsPage = () => {
    const [playTest, setPlayTest] = useState(null);
    const [title, setTitle] = useState(null);
    const [errors, setErrors] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedQuestions, setSelectedQuestions] = useState({});
    const { id, playtest_id } = useParams(); // title_id, playtest_id
    const navigate = useNavigate();

    const questionsList = [
        { id: 1, text: 'Was the game easy to understand from the start?' },
        { id: 2, text: 'Were there any mechanics that felt confusing or difficult to use?' },
        { id: 3, text: 'How challenging did you find the game overall: too easy, too hard, or just right?' },
        { id: 4, text: 'Did you encounter any bugs or issues during gameplay?' },
        { id: 5, text: 'Were the controls intuitive and responsive? If not, what improvements would you suggest?' },
        { id: 6, text: 'What aspects of the game did you enjoy the most? Why?' },
        { id: 7, text: 'Were there any parts of the game that felt repetitive or boring?' },
        { id: 8, text: 'How long did you feel engaged with the game before it started to lose your attention?' },
        { id: 9, text: 'Would you want to play this game again? Why or why not?' },
        { id: 10, text: 'How likely would you be to recommend this game to a friend?' },
        { id: 11, text: 'Did you feel invested in the story or the game world?' },
        { id: 12, text: 'Were there any characters, settings, or story elements that stood out to you?' },
        { id: 13, text: 'Was there anything about the world or story that felt unclear or hard to follow?' },
        { id: 14, text: 'How would you rate the game’s visuals? Did anything stand out as particularly impressive or lacking?' },
        { id: 15, text: 'How did the audio (music, sound effects, voice acting, etc.) contribute to the experience?' },
        { id: 16, text: 'Were there any audio or visual elements that distracted you from the gameplay?' },
        { id: 17, text: 'Did you experience any difficulties related to visibility, accessibility, or ease of use?' },
        { id: 18, text: 'Were there any features or options you wish the game had to make it easier to play?' },
        { id: 19, text: 'How would you describe the game in a few sentences?' },
        { id: 20, text: 'What is one thing you would change or add to improve the game?' },
        { id: 21, text: 'What was your overall impression of the game?' }
    ];


    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            navigate(Navigate.authLogin());
            return;
        }

        const fetchData = async () => {
            try {
                // Fetch play test details
                const playTestResponse = await Glitch.api.PlayTests.show(id, playtest_id);
                setPlayTest(playTestResponse.data.data);

                // Fetch title details
                const titleResponse = await Glitch.api.Titles.view(id);
                setTitle(titleResponse.data.data);

                // Initialize selected questions
                const initialQuestions = {
                    developer_testing_requirements: playTestResponse.data.data.developer_testing_requirements,
                    user_access_key: playTestResponse.data.data.user_access_key,
                };
                questionsList.forEach((q) => {
                    initialQuestions[`q_${q.id}_enabled`] = playTestResponse.data.data[`q_${q.id}_enabled`] || false;
                });
                setSelectedQuestions(initialQuestions);
            } catch (error) {
                console.error('Error fetching data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id, playtest_id, navigate]);

    const handleQuestionChange = (e) => {
        const { name, checked } = e.target;
        setSelectedQuestions((prev) => ({ ...prev, [name]: checked }));
    };

    const handleInputChange = (e) => {
        setSelectedQuestions({ ...selectedQuestions, [e.target.name]: e.target.value });
    };

    const handleWysiwigInputChange = (name, value) => {
        setSelectedQuestions(selectedQuestions => ({ ...selectedQuestions, [name]: value }));

    };

    const handleSaveChanges = async () => {
        try {
            await Glitch.api.PlayTests.updateQuestions(id, playtest_id, selectedQuestions);

            Alerts.display("Success", 'Questions updated successfully.')
        } catch (error) {
            const message = error?.response?.data?.error;

            if (message) {
                Alerts.display("Update Failed", message);
            } else {
                alert('Failed to update questions.');
            }
        }
    };

    return (
        <>
            <PublisherHeader position="relative" />
            <div className="container mt-4">
                <Breadcrumbs
                    items={[
                        { name: 'Play Testing', link: Navigate.playtestingPublishersListPage() },
                        { name: title?.name || 'Loading...', link: Navigate.playtestingPublishersViewPage(id) },
                        { name: 'Update Test Questions', link: Navigate.playtestingPublishersViewQuestionsPage(id, playtest_id) },
                    ]}
                />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title">Manage Play Test Requirements & Questions</h2>
                        <p className="lead">Enable or disable questions for this play test.</p>
                    </div>
                </div>
            </section>

            <div className="container mt-5 mb-5">
                {isLoading ? (
                    <Loading />
                ) : playTest ? (
                    <>

                        <div className="card mb-5">
                            <div className="card-header text-black">
                                <h3 className='text-black'>Key And Requirements</h3>
                            </div>
                            <div className="card-body">
                                <p className='lead'>Manage the key and your requirements for the tester here.</p>

                                <div className="row">
                                    {createInput(
                                        'Key',
                                        'user_access_key',
                                        selectedQuestions?.user_access_key,
                                        handleInputChange,
                                        'text',
                                        'fas fa-key',
                                        errors,
                                        'If a key is required to access the game, enter it here.',
                                        false
                                    )}

                                    {createTextarea(
                                        'Testing Requirements',
                                        'developer_testing_requirements',
                                        selectedQuestions?.developer_testing_requirements,
                                        {},
                                        errors,
                                        'Enter any requirements that you want done for this play test by the tester.',
                                        true
                                    )}
                                </div>

                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header text-black">
                                <h3 className='text-black'>Questions</h3>
                            </div>
                            <div className="card-body">
                                <p className='lead'>For the user, you can select which questions you'd like them to answer. Aim to choose around five questions, as asking too many can reduce the number of responses you receive from testers.</p>

                                <div className="form-check">
                                    {questionsList.map((question) => (
                                        <div key={question.id} className="mb-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`question_${question.id}`}
                                                name={`q_${question.id}_enabled`}
                                                checked={selectedQuestions[`q_${question.id}_enabled`] || false}
                                                onChange={handleQuestionChange}
                                            />
                                            <label className="form-check-label" htmlFor={`question_${question.id}`}>
                                                {question.text}
                                            </label>
                                        </div>
                                    ))}
                                </div>

                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <button className="btn btn-primary btn-lg" onClick={handleSaveChanges}>
                                Save Changes
                            </button>
                        </div>
                    </>
                ) : (
                    <p>Play test not found.</p>
                )}
            </div>
        </>
    );

    function createInput(
        label,
        name,
        value,
        handleChange,
        type = 'text',
        icon,
        errors,
        description = '',
        required = false
    ) {
        return (
            <div className="col">
                <div className="form-group mb-3">
                    <label htmlFor={name}>
                        <i className={`${icon} mr-2`}></i> &nbsp;{label} {required ? <RequiredAsterisk /> : ''}
                    </label>
                    <p className="small">{description}</p>
                    <input
                        type={type}
                        className="form-control"
                        id={name}
                        name={name}
                        value={value || ''}
                        onChange={handleChange}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }

    function createTextarea(label, name, value, handleChange, errors, description, required = false) {
        return (
            <div className="col-md-12">
                <div className="mb-3">
                    <label htmlFor={name}>
                        {label} {required && <RequiredAsterisk />}
                    </label>
                    <p className="small">{description}</p>
                    <Wysiwyg
                        children={value || ''}
                        name={name}
                        id={name}
                        onChange={(value) => {
                            handleWysiwigInputChange(name, value);
                        }}
                    />
                    {errors && errors[name] && errors[name].map((message, index) => (
                        <Danger message={message} key={index} />
                    ))}
                </div>
            </div>
        );
    }
};

export default PlayTestManageQuestionsPage;
