import React from "react";



export default function Warning({ message }) {
    const displayMessage = typeof message === 'object' ? JSON.stringify(message) : message;
    return (
        <>
            <div className="alert alert-warning" role="alert">
                {displayMessage}
            </div>
        </>
    );
}
