import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalculator,
  faGamepad,
  faUsers,
  faBook,
  faDatabase,
  faBullhorn,
  faCalendar,
  faMap
} from '@fortawesome/free-solid-svg-icons';
import Navigate from '../../../../../util/Navigate';

const FreeToolsSubmenu = () => {
  const location = useLocation();

  // Determine the current path to highlight the correct menu item
  const isActive = (path) => location.pathname === path;

  return (
    <div className="container-fluid py-3 submenu-container">
      {/* Mobile Toggle Button */}
      <div className="d-lg-none text-center mb-2">
        <button
          className="btn btn-outline-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#submenuCollapse"
          aria-expanded="false"
          aria-controls="submenuCollapse"
        >
          Free Tools Menu
        </button>
      </div>

      {/* Submenu Items */}
      <div className="collapse d-lg-block" id="submenuCollapse">
        <div className="submenu-wrapper text-center">
          <ul className="nav submenu-nav justify-content-center">
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsGuidePage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsGuidePage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faMap} className="me-2" /> Guide
              </Link>
            </li>
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsPricingPage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsPricingPage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faCalculator} className="me-2" /> Pricing Calculator
              </Link>
            </li>
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsDatabasePage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsDatabasePage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faDatabase} className="me-2" /> Databases
              </Link>
            </li>
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsPRPage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsPRPage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faGamepad} className="me-2" /> Gaming PR
              </Link>
            </li>
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsInfluencersPage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsInfluencersPage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faBullhorn} className="me-2" /> Influencers
              </Link>
            </li>
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsForumPage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsForumPage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faUsers} className="me-2" /> Communities
              </Link>
            </li>
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsShowcasesPage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsShowcasesPage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faCalendar} className="me-2" /> Showcases
              </Link>
            </li>
            <li className="nav-item" style={{marginLeft: 0}}>
              <Link
                to={Navigate.publishersToolsWikiPage()}
                className={`nav-link submenu-link ${
                  isActive(Navigate.publishersToolsWikiPage()) ? 'active' : ''
                }`}
              >
                <FontAwesomeIcon icon={faBook} className="me-2" /> Wikis
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FreeToolsSubmenu;
