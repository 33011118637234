import React from 'react';

const MessageParticipantsList = ({ users }) => {
  return (
    <div className="participants-list mt-4 mb-5">
      <h3>Participants</h3>
      <div className="d-flex">
        {users.map(user => (
          <div key={user.id} className="participant me-3 text-center">
            <img 
              src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} 
              alt={user.username} 
              className="rounded-circle mb-1" 
              style={{ width: '60px', height: '60px', objectFit: 'cover' }}
            />
            <p className="small mb-0">{user.display_name || user.username}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MessageParticipantsList;
