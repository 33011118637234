import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navigate from '../../../../../util/Navigate';

const CampaignNavbar = ({ campaignId }) => {
    const location = useLocation();

    // Function to check if the current route matches the given path
    const isActive = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav small">
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsViewPage(campaignId))}`} to={Navigate.campaignsViewPage(campaignId)}>
                                <i className="fas fa-eye"></i> Manage
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsUpdatePage(campaignId))}`} to={Navigate.campaignsUpdatePage(campaignId)}>
                                <i className="fas fa-edit"></i> Edit
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsInfluencersPage(campaignId))}`} to={Navigate.campaignsInfluencersPage(campaignId)}>
                                <i className="fas fa-users"></i> Influencers
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsFindInfluencers(campaignId))}`} to={Navigate.campaignsFindInfluencers(campaignId)}>
                                <i className="fas fa-search"></i> Find
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsRecommendedInfluencers(campaignId))}`} to={Navigate.campaignsRecommendedInfluencers(campaignId)}>
                                <i className="fas fa-thumbs-up"></i> Recommended
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsInvitesPage(campaignId))}`} to={Navigate.campaignsInvitesPage(campaignId)}>
                                <i className="fas fa-envelope"></i> Invites
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsContentPage(campaignId))}`} to={Navigate.campaignsContentPage(campaignId)}>
                                <i className="fas fa-file-alt"></i> Content
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsStatisticsPage(campaignId))}`} to={Navigate.campaignsStatisticsPage(campaignId)}>
                                <i className="fas fa-chart-bar"></i> Stats
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsStartPage(campaignId))}`} to={Navigate.campaignsStartPage(campaignId)}>
                                <i className="far fa-check-square"></i> Checklist
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${isActive(Navigate.campaignsLedgerPage(campaignId))}`} to={Navigate.campaignsLedgerPage(campaignId)}>
                                <i className="fas fa-dollar-sign"></i> Ledger
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default CampaignNavbar;
