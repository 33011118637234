import React from "react";



export default function Info({ message }) {
    const displayMessage = typeof message === 'object' ? JSON.stringify(message) : message;

    return (
        <>
            <div className="alert alert-info" role="alert">
                {displayMessage}
            </div>
        </>
    );
}
