import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faUserCheck,
  faBullhorn,
  faHandshake,
  faThumbsUp,
  faTools,
  faGamepad,
  faDollarSign,
  faRobot,
  faDatabase,
  faMoneyBill,
  faUsers,
  faGift,
  faEnvelope,
  faShareAlt,
  faCheck,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import Header from '../../component/layout/header';
import Footer from '../../component/layout/footer';
import Navigate from '../../../../util/Navigate';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function PublisherPricingPage() {
  const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Platform Pricing | Glitch</title>
        <meta
          name="description"
          content="Discover the cost-effective pricing plans for Glitch's performance-based influencer marketing services. Maximize your game's exposure with authentic, quality content from micro-influencers."
        />
        <meta name="robots" content="index, follow" />
        <meta
          property="og:title"
          content="Platform Pricing For Influencer Marketing | Glitch"
        />
        <meta
          property="og:description"
          content="Unlock the power of performance-based influencer marketing for your game. Glitch offers innovative solutions for authenticity, quality content, and optimized platform utilization to maximize game exposure and engage effectively with micro-influencers."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta
          property="og:image"
          content="https://www.glitch.fun/assets/images/publishers/banner_3.png"
        />
        <meta
          name="keywords"
          content="influencer marketing, game marketing, performance-based marketing, micro-influencers, marketing pricing plans, Glitch"
        />
        <link rel="canonical" href={currentUrl} />
      </Helmet>
      <Header />
      <div className="container-fluid" style={bannerStyle}>
        <br />
        <br />
        <h1 style={{ fontSize: '2.5rem' }}>
          Platform Pricing For <br />
          <br />
          Game Publishers and Indie Developers
        </h1>
      </div>

      <section className="pricing-chart-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>How Pricing Works</h2>
              <p>
                Monthly pricing is based on the number of content creators you
                choose to work with, after you have approved them to begin
                creating content for your campaign. You will be able to work
                with the number of influencers for your package price, and any
                number over that will incur an overcharge.
              </p>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col" className="text-center">
                        Monthly Plan
                      </th>
                      <th scope="col" className="text-center">
                        Free
                      </th>
                      <th scope="col" className="text-center">
                        Solo Dev Plan
                        <br />
                        2 Active Influencers Per Month
                      </th>
                      <th scope="col" className="text-center">
                        Small Team Plan
                        <br />
                        10 Active Influencers Per Month
                      </th>
                      <th scope="col" className="text-center">
                        Studio Plan
                        <br />
                        25 Active Influencers Per Month
                      </th>
                      <th scope="col" className="text-center">
                        Publisher Plan
                        <br />
                        40 Active Influencers Per Month
                      </th>
                      <th scope="col" className="text-center">
                        Enterprise
                        <br />
                        50+ Active Influencers Per Month
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Price</td>
                      <td>Free</td>
                      <td>$20 / month</td>
                      <td>$240 / month</td>
                      <td>$450 / month</td>
                      <td>$840 / month</td>
                      <td>Call or Email</td>
                    </tr>
                    <tr className="table-secondary">
                      <td colSpan="7">
                        <strong>Influencer Features</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Search Database</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                    </tr>
                    <tr>
                      <td>Active Influencers</td>
                      <td>0</td>
                      <td>2</td>
                      <td>10</td>
                      <td>25</td>
                      <td>40</td>
                      <td>50+</td>
                    </tr>
                    <tr>
                      <td>*Overcharge</td>
                      <td>-</td>
                      <td>$50</td>
                      <td>$35</td>
                      <td>$30</td>
                      <td>$20</td>
                      <td>Custom</td>
                    </tr>
                    <tr>
                      <td>**Outreach Credits</td>
                      <td>-</td>
                      <td>30</td>
                      <td>100</td>
                      <td>200</td>
                      <td>350</td>
                      <td>Custom</td>
                    </tr>
                    <tr>
                      <td>***Additional Credits</td>
                      <td>-</td>
                      <td>$1.50 per credit</td>
                      <td>$0.80 per credit</td>
                      <td>$0.60 per credit</td>
                      <td>$0.50 per credit</td>
                      <td>Custom</td>
                    </tr>
                    <tr className="table-secondary">
                      <td colSpan="7">
                        <strong>Community Features</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Community Forums</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                    </tr>
                    <tr>
                      <td>Gaming Showcases</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                    </tr>
                    <tr>
                      <td>Host Tournaments</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                    </tr>
                    <tr>
                      <td>Media Kits</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                    </tr>
                    <tr>
                      <td>Watermark On Showcases</td>
                      <td>Yes</td>
                      <td>No</td>
                      <td>No</td>
                      <td>No</td>
                      <td>No</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Watermark On Mediakit</td>
                      <td>Yes</td>
                      <td>No</td>
                      <td>No</td>
                      <td>No</td>
                      <td>No</td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Videos On MediaKit</td>
                      <td>No</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                      <td>Yes</td>
                    </tr>
                    <tr className="table-secondary">
                      <td colSpan="7">
                        <strong>Newsletter Features</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Newsletters Sign-Ups</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                    </tr>
                    <tr>
                      <td>Monthly Newsletter Emails</td>
                      <td>0</td>
                      <td>10,000</td>
                      <td>150,000</td>
                      <td>300,000</td>
                      <td>500,000</td>
                      <td>Custom</td>
                    </tr>
                    <tr className="table-secondary">
                      <td colSpan="7">
                        <strong>Play Testers Features</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Search Play Testers</td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                      </td>
                    </tr>
                    <tr>
                      <td>Play Tester Invites</td>
                      <td>0</td>
                      <td>15</td>
                      <td>30</td>
                      <td>45</td>
                      <td>60</td>
                      <td>100+</td>
                    </tr>
                    <tr>
                      <td>Register</td>
                      <td>
                        <Link
                          className="text-info text-bold"
                          to={Navigate.publishersOnboardingStep1Page()}
                        >
                          Register
                        </Link>
                      </td>
                      <td>
                        <Link
                          className="text-info text-bold"
                          to={Navigate.publishersOnboardingStep1Page()}
                        >
                          Choose Plan
                        </Link>
                      </td>
                      <td>
                        <Link
                          className="text-info text-bold"
                          to={Navigate.publishersOnboardingStep1Page()}
                        >
                          Choose Plan
                        </Link>
                      </td>
                      <td>
                        <Link
                          className="text-info text-bold"
                          to={Navigate.publishersOnboardingStep1Page()}
                        >
                          Choose Plan
                        </Link>
                      </td>
                      <td>
                        <Link
                          className="text-info text-bold"
                          to={Navigate.publishersOnboardingStep1Page()}
                        >
                          Choose Plan
                        </Link>
                      </td>
                      <td>
                        <a
                          href="https://calendly.com/ddixon/30min?back=1&month=2024-10"
                          className="text-info text-bold"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Schedule Meeting
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <strong>Active Influencers:</strong> The active influencers
                  count represents the number of influencers considered active
                  on a campaign in a given month, and either are producing
                  content or their past content is be tracked. If the number of
                  active influencers exceeds the allotted amount, an overcharge
                  fee is incurred for each additional influencer.
                </p>
                <p>
                  <strong>*Overcharge:</strong> The overcharge is if you work
                  with more influencers than allotted in your package. You will
                  be charged the overcharge for each additional influencer you
                  choose to work with.
                </p>
                <p>
                  <strong>**Outreach Credits:</strong> You can invite influencers
                  to your campaign through our database. Each invite will count
                  as one credit.
                </p>
                <p>
                  <strong>***Additional Credits:</strong> The cost of each
                  additional credit to send an invite once your monthly limit is
                  used up.
                </p>

                <hr />
                <h3>Free Features</h3>
                <p>
                  We offer a lot of free features that you can use without a
                  subscription. Some of the features include:
                </p>
                <ul>
                  <li>Creating a Campaign (With or Without AI)</li>
                  <li>Searching Through Our Influencer Database</li>
                  <li>Getting Recommended Influencers for Your Campaign</li>
                  <li>Viewing Influencer Statistics</li>
                  <li>Generating Campaign Assets</li>
                  <li>30-Minute Consultancy</li>
                  <li>And More!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Managed Services Section */}
      <section className="managed-services-section py-5 bg-light text-black">
        <div className="container">
          <h2 className="text-center mb-4 text-black">Managed Services</h2>
          <p className="text-center lead">
            Let us handle the heavy lifting while you focus on developing amazing
            games. Our managed services include:
          </p>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <ul className="list-group list-group-flush mb-4">
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faEnvelope} className="me-2 text-primary" />{' '}
                  Manage Newsletter Sending
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faShareAlt} className="me-2 text-primary" />{' '}
                  Manage Social Media
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faBullhorn} className="me-2 text-primary" />{' '}
                  Manage Influencer Outreach
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faUsers} className="me-2 text-primary" />{' '}
                  Manage Community Engagement
                </li>
                <li className="list-group-item">
                  <FontAwesomeIcon icon={faGamepad} className="me-2 text-primary" />{' '}
                  Manage Gaming Showcases
                </li>
              </ul>
              <p className="text-center lead">
                Management plans for indie developers start as low as{' '}
                <strong>$150 per month</strong>. For enterprises, please call us to
                discuss your needs.
              </p>
              <div className="text-center">
                <a
                  href="https://calendly.com/ddixon/30min?back=1&month=2024-10"
                  className="btn btn-primary btn-lg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Schedule a Meeting
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-center mb-4">
        <h4>Learn More About Performance-Based Influencer Marketing</h4>
        <div className="d-flex justify-content-center align-items-center flex-wrap">
          <Link
            to={Navigate.publishersAIPage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faRobot} className="me-1" /> AI
          </Link>
          <Link
            to={Navigate.publishersCRMPage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faUsers} className="me-1" /> CRM
          </Link>
          <Link
            to={Navigate.publishersDatabasePage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faDatabase} className="me-1" /> Database
          </Link>
          <Link
            to={Navigate.publishersBenefitsPage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faThumbsUp} className="me-1" /> Performance-Based
          </Link>
          <Link
            to={Navigate.publishersOptimizationPage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faTools} className="me-1" /> Optimization
          </Link>
          <Link to={Navigate.publishersContentPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faGamepad} className="me-1" /> Diverse Content
          </Link>
          <Link
            to={Navigate.publishersAcquisitionPage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faDollarSign} className="me-1" /> Acquisition
            Costs
          </Link>
          <Link to={Navigate.publishersPlayTesterPage()} className="btn btn-outline-primary mx-2 mb-2">
            <FontAwesomeIcon icon={faVial} className="me-1" /> Play Testers
          </Link>
          <Link
            to={Navigate.publishersToolsPage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faGift} className="me-1" /> Free Tools
          </Link>
          <Link
            to={Navigate.publishersPricingPage()}
            className="btn btn-outline-primary mx-2 mb-2"
          >
            <FontAwesomeIcon icon={faMoneyBill} className="me-1" /> Pricing
          </Link>
        </div>
      </div>

      <div
        className="pt-5 pb-5 bg-dark text-white text-center rounded"
        style={{ backgroundImage: 'url(/assets/images/video/bg.jpg)' }}
      >
        <h2 className="text-danger mb-4">
          Sign Up to Utilize Our Free Features
        </h2>
        <p className="lead">
          Give our free features a try to see how they can help you begin your
          journey with influencer marketing.
        </p>
        <Link
          to={Navigate.publishersOnboardingStep1Page()}
          className="btn btn-lg btn-danger text-white"
        >
          Sign Up Now
        </Link>
      </div>
      <Footer />
    </>
  );
}

const bannerStyle = {
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/assets/images/publishers/banner_3.png")',
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '2.5rem',
  fontWeight: 'bold',
  textAlign: 'center',
  textShadow: '3px 3px 6px rgba(0, 0, 0, 0.9)',
};

export default PublisherPricingPage;
